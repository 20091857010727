import {CONSOLE_DEBUG} from '../consties'

export function format(source: string, parameterObject: any): string {
    let target = source
    if (parameterObject) {
        for (let key in parameterObject) {
            let urlKey = '{' + key + '}'
            let urlValue = parameterObject[key]
            target = target.replaceAll(urlKey, urlValue ? urlValue : false)
        }
    }
    if (CONSOLE_DEBUG) console.debug(source, ' + ', parameterObject,
        ' ==> ' + target)
    return target
}

export function toShortString(source: string | null, length: number): string {
    if (!source) {
        return ''
    }
    let shortString = source.substr(0, length)
    if (shortString.length < source.length) {
        shortString += '...'
    }
    return shortString
}

export function toHours(source: number | null): string {
    return toFixedNumber(source) + 'h'
}

export function toFixedNumber(source: number | null): string {
    if (!source) {
        return '0,00'
    }
    return source.toLocaleString('de-de', {
        minimumFractionDigits: 2, maximumFractionDigits: 2
    })
}

export function encodeHtml(source: any): string {
    if (!source) {
        return "";
    }
    return source.replaceAll("<", "&lt;").replaceAll(">", "&gt;");
}

export function containsIgnoreCase(source: string | null, subString: any) {
    if (!source) {
        return false;
    }
    return source.trim()
        .toLocaleLowerCase()
        .indexOf((subString + "").trim().toLocaleLowerCase()) > -1;
}