import {onChange, onClick, onEnter, onKeyUp} from '../../tools/templateTools'
import {confirmYesNo, toStringOrEmpty, toNumber} from '../../tools/tools'
import {createNewFromTemplate, scanForTemplates} from '../../tools/templates'
import {AbstractClass} from '../../tools/abstract_class'
import {ITaskTypeAbbreviationDto, taskTypeAbbreviationDataProvider, TaskTypeAbbreviationDto} from '../../model/tasktypeAbbreviation'

const TaskTypeAbbreviation_Html_Prefix = 'settings-tasktype-abbreviation-'
const Template_TaskTypeAbbreviation = TaskTypeAbbreviation_Html_Prefix + 'template'

class TaskTypeAbbreviationPage extends AbstractClass {
	private list: Array<ITaskTypeAbbreviationDto> = []
	private visible: Array<ITaskTypeAbbreviationDto> = []

	public fill(target: JQuery) {
		scanForTemplates([Template_TaskTypeAbbreviation])

		this.getContainerHtml().empty()
		this.list = []
		this.visible = []
		taskTypeAbbreviationDataProvider.loadListAll(taskTypes => {
			taskTypes.forEach(taskType => {
				this.list.push(taskType)
				this.visible.push(taskType)
			})

			setTimeout(() => {
				this.updateTable()
			}, 150)
		}, true)

		onEnter(this.getAddAbbreviationHtml(), this.handleAddNew)
		onEnter(this.getAddNameHtml(), this.handleAddNew)
		onClick(this.getAddBtnHtml(), this.handleAddNew)
		onKeyUp(this.getSearchHtml(), this.handleSearch)
	}

	private updateTable() {
		this.getContainerHtml().empty()
		this.visible.length && this.visible.forEach(taskType => this.createEntry(taskType))
	}

	private getSearchHtml(): JQuery {
		return this.getHtml('search')
	}

	private getAddBtnHtml(): JQuery {
		return this.getHtml('add-btn')
	}

	private getAddNameHtml(): JQuery {
		return this.getHtml('add-name')
	}

	private getAddAbbreviationHtml(): JQuery {
		return this.getHtml('add-abbreviation')
	}

	private getHtml(postFix: string): JQuery {
		return $('#' + TaskTypeAbbreviation_Html_Prefix + postFix)
	}

	private getContainerHtml(): JQuery {
		return this.getHtml('container')
	}

	private filterSearch(_v: string): Array<ITaskTypeAbbreviationDto> {
		const _transformString = (str: string | null | undefined) => (str ? str.replace(/\s/g, '').toLowerCase() : '')
		const _testValue = (str: string | null | undefined, search: string): boolean =>
			_transformString(str).includes(_transformString(search))

		const _filterAttr = (_c: ITaskTypeAbbreviationDto) => _testValue(_c.abbreviation, _v) || _testValue(_c.name, _v)

		return _v ? this.list.filter(item => _filterAttr(item)) : this.list
	}

	private handleSearch() {
		let search = this.getSearchHtml().val()
		search = toStringOrEmpty(search)
		this.visible = this.filterSearch(search)
		this.updateTable()
	}

	private handleAddNew() {
		let abbreviation = this.getAddAbbreviationHtml().val()
		let name = this.getAddNameHtml().val()
		abbreviation = toStringOrEmpty(abbreviation)
		name = toStringOrEmpty(name)
		if (name.length <= 0 || abbreviation.length <= 0) {
			return
		}
		this.getAddAbbreviationHtml().val('')
		this.getAddNameHtml().val('')
		this.createNewEntry(abbreviation, name)
	}

	private createNewEntry(abbreviation: string, name: string): JQuery {
		let taskType: ITaskTypeAbbreviationDto = new TaskTypeAbbreviationDto()

		taskType.abbreviation = abbreviation
		taskType.name = name

		taskTypeAbbreviationDataProvider.save(taskType)
		return this.createEntry(taskType)
	}

	private createEntry(taskTypeDto: ITaskTypeAbbreviationDto): JQuery {
		let template = createNewFromTemplate(Template_TaskTypeAbbreviation)
		let abbreviationHtml = template.find('.' + TaskTypeAbbreviation_Html_Prefix + 'abbreviation')
		let nameHtml = template.find('.' + TaskTypeAbbreviation_Html_Prefix + 'name')
		abbreviationHtml.val(toStringOrEmpty(taskTypeDto.abbreviation))
		nameHtml.val(toStringOrEmpty(taskTypeDto.name))

		onChange(abbreviationHtml, () => {
			taskTypeDto.abbreviation = toStringOrEmpty(abbreviationHtml.val())
			taskTypeAbbreviationDataProvider.save(taskTypeDto)
		})
		onChange(nameHtml, () => {
			taskTypeDto.name = toStringOrEmpty(nameHtml.val())
			taskTypeAbbreviationDataProvider.save(taskTypeDto)
		})

		let removeBtn = template.find('.' + TaskTypeAbbreviation_Html_Prefix + 'remove-btn')
		onClick(removeBtn, () => {
			if (!confirmYesNo('Wirklich löschen? ' + taskTypeDto.name)) {
				return
			}
			taskTypeAbbreviationDataProvider.delete(toNumber(taskTypeDto.id))
			template.remove()
		})

		this.getContainerHtml().append(template)
		return template
	}
}

export const taskTypeAbbreviationPage = new TaskTypeAbbreviationPage()
