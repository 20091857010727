import {onClick, onEnter} from '../../tools/templateTools'
import {CalendarSettingsDto, ICalendarSettingsDto} from '../../model/calendar_settings'
import {toStringOrEmpty, HashMap} from '../../tools/tools'
import {AbstractCalendarSettingsPage} from './abstract_settings_page'
import {createNewFromTemplate} from '../../tools/templates'
import {AbstractClass} from '../../tools/abstract_class'

const Template_Floor = 'settings-lage-floor-template'
const Template_Position = 'settings-lage-position-template'

class CalendarSettingsStringListPage extends AbstractCalendarSettingsPage {
	private templateId: string
	private list = new HashMap<JQuery>()
	private parameterName: string

	constructor(htmlIdPrefix: string, templateId: string, parameterName: string) {
		super(htmlIdPrefix, [templateId])
		this.templateId = templateId
		this.parameterName = parameterName
	}

	public customFill(target: JQuery, calendarSettings: CalendarSettingsDto) {
		this.list.clear()
		this.getContainerHtml().empty()

		this.getList(calendarSettings).forEach(value => {
			this.createEntry(value)
		})

		onEnter(this.getAddNameHtml(), this.handleNewEntry)
		onClick(this.getAddBtnHtml(), this.handleNewEntry)
	}

	private handleNewEntry() {
		let newValue = this.getAddNameHtml().val()
		newValue = toStringOrEmpty(newValue)
		if (newValue.length <= 0) {
			return
		}
		this.getAddNameHtml().val('')
		this.createEntry(newValue)
		//todo focus to the newHtml
	}

	protected getList(dto: ICalendarSettingsDto): string[] {
		let list: string[] = dto[this.parameterName]
		return list ? list : []
	}

	protected prepareForSave(dto: ICalendarSettingsDto, newList: string[]): void {
		dto[this.parameterName] = newList
	}

	protected save() {
		let dto = new CalendarSettingsDto()
		dto.id = -1
		let resultList = this.list
			.toArray()
			.map(html => toStringOrEmpty(html.val()))
			.filter(value => value.length > 0)
		this.prepareForSave(dto, resultList)
		this.dataProvider.save(dto)
	}

	private getContainerHtml(): JQuery {
		return this.getHtml('container')
	}

	private getAddBtnHtml(): JQuery {
		return this.getHtml('add-btn')
	}

	private getAddNameHtml(): JQuery {
		return this.getHtml('add-name')
	}

	private createEntry(value: string): JQuery {
		let template = createNewFromTemplate(this.templateId)
		let nameHtml = template.find('.' + this.htmlIdPrefix + 'name')
		nameHtml.val(toStringOrEmpty(value))
		let index = this.list.putWithIndex(nameHtml)

		let removeBtn = template.find('.' + this.htmlIdPrefix + 'remove-btn')
		onClick(removeBtn, () => {
			this.list.remove(index)
			template.remove()
		})

		this.getContainerHtml().append(template)
		return template
	}
}

export class LagePage extends AbstractClass {
	private floorPage = new CalendarSettingsStringListPage('settings-lage-floor', Template_Floor, 'floorNames')
	private positionPage = new CalendarSettingsStringListPage('settings-lage-position', Template_Position, 'positionNames')

	public fill(target: JQuery) {
		this.floorPage.fill(target)
		this.positionPage.fill(target)
	}
}

export const lagePage = new LagePage()
