import {ICallback, IDynmicObject} from './tools'
import {clearPageCache, currentUserProvider} from '../pages/page_manager'
import Cookies from 'js-cookie'
import {IUserDto} from '../model/user'
import {CONSOLE_DEBUG} from '../consties'
import {preventCloseInit} from './prevent_close'
import {initCalendar, initWeekCalendar} from './calendar_widget'
import {initMultiSelectLegacy} from './multi_select'

export var translations: IDynmicObject = {}

export const getUserLanguage = (): string => Cookies.get('language') ?? Cookies.set('language', 'de') ?? 'de'
export const setUserLanguage = (val = 'de'): void => {
	Cookies.set('language', val)
}

const getCurrentUser = (): IUserDto | {languageKey: string} =>
	currentUserProvider ? currentUserProvider.current.content : {languageKey: getUserLanguage()}

const responseValidator = (response: Response): IDynmicObject => {
	const _result = {}
	try {
		return response
			.json()
			.then((res: IDynmicObject) => res)
			.catch(err => {
				if (CONSOLE_DEBUG) console.warn('No such file')
				return _result
			})
	} catch (err) {
		if (CONSOLE_DEBUG) console.warn(err)
		return _result
	}
}

const getTranslationByKey = (key = 'de') => fetch(`/translations/${key}.json`).then(responseValidator)

const getUserTranslationOrDefault = async () => {
	const _userLang = await getTranslationByKey(getUserLanguage())
	if (Object.keys(_userLang).length > 0) {
		return _userLang
	}

	setUserLanguage()

	return await getTranslationByKey('de')
}

export async function loadTranslations(success: ICallback<void, void> | null = null) {
	const _translation = await getUserTranslationOrDefault()

	translations = _translation

	if (success) {
		success()
	}

	setPageTranslationTexts()
}

export function gt(textId: string, getNull = false): string {
	const result = translations[textId]
	const nullOrId = getNull ? null : textId
	return result ? result : nullOrId
}

export function gtObj(textId: string): {label: string; placeholder: string} {
	const result = translations[textId]
	return {
		label: result.label,
		placeholder: result.placeholder
	}
}

export function pageTitleGt(pageName: string): string {
	return gt(pageGt(pageName, 'title'))
}

export function pageNameGt(pageName: string): string {
	return gt(pageGt(pageName, 'name'))
}

export function pageGt(pageName: string, textId: string): string {
	return gt(pageTextId(pageName) + '.' + textId)
}

export function pageTextId(pageName: string): string {
	return 'page.' + pageName
}

const setTranslated = (elem: Element | HTMLInputElement, compare = true) => {
	elem.setAttribute('data-translate', `${compare}`)
}

const clearTranslated = () => {
	const _all = document.querySelectorAll('[data-translate="true"]')
	if (!_all.length) return
	_all.forEach(item => setTranslated(item, false))
}

const setTranslation = (elem: Element, text: string, compare = true) => {
	elem.textContent = `${text}`
	setTranslated(elem, compare)
}

const qsa = (key: string, parent: Element | Document = document) => {
	const _except = '[data-translate="true"]'
	return parent.querySelectorAll(`${key}:not([data-non-translate]):not(${_except})`)
}

const getGroupChildren = (group: Element): Element[] => {
	return qsa('[data-translation-key-item]', group).length > 0
		? [...qsa('[data-translation-key-item]', group)]
		: [...group.children].filter(child => !child.hasAttribute('data-non-translate'))
}

const haveSplitContent = (_item: HTMLElement, _content: string) => {
	if (!_item || !_content) return

	const _contentSplit = _content.split('|')
	const _split = qsa('[data-translation-key-split-item]', _item)
	_split.length &&
		_split.forEach((splitItem, id) => {
			const _word = _contentSplit.at(id) ? _contentSplit.at(id) : ''
			if (_word) {
				setTranslation(splitItem, _word)
			}
		})
}

const setTranslateDataSinglePluralItems = (_itemsPlural: NodeListOf<Element>) => {
	if (!_itemsPlural.length) return

	_itemsPlural.forEach(elem => {
		const _item = elem as HTMLElement
		const _keys = {
			single: _item.dataset.translationKeySingle,
			multiple: _item.dataset.translationKeyMultiple
		}
		const _content = {
			single: _keys.single ? gt(_keys.single) : '',
			multiple: _keys.multiple ? gt(_keys.multiple) : ''
		}

		if (_content.single && _content.multiple) {
			_item.dataset.contentSingle = _content.single
			_item.dataset.contentMultiple = _content.multiple
			_item.classList.toggle('card-header-label--plural', true)
			_item.textContent = ''
		} else {
			_item.classList.toggle('card-header-label--plural', false)
			_item.textContent = _content.multiple
		}
	})
}

const setTranslateDataContentItems = (_itemContent: NodeListOf<Element>) => {
	if (!_itemContent.length) return

	_itemContent.forEach(elem => {
		const _item = elem as HTMLElement
		const _key = _item.dataset.translationContentKey ?? ''
		const _content = gt(_key)
		if (_item.dataset.content) {
			_item.dataset.content = _content
		}
	})
}

const setTranslateItemsGroup = (_groups: NodeListOf<Element>) => {
	if (!_groups.length) return

	_groups.forEach(group => {
		const _key = group.getAttribute('data-translation-key-group')
		const _fields: Array<string> = _key ? translations[_key] : []
		const _childs = getGroupChildren(group)

		setTranslated(group, true)
		if (!_fields.length || !_childs.length) return

		_childs.forEach((item, index) => {
			const _item = item as HTMLElement
			const _content = _fields.at(index)
			if (!_content) return

			if (typeof _item.dataset.translationKeySplit !== 'undefined') {
				haveSplitContent(_item, _content)
			} else {
				setTranslation(_item, _content)
			}
		})
	})
}

export const setPageTranslationTexts = () => {
	const _all = {
		list: qsa('[data-translation-key]'),
		groups: qsa('[data-translation-key-group]'),
		dataContent: qsa('[data-translation-content-key]'),
		plural: qsa('[data-translation-key-single][data-translation-key-multiple]')
	}
	const _list = _all.list
	setTranslateItemsGroup(_all.groups)
	setTranslateDataContentItems(_all.dataContent)
	setTranslateDataSinglePluralItems(_all.plural)

	if (!_list.length) return

	_list.forEach(elem => {
		const _item = elem as HTMLElement
		const _key = _item.dataset.translationKey ?? ''

		if (!_key) return

		if (!_item.tagName.toLowerCase().includes('input') && !_item.tagName.toLowerCase().includes('select')) {
			setTranslation(_item, gt(_key) === _key ? _item.textContent ?? _key : gt(_key), gt(_key) !== _key)
		} else {
			const _input = _item as HTMLInputElement
			_input.placeholder = gt(_key) === _key ? _input.placeholder : gt(_key)
			setTranslated(_input, gt(_key) !== _key)
		}
	})
}

export const isEngLang = (): boolean => {
	const _lang = Cookies.get('language') ?? 'de'
	return _lang.includes('de')
}

window.addEventListener(
	'load',
	() => {
		setPageTranslationTexts()
		preventCloseInit()
		document.addEventListener(
			'handlePageLoaded',
			() => {
				//initMultiSelectLegacy('select.new-order__form-group__select[multiple]')
				initCalendar('[data-type="calendar"]')
				initWeekCalendar('[data-type="week-calendar"]')
				setPageTranslationTexts()
				setTimeout(() => {
					if (document && window) {
						setPageTranslationTexts()
					}
				}, 650)
			},
			{passive: true}
		)

		document.addEventListener(
			'settingsChanged',
			() => {
				const _user = getCurrentUser()
				const _saved = Cookies.get('language')

				if (_saved !== _user.languageKey) {
					Cookies.set('language', _user.languageKey)
					document.dispatchEvent(new Event('languageChanged'))
				}
			},
			{passive: true}
		)

		document.addEventListener(
			'languageChanged',
			() => {
				clearTranslated()
				clearPageCache()
				loadTranslations(() => {
					clearTranslated()
					document.dispatchEvent(new Event('handlePageLoaded'))
				})
			},
			{passive: true}
		)
	},
	{once: true}
)
