import {
    AbtractDataProviderWithName, IAbstractWithIdAndNameDto
} from '../../model/model_base'
import {popupService} from '../../tools/popups'
import {
    createElementFromTemplate,
    findTemplateAndSetTooltip,
    findTemplateAndSetHtml,
    findTemplateAndSetOnClick,
    onClick,
    setHtmlId, findTemplateAndSetText
} from '../../tools/templateTools'
import {
    confirmYesNo, toStringIfNotNull, ICallback, nN
} from '../../tools/tools'
import {IUserDto, userDataProvider} from '../../model/user'
import {
    IUserGroupDto, userGroupDataProvider, UserGroupDto
} from '../../model/usergroup'
import {globalErrorHandler} from '../../tools/error_handler'
import {Tooltip} from '../../tools/tooltip'
import {globalstate} from "../../model/globalstate";

const HTML_ID_ALL_USER_CONTAINER = 'all-user-container'
const HTML_ID_ALL_USER_COUNT = 'all-user-count'
const HTML_ID_TEMPLATE_USER = 'template-user'
const HTML_ID_TEMPLATE_USER_NAME = 'template-user-name'
const HTML_ID_TEMPLATE_USER_COMMENT = 'template-user-comment'
const HTML_ID_TEMPLATE_USER_ADD_BTN = 'template-user-add-btn'

const HTML_ID_USERS_IN_GROUP_CONTAINER = 'users-in-group-container'
const HTML_ID_USERS_IN_GROUP_COUNT = 'users-in-group-count'
const HTML_ID_USERS_IN_GROUP_NAME = 'users-in-group-name'
const HTML_ID_TEMPLATE_USER_IN_GROUP = 'template-users-in-group'
const HTML_ID_TEMPLATE_USER_IN_GROUP_USERNAME = 'template-users-in-group-username'
const HTML_ID_TEMPLATE_USER_IN_GROUP_USERCOMMENT = 'template-users-in-group-usercomment'
const HTML_ID_TEMPLATE_USER_IN_GROUP_REMOVE_BTN = 'template-users-in-group-remove-btn'

const HTML_ID_ALL_GROUPS_CONTAINER = 'all-groups-container'
const HTML_ID_ALL_GROUPS_COUNT = 'all-groups-count'
const HTML_ID_ALL_GROUPS_NEW_NAME = 'all-groups-new-name'
const HTML_ID_TEMPLATE_GROUP = 'template-group'
const HTML_ID_TEMPLATE_GROUP_NAME = 'template-group-name'
// const HTML_ID_TEMPLATE_GROUP_NAME_FIELD = 'template-group-name-field'
const HTML_ID_TEMPLATE_GROUP_MEMBER_COUNT = 'template-group-member-count'
const HTML_ID_TEMPLATE_GROUP_COMMENT = 'template-group-comment'
const HTML_ID_TEMPLATE_GROUP_SELECT_BTN = 'template-group-select-btn'
const HTML_ID_TEMPLATE_GROUP_DELETE_BTN = 'template-group-delete-btn'

class ElementContainer<DTO> {
    element: JQuery
    source: DTO

    constructor(element: JQuery, source: DTO) {
        this.element = element
        this.source = source
    }
}

export class MitarbeiterGruppenPage {
    currentUserGroup: IUserGroupDto | null = null
    usersAll: IUserDto[] = []
    usersInCurrentGroup: IUserDto[] = []
    userGroups: IUserGroupDto[] = []

    public handleLoadedAllUsers(users: IUserDto[]) {
        this.usersAll = users
        this.refreshList(this.usersAll, HTML_ID_TEMPLATE_USER,
            HTML_ID_ALL_USER_CONTAINER, HTML_ID_TEMPLATE_USER_NAME,
            HTML_ID_TEMPLATE_USER_COMMENT, HTML_ID_TEMPLATE_USER_ADD_BTN,
            HTML_ID_ALL_USER_COUNT, userDataProvider,
            member => this.addMember(member))
    }

    public handleLoadedGroups(newUserGroups: IUserGroupDto[]) {
        let currentUserGroup = this.currentUserGroup

        this.userGroups = newUserGroups
        this.refreshList(this.userGroups, HTML_ID_TEMPLATE_GROUP,
            HTML_ID_ALL_GROUPS_CONTAINER, HTML_ID_TEMPLATE_GROUP_NAME,
            HTML_ID_TEMPLATE_GROUP_COMMENT, HTML_ID_TEMPLATE_GROUP_SELECT_BTN,
            HTML_ID_ALL_GROUPS_COUNT, userGroupDataProvider,
            group => this.setCurrentUserGroup(group),
            (container: ElementContainer<IUserGroupDto>) => {
                let memberCount = container.source.memberIds?.length;
                findTemplateAndSetHtml(container.element,
                    HTML_ID_TEMPLATE_GROUP_MEMBER_COUNT,
                    memberCount ? memberCount : "0")
                findTemplateAndSetTooltip(container.element,
                    HTML_ID_TEMPLATE_GROUP_COMMENT, container.source.comment)
                findTemplateAndSetOnClick(container.element,
                    HTML_ID_TEMPLATE_GROUP_DELETE_BTN, () => {
                        this.deleteGroup(container.source)
                    })
            })
        if (!this.currentUserGroup && this.userGroups.length > 0) {
            currentUserGroup = this.userGroups[0]
        }
        this.setCurrentUserGroup(currentUserGroup)
    }

    public deleteGroup(userGroup: IUserGroupDto) {
        if (this.currentUserGroup) {
            if (this.currentUserGroup.id === userGroup.id) {
                this.currentUserGroup = null
            }
        }

        if (!userGroup.id) {
            globalErrorHandler('UserGroup for delete not set!')
            return
        }

        if (!confirmYesNo('Wirklich löschen? ' + userGroup.name)) {
            return
        }

        //delete group
        userGroupDataProvider.delete(userGroup.id, () => {
            globalstate.refresh(() => this.refreshUserGroups(() => {
                popupService.info('Gruppe gelöscht: ' + userGroup.name)
            }))
        })

        this.filterAll()
    }

    public addMember(user: IUserDto) {
        if (!this.currentUserGroup) {
            return
        }

        if (!this.currentUserGroup.id) {
            globalErrorHandler('UserGroup for add new member not set!')
            return
        }
        if (!this.currentUserGroup.memberIds) {
            this.currentUserGroup.memberIds = [];
        }
        this.currentUserGroup.memberIds?.push(user.id!);
        userGroupDataProvider.addMember(this.currentUserGroup.id, user.id);
        this.refreshUserGroups();
        this.setCurrentUserGroup(this.currentUserGroup);
        this.filterAll();
    }

    public removeMember(user: IUserDto) {
        if (!this.currentUserGroup) {
            return
        }
        if (!this.currentUserGroup.id) {
            globalErrorHandler('UserGroup for add new member not set!')
            return
        }
        if (!this.currentUserGroup.memberIds) {
            return;
        }
        this.currentUserGroup.memberIds = this.currentUserGroup.memberIds?.filter(
            id => id !== user.id) || [];
        userGroupDataProvider.removeMember(this.currentUserGroup.id, user.id);
        this.refreshUserGroups();
        this.setCurrentUserGroup(this.currentUserGroup);
        this.filterAll();
    }

    public initPage() {

        onClick($('#btn-back'), () => {
            window.location.assign('/#mitarbeiter')
        })

        this.setCurrentUserGroup(null)

        // load users
        this.refreshAllUsers()

        // load usergroups
        this.refreshUserGroups()

        /*Suche Mitarbeiter*/
        this.search('#mitarbeiter')

        /*Suche Gruppe*/
        this.search('#gruppe')

        /*Suche Objekte*/
        this.search('#objekte')
    }

    public renameDoubleClick(e: any) {
        const el = e as HTMLElement

        const input = el.querySelector('input')
        const span = el.querySelector('span')
        const name = span && span.textContent ? span.textContent : ''

        const onInputChange = (newName: string) => {
            if (this.currentUserGroup) {
                this.currentUserGroup.name = newName
                userGroupDataProvider.save(this.currentUserGroup,
                    () => globalstate.refresh(() => this.refreshUserGroups()));

                if (span) {
                    span.textContent = newName
                }
            }

            if (el.dataset.state === 'true') {
                el.dataset.state = 'false'
            }
        }

        if (input) {
            input.value = name
            input.addEventListener('change', () => onInputChange(input.value))
        }

        if (el.dataset.state === 'false') {
            el.dataset.state = 'true'
        }
    }

    public createGroup() {
        //get name
        let nameField = $('#' + HTML_ID_ALL_GROUPS_NEW_NAME)
        let name = toStringIfNotNull(nameField.val())
        nameField.val('')
        if (!name) {
            return
        }

        //create group
        let newGroup = new UserGroupDto()
        newGroup.name = name
        userGroupDataProvider.saveNew(newGroup, result => {

            let resultAsNumber: number = +result
            //error -> message / exit
            if (isNaN(resultAsNumber)) {
                popupService.error(
                    "FEHLER: Neue Gruppe '" + name + "' konnte nicht gespeichert werden!<br> " + result)
                return
            }
            globalstate.refresh(() => {
                //refresh list
                this.refreshUserGroups()

                //set new group as active
                this.setCurrentUserGroup(
                    userGroupDataProvider.getFromAllCachs(resultAsNumber))

                //print succes message
                popupService.info("Neue Gruppe '" + name + "' gespeichert!")
            })
        })
    }

    private filterAll() {
        /*Suche Mitarbeiter*/
        this.filter('#mitarbeiter')

        /*Suche Gruppe*/
        this.filter('#gruppe')

        /*Suche Objekte*/
        this.filter('#objekte')
    }

    private getUserGroupElement(userGroup: IUserGroupDto | null): JQuery | null {
        if (!userGroup) {
            return null
        }
        let element = $('#' + HTML_ID_TEMPLATE_GROUP + '_' + userGroup.id)
        if (element.length === 0) {
            return null
        }
        return element
    }

    private getCurrentUserGroupElement(): JQuery | null {
        return this.getUserGroupElement(this.currentUserGroup)
    }

    private refreshList<DTO extends IAbstractWithIdAndNameDto>(dtos: DTO[],
                                                               templateHtmlId: string,
                                                               containerHtmlId: string,
                                                               nameHtmlId: string,
                                                               commentHtmlId: string,
                                                               onClickActionHtmlId: string,
                                                               countHtmlId: string,
                                                               dataProvider: AbtractDataProviderWithName<DTO>,
                                                               onClickAction: ICallback<DTO, void>,
                                                               customFillFkt: ICallback<ElementContainer<DTO>, void> | null = null) {
        //set count
        this.setCount(countHtmlId, dtos)

        //clear containter
        $('#' + containerHtmlId).empty()

        //fill container
        dtos.forEach(dto => {
            this.createListElement(dto, templateHtmlId, containerHtmlId,
                nameHtmlId, commentHtmlId, onClickActionHtmlId, dataProvider,
                onClickAction, customFillFkt);
        })

        this.filterAll()

        Tooltip.init()
    }

    private setCount<T>(htmlId: string, source: number | T[] | null): void {
        let value: string = '0'
        if (source) {
            if (Array.isArray(source)) {
                value = '' + (source as []).length
            } else {
                value = '' + source
            }
        }

        const _num = !isNaN(parseInt(value)) ? parseInt(value) : 0
        $('#' + htmlId).attr('data-plural', _num < 2 ? 'single' : 'multiple')
        $('#' + htmlId).text(value)
    }

    private createListElement<DTO extends IAbstractWithIdAndNameDto>(dto: DTO,
                                                                     templateHtmlId: string,
                                                                     containerHtmlId: string,
                                                                     nameHtmlId: string,
                                                                     commentHtmlId: string,
                                                                     onClickActionHtmlId: string,
                                                                     dataProvider: AbtractDataProviderWithName<DTO>,
                                                                     onClickAction: ICallback<DTO, void>,
                                                                     customFillFkt: ICallback<ElementContainer<DTO>, void> | null = null) {
        let element = createElementFromTemplate(templateHtmlId)
        setHtmlId(element, templateHtmlId + '_' + dto.id)

        findTemplateAndSetText(element, nameHtmlId,
            dataProvider.getFullName(dto))
        findTemplateAndSetTooltip(element, commentHtmlId, dto.comment)
        findTemplateAndSetOnClick(element, onClickActionHtmlId,
            () => onClickAction(dto))

        if (customFillFkt) {
            customFillFkt(new ElementContainer(element, dto))
        }

        $('#' + containerHtmlId).append(element)
    }

    private search(htmlInputId: string) {
        $(htmlInputId + ' .search input')
            .on('input', () => this.filter(htmlInputId))
    }

    private filter(htmlInputId: string) {
        let value = $(htmlInputId + ' .search input').val() + ''
        if (!value) {
            $(htmlInputId + ' .form-holder').each(function () {
                $(this).show()
            })
            return
        }
        value = value.toLowerCase()
        $(htmlInputId + ' .form-holder').each(function () {
            if ($(this).text().toLowerCase().includes(value)) {
                $(this).show()
            } else {
                $(this).hide()
            }
        })
    }

    private handleLandleUserInCurrentGroup(members: IUserDto[]) {
        this.usersInCurrentGroup = members
        this.refreshAllUsers()
        this.refreshList(members, HTML_ID_TEMPLATE_USER_IN_GROUP,
            HTML_ID_USERS_IN_GROUP_CONTAINER,
            HTML_ID_TEMPLATE_USER_IN_GROUP_USERNAME,
            HTML_ID_TEMPLATE_USER_IN_GROUP_USERCOMMENT,
            HTML_ID_TEMPLATE_USER_IN_GROUP_REMOVE_BTN,
            HTML_ID_USERS_IN_GROUP_COUNT, userDataProvider,
            member => this.removeMember(member))
    }

    public setCurrentUserGroupById(groupId: number) {
        let groupDto = this.userGroups.find(
            userGroup => userGroup.id == groupId);
        if (!groupDto) {
            return;
        }
        this.setCurrentUserGroup(groupDto);
    }

    private setCurrentUserGroup(currentUserGroup: IUserGroupDto | null) {
        //remove old selection marker
        let currentUserGroupElement = this.getCurrentUserGroupElement()
        if (currentUserGroupElement) {
            currentUserGroupElement.removeClass('marked_red')
        }

        this.currentUserGroup = currentUserGroup
        if (this.currentUserGroup && this.currentUserGroup.id) {
            let currentUserGroupElement = this.getCurrentUserGroupElement()
            if (currentUserGroupElement) {
                currentUserGroupElement.addClass('marked_red')
            }
            //set group name
            $('#' + HTML_ID_USERS_IN_GROUP_NAME)
                .text(userGroupDataProvider.getFullName(this.currentUserGroup))
            //TODO filter
            this.handleLandleUserInCurrentGroup(
                userGroupDataProvider.getAllMembers(this.currentUserGroup));
        } else {
            //clear group name
            $('#' + HTML_ID_USERS_IN_GROUP_NAME).text('???')
            //clear all users
            $('#' + HTML_ID_USERS_IN_GROUP_CONTAINER).empty()
            //set count
            this.setCount(HTML_ID_USERS_IN_GROUP_COUNT, 0)
        }
    }

    private refreshUserGroups(callback: Function | null = null) {
        this.handleLoadedGroups(
            userGroupDataProvider.loadListAllManagedCache?.content!)
        nN(callback)
    }

    private refreshAllUsers() {
        this.handleLoadedAllUsers(
            userDataProvider.removeAFromB(this.usersInCurrentGroup,
                userDataProvider.loadListAllInternalManagedCache?.content!))
    }
}

export const mitarbeiterGruppenPage = new MitarbeiterGruppenPage()
