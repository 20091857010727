import {AbstractEditModal} from '../abstract_edit_modal'
import {IUserDto, userDataProvider, UserDataProvider, UserDto} from '../../../model/user'

export class VariableWochenzeiten extends AbstractEditModal<IUserDto, UserDataProvider> {
	private static instance: VariableWochenzeiten
	constructor() {
		super(userDataProvider, 'variable-wochenzeiten')
		if (VariableWochenzeiten.instance) {
			return VariableWochenzeiten.instance
		}
		return this
	}

	protected getHtmlDir(): string {
		return 'kalendar'
	}

	protected createNewDto(): IUserDto {
		return new UserDto()
	}
}

export const variableWochenzeiten = new VariableWochenzeiten()
