import {
	CustomerContactPersonDataProvider,
	customerDataProvider,
	CustomerDataProvider,
	CustomerDto,
	CustomerType,
	ICustomerDto,
	toCustomerType
} from '../../model/customer'
import {AddressDto} from '../../model/model_base'
import {
	GenderEnum, IUserDto, toGender, userDataProvider, UserDto
} from '../../model/user'
import {
	createElementFromTemplate,
	findTemplateAndSetChecked,
	findTemplateAndSetOnClick,
	findTemplateAndSetText,
	setHtmlId,
	setOnClick
} from '../../tools/templateTools'
import {
	confirmYesNo,
	getInputValAsString,
	getSelectedValFromRadio,
	setChecked,
	setValOrEmpty
} from '../../tools/tools'
import {AbstractEditModal} from './abstract_edit_modal'
import {auftraggeberListPage} from '../list/auftraggeber_list_page'
import {CONSOLE_DEBUG} from '../../consties'

const HTML_ID_AUFTTRAGGEBER_MODAL = 'modal-auftraggeber'
const HTML_ID_AUFTTRAGGEBER_MODAL_TYPE = HTML_ID_AUFTTRAGGEBER_MODAL + '-type'
const HTML_ID_AUFTTRAGGEBER_MODAL_NAME = HTML_ID_AUFTTRAGGEBER_MODAL + '-name'
const HTML_ID_AUFTTRAGGEBER_MODAL_NAME_CONTAINER = HTML_ID_AUFTTRAGGEBER_MODAL + '-name-container'
const HTML_ID_AUFTTRAGGEBER_MODAL_CUSTOM_ID = HTML_ID_AUFTTRAGGEBER_MODAL + '-custom-id'
const HTML_ID_AUFTTRAGGEBER_MODAL_COMMENT = HTML_ID_AUFTTRAGGEBER_MODAL + '-comment'

const HTML_ID_AUFTTRAGGEBER_MODAL_SAVE_PERSON_BTN = HTML_ID_AUFTTRAGGEBER_MODAL + '-save-person-btn'
const HTML_ID_AUFTTRAGGEBER_MODAL_SAVE_PERSON_BTN_CONTAINER = HTML_ID_AUFTTRAGGEBER_MODAL_SAVE_PERSON_BTN + '-container'

const HTML_ID_AUFTTRAGGEBER_MODAL_SAVE_COMPANY_BTN = HTML_ID_AUFTTRAGGEBER_MODAL + '-save-company-btn'
const HTML_ID_AUFTTRAGGEBER_MODAL_SAVE_COMPANY_BTN_CONTAINER = HTML_ID_AUFTTRAGGEBER_MODAL_SAVE_COMPANY_BTN + '-container'

const HTML_ID_AUFTTRAGGEBER_MODAL_CONTACT = HTML_ID_AUFTTRAGGEBER_MODAL + '-contact'
const HTML_ID_AUFTTRAGGEBER_MODAL_CONTACT_GENDER = HTML_ID_AUFTTRAGGEBER_MODAL_CONTACT + '-gender'

const HTML_ID_AUFTTRAGGEBER_MODAL_CONTACT_COMMENT = HTML_ID_AUFTTRAGGEBER_MODAL_CONTACT + '-comment'
const HTML_ID_AUFTTRAGGEBER_MODAL_CONTACT_FIRSTNAME = HTML_ID_AUFTTRAGGEBER_MODAL_CONTACT + '-firstname'
const HTML_ID_AUFTTRAGGEBER_MODAL_CONTACT_LASTNAME = HTML_ID_AUFTTRAGGEBER_MODAL_CONTACT + '-lastname'
const HTML_ID_AUFTTRAGGEBER_MODAL_CONTACT_STREET_AND_NR = HTML_ID_AUFTTRAGGEBER_MODAL_CONTACT + '-street-and-nr'
const HTML_ID_AUFTTRAGGEBER_MODAL_CONTACT_ZIPCODE = HTML_ID_AUFTTRAGGEBER_MODAL_CONTACT + '-zipcode'
const HTML_ID_AUFTTRAGGEBER_MODAL_CONTACT_CITY = HTML_ID_AUFTTRAGGEBER_MODAL_CONTACT + '-city'
const HTML_ID_AUFTTRAGGEBER_MODAL_CONTACT_EMAIL = HTML_ID_AUFTTRAGGEBER_MODAL_CONTACT + '-email'
const HTML_ID_AUFTTRAGGEBER_MODAL_CONTACT_PHONENUMBER = HTML_ID_AUFTTRAGGEBER_MODAL_CONTACT + '-phonenumber'

const HTML_ID_AUFTTRAGGEBER_MODAL_CONTACT_NEW_BTN = HTML_ID_AUFTTRAGGEBER_MODAL_CONTACT + '-new-btn'
const HTML_ID_AUFTTRAGGEBER_MODAL_CONTACT_SAVE_BTN = HTML_ID_AUFTTRAGGEBER_MODAL_CONTACT + '-save-btn'
const HTML_ID_AUFTTRAGGEBER_MODAL_CONTACT_BTN_CONTAINER = HTML_ID_AUFTTRAGGEBER_MODAL_CONTACT + '-btn-container'

const HTML_ID_AUFTTRAGGEBER_MODAL_CONTACTS = HTML_ID_AUFTTRAGGEBER_MODAL + '-contacts'
const HTML_ID_AUFTTRAGGEBER_MODAL_CONTACTS_CONTAINER = HTML_ID_AUFTTRAGGEBER_MODAL_CONTACTS + '-container'
const HTML_ID_AUFTTRAGGEBER_MODAL_CONTACTS_BTN_CONTAINER = HTML_ID_AUFTTRAGGEBER_MODAL_CONTACTS + '-btn-container'
const HTML_ID_AUFTTRAGGEBER_MODAL_CONTACTS_TBODY = HTML_ID_AUFTTRAGGEBER_MODAL_CONTACTS + '-tbody'

const HTML_ID_AUFTTRAGGEBER_MODAL_CONTACTS_TEMPLATE = HTML_ID_AUFTTRAGGEBER_MODAL_CONTACTS + '-template'

const HTML_ID_AUFTTRAGGEBER_MODAL_CONTACTS_ID = HTML_ID_AUFTTRAGGEBER_MODAL_CONTACTS + '-id'
const HTML_ID_AUFTTRAGGEBER_MODAL_CONTACTS_IS_MAINCONTACT = HTML_ID_AUFTTRAGGEBER_MODAL_CONTACTS + '-is-maincontact'
const HTML_ID_AUFTTRAGGEBER_MODAL_CONTACTS_FULLNAME = HTML_ID_AUFTTRAGGEBER_MODAL_CONTACTS + '-fullname'
const HTML_ID_AUFTTRAGGEBER_MODAL_CONTACTS_PHONENUMBER = HTML_ID_AUFTTRAGGEBER_MODAL_CONTACTS + '-phonenumber'
const HTML_ID_AUFTTRAGGEBER_MODAL_CONTACTS_EMAIL = HTML_ID_AUFTTRAGGEBER_MODAL_CONTACTS + '-email'
const HTML_ID_AUFTTRAGGEBER_MODAL_CONTACTS_ZIPCODE = HTML_ID_AUFTTRAGGEBER_MODAL_CONTACTS + '-zipcode'
const HTML_ID_AUFTTRAGGEBER_MODAL_CONTACTS_CITY = HTML_ID_AUFTTRAGGEBER_MODAL_CONTACTS + '-city'
const HTML_ID_AUFTTRAGGEBER_MODAL_CONTACTS_STREET_AND_NR = HTML_ID_AUFTTRAGGEBER_MODAL_CONTACTS + '-street-and-nr'
const HTML_ID_AUFTTRAGGEBER_MODAL_CONTACTS_DELETE_BTN = HTML_ID_AUFTTRAGGEBER_MODAL_CONTACTS + '-delete-btn'

export class AuftraggeberEditModal extends AbstractEditModal<ICustomerDto, CustomerDataProvider> {
	private static instance: AuftraggeberEditModal
	activeContact: IUserDto | null = null
	currentCustomerContactPersonDataProvider: CustomerContactPersonDataProvider | null = null

	constructor() {
		super(customerDataProvider, HTML_ID_AUFTTRAGGEBER_MODAL)
		if (AuftraggeberEditModal.instance) {
			return AuftraggeberEditModal.instance
		}
		return this
	}

	protected collectDataToDto(dto: ICustomerDto): void {
		//type
		dto.customerType = toCustomerType(this.getCustomerTypeFromUi())
		//name
		dto.name = getInputValAsString(HTML_ID_AUFTTRAGGEBER_MODAL_NAME)
		//customerId
		dto.customId = getInputValAsString(HTML_ID_AUFTTRAGGEBER_MODAL_CUSTOM_ID)
		//comment
		dto.comment = getInputValAsString(HTML_ID_AUFTTRAGGEBER_MODAL_COMMENT)
	}

	protected validateBeforeSave(dto: ICustomerDto): boolean {
		return true
	}

	protected createNewDto(): ICustomerDto {
		return new CustomerDto()
	}

	protected fillFromDto(dto: ICustomerDto): void {
		setValOrEmpty(HTML_ID_AUFTTRAGGEBER_MODAL_NAME, dto.name)
		setValOrEmpty(HTML_ID_AUFTTRAGGEBER_MODAL_CUSTOM_ID, dto.customId)
		setValOrEmpty(HTML_ID_AUFTTRAGGEBER_MODAL_COMMENT, dto.comment)

		setChecked(HTML_ID_AUFTTRAGGEBER_MODAL_TYPE, dto.customerType)
		if (dto.mainContactPerson) {
			this.setActiveContact(dto.mainContactPerson)
		}

		if (dto.contactPersons) {
			dto.contactPersons.forEach(contact => this.addContact(contact))
		}

		this.checkUiForCustomerType()
	}

	protected addContact(contact: IUserDto) {
		let newTr = createElementFromTemplate(HTML_ID_AUFTTRAGGEBER_MODAL_CONTACTS_TEMPLATE)
		this.setContactValues(newTr, contact)
		$('#' + HTML_ID_AUFTTRAGGEBER_MODAL_CONTACTS_TBODY).append(newTr)
	}

	private updateOrAddContactValues(contact: IUserDto) {
		if (CONSOLE_DEBUG) console.log('updateOrAddContactValues:', contact)
		let targetElement = $('#' + this.getContactId(contact))
		if (targetElement.length > 0) {
			this.setContactValues(targetElement, contact)
		} else {
			this.addContact(contact)
		}
	}

	private getContactId(contact: IUserDto): string {
		return HTML_ID_AUFTTRAGGEBER_MODAL_CONTACTS_ID + '_' + contact.id
	}

	protected postSaveToServer(customer: ICustomerDto) {
		if (!customer.id) {
			throw new Error('No Id in saved Customer')
		}
		this.currentCustomerContactPersonDataProvider = new CustomerContactPersonDataProvider(customer.id)
	}

	private setContactValues(newTr: JQuery<HTMLElement>, contact: IUserDto) {
		let setActiveContactFkt = () => this.setActiveContact(contact)
		setHtmlId(newTr, this.getContactId(contact))

		findTemplateAndSetText(newTr, HTML_ID_AUFTTRAGGEBER_MODAL_CONTACTS_FULLNAME, userDataProvider.getFullName(contact)).on(
			'click',
			setActiveContactFkt
		)

		findTemplateAndSetText(newTr, HTML_ID_AUFTTRAGGEBER_MODAL_NAME, contact.name).on('click', setActiveContactFkt)

		findTemplateAndSetText(newTr, HTML_ID_AUFTTRAGGEBER_MODAL_CUSTOM_ID, contact.customId).on('click', setActiveContactFkt)

		if (contact.address) {
			findTemplateAndSetText(newTr, HTML_ID_AUFTTRAGGEBER_MODAL_CONTACTS_STREET_AND_NR, contact.address.streetAndNr).on(
				'click',
				setActiveContactFkt
			)

			findTemplateAndSetText(newTr, HTML_ID_AUFTTRAGGEBER_MODAL_CONTACTS_ZIPCODE, contact.address.zipCode).on(
				'click',
				setActiveContactFkt
			)

			findTemplateAndSetText(newTr, HTML_ID_AUFTTRAGGEBER_MODAL_CONTACTS_CITY, contact.address.city).on('click', setActiveContactFkt)

			findTemplateAndSetText(newTr, HTML_ID_AUFTTRAGGEBER_MODAL_CONTACTS_EMAIL, contact.address.email).on(
				'click',
				setActiveContactFkt
			)

			findTemplateAndSetText(newTr, HTML_ID_AUFTTRAGGEBER_MODAL_CONTACTS_PHONENUMBER, contact.address.phone).on(
				'click',
				setActiveContactFkt
			)
		}

		let isMainContact =
			this.dto && this.dto.mainContactPerson && this.dto.mainContactPerson.id ? contact.id === this.dto.mainContactPerson.id : false
		let isMainContactElement = findTemplateAndSetChecked(newTr, HTML_ID_AUFTTRAGGEBER_MODAL_CONTACTS_IS_MAINCONTACT, isMainContact)
		if (!isMainContact) {
			setOnClick(isMainContactElement, () => {
				if (!this.dto || !this.dto.id || !contact.id) {
					return
				}
				customerDataProvider.setMainContact(this.dto.id, contact.id, () => this.reload())
			})
		}

		findTemplateAndSetOnClick(newTr, HTML_ID_AUFTTRAGGEBER_MODAL_CONTACTS_DELETE_BTN, () => this.deleteContact(contact))
	}

	protected setActiveContact(contact: IUserDto | null) {
		if (!contact) {
			this.clearActiveContact()
			return
		}
		this.activeContact = contact
		setChecked(HTML_ID_AUFTTRAGGEBER_MODAL_CONTACT_GENDER, contact.gender)

		setValOrEmpty(HTML_ID_AUFTTRAGGEBER_MODAL_CONTACT_COMMENT, contact.comment)
		setValOrEmpty(HTML_ID_AUFTTRAGGEBER_MODAL_CONTACT_FIRSTNAME, contact.firstname)
		setValOrEmpty(HTML_ID_AUFTTRAGGEBER_MODAL_CONTACT_LASTNAME, contact.lastname)

		if (contact.address) {
			setValOrEmpty(HTML_ID_AUFTTRAGGEBER_MODAL_CONTACT_STREET_AND_NR, contact.address.streetAndNr)
			setValOrEmpty(HTML_ID_AUFTTRAGGEBER_MODAL_CONTACT_ZIPCODE, contact.address.zipCode)
			setValOrEmpty(HTML_ID_AUFTTRAGGEBER_MODAL_CONTACT_CITY, contact.address.city)
			setValOrEmpty(HTML_ID_AUFTTRAGGEBER_MODAL_CONTACT_PHONENUMBER, contact.address.phone)
			setValOrEmpty(HTML_ID_AUFTTRAGGEBER_MODAL_CONTACT_EMAIL, contact.address.email)
		}
	}

	protected clearActiveContact() {
		this.activeContact = null
		setChecked(HTML_ID_AUFTTRAGGEBER_MODAL_CONTACT_GENDER, GenderEnum.DIVERSE, false)
		setChecked(HTML_ID_AUFTTRAGGEBER_MODAL_CONTACT_GENDER, GenderEnum.FEMALE, false)
		setChecked(HTML_ID_AUFTTRAGGEBER_MODAL_CONTACT_GENDER, GenderEnum.MALE, false)

		setValOrEmpty(HTML_ID_AUFTTRAGGEBER_MODAL_CONTACT_COMMENT, '')
		setValOrEmpty(HTML_ID_AUFTTRAGGEBER_MODAL_CONTACT_FIRSTNAME, '')
		setValOrEmpty(HTML_ID_AUFTTRAGGEBER_MODAL_CONTACT_LASTNAME, '')

		setValOrEmpty(HTML_ID_AUFTTRAGGEBER_MODAL_CONTACT_STREET_AND_NR, '')
		setValOrEmpty(HTML_ID_AUFTTRAGGEBER_MODAL_CONTACT_ZIPCODE, '')
		setValOrEmpty(HTML_ID_AUFTTRAGGEBER_MODAL_CONTACT_CITY, '')
		setValOrEmpty(HTML_ID_AUFTTRAGGEBER_MODAL_CONTACT_PHONENUMBER, '')
		setValOrEmpty(HTML_ID_AUFTTRAGGEBER_MODAL_CONTACT_EMAIL, '')
	}

	private loadActiveContactFromUi() {
		if (!this.activeContact) {
			this.activeContact = new UserDto()
		}

		this.activeContact.gender = toGender(getSelectedValFromRadio(HTML_ID_AUFTTRAGGEBER_MODAL_CONTACT_GENDER))

		this.activeContact.comment = getInputValAsString(HTML_ID_AUFTTRAGGEBER_MODAL_CONTACT_COMMENT)
		this.activeContact.firstname = getInputValAsString(HTML_ID_AUFTTRAGGEBER_MODAL_CONTACT_FIRSTNAME)
		this.activeContact.lastname = getInputValAsString(HTML_ID_AUFTTRAGGEBER_MODAL_CONTACT_LASTNAME)

		if (!this.activeContact.address) {
			this.activeContact.address = new AddressDto()
		}
		this.activeContact.address.streetAndNr = getInputValAsString(HTML_ID_AUFTTRAGGEBER_MODAL_CONTACT_STREET_AND_NR)
		this.activeContact.address.zipCode = getInputValAsString(HTML_ID_AUFTTRAGGEBER_MODAL_CONTACT_ZIPCODE)
		this.activeContact.address.city = getInputValAsString(HTML_ID_AUFTTRAGGEBER_MODAL_CONTACT_CITY)
		this.activeContact.address.phone = getInputValAsString(HTML_ID_AUFTTRAGGEBER_MODAL_CONTACT_PHONENUMBER)
		this.activeContact.address.email = getInputValAsString(HTML_ID_AUFTTRAGGEBER_MODAL_CONTACT_EMAIL)
	}

	private deleteContact(contact: IUserDto) {
		if (!contact.id) {
			return
		}
		if (!confirmYesNo('Kontakt-Person wirklich löschen: ' + userDataProvider.getFullName(contact) + '?')) {
			return
		}
		this.getCustomerContactPersonDataProvider().delete(contact.id, () => {
			this.reload()
		})
	}

	private saveActiveContact(success: Function | null = null) {
		//create new user and get data from ui
		this.loadActiveContactFromUi()

		// can be saved?
		if (!this.activeContact?.firstname && !this.activeContact?.lastname) {
			if (success) success()
			return
		}

		//save user
		if (!this.activeContact) {
			if (success) success()
			return
		}

		let activeContact = this.activeContact

		if (!this.dto || !this.dto.id) {
			this.saveToServer(() => {
				this.saveActiveContactImpl(activeContact, success)
			}, false)
		} else {
			this.saveActiveContactImpl(activeContact, success)
		}
	}

	private getCustomerContactPersonDataProvider(): CustomerContactPersonDataProvider {
		if (!this.currentCustomerContactPersonDataProvider) {
			if (!this.dto || !this.dto.id) {
				throw new Error('Customer has no ID!')
			}
			this.currentCustomerContactPersonDataProvider = new CustomerContactPersonDataProvider(this.dto.id)
		}
		return this.currentCustomerContactPersonDataProvider
	}

	private saveActiveContactImpl(activeContact: IUserDto, success: Function | null = null) {
		//connect user to customer
		this.getCustomerContactPersonDataProvider().save(activeContact, result => {
			if (!this.activeContact) {
				throw new Error('Unexpected Error!')
			}

			//set user id
			this.activeContact.id = +result

			this.updateOrAddContactValues(this.activeContact)

			if (success) success()
		})
	}

	protected fillDefaults(callback: Function): void {
		setChecked(HTML_ID_AUFTTRAGGEBER_MODAL_TYPE, CustomerType.COMPANY)
		this.checkUiForCustomerType()
		callback()
	}

	protected clearCustom(): void {
		$('#' + HTML_ID_AUFTTRAGGEBER_MODAL_CONTACTS_TBODY).empty()
		this.clearActiveContact()
		this.currentCustomerContactPersonDataProvider = null
	}

	protected postHtmlLoaded(): void {
		$("input[name='" + HTML_ID_AUFTTRAGGEBER_MODAL_TYPE + "']").on('click', () => this.checkUiForCustomerType())
		$('#' + HTML_ID_AUFTTRAGGEBER_MODAL_CONTACT_NEW_BTN).on('click', () => this.clearActiveContact())
		$('#' + HTML_ID_AUFTTRAGGEBER_MODAL_CONTACT_SAVE_BTN).on('click', () => this.saveActiveContact())

		$('#' + HTML_ID_AUFTTRAGGEBER_MODAL_SAVE_PERSON_BTN).on('click', () => this.saveAll())
		$('#' + HTML_ID_AUFTTRAGGEBER_MODAL_SAVE_COMPANY_BTN_CONTAINER).on('click', () => this.saveAll())
	}

	private saveAll() {
		this.saveToServer(() => {
			this.saveActiveContact(() => {
				auftraggeberListPage.fillTable()
			})
		})
	}

	private checkUiForCustomerType() {
		var customerType = this.getCustomerTypeFromUi()
		if (customerType === CustomerType.PERSON) {
			this.setModusToPerson()
		} else {
			this.setModusToCompany()
		}
	}

	private getCustomerTypeFromUi() {
		return getSelectedValFromRadio(HTML_ID_AUFTTRAGGEBER_MODAL_TYPE)
	}

	private setModusToPerson() {
		$('#' + HTML_ID_AUFTTRAGGEBER_MODAL_NAME_CONTAINER).hide()
		$('#' + HTML_ID_AUFTTRAGGEBER_MODAL_CONTACT_BTN_CONTAINER).hide()
		$('#' + HTML_ID_AUFTTRAGGEBER_MODAL_CONTACTS_CONTAINER).hide()
		$('#' + HTML_ID_AUFTTRAGGEBER_MODAL_CONTACTS_BTN_CONTAINER).hide()
		$('#' + HTML_ID_AUFTTRAGGEBER_MODAL_SAVE_COMPANY_BTN_CONTAINER).hide()

		$('#' + HTML_ID_AUFTTRAGGEBER_MODAL_SAVE_PERSON_BTN_CONTAINER).show()

		this.setActiveContact(this.getDtoOrCreate().mainContactPerson)
	}

	private setModusToCompany() {
		$('#' + HTML_ID_AUFTTRAGGEBER_MODAL_NAME_CONTAINER).show()
		$('#' + HTML_ID_AUFTTRAGGEBER_MODAL_CONTACT_BTN_CONTAINER).show()
		$('#' + HTML_ID_AUFTTRAGGEBER_MODAL_CONTACTS_CONTAINER).show()
		$('#' + HTML_ID_AUFTTRAGGEBER_MODAL_CONTACTS_BTN_CONTAINER).show()
		$('#' + HTML_ID_AUFTTRAGGEBER_MODAL_SAVE_COMPANY_BTN_CONTAINER).show()

		$('#' + HTML_ID_AUFTTRAGGEBER_MODAL_SAVE_PERSON_BTN_CONTAINER).hide()
	}
}

export const auftraggeberEditModal = new AuftraggeberEditModal()
