import {getHtmlId, onClick, setHtmlId} from './templateTools'
import {HashMap, nN} from './tools'

const POPUP_TYPE_ERROR = 'error'
const POPUP_TYPE_INFO = 'info'
const POPUP_CONTAINER_HTML_ID_TOP = 'popup_messages_top'

export const POPUP_FOREVER = -1

class PopupService {
	private index: number = 0
	private allPopUps = new HashMap<JQuery>()

	public info(message: string, timeInSec: number | null = null, callback: Function | null = null): JQuery {
		return this.addPopupUp(message, POPUP_TYPE_INFO, timeInSec, callback)
	}

	public error(message: string, timeInSec: number | null = null, callback: Function | null = null): JQuery {
		return this.addPopupUp(message, POPUP_TYPE_ERROR, timeInSec, callback)
	}

	public removeAllPopUps() {
		this.allPopUps.forEach(popup => {
			popup.remove()
		})
		this.allPopUps.clear()
	}

	private addPopupUp(message: string, type: string, timeInSec: number | null = null, callback: Function | null = null): JQuery {
		let popup = this.createPopUp(message, type)
		this.allPopUps.put(getHtmlId(popup) + '', popup)
		if (callback) {
			popup.addClass('clickable')
			onClick(popup, () => {
				popup.remove()
				nN(callback)
			})
		}

		//add popup
		$('#' + POPUP_CONTAINER_HTML_ID_TOP).append(popup)

		if (timeInSec !== POPUP_FOREVER) {
			//start timer for remove
			setTimeout(
				() => {
					popup.remove()
					this.allPopUps.remove(getHtmlId(popup) + '')
				},
				timeInSec ? timeInSec * 1000 : type === POPUP_TYPE_INFO ? 2000 : 10000
			)
		}
		return popup
	}

	private createPopUp(message: string, type: string) {
		//create popup
		let popup = $('<div />')
		popup.text(message)
		popup.addClass('popup_message_' + type)

		//create popup id
		let id = 'POPUP_' + this.index++
		setHtmlId(popup, id)
		return popup
	}
}

export const popupService = new PopupService()
