import {AbstractDto, AbtractDataProvider, IAbstractDto} from './model_base'
import {
    arrayConvert,
    executeRestCallJson,
    IJsonToObjectConverter,
    nullJsonObjectConverter
} from '../tools/rest'
import {IUserDto, userDataProvider} from './user'
import {ITaskTypeDto, taskTypeDataProvider} from './tasktype'
import {customerDataProvider, ICustomerDto} from './customer'
import {buildingConverter, BuildingDto, IBuildingDto} from './building'
import {DayOfWeek, toDayOfWeek} from './dayofweek'
import {ICallback, mustNotNull, newDateOrNull} from '../tools/tools'
import {
    REST_CONTROLLER, REST_METHODS, REST_OPERATION
} from '../tools/restConsties'
import {globalstate} from "./globalstate";

export enum JobType {
    ONCE = 'ONCE',
    WEEKLY = 'WEEKLY',
    TWO_WEEKS = 'TWO_WEEKS',
    THREE_WEEKS = 'THREE_WEEKS',
    FOUR_WEEKS = 'FOUR_WEEKS',
    YEARLY = 'YEARLY',
    EVERY_X_WEEKS = 'EVERY_X_WEEKS'
}

export const JobTypes = [JobType.ONCE, JobType.WEEKLY, JobType.TWO_WEEKS,
    JobType.THREE_WEEKS, JobType.FOUR_WEEKS, JobType.YEARLY,
    JobType.EVERY_X_WEEKS]

export function toJobType(jobTypeName: any): JobType {
    return JobType[jobTypeName as keyof typeof JobType]
}

export interface IWorkWindowDto extends IAbstractDto {
    workWindowStart: string | null
    workWindowEnd: string | null
    workHours: number | null
    dayOfWeek: DayOfWeek | null
}

export const workWindowConverter: IJsonToObjectConverter<IWorkWindowDto> = {
    convert(source: IWorkWindowDto | null): IWorkWindowDto | null {
        if (!source) {
            return source
        }
        source.dayOfWeek = toDayOfWeek(source.dayOfWeek)
        return source
    }
}

export class WorkWindowDto extends AbstractDto implements IWorkWindowDto {
    workWindowStart: string | null = null
    workWindowEnd: string | null = null
    workHours: number | null = null
    dayOfWeek: DayOfWeek | null = null
}

export interface IJobDto extends IAbstractDto {
    customer: ICustomerDto | null
    building: IBuildingDto

    startDate: Date | null
    endDate: Date | null
    jobType: JobType | null
    workWindows: IWorkWindowDto[] | null
    contact: IUserDto | null

    tasks: ITaskTypeDto[]

    annualBilling: boolean | null
    floor: string | null
    position: string | null
    repeatWeekNumbers: Array<number> | null
    repeatsEveryXWeeks: number | null
    rhythmChangedDates: Array<string> | null
    changeRhythmFromDate: Date | null
    sorting: number | null
}

export class JobDto extends AbstractDto implements IJobDto {
    customer: ICustomerDto | null = null
    building: IBuildingDto = new BuildingDto()

    startDate: Date | null = null
    endDate: Date | null = null
    jobType: JobType | null = null
    workWindows: IWorkWindowDto[] | null = null
    contact: IUserDto | null = null

    tasks: ITaskTypeDto[] = []

    annualBilling: boolean | null = null
    floor: string | null = null
    position: string | null = null
    repeatWeekNumbers: Array<number> = []
    repeatsEveryXWeeks: number = 0
    rhythmChangedDates: Array<string> | null = null
    changeRhythmFromDate: Date | null = null
    sorting: number | null = null
}

export class JobDataProvider extends AbtractDataProvider<IJobDto> {
    constructor() {
        super(REST_CONTROLLER.JOB)
    }

    protected getUserGlobalState(): boolean {
        return false;
    }

    public updateSorting(data: { targetJobId: number; beforeJobId?: number; afterJobId?: number },
                         success?: ICallback<number, void>) {
        executeRestCallJson(this.controller, REST_OPERATION.SORTING,
            REST_METHODS.PUT, nullJsonObjectConverter, success, null, data);
    }

    createDto(): IJobDto {
        return new JobDto()
    }

    convert(source: IJobDto): IJobDto {
        if (source) {
            if (source.customer) {
                source.customer = customerDataProvider.convert(source.customer)
            }
            source.building = mustNotNull(
                buildingConverter.convert(source.building))
            source.startDate = newDateOrNull(source.startDate)
            source.endDate = newDateOrNull(source.endDate)
            source.jobType = toJobType(source.jobType + '')
            source.workWindows = arrayConvert(workWindowConverter,
                source.workWindows)
            if (source.contact) {
                source.contact = userDataProvider.convert(source.contact)
            }

            if (source.tasks) {
                let tasks: ITaskTypeDto[] = []
                for (const tasksKey in source.tasks) {
                    const cachedTask = taskTypeDataProvider.getFromAll(
                        source.tasks[tasksKey].id);
                    if (cachedTask) {
                        tasks.push(cachedTask);
                    }
                }
                source.tasks = tasks;
            } else {
                source.tasks = [];
            }
        }
        return super.convert(source)
    }

}

export const jobDataProvider = new JobDataProvider()
globalstate.jobDataProvider = jobDataProvider;
