import {
    CONSOLE_DEBUG,
    DEBUG_PAGE,
    FILE_VERSION,
    HTML_ROOT_ID,
    PAGE_ACCOUNT_VERWALTEN,
    PAGE_AGB,
    PAGE_AUFTRAGGEBER,
    PAGE_AUFTRAGGEBER_FORMULAR,
    PAGE_AUFTRAGGEBER_KONTAKTE_FORMULAR,
    PAGE_AUFTRAGGEBER_OBJEKTE_FORMULAR,
    PAGE_AUSWERTUNG,
    PAGE_BACKUP,
    PAGE_BANKVERBINDUNG,
    PAGE_CALENDAR_DAY_VIEW,
    PAGE_CALENDAR_WEEK_VIEW,
    PAGE_DASHBOARD,
    PAGE_DATENSCHUTZ,
    PAGE_EINSAETZE,
    PAGE_EINSATZ_ARCHIV,
    PAGE_EINSTELLUNGEN,
    PAGE_EINSTELLUNGEN_KALENDAR,
    PAGE_EINSTELLUNGEN_LAGE,
    PAGE_EINSTELLUNGEN_LEISTUNGSART,
    PAGE_EINSTELLUNGEN_TAGESANSICHT,
    PAGE_EINSTELLUNGEN_WOCHENANSICHT,
    PAGE_EXPORT_IMPORT,
    PAGE_FIRMENDATEN,
    PAGE_GEBAEUDE,
    PAGE_IMPRESSUM,
    PAGE_LOGIN,
    PAGE_MITARBEITER,
    PAGE_MITARBEITER_FORMULAR,
    PAGE_MITARBEITER_GRUPPEN,
    PAGE_PASSWORT_AENDERN,
    PAGE_PASSWORT_VERGESSEN,
    PAGE_PROFIL,
    PAGE_RECHNUNGEN,
    PAGE_REGISTER,
    PAGE_TUTORIALS,
    PAGE_URLAUB
} from '../consties'
import {
    checkIsOnline, executeRestCall, login, logout, passwordReset
} from '../tools/rest'
import {
    pageNameGt, pageTitleGt, setPageTranslationTexts
} from '../tools/translation'
import {
    auftraggeberEditModal, AuftraggeberEditModal
} from './modals/auftraggeber_edit_modal'
import {
    auftraggeberListPage, AuftraggeberListPage
} from './list/auftraggeber_list_page'
import {footer} from './footer'
import {
    mitarbeiterGruppenPage, MitarbeiterGruppenPage
} from './list/mitarbeitergruppen_edit_page'
import {
    MitarbeiterModal, mitarbeiterModal
} from './modals/mitarbeiter_edit_modal'
import {
    MitarbeiterListPage, mitarbeiterListPage
} from './list/mitarbeiter_list_page'
import {BuildingListPage} from './list/building_list_page'
import {ICustomerDto} from '../model/customer'
import {
    maHinzufuegenModal, MaHinzufuegenModal
} from './modals/kalendar/ma_hinzufuegen_modal'
import {
    workerAwayModal, WorkerAwayModal
} from './modals/kalendar/worker_away_modal';
//TODO: import {AuftragModal, auftragModal} from './modals/kalendar/auftrag_modal'
import {
    variableWochenzeiten, VariableWochenzeiten
} from './modals/kalendar/variable-wochenzeiten'
import {activeJobModal} from './modals/kalendar/active_job_modal'
import {kalendarPage} from './settings/kalendar_page'
import {tagesansichtPage} from './settings/tagesansicht_page'
import {wochenansichtPage} from './settings/wochenansicht_page'
import {IUserDto, UserDataProvider, userDataProvider} from '../model/user'
import {profilPage} from './settings/profil_page'
import {passwortAendernPage} from './settings/passwort_aendern_page'
import {companyPage} from './settings/company_page'
import {bankPage} from './settings/bank_page'
import {lagePage} from './settings/lage_page'
import {taskTypePage} from './settings/tasktype_page'
import {taskTypeAbbreviationPage} from './settings/tasktype_abbreviation_page'
import {taskTypeDataProvider} from '../model/tasktype'
import {onEnter} from '../tools/templateTools'
import {calendarWeekView} from './calendar/calendar_week_view'
import {einsaetzeListPage} from './list/einsaetze_list_page'
import {
    convertDateToDateStr,
    convertDateToTimeStr,
    getValueOrError,
    HashMap,
    ICallback
} from '../tools/tools'
import {maFormular} from './formulare/maFormular'
import {userTypeDataProvider} from '../model/usertype'
import {
    AuftraggeberFormular, auftraggeberFormular
} from './formulare/auftrageberFormular'
import {ContactFormular} from './formulare/contactFormular'
import {BuildingsFormular} from './formulare/buildingsFormular'
import {IBuildingDto} from '../model/building'
import {deleteJobModal} from './modals/kalendar/deleteJobModal'
import {selectMaForEinsatzReport} from './modals/selectMaForEinsatzReport'
import {
    isOnline, REST_CONTROLLER, REST_METHODS, REST_OPERATION
} from '../tools/restConsties'
import {popupService} from '../tools/popups'
import moment from 'moment'
import {tryRegister} from './register_page'
import {TableHelper} from '../tools/tableHelper'
import {Tooltip} from '../tools/tooltip'
import Cookies from 'js-cookie'
import {cookieBanner, CookieBanner} from './modals/cookie_banner'
import {NewOrderModal, newOrderModal} from './modals/kalendar/new-order'
import {activeJobDataProvider} from "../model/active_job";
import {companyDataProvider} from "../model/company";
import {jobDataProvider} from "../model/job";
import {jobGroupDataProvider} from "../model/job_group";
import {userGroupDataProvider} from "../model/usergroup";
import {globalstate} from "../model/globalstate";
import {initCalendar} from "../tools/calendar_widget";
import {evaluationsListPage} from "./list/evaluations_list_page";

//#### ACTIONS ####
export interface IActions {
    tryLogin(): void

    resetPassword(): void

    tryLogout(): void

    loadDashboard(): void

    loadEinstellungen(): void

    loadMitarbeiter(): void

    loadMitarbeiterGruppen(pageLoadedCallback: Function | null): void

    loadLogin(): void

    loadRegister(): void

    tryRegister(): void

    loadCurrentPage(): void

    loadAuftraggeber(): void

    loadFirmendaten(): void

    loadBankverbindung(): void

    loadRechnungen(): void

    loadAccountVerwalten(): void

    loadProfil(): void

    loadPasswortAendern(): void

    loadEinstellungenKalendar(): void

    loadEinstellungenTagesansicht(): void

    loadEinstellungenWochenansicht(): void

    loadEinstellungenLeistungsart(): void

    loadEinstellungenLage(): void

    loadEinsatzArchiv(): void

    loadBackUp(): void

    loadExportImport(): void

    loadTutorials(): void

    loadEinsaetze(): void

    loadEvaluations(): void

    loadPasswortVergessen(): void

    loadImpressum(): void

    loadAgb(): void

    loadDatenschutz(): void

    loadCalendarDayView(date: Date | null): void

    loadCalendarWeekView(date: Date | null): void

    loadMaFormular(ma: IUserDto | null): void

    loadAuftraggeberFormular(customer: ICustomerDto | null): void

    loadAuftraggeberKontakteFormular(customer: ICustomerDto | null,
                                     contact: IUserDto | null | number,
                                     pageLoadedCallback: Function | null): void

    loadAuftraggeberObjekteFormular(customer: ICustomerDto | null,
                                    building: IBuildingDto | null | number,
                                    pageLoadedCallback: Function | null): void

    loadUrlaub(): void

    onSearchChange(selector: string): void

    currentPage: string

    mitarbeiterListPage: MitarbeiterListPage
    mitarbeiterEditModal: MitarbeiterModal
    mitarbeiterGruppenPage: MitarbeiterGruppenPage
    auftraggeberListPage: AuftraggeberListPage
    auftraggeberEditModal: AuftraggeberEditModal
    buildingListPage: BuildingListPage
    maHinzufuegenModal: MaHinzufuegenModal
    workerAwayModal: WorkerAwayModal
    //CHANGED: neuerAuftragModal: AuftragModal
    newOrderModal: NewOrderModal
    variableWochenzeiten: VariableWochenzeiten
    cookieBanner: CookieBanner
}

declare const actions: IActions
export const actionsInternal = actions

export let currentUserProvider: UserDataProvider

actions.variableWochenzeiten = variableWochenzeiten
//CHANGED: actions.neuerAuftragModal = neuerAuftragModal
actions.newOrderModal = newOrderModal
actions.maHinzufuegenModal = maHinzufuegenModal
actions.workerAwayModal = workerAwayModal
actions.mitarbeiterEditModal = mitarbeiterModal
actions.mitarbeiterListPage = mitarbeiterListPage
actions.mitarbeiterGruppenPage = mitarbeiterGruppenPage
actions.auftraggeberListPage = auftraggeberListPage
actions.auftraggeberEditModal = auftraggeberEditModal
actions.cookieBanner = cookieBanner
actions.loadRegister = () => loadPage(PAGE_REGISTER)
actions.tryRegister = tryRegister
actions.loadFirmendaten = () => loadPage(PAGE_FIRMENDATEN)
actions.loadBankverbindung = () => loadPage(PAGE_BANKVERBINDUNG)
actions.loadRechnungen = () => loadPage(PAGE_RECHNUNGEN)
actions.loadAccountVerwalten = () => loadPage(PAGE_ACCOUNT_VERWALTEN)
actions.loadProfil = () => loadPage(PAGE_PROFIL)
actions.loadPasswortAendern = () => loadPage(PAGE_PASSWORT_AENDERN)
actions.loadEinstellungenKalendar = () => loadPage(PAGE_EINSTELLUNGEN_KALENDAR)
actions.loadEinstellungenTagesansicht = () => loadPage(
    PAGE_EINSTELLUNGEN_TAGESANSICHT)
actions.loadEinstellungenWochenansicht = () => loadPage(
    PAGE_EINSTELLUNGEN_WOCHENANSICHT)
actions.loadEinstellungenLeistungsart = () => loadPage(
    PAGE_EINSTELLUNGEN_LEISTUNGSART)
actions.loadEinstellungenLage = () => loadPage(PAGE_EINSTELLUNGEN_LAGE)
actions.loadBackUp = () => loadPage(PAGE_BACKUP)
actions.loadEinsatzArchiv = () => loadPage(PAGE_EINSATZ_ARCHIV)
actions.loadExportImport = () => loadPage(PAGE_EXPORT_IMPORT)
actions.loadTutorials = () => loadPage(PAGE_TUTORIALS)
actions.loadEinsaetze = () => loadPage(PAGE_EINSAETZE)
actions.loadEvaluations = () => loadPage(PAGE_AUSWERTUNG)
actions.loadPasswortVergessen = () => loadPage(PAGE_PASSWORT_VERGESSEN)
actions.loadImpressum = () => loadPage(PAGE_IMPRESSUM)
actions.loadAgb = () => loadPage(PAGE_AGB)
actions.loadDatenschutz = () => loadPage(PAGE_DATENSCHUTZ)
actions.loadCalendarDayView = date => loadPage(PAGE_CALENDAR_DAY_VIEW, null,
    date)
export const loadCalendarDayView = actions.loadCalendarDayView
actions.loadCalendarWeekView = date => loadPage(PAGE_CALENDAR_WEEK_VIEW, null,
    date)
export const loadCalendarWeekView = actions.loadCalendarWeekView
actions.loadUrlaub = () => loadPage(PAGE_URLAUB)
actions.loadMaFormular = ma => loadPage(PAGE_MITARBEITER_FORMULAR, null, ma)
export const loadMaFormular = actions.loadMaFormular
actions.onSearchChange = TableHelper.onSearchChange
actions.loadAuftraggeberFormular = customer => loadPage(
    PAGE_AUFTRAGGEBER_FORMULAR, null, customer)
export const loadAuftraggeberFormular = actions.loadAuftraggeberFormular
actions.loadAuftraggeberKontakteFormular = (customer, contact,
                                            pageLoadedCallbac) => {
    AuftraggeberFormular.createSubForms(getValueOrError(customer))
    loadPage(PAGE_AUFTRAGGEBER_KONTAKTE_FORMULAR, pageLoadedCallbac, contact)
}
export const loadAuftraggeberKontakteFormular = actions.loadAuftraggeberKontakteFormular
actions.loadAuftraggeberObjekteFormular = (customer, building,
                                           pageLoadedCallbac) => {
    AuftraggeberFormular.createSubForms(getValueOrError(customer))
    loadPage(PAGE_AUFTRAGGEBER_OBJEKTE_FORMULAR, pageLoadedCallbac, building)
}
export const loadAuftraggeberObjekteFormular = actions.loadAuftraggeberObjekteFormular

const loginCallback = () => {
    if (userDataProvider.current.content) {
        currentUserProvider = userDataProvider

        if (userDataProvider.current.content.languageKey) {
            const _saved = Cookies.get('language')
            if (_saved !== userDataProvider.current.content.languageKey) {
                Cookies.set('language',
                    userDataProvider.current.content.languageKey)
                document.dispatchEvent(new Event('languageChanged'))
            }
        }


        if (userDataProvider.current.content.passwordTempIsValidUntil) {
            if (moment(
                userDataProvider.current.content.passwordTempIsValidUntil)
                .isAfter(new Date())) {
                popupService.error(
                    'Du hast ein temporären Password aktiviert, gültig bis: ' + convertDateToDateStr(
                        userDataProvider.current.content.passwordTempIsValidUntil) + ' ' + convertDateToTimeStr(
                        userDataProvider.current.content.passwordTempIsValidUntil) + '!<br> Bitte ändere Dein Password! (Hier klicken!)',
                    -1, () => loadPage(PAGE_PASSWORT_AENDERN))
            }
        }
        if (!userDataProvider.current.content.address?.email) {
            popupService.error(
                'Es ist keine Email für diesen Account hinterlegt! (Hier klicken!)',
                -1, () => loadPage(PAGE_PROFIL))
        }
        if (false) {
            popupService.error(
                'Wartungsarbeiten: Montag 01.04.2022 08:00-10:00 - in dieser Zeit kann es zu Störungen kommen!',
                -1)
        }

        executeRestCall(REST_CONTROLLER.BACKUP, REST_OPERATION.CREATE_IF_NEEDED,
            REST_METHODS.GET, null, result => {
                if (CONSOLE_DEBUG) console.log(result)
            })

        loadDashboard()
    }
}

actions.tryLogin = () => {
    actions.tryLogout()
    let username = $('#username').val() as string
    let password = $('#current-password').val() as string
    let companyFlag = username.split('@')[1]
    username = username.split('@')[0]
    login(username, password, companyFlag, response => {

        if (!response) {
            if (CONSOLE_DEBUG) console.debug('Login failed!', response)
            return
        }
        if (CONSOLE_DEBUG) console.debug('Login success!', response)
        let callback = () => {
            globalstate.startAutoRefresh()
            if (userDataProvider.current) {
                currentUserProvider = userDataProvider

                if (userDataProvider.current.content.languageKey) {
                    const _saved = Cookies.get('language')
                    if (_saved !== userDataProvider.current.content.languageKey) {
                        Cookies.set('language',
                            userDataProvider.current.content.languageKey)
                        document.dispatchEvent(new Event('languageChanged'))
                    }
                }
            }

            if (userDataProvider.current.content.passwordTempIsValidUntil) {
                if (moment(
                    userDataProvider.current.content.passwordTempIsValidUntil)
                    .isAfter(new Date())) {
                    popupService.error(
                        'Du hast ein temporären Password aktiviert, gültig bis: ' + convertDateToDateStr(
                            userDataProvider.current.content.passwordTempIsValidUntil) + ' ' + convertDateToTimeStr(
                            userDataProvider.current.content.passwordTempIsValidUntil) + '!<br> Bitte ändere Dein Password! (Hier klicken!)',
                        -1, () => loadPage(PAGE_PASSWORT_AENDERN))
                }
            }
            if (!userDataProvider.current.content.address?.email) {
                popupService.error(
                    'Es ist keine Email für diesen Account hinterlegt! (Hier klicken!)',
                    -1, () => loadPage(PAGE_PROFIL))
            }
            if (false) {
                popupService.error(
                    'Wartungsarbeiten: Montag 01.04.2022 08:00-10:00 - in dieser Zeit kann es zu Störungen kommen!',
                    -1)
            }

            executeRestCall(REST_CONTROLLER.BACKUP,
                REST_OPERATION.CREATE_IF_NEEDED, REST_METHODS.GET, null,
                result => {
                    if (CONSOLE_DEBUG) console.log(result)
                })

            loadDashboard()
        }
        globalstate.refresh(callback)
    })
}

actions.resetPassword = () => {
    actions.tryLogout()
    let username = $('#username').val() as string
    let companyFlag = username.split('@')[1]
    username = username.split('@')[0]
    passwordReset(username, companyFlag)
}

actions.tryLogout = () => {
    activeJobDataProvider.clearCache()
    companyDataProvider.clearCache()
    jobDataProvider.clearCache()
    jobGroupDataProvider.clearCache()
    taskTypeDataProvider.clearCache()
    userDataProvider.clearCache()
    userGroupDataProvider.clearCache()
    userTypeDataProvider.clearCache()

    if (!isOnline()) {
        return
    }
    logout(response => {
        if (CONSOLE_DEBUG) console.log('Logout success!', response)
        loadLogin()
    })
}

function loadDashboard() {
    loadPage(PAGE_DASHBOARD)
}

actions.loadDashboard = loadDashboard

function loadEinstellungen() {
    loadPage(PAGE_EINSTELLUNGEN)
}

actions.loadEinstellungen = loadEinstellungen

export function loadLogin(pageLoadedCallback: Function | null = null) {
    popupService.removeAllPopUps()
    loadPage(PAGE_LOGIN, pageLoadedCallback)
}

actions.loadLogin = loadLogin

actions.loadMitarbeiterGruppen = (pageLoadedCallback) => loadPage(
    PAGE_MITARBEITER_GRUPPEN, pageLoadedCallback)
actions.loadMitarbeiter = () => loadPage(PAGE_MITARBEITER)
actions.loadAuftraggeber = () => loadPage(PAGE_AUFTRAGGEBER)

export function loadBuildings(customer: ICustomerDto) {
    loadPage(PAGE_GEBAEUDE, () => {
        footer.addParent(pageNameGt(PAGE_AUFTRAGGEBER),
            actions.loadAuftraggeber)
        actions.buildingListPage = new BuildingListPage(customer)
        actions.buildingListPage.initAfterHtmlLoaded(
            actions.buildingListPage.fillTable)
        actions.buildingListPage.buildingEditModal.loadHtml()
    })
}

actions.loadCurrentPage = loadCurrentPage

function isPageAllowedWithoutBegingLoggedIn(pageName: string): boolean {
    return (pageName === PAGE_LOGIN || pageName === PAGE_PASSWORT_VERGESSEN || pageName === PAGE_IMPRESSUM || pageName === PAGE_DATENSCHUTZ || pageName === PAGE_AGB || pageName === PAGE_REGISTER || pageName === DEBUG_PAGE)
}

function loadDebugPage() {
    loadPage(DEBUG_PAGE)
}

function checkIsLoginAndIfNotThenGotToLoginPage() {
    if (isPageAllowedWithoutBegingLoggedIn(actions.currentPage)) {
        return
    }
    if (DEBUG_PAGE.length > 0) {
        loadDebugPage()
    } else if (!isOnline()) {
        loadLogin()
    } else {
        checkIsOnline(isOnline => {
            if (!isOnline) {
                loadLogin()
            }
        })
    }
}

export function loadCurrentPage() {
    if (!isOnline()) {
        actions.currentPage = PAGE_LOGIN
        loadPage(actions.currentPage)
        return
    }
    globalstate.refresh(() => {
        let newUrl = window.location.hash.split('#')
        if (newUrl.length > 1) {
            if (newUrl[1] !== 'undefined') {
                actions.currentPage = newUrl[1];
            } else {
                actions.currentPage = PAGE_DASHBOARD
            }
        }
        loadPage(actions.currentPage)
    });
}

export var DIALOG_OPEN = false
export var DIALOG_OPEN_ALERT_OPEN = false

window.onhashchange = function (event) {
    if (isOnline()) {
        if (DIALOG_OPEN_ALERT_OPEN) {
            DIALOG_OPEN_ALERT_OPEN = false
            return
        }
        if (DIALOG_OPEN) {
            DIALOG_OPEN_ALERT_OPEN = true
            setHistory(actions.currentPage)
            alert('Bitte erst den Dialog schließen!')
            return
        }
    } else {
        DIALOG_OPEN_ALERT_OPEN = false
        DIALOG_OPEN = false
    }
    // @ts-ignore
    let newUrl = event.newURL
    // @ts-ignore
    newUrl = newUrl.split('#')
    if (newUrl.length > 1) {
        if (actions.currentPage !== newUrl[1]) {
            loadPage(newUrl[1], null, null)
        }
    }
}

export function setHistory(page: string) {
    if (page && page !== "undefined" && page !== "null") {
        window.location.hash = page
    } else {
        throw Error("page name undefined")
    }
}

export function setDialogOpen() {
    DIALOG_OPEN = true
}

export function setDialogClosed() {
    DIALOG_OPEN = false
}

let pageHtmlCache = new HashMap<JQuery>()

export function clearPageCache() {
    pageHtmlCache = new HashMap<JQuery>()
}

//#### INTERNAL FUNCTIONS ####
function loadPage(pageName: string, pageLoadedCallback: Function | null = null,
                  data: any | null = null,
                  forceReload: boolean | null = false) {
    if (!pageName || pageName === "undefined" || pageName === "null") {
        pageName = PAGE_LOGIN
    }
    if (pageName === PAGE_LOGIN && isOnline()) {
        pageName = PAGE_DASHBOARD;
    }
    setHistory(pageName)
    if (pageLoadedCallback && actions.currentPage === pageName) {
        pageLoadedCallback()
        return
    }
    if (CONSOLE_DEBUG) console.log('Try loading page: ' + pageName)

    if (!isPageAllowedWithoutBegingLoggedIn(pageName)) {
        if (!isOnline()) {
            if (CONSOLE_DEBUG) console.log(
                'Not login, goto Login Page (current page: ' + pageName + ')')
            loadLogin()
            return
        } else {
            checkIsLoginAndIfNotThenGotToLoginPage()
        }
    }

    actions.currentPage = pageName

    let pageHtmlFileName = getHtmlFileNameFromPageName(pageName)
    let pageHtml = pageHtmlCache.get(pageHtmlFileName)
    if (pageHtml) {
        if (CONSOLE_DEBUG) console.log('use cached page: ' + pageName)
        getRootHtml().empty()
        getRootHtml().append(pageHtml)
        setPageTranslationTexts()
        handlePageLoaded(pageName, pageLoadedCallback, data)
    } else {
        if (CONSOLE_DEBUG) console.log('Load page: ' + pageName)
        getRootHtml()
            .load('pages/' + pageHtmlFileName + '.html?' + FILE_VERSION, () => {
                if (isOnline()) {
                    globalstate.refresh();
                }
                if (CONSOLE_DEBUG) {
                    console.log('Loaded page: ' + pageName + ' -> load header')
                }
                $('#header').load('modules/header.html?' + FILE_VERSION, () => {
                    if (CONSOLE_DEBUG) console.log(
                        'Haeder loaded page: ' + pageName + ' -> load footer')
                    if (!isOnline()) {
                        //TODO funktioniert mit gecachten pages nicht
                        $('.ifLogin').remove()
                    }
                    $('#footer')
                        .load('modules/footer.html?' + FILE_VERSION, () => {
                            if (CONSOLE_DEBUG) console.log(
                                'Footer loaded page: ' + pageName)
                            $('#currentPage').text(pageNameGt(pageName))

                            pageHtmlCache.put(actions.currentPage,
                                $(getRootHtml().children()[0]))
                            handlePageLoaded(pageName, pageLoadedCallback, data)
                            if (CONSOLE_DEBUG) console.log(
                                'Page loading finished: ' + pageName)
                            Tooltip.init()
                        })
                })
                setPageTranslationTexts()
            })
    }
}

function getHtmlFileNameFromPageName(pageName: string): string {
    switch (pageName) {
        case PAGE_MITARBEITER_FORMULAR:
        case PAGE_AUFTRAGGEBER_FORMULAR:
        case PAGE_AUFTRAGGEBER_KONTAKTE_FORMULAR:
        case PAGE_AUFTRAGGEBER_OBJEKTE_FORMULAR:
            return 'formular'
        default:
            return pageName
    }
}

function getRootHtml(): JQuery {
    return $($('#' + HTML_ROOT_ID))
}

function cookieBannerRise() {
    const _cookieState = Cookies.get('cookies')
    if (!_cookieState || _cookieState !== 'accepted') {
        cookieBanner.loadHtml()
    }
}

function handlePageLoaded(pageName: string,
                          pageLoadedCallback: Function | null = null,
                          data: any) {
    document.dispatchEvent(new Event('handlePageLoaded'))

    cookieBannerRise()

    switch (pageName) {
        case PAGE_AUFTRAGGEBER_OBJEKTE_FORMULAR: {
            BuildingsFormular.instance.fill(data)
            break
        }
        case PAGE_AUFTRAGGEBER_KONTAKTE_FORMULAR: {
            ContactFormular.instance.fill(data)
            break
        }
        case PAGE_AUFTRAGGEBER_FORMULAR: {
            auftraggeberFormular.fill(data)
            break
        }
        case PAGE_MITARBEITER_FORMULAR: {
            maFormular.fill(data)
            break
        }
        case PAGE_MITARBEITER: {
            mitarbeiterListPage.initAfterHtmlLoaded(
                mitarbeiterListPage.fillTable)
            break
        }
        case PAGE_EINSTELLUNGEN_KALENDAR: {
            kalendarPage.fill(getRootHtml())
            footer.addParent(pageNameGt(PAGE_EINSTELLUNGEN),
                actions.loadEinstellungen)
            break
        }
        case PAGE_EINSTELLUNGEN_TAGESANSICHT: {
            tagesansichtPage.fill(getRootHtml())
            footer.addParent(pageNameGt(PAGE_EINSTELLUNGEN),
                actions.loadEinstellungen)
            break
        }
        case PAGE_EINSTELLUNGEN_WOCHENANSICHT: {
            wochenansichtPage.fill(getRootHtml())
            footer.addParent(pageNameGt(PAGE_EINSTELLUNGEN),
                actions.loadEinstellungen)
            break
        }
        case PAGE_PROFIL: {
            profilPage.fill(getRootHtml())
            footer.addParent(pageNameGt(PAGE_EINSTELLUNGEN),
                actions.loadEinstellungen)
            break
        }
        case PAGE_PASSWORT_AENDERN: {
            passwortAendernPage.fill(getRootHtml())
            footer.addParent(pageNameGt(PAGE_EINSTELLUNGEN),
                actions.loadEinstellungen)
            break
        }
        case PAGE_FIRMENDATEN: {
            companyPage.fill(getRootHtml())
            footer.addParent(pageNameGt(PAGE_EINSTELLUNGEN),
                actions.loadEinstellungen)
            break
        }
        case PAGE_BANKVERBINDUNG: {
            bankPage.fill(getRootHtml())
            footer.addParent(pageNameGt(PAGE_EINSTELLUNGEN),
                actions.loadEinstellungen)
            break
        }
        case PAGE_EINSTELLUNGEN_LAGE: {
            lagePage.fill(getRootHtml())
            footer.addParent(pageNameGt(PAGE_EINSTELLUNGEN),
                actions.loadEinstellungen)
            break
        }
        case PAGE_EINSTELLUNGEN_LEISTUNGSART: {
            taskTypePage.fill(getRootHtml())
            taskTypeAbbreviationPage.fill(getRootHtml())
            footer.addParent(pageNameGt(PAGE_EINSTELLUNGEN),
                actions.loadEinstellungen)
            break
        }
        case PAGE_BACKUP:
        case PAGE_EXPORT_IMPORT:
        case PAGE_TUTORIALS:
        case PAGE_RECHNUNGEN:
        case PAGE_ACCOUNT_VERWALTEN: {
            footer.addParent(pageNameGt(PAGE_EINSTELLUNGEN),
                actions.loadEinstellungen)
            break
        }
        case PAGE_MITARBEITER_GRUPPEN: {
            footer.addParent(pageNameGt(PAGE_MITARBEITER),
                actions.loadMaFormular)
            mitarbeiterGruppenPage.initPage()
            break
        }
        case PAGE_AUFTRAGGEBER: {
            auftraggeberListPage.initAfterHtmlLoaded(
                auftraggeberListPage.fillTable)
            break
        }
        case PAGE_CALENDAR_DAY_VIEW: {
            maHinzufuegenModal.loadHtml()
            //CHANGED: auftragModal.loadHtml()
            newOrderModal.loadHtml()
            activeJobModal.loadHtml()
            deleteJobModal.loadHtml()
            break
        }
        case PAGE_CALENDAR_WEEK_VIEW: {
            calendarWeekView.fill(data)
            maHinzufuegenModal.loadHtml()
            workerAwayModal.loadHtml()
            //CHANGED:  auftragModal.loadHtml()
            newOrderModal.loadHtml()
            activeJobModal.loadHtml()
            deleteJobModal.loadHtml()
            break
        }
        case PAGE_LOGIN: {
            onEnter($('#current-password'), actions.tryLogin)
            break
        }
        case PAGE_EINSAETZE: {
            einsaetzeListPage.initAfterHtmlLoaded(einsaetzeListPage.fillTable)
            maHinzufuegenModal.loadHtml()
            workerAwayModal.loadHtml()
            newOrderModal.loadHtml()
            activeJobModal.loadHtml()
            deleteJobModal.loadHtml()
            selectMaForEinsatzReport.loadHtml()
            break
        }
        case PAGE_AUSWERTUNG: {
            evaluationsListPage.initAfterHtmlLoaded(
                evaluationsListPage.fillTable)
            maHinzufuegenModal.loadHtml()
            workerAwayModal.loadHtml()
            newOrderModal.loadHtml()
            activeJobModal.loadHtml()
            deleteJobModal.loadHtml()
            selectMaForEinsatzReport.loadHtml()
            break
        }
    }
    setPageTitle(pageName)

    //set visible
    //    $("#" + HTML_ROOT_ID).remove();
    //    container.css("display", "inline");
    //    setHtmlId(container, HTML_ROOT_ID);
    if (pageLoadedCallback) pageLoadedCallback()
}

function setPageTitle(pageName: string) {
    $('#page-title').text(pageTitleGt(pageName))
}

function startShedularForCheckIsLoginAndIfNotThenGotToLoginPage() {
    checkIsLoginAndIfNotThenGotToLoginPage()
    //setTimeout(startShedularForCheckIsLoginAndIfNotThenGotToLoginPage, 60000)
}

startShedularForCheckIsLoginAndIfNotThenGotToLoginPage()
