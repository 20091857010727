import {AbstractSettingWithBindingsPage, DataToHtmlBinding} from './abstract_settings_page'
import {companyDataProvider, CompanyDataProvider, CompanyDto, ICompanyDto} from '../../model/company'

export class BankPage extends AbstractSettingWithBindingsPage<ICompanyDto, CompanyDataProvider> {
	constructor() {
		super('settings-bank', companyDataProvider, [
			new DataToHtmlBinding('name', 'bankName', false),
			new DataToHtmlBinding('iban', 'iban', false),
			new DataToHtmlBinding('bic', 'bic', false)
		])
	}

	public customFill2(target: JQuery, loadedCompany: ICompanyDto) {}

	protected createDto(): ICompanyDto {
		return new CompanyDto()
	}
}

export const bankPage = new BankPage()
