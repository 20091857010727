import {JobType} from '../../model/job'
import { Tooltip } from '../../tools/tooltip';

export enum RowType {
	once = 'once',
	repeating = 'repeating',
	ma = 'ma'
}

export function convertJobTypeToRowType(jobType: JobType | null): RowType {
	switch (jobType) {
		case JobType.FOUR_WEEKS:
		case JobType.THREE_WEEKS:
		case JobType.TWO_WEEKS:
		case JobType.WEEKLY:
		case JobType.EVERY_X_WEEKS:
		case JobType.YEARLY:
			return ( Tooltip.init(), RowType.repeating)
		default:
			return RowType.once
	}
}
