import {AbstractDto, AbtractDataProvider, IAbstractDto} from './model_base'
import {DayOfWeek, toDayOfWeek} from './dayofweek'
import {ICallback, toNumber} from '../tools/tools'
import {addOption, fillSelectWithTimes} from '../tools/templateTools'
import {REST_CONTROLLER} from '../tools/restConsties'
import {globalstate} from "./globalstate";

export interface ICalendarSettingsDto extends IAbstractDto {
    stepsInH: number | null
    dayStartTime: number | null
    dayEndTime: number | null

    showTimeInDecimal: boolean | null

    weekStartDay: DayOfWeek | null
    weekEndDay: DayOfWeek | null

    floorNames: string[] | null
    positionNames: string[] | null
}

export class CalendarSettingsDto extends AbstractDto implements ICalendarSettingsDto {
    stepsInH: number | null = null
    dayStartTime: number | null = null
    dayEndTime: number | null = null

    showTimeInDecimal: boolean | null = null

    weekStartDay: DayOfWeek | null = null
    weekEndDay: DayOfWeek | null = null

    floorNames: string[] | null = null
    positionNames: string[] | null = null
}

export class CalendarSettingsDataProvider extends AbtractDataProvider<ICalendarSettingsDto> {
    constructor() {
        super(REST_CONTROLLER.CALENDAR_SETTINGS)
    }

    protected getUserGlobalState(): boolean {
        return true;
    }

    convert(source: ICalendarSettingsDto): ICalendarSettingsDto {
        if (source) {
            source.weekStartDay = toDayOfWeek(source.weekStartDay)
            source.weekEndDay = toDayOfWeek(source.weekEndDay)
        }
        return super.convert(source)
    }

    public fillSelectWithTimes(target: JQuery, start: number, end: number,
                               filter: ICallback<number, boolean> | null = null,
                               converterForLabel: ICallback<number, string> | null = null) {
        if (this.current && this.current.content.stepsInH) {
            fillSelectWithTimes(target, start, end,
                toNumber(this.current.content.stepsInH), filter, converterForLabel)
        }
    }

    public fillSelectWithFloorNames(target: JQuery) {
        target.empty()
        addOption(target, '', '', true)
        this.current.content.floorNames?.forEach(name => {
            addOption(target, name, name)
        })
    }

    public fillSelectWithPositionNames(target: JQuery) {
        target.empty()
        addOption(target, '', '', true)
        this.current.content.positionNames?.forEach(name => {
            addOption(target, name, name)
        })
    }

    createDto(): ICalendarSettingsDto {
        return new CalendarSettingsDto()
    }
}

export const calendarSettingsDataProvider = new CalendarSettingsDataProvider()
globalstate.calendarSettingsDataProvider = calendarSettingsDataProvider
