import {actionsInternal} from '../../page_manager'
import {PAGE_CALENDAR_WEEK_VIEW, PAGE_EINSAETZE} from '../../../consties'
import {calendarWeekView} from '../../calendar/calendar_week_view'
import {einsaetzeListPage} from '../../list/einsaetze_list_page'

export function refreshUIAfterJobChanged(force: boolean) {
    if (actionsInternal.currentPage === PAGE_CALENDAR_WEEK_VIEW) {
        calendarWeekView.refresh(force)
    } else if (actionsInternal.currentPage === PAGE_EINSAETZE) {
        einsaetzeListPage.fillTable()
    }
}
