import {IUserDto, UserDataProvider, userDataProvider} from '../../model/user'
import {
    findTemplateAndSetText, onClick, setDisabledVisual
} from '../../tools/templateTools'
import {mitarbeiterModal} from '../modals/mitarbeiter_edit_modal'
import {AbstractListPageWithName} from './abstract_list_page'
import {AbstractEditModal} from '../modals/abstract_edit_modal'
import {ICallback} from '../../tools/tools'
import {loadMaFormular} from '../page_manager'
import {Tooltip} from '../../tools/tooltip'
import moment from "moment/moment";
import {downloadFileFromServer} from "../../tools/rest";
import {DownloadFileTypes} from "../../tools/file_tools";
//table
const HTML_ID_MITARBEITER_TBODY = 'Mitarbeiter_TBODY'
const HTML_ID_MITARBEITER_TR_TEMPLATE = 'Mitarbeiter_TR_Template'
const HTML_ID_MITARBEITER_TR = 'Mitarbeiter_TR_'
const HTML_ID_MITARBEITER_TR_TEMPLATE_FIRSTNAME = 'Mitarbeiter_TR_Firstname'
const HTML_ID_MITARBEITER_TR_TEMPLATE_PN = 'Mitarbeiter_TR_PN'
const HTML_ID_MITARBEITER_TR_TEMPLATE_LASTNAME = 'Mitarbeiter_TR_Lastname'
const HTML_ID_MITARBEITER_TR_TEMPLATE_PHONENAME = 'Mitarbeiter_TR_Phone'
const HTML_ID_MITARBEITER_TR_TEMPLATE_EMAILNAME = 'Mitarbeiter_TR_Email'
const HTML_ID_MITARBEITER_TR_TEMPLATE_IS_ACTIVE = 'Mitarbeiter_TR_isActive'
const HTML_ID_MITARBEITER_TR_TEMPLATE_COMMENT = 'Mitarbeiter_TR_comment'
const HTML_ID_MITARBEITER_TR_TEMPLATE_DELETE = 'Mitarbeiter_TR_delete'
const HTML_ID_MITARBEITER_TR_TEMPLATE_EDIT = 'Mitarbeiter_TR_edit'

export class MitarbeiterListPage extends AbstractListPageWithName<IUserDto, UserDataProvider> {
    constructor() {
        super(userDataProvider, HTML_ID_MITARBEITER_TBODY,
            HTML_ID_MITARBEITER_TR_TEMPLATE, HTML_ID_MITARBEITER_TR)
    }

    protected getModal(): AbstractEditModal<IUserDto, UserDataProvider> {
        return mitarbeiterModal
    }

    protected loadList(success: ICallback<IUserDto[], void>) {
        this.dataProvider.loadListAllInternalManaged(success, true, true)
    }

    protected loadCurrentPage() {
        const results = this.pagination.paginatedResult()
        const dtos = results.list
        this.updateTable(dtos)
        Tooltip.init()
    }

    public fillTable() {
        this.loadList(dtos => {
            this.dtos = dtos
            this.pagination.setPage(1)
            this.pagination.saveList(dtos)
            this.pagination.initElements()
            this.loadCurrentPage()
        })

        document.addEventListener('searchEvent', () => {
            setTimeout(() => {
                this.loadCurrentPage()
            }, 250)
        })

        document.addEventListener('updatePage', () => {
            setTimeout(() => {
                this.loadCurrentPage()
            }, 150)
        })

        onClick(this.getCsvBtnHtml(), () => {
            this.loadCsvWorker()
        })
    }

    private loadCsvWorker() {
        const currentDateAndTimeStr = moment().format('YYYY-MM-DD_HH-mm-ss')
        downloadFileFromServer('/user/1/internal/allmanaged/export/csv', null,
            "TIM-Mitarbeiter_" + currentDateAndTimeStr + ".csv",
            DownloadFileTypes.CSV);
    }
    protected fillTableEntry(user: IUserDto, tableEntry: JQuery): void {
        findTemplateAndSetText(tableEntry,
            HTML_ID_MITARBEITER_TR_TEMPLATE_PN, user.customId)
        findTemplateAndSetText(tableEntry,
            HTML_ID_MITARBEITER_TR_TEMPLATE_FIRSTNAME, user.firstname)
        findTemplateAndSetText(tableEntry,
            HTML_ID_MITARBEITER_TR_TEMPLATE_LASTNAME, user.lastname)
        if (user.address) {
            findTemplateAndSetText(tableEntry,
                HTML_ID_MITARBEITER_TR_TEMPLATE_PHONENAME, user.address.phone)
            findTemplateAndSetText(tableEntry,
                HTML_ID_MITARBEITER_TR_TEMPLATE_EMAILNAME, user.address.email)
        }

        //comment
        this.createComment(user, tableEntry,
            HTML_ID_MITARBEITER_TR_TEMPLATE_COMMENT)

        //is active
        if (userDataProvider.current.content.id !== user.id) {
            this.createIsActive(user, tableEntry,
                HTML_ID_MITARBEITER_TR_TEMPLATE_IS_ACTIVE)
        } else {
            setDisabledVisual(tableEntry, true)
            let deleteBtn = tableEntry.find(
                '#' + HTML_ID_MITARBEITER_TR_TEMPLATE_IS_ACTIVE)
            deleteBtn.remove()
        }

        //edit user
        this.createEditBtn(user, tableEntry,
            HTML_ID_MITARBEITER_TR_TEMPLATE_EDIT, () => this.openForEdit(user))

        //delete
        if (userDataProvider.current.content.id !== user.id) {
            this.createDeleteBtn(user, tableEntry,
                HTML_ID_MITARBEITER_TR_TEMPLATE_DELETE)
        } else {
            let deleteBtn = tableEntry.find(
                '#' + HTML_ID_MITARBEITER_TR_TEMPLATE_DELETE)
            deleteBtn.remove()
        }
    }

    protected openForEdit(user: IUserDto) {
        loadMaFormular(user)
    }

    private getCsvBtnHtml(): JQuery {
        return $('#mitarbeiter-list-csv-objects-btn')
    }
}

export const mitarbeiterListPage = new MitarbeiterListPage()
