import {AbstractListPageWithName} from './abstract_list_page'
import {IBuildingDto} from '../../model/building'
import {findTemplateAndSetText, onClick} from '../../tools/templateTools'
import {CustomerBuildingDataProvider, customerDataProvider, ICustomerDto} from '../../model/customer'
import {BuildingEditModal} from '../modals/building_edit_modal'
import {getValueOrError} from '../../tools/tools'
import {AbstractEditModal} from '../modals/abstract_edit_modal'
import {actionsInternal} from '../page_manager'
import {DTO_CREATE_NEW} from '../formulare/formular'

export class BuildingListPage extends AbstractListPageWithName<IBuildingDto, CustomerBuildingDataProvider> {
	public buildingEditModal: BuildingEditModal
	private static customer: ICustomerDto

	constructor(customer: ICustomerDto) {
		super(
			new CustomerBuildingDataProvider(getValueOrError(customer.id)),
			'building-list-page-tbody',
			'building-list-page-template',
			'building-list-page-tr'
		)
		BuildingListPage.customer = customer
		this.buildingEditModal = new BuildingEditModal(this.dataProvider, BuildingListPage.customer)
		let subTitle = $('#building-list-page-customer-name')
		subTitle.text(
			`AG: (${BuildingListPage.customer.customId ?? BuildingListPage.customer.id}) ${customerDataProvider.getFullName(
				BuildingListPage.customer
			)}`
		)
		onClick(subTitle, actionsInternal.loadAuftraggeber)
	}

	public init() {
		this.buildingEditModal = new BuildingEditModal(this.dataProvider, BuildingListPage.customer)

		let subTitle = $('#building-list-page-customer-name')
		subTitle.text(
			`AG: (${BuildingListPage.customer.customId ?? BuildingListPage.customer.id}) ${customerDataProvider.getFullName(
				BuildingListPage.customer
			)}`
		)
		onClick(subTitle, actionsInternal.loadAuftraggeber)
	}

	public openDialogForNew() {
		actionsInternal.loadAuftraggeberObjekteFormular(BuildingListPage.customer, DTO_CREATE_NEW, null)
	}

	protected getModal(): AbstractEditModal<IBuildingDto, CustomerBuildingDataProvider> {
		return this.buildingEditModal
	}

	protected fillTableEntry(dto: IBuildingDto, tableEntry: JQuery<HTMLElement>): void {
		this.onClickOpenEdit(findTemplateAndSetText(tableEntry, 'building-list-customId', dto.customId), dto)
		this.onClickOpenEdit(findTemplateAndSetText(tableEntry, 'building-list-name', this.dataProvider.getFullName(dto)), dto)
		this.onClickOpenEdit(findTemplateAndSetText(tableEntry, 'building-list-zipcode', dto.address?.zipCode), dto)
		this.onClickOpenEdit(findTemplateAndSetText(tableEntry, 'building-list-city', dto.address?.city), dto)
		this.onClickOpenEdit(findTemplateAndSetText(tableEntry, 'building-list-street-and-nr', dto.address?.streetAndNr), dto)
		this.onClickOpenEdit(findTemplateAndSetText(tableEntry, 'building-list-comment', dto.comment), dto)

		this.createIsActive(dto, tableEntry, 'building-list-is-active', true)

		//edit user
		this.createEditBtn(dto, tableEntry, 'building-list-edit-btn', () => this.openForEdit(dto))

		//delete
		this.createDeleteBtn(dto, tableEntry, 'building-list-delete-btn')
	}

	protected openForEdit(dto: IBuildingDto) {
		actionsInternal.loadAuftraggeberObjekteFormular(BuildingListPage.customer, dto, null)
	}
}
