import {AbtractDataProvider} from './model_base'
import {
    executeRestCall, executeRestCallJson, IdParameter, nullJsonObjectConverter
} from '../tools/rest'
import {ICallback, mustNotNull} from '../tools/tools'
import {
    REST_CONTROLLER, REST_METHODS, REST_OPERATION
} from '../tools/restConsties'
import {globalstate} from "./globalstate";

export enum AWAY_TYPE {
    HOLIDAY = 'HOLIDAY', ILLNESS = 'ILLNESS', NOT_AVAILABLE = 'NOT_AVAILABLE'
}


export interface IWorkerAwayDto {
    id: number | null;
    useOnlyId: boolean | null;
    customId: string | null;
    customValue: string | null;
    comment: string | null;
    active: boolean | null;
    createTime: Date | null;
    awayType: AWAY_TYPE | null;
    fullDay: boolean | null;
    startDate: Date | null;
    endDate: Date | null;
}

export class WorkerAway implements IWorkerAwayDto {
    id: number | null = null;
    useOnlyId: boolean | null = null;
    customId: string | null = null;
    customValue: string | null = null;
    comment: string | null = null;
    active: boolean | null = null;
    createTime: Date | null = null;
    awayType: AWAY_TYPE | null = null;
    fullDay: boolean | null = null;
    startDate: Date | null = null;
    endDate: Date | null = null;
}

export class WorkerAwayDataProvider extends AbtractDataProvider<IWorkerAwayDto> {
    constructor() {
        super(REST_CONTROLLER.USER)
    }

    protected getUserGlobalState(): boolean {
        return false;
    }

    convert(source: IWorkerAwayDto): IWorkerAwayDto {
        source.startDate = new Date(mustNotNull(source.startDate));
        source.endDate = new Date(mustNotNull(source.endDate));
        source.createTime = new Date(mustNotNull(source.createTime));
        return super.convert(source);
    }

    public createDto(): IWorkerAwayDto {
        return new WorkerAway();
    }

    public getAwayDates(userId: number, success?: ICallback<any, void>) {
        executeRestCallJson(this.controller, REST_OPERATION.AWAY,
            REST_METHODS.GET, nullJsonObjectConverter, success, null, null,
            {userId});
    }

    public createAwayDate(userId: number, data: IWorkerAwayDto,
                          success?: ICallback<any, void>) {
        executeRestCallJson(this.controller, REST_OPERATION.AWAY,
            REST_METHODS.POST, nullJsonObjectConverter, success, null, data,
            {userId});
    }

    public deleteAwayDate(userId: number, awayId: number,
                          success?: ICallback<any, void>) {
        executeRestCallJson(this.controller, REST_OPERATION.AWAY_DELETE,
            REST_METHODS.DELETE, nullJsonObjectConverter, success, null, null,
            {userId, awayId});
    }
}

export const workerAwayDataProvider = new WorkerAwayDataProvider();
globalstate.workerAwayDataProvider = workerAwayDataProvider;
