export class Tooltip {
	static _attempts = 3

	static init() {
		let count = 0
		const _interval = setInterval(() => {
			count++
			const _search = document.querySelectorAll('[data-type="tooltip"]')

			if (count <= this._attempts || _search.length > 0) {
				clearInterval(_interval)
				this.initElements()
			}
		}, 100)
	}

	static initElements() {
		const _tooltips = document.querySelectorAll('[data-type="tooltip"]')
		if (!_tooltips.length) return

		_tooltips.forEach(item => {
			const tooltip = item as HTMLElement

			tooltip.addEventListener(
				'mouseenter',
				e => {
					tooltip.classList.toggle('tooltip-active', true)
					// tooltip.style.top = `${e.pageY}px`
					// tooltip.style.left = `${e.pageX}px`
					tooltip.style.maxWidth = `${window.innerWidth - 48 - e.pageX}px`
				},
				{passive: true}
			)
			tooltip.addEventListener(
				'mouseleave',
				() => {
					tooltip.classList.toggle('tooltip-active', false)
				},
				{passive: true}
			)
		})
	}
}
