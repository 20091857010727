import dragula from 'dragula';

import {
    IJobGroupDto, jobGroupDataProvider, JobGroupType
} from '../../model/job_group';
import {AbstractClass} from '../../tools/abstract_class'
import {
    addDiv,
    addElement,
    addSpan,
    addTable,
    addTd,
    addTh,
    addTr,
    addTrAfter,
    createInputElement,
    onClick,
    onEnter,
    onFocus,
    onKeyUp,
    onSearch,
    setColspan,
    setToolTip
} from '../../tools/templateTools'
import {
    addDays,
    confirmYesNo,
    convertDateToDateStr,
    convertDateToDateStrNoYear,
    convertDateToTimeNumber,
    getDayInWeekFromDays,
    getDaysBetweenDates,
    getValueOrError,
    HashMap,
    HashMapWithArray,
    mustNotNull,
    runAsync,
    toNumber,
    toStringOrEmpty
} from '../../tools/tools'
import {calendarSettingsDataProvider} from '../../model/calendar_settings'
import {convertDateToDayOfWeek, DayOfWeek} from '../../model/dayofweek'
import {convertJobTypeToRowType, RowType} from './calendar_base'
import {
    activeJobDataProvider, ActiveJobDto, IActiveJobDto
} from '../../model/active_job'
//CHANGED: import {auftragModal} from '../modals/kalendar/auftrag_modal'
import {newOrderModal} from '../modals/kalendar/new-order'
import moment from 'moment'
import {activeJobModal} from '../modals/kalendar/active_job_modal'
import {maHinzufuegenModal} from '../modals/kalendar/ma_hinzufuegen_modal'
import {
    handleWorkerAwayModalOpenForWeek, workerAwayModal
} from '../modals/kalendar/worker_away_modal'
import {IUserDto, Roles, userDataProvider, UserDto} from '../../model/user'
import {gt} from '../../tools/translation'
import {IJobDto, jobDataProvider, JobType} from '../../model/job'
import {toFixedNumber, toHours, toShortString} from '../../tools/StringTools'
import {deleteJobModal} from '../modals/kalendar/deleteJobModal'
import {IBuildingDto} from '../../model/building'
import {loadMaFormular} from '../page_manager'
import CalendarPersistence from './calendar_persistence'
import {Events} from '../../tools/calendar_widget'
import {AWAY_TYPE} from "../../model/worker_away";
import {TimeTools} from "../../tools/time_tools";
import {globalstate} from "../../model/globalstate";

class CalendarWeekView extends AbstractClass {
    private static instance: CalendarWeekView
    private currentDate: Date = new Date()
    private weekStartDay: Date = new Date()
    private weekEndDay: Date = new Date()
    private rowsByJobId = new HashMap<JQuery>()
    private activeJobsByJobId = new HashMapWithArray<IActiveJobDto>()
    private workerRows: Map<string, { row: HTMLElement; user: IUserDto }> = new Map()
    private days: DayOfWeek[] = []
    private isDragAndDropInitialized = false;
    private dragonDrop = null;
    private openGroups = new HashMap<JQuery<HTMLElement>>();

    constructor() {
        super()
        if (CalendarWeekView.instance) {
            return this
        }
        CalendarWeekView.instance = this
    }

    private DateUpdateListener = ((event: CustomEvent) => {
        const _date = event.detail.date as Date
        this.setDate(_date)
    }) as EventListener

    public fill(date: Date | null = null) {
        const calendar = document.querySelector(
            `#calendar-week-view`) as HTMLElement;
        const groupRowClass = 'calendar-week-view-row-group';
        const folderIdPrefix = '#calendar-week-view-header-folder-btn-';
        const addNewGroupIdPrefix = '#calendar-week-view-header-folder-btn-add-new-';
        const groupNameInputClass = '.calendar-week-view-folder-name';
        const groupNameInputErrorClass = 'calendar-week-view-folder-name-error';
        const groupNameInputIdPrefix = '#calendar-week-view-folder-name-';
        const cancelAddingFolderClass = 'calendar-week-view-header-folder-btn-base-cancel';
        const addNewGroupCallback = (type: RowType) => {
            const bodyIdPrefix = '#calendar-week-view-rows-';
            const groupNameInput = $(`${groupNameInputIdPrefix}${type}`);
            const groupName = groupNameInput.val() as string;

            if (groupName.length) {
                const payload = {name: groupName, type: type.toUpperCase()};

                jobGroupDataProvider.addGroup(payload, (response) => {
                    $(`${folderIdPrefix}${type}`)
                        .toggleClass(cancelAddingFolderClass);
                    $(`${groupNameInputIdPrefix}${type}`).val('');
                    globalstate.refresh(() => {
                        this.refresh(false)
                    })
                });
            } else {
                groupNameInput.addClass(groupNameInputErrorClass);
            }
        }

        this.updateTableExpandState()

        onClick($(`[data-id="Mehrmals"]`), () => {
            CalendarPersistence.setExpandState(0)
            this.updateTableExpandState()
        })
        onClick($(`[data-id="Einmalig"]`), () => {
            CalendarPersistence.setExpandState(1)
            this.updateTableExpandState()
        })
        onClick($(`[data-id="Mitarbeiter"]`), () => {
            CalendarPersistence.setExpandState(2)
            this.updateTableExpandState()
        })

        onClick($('#calendar-week-view-new-once-job'), () => {
            //CHANGED: auftragModal.openDialogForNew()
            newOrderModal.openDialogForNew()
        })

        onClick($('#calendar-week-view-current-week-btn'), () => {
            calendar.dispatchEvent(new Event(Events.SET_TODAY))
        })

        onClick($(`${folderIdPrefix}${RowType.repeating}`), () => {
            $(`${folderIdPrefix}${RowType.repeating}`)
                .toggleClass(cancelAddingFolderClass);
            $(groupNameInputClass).removeClass(groupNameInputErrorClass);
        })
        onClick($(`${folderIdPrefix}${RowType.once}`), () => {
            $(`${folderIdPrefix}${RowType.once}`)
                .toggleClass(cancelAddingFolderClass);
            $(groupNameInputClass).removeClass(groupNameInputErrorClass);
        })

        onClick($(`${addNewGroupIdPrefix}${RowType.repeating}`),
            () => addNewGroupCallback(RowType.repeating));
        onClick($(`${addNewGroupIdPrefix}${RowType.once}`),
            () => addNewGroupCallback(RowType.once));

        onEnter($(`${groupNameInputIdPrefix}${RowType.repeating}`),
            () => addNewGroupCallback(RowType.repeating));
        onEnter($(`${groupNameInputIdPrefix}${RowType.once}`),
            () => addNewGroupCallback(RowType.once));

        onFocus($(groupNameInputClass), () => {
            if ($(groupNameInputClass).hasClass(groupNameInputErrorClass)) {
                $(groupNameInputClass).removeClass(groupNameInputErrorClass);
            }
        })

        this.setDate(
            date ? date : this.currentDate ? this.currentDate : new Date())
        calendar.removeEventListener(Events.DATE_UPDATED,
            this.DateUpdateListener)
        calendar.addEventListener(Events.DATE_UPDATED, this.DateUpdateListener)
    }

    public setDate(date: Date = this.currentDate): void {
        this.currentDate = date
        this.refresh(false)
    }

    public refresh(forceRefresh: boolean = true) {
        this.rowsByJobId.clear()
        this.activeJobsByJobId.clear()
        this.days = []


        this.getRowsHtml(RowType.repeating).empty()
        this.getRowsHtml(RowType.once).empty()
        this.getRowsHtml(RowType.ma).empty()
        if (this.dragonDrop) {
            (this.dragonDrop as any).destroy();
            this.isDragAndDropInitialized = false;
        }

        this.loadDataFromServer(forceRefresh)
    }

    protected updateTableExpandState = () => {
        const _names = ['Mehrmals', 'Einmalig', 'Mitarbeiter']

        const _togglers = _names.map(name => `[data-id="${name}"]`).join(',')
        const _targets = _names.map(name => `[data-target="${name}"]`).join(',')

        const _expand = CalendarPersistence.getExpandState()

        $(_togglers).map((id, item) => (item.dataset.expand = `${_expand[id]}`))
        $(_targets).map((id, item) => (item.dataset.expand = `${_expand[id]}`))
    }


    private calculateWorkTime(workTimeStart: number[],
                              workTimeEnd: number[]): string[] {
        return workTimeStart.map((item: number, index: number) => {
            return (workTimeEnd[index] - item) + "h"
        });
    }

    private initializeDragAndDrop() {
        let containers: any[] = [];
        document.querySelectorAll('.calendar-week-view-rows-base')
            .forEach(rows => {
                containers.push(rows)
            });

        document.querySelectorAll('.calendar-week-view-group-table')
            .forEach(rows => {
                containers.push(rows);
            });

        this.dragonDrop = dragula(containers, {
            moves: (item: HTMLElement, container: HTMLElement,
                    handle: HTMLElement) => handle.classList.contains(
                'draggable')
        })
            .on('drop',
                (item: HTMLElement, target: HTMLElement, source: HTMLElement,
                 sibling: HTMLElement) => {
                    const isJobInGroup = item.getAttribute(
                        'data-parent-group-id');
                    const jobRowClass = 'calendar-week-view-row-job';
                    const emptyRowClass = 'calendar-week-view-row-empty';
                    const targetJobId = item.getAttribute('data-job-id');
                    const beforeJobId = item.previousElementSibling && item.previousElementSibling.getAttribute(
                        'data-job-id');
                    const afterJobId = sibling && sibling.getAttribute(
                        'data-job-id');
                    if (sibling) {
                        if (sibling.className === jobRowClass) {
                            if (targetJobId && afterJobId) {
                                jobDataProvider.updateSorting({
                                    targetJobId: +targetJobId, ...(beforeJobId && {beforeJobId: +beforeJobId}),
                                    afterJobId: +afterJobId
                                });
                            }
                        }
                        if (sibling.className === emptyRowClass && sibling.getAttribute(
                            'data-hide') === 'false') {
                            const groupRowClass = '.calendar-week-view-row-group';
                            const parentId = sibling.getAttribute(
                                'data-parent-group-id');
                            const jobId = item.getAttribute('data-job-id');

                            jobGroupDataProvider.addJobToGroup(Number(parentId),
                                Number(jobId), () => {
                                    const jobsCount = $(`${groupRowClass}[data-group-id='${parentId}'] .calendar-week-view-group-elements-count`);
                                    jobsCount.text(
                                        `${Number(jobsCount.text()) + 1}`);
                                    if (parentId) {
                                        item.setAttribute(
                                            'data-parent-group-id', parentId);
                                    }
                                });
                        }
                    } else {
                        if (targetJobId && beforeJobId) {
                            jobDataProvider.updateSorting({
                                targetJobId: +targetJobId,
                                beforeJobId: +beforeJobId
                            });
                        }
                    }
                    if (target !== source && isJobInGroup) {
                        jobGroupDataProvider.deleteJobFromGroup(
                            Number(item.getAttribute('data-parent-group-id')),
                            Number(item.getAttribute('data-job-id')), () => {
                                const parentId = item.getAttribute(
                                    'data-parent-group-id');
                                const groupRowClass = '.calendar-week-view-row-group';
                                const jobsCount = $(`${groupRowClass}[data-group-id='${parentId}'] .calendar-week-view-group-elements-count`);
                                jobsCount.text(
                                    `${Number(jobsCount.text()) - 1}`);
                                item.removeAttribute('data-parent-group-id');
                            });
                    }
                });
        this.isDragAndDropInitialized = true;
    }

    public createCache(date: Date, minusCacheWeeks = 1, cacheWeeks = 3) {
        const firstDay = moment(getDayInWeekFromDays(date, DayOfWeek.MONDAY))
            .subtract(minusCacheWeeks, "weeks")
        const lastDay = moment(getDayInWeekFromDays(date, DayOfWeek.SUNDAY))
            .subtract(minusCacheWeeks, "weeks")

        for (let i = 0; i < cacheWeeks; i++) {
            activeJobDataProvider.loadListBetweenDate(firstDay.toDate(),
                lastDay.toDate(), null, false, false)

            firstDay.add(1, "weeks")
            lastDay.add(1, "weeks")
        }
    }

    private loadDataFromServer(forceRefresh: boolean) {
        this.startBackBuffers();

        const firstDay = this.getMondayDate()
        const lastDay = this.getSundayDate()

        activeJobDataProvider.loadListBetweenDate(firstDay, lastDay,
            activeJobs => {
                let jobIdList: number[] = [];
                activeJobs.activeJobDtos.forEach(activeJob => {
                    if (activeJob && activeJob.job) {
                        jobIdList.push(activeJob?.job.id as number);
                    }
                    this.activeJobsByJobId.putArray(
                        mustNotNull(activeJob.job?.id), activeJob)
                })

                jobGroupDataProvider.loadListAllManaged(groups => {
                    groups.map(group => this.printGroup(group, jobIdList));
                    //find first and last weekday
                    this.weekStartDay = getDayInWeekFromDays(this.currentDate,
                        calendarSettingsDataProvider.current.content.weekStartDay)
                    this.weekEndDay = getDayInWeekFromDays(this.currentDate,
                        calendarSettingsDataProvider.current.content.weekEndDay)

                    const jobsById = new HashMap<IJobDto>();
                    activeJobs.activeJobDtos
                        .sort((jobA, jobB) => mustNotNull(
                            (jobA.job as IJobDto).sorting) - mustNotNull(
                            (jobB.job as IJobDto).sorting))
                        .forEach(activeJob => {
                            const momentActiveJob = moment(activeJob.date)
                            const momentWeekStartDay = moment(this.weekStartDay)
                            if (momentActiveJob.isBefore(momentWeekStartDay)) {
                                this.weekStartDay = momentActiveJob.toDate()
                            }

                            const momentWeekEndDay = moment(this.weekEndDay)
                            if (momentActiveJob.isAfter(momentWeekEndDay)) {
                                this.weekEndDay = momentActiveJob.toDate()
                            }

                            if (jobsById.contains(activeJob.job?.id!)) {
                                return
                            }
                            jobsById.put(activeJob.job?.id!, activeJob.job!)
                            this.printObjektAndLeistung(activeJob)
                        })
                    this.weekStartDay.setHours(0, 0, 0)
                    this.weekEndDay.setHours(23, 59, 59)


                    //### JOBS ###
                    //print job timeline
                    this.createTimeLine($('#calendar-week-view-timeline'),
                        false, true)

                    //active jobs
                    this.activeJobsByJobId.forEach(activeJobs => {
                        const activeJob = activeJobs[0]
                        const row = this.getJobRow(activeJob)
                        const rowType = convertJobTypeToRowType(
                            mustNotNull(activeJob.job).jobType)
                        let jobsCount = 0;
                        for (let date = this.weekStartDay; moment(date)
                            .isSameOrBefore(
                                moment(this.weekEndDay)); date = addDays(date,
                            1)) {
                            const dateStr = convertDateToDateStr(date)
                            const activeJob = activeJobs.find(
                                activeJob => convertDateToDateStr(
                                    mustNotNull(activeJob.date)) === dateStr)
                            const jobTd = addTd(row)
                            jobTd.addClass('empty-dark-row')
                            if (activeJob) {
                                const maCount = activeJob.activeUserJobs ?
                                    activeJob.activeUserJobs.length : 0

                                jobTd.addClass('calendar-week-view-base')
                                jobTd.addClass('calendar-week-view-' + rowType)

                                const div1 = addDiv(jobTd)
                                div1.addClass('comment-edit')
                                if (activeJob.job?.jobType === JobType.ONCE) {
                                    div1.addClass('comment-orange')
                                }

                                //##### Status / Zeit / Edit
                                const workTimeAndEdit = addSpan(div1)
                                workTimeAndEdit.addClass(
                                    'calendar-week-view-clickable clickable SC-hour_red_green')
                                //red or green?
                                //sc-hour_red or sc-hour_green
                                let workHoursBooked = (activeJob as ActiveJobDto).calculateBookedTime();
                                workTimeAndEdit.addClass(
                                    workHoursBooked === activeJob.workHours ?
                                        'sc-hour_green' : 'sc-hour_red')
                                workTimeAndEdit.text(
                                    toFixedNumber(activeJob.workHours))
                                //Edit ActiveJob
                                onClick(workTimeAndEdit,
                                    () => activeJobModal.openDialogForEdit(
                                        activeJob))
                                //##### Mitarbeiter
                                const bookedMa = addSpan(div1)
                                bookedMa.addClass(
                                    'calendar-week-view-clickable clickable comment-edit-user')
                                if (activeJob.activeUserJobs) {
                                    const nameUser = activeJob.activeUserJobs?.map(
                                        (user) => toStringOrEmpty(
                                            user.firstname) + "  " + toStringOrEmpty(
                                            user.lastname))
                                    const startHour = activeJob.activeUserJobs?.map(
                                        (job) => convertDateToTimeNumber(
                                            job.startTime));
                                    const endHour = activeJob.activeUserJobs?.map(
                                        (job) => convertDateToTimeNumber(
                                            job.endTime, true));
                                    const diff = this.calculateWorkTime(
                                        startHour, endHour)
                                    const arrayNameStundens = nameUser.map(
                                        (user, index) => [user,
                                            diff && diff[index]]);
                                    const namenStunden = arrayNameStundens.map(
                                        (arrayNameStundens) => arrayNameStundens.join(
                                            ': ')).join('\n')
                                    //TODO to slow
                                    // bookedMa.attr('data-type', 'tooltip');
                                    // bookedMa.attr('data-content', `${namenStunden}`)
                                    setToolTip(bookedMa, `${namenStunden}`)
                                }
                                const maIcon = addElement(bookedMa, 'i')
                                maIcon.addClass('fas i-Mitarbeiter')
                                maIcon.css('margin-right', '5px')
                                //add ma count
                                bookedMa.append(
                                    '' + maCount + (activeJob.hasDifferentToTheOriginalJob ?
                                        "*" : ""))
                                onClick(bookedMa, () => {
                                    maHinzufuegenModal.openDialogForEdit(
                                        activeJob,
                                        () => maHinzufuegenModal.setDefaultStart(
                                            activeJob && activeJob.date ?
                                                activeJob.date : new Date()))
                                })

                                //##### delete
                                const deleteBtn = addSpan(div1)
                                deleteBtn.addClass(
                                    'calendar-week-view-clickable clickable comment-edit-close')
                                const deleteIcon = addElement(deleteBtn, 'i')
                                deleteIcon.addClass('fas i-Close')
                                //delete
                                onClick(deleteIcon, () => {
                                    if (activeJob && activeJob.id) {
                                        deleteJobModal.showDialogWithData(
                                            activeJob, () => {
                                                this.refresh(true)
                                            })
                                    }
                                })
                                const chatBtn = addSpan(div1)
                                chatBtn.addClass(
                                    'calendar-week-view-clickable clickable ic_checkmark_white')
                                const chatIcon = addElement(chatBtn, 'i')
                                chatIcon.addClass('fas i-Chat')
                                //TODO Chat
                                //ic_chat_blank_white
                                //ic_chat_written_white
                                //ic_new_message
                                //ic_checkmark_white
                            }
                            jobsCount++
                        }
                        row.attr('data-job-columns', jobsCount)
                    });


                    //### Mitarbeiter
                    //print job timeline
                    this.createTimeLine($('#calendar-week-view-timeline-ma'),
                        true, false)

                    this.workerRows.clear()
                    const userDtos = userDataProvider.loadListAllInternalManagedCache?.content!;
                    for (const ma of userDtos) {
                        this.addMaRow(ma, activeJobs.activeJobDtos);
                    }


                    this.showBackBuffers();

                    this.onFilterType2(
                        this.getSearchWorkerFilter()[0]! as HTMLInputElement)
                    this.onFilterType2(
                        this.getSearchJobFilter()[0]! as HTMLInputElement);

                    if (!this.isDragAndDropInitialized) {
                        this.initializeDragAndDrop();
                    }

                    //open groups
                    this.openGroups.forEachWithKeys(
                        openGroup => this.openGroup(openGroup[1],
                            openGroup[0] as number, true));

                    document.dispatchEvent(new Event('handlePageLoaded'))
                });


            }, forceRefresh);

        runAsync(() => this.createCache(firstDay));

    }


    private getMondayDate(): Date {
        return getDayInWeekFromDays(this.currentDate, DayOfWeek.MONDAY)
    }

    private getSundayDate(): Date {
        return getDayInWeekFromDays(this.currentDate, DayOfWeek.SUNDAY)
    }

    private onFilterType(e: JQuery.TriggeredEvent) {
        const target = e.target as HTMLInputElement
        this.onFilterType2(target);
    }

    private onFilterType2(target: HTMLInputElement) {
        const expandState = CalendarPersistence.getExpandState()
        const type = target.dataset.type?.includes('object') ? 'object' :
            'person'
        const value = target.value.toLowerCase().trim()

        if (type === 'object') {
            if (!expandState[0]) {
                CalendarPersistence.setExpandState(0)
                this.updateTableExpandState()
            }

            let visibleGroups = new Map();

            this.activeJobsByJobId.forEach(activeJobs => {
                const activeJob = activeJobs[0]
                const row = this.getJobRow(activeJob)
                const groupId = row.attr('data-parent-group-id');
                const group = $(`.calendar-week-view-row-group[data-group-id=${groupId}]`);
                const emptyRow = $(`.calendar-week-view-row-empty[data-parent-group-id=${groupId}]`);
                const openedGroupClass = 'calendar-week-view-row-group-opened';

                if (value.length === 0) {
                    row.attr('data-hide', 'false');
                    emptyRow.attr('data-hide', 'true');
                    if (groupId) {
                        row.attr('data-hide', 'true');
                    }
                    group.removeClass(openedGroupClass)
                        .attr('data-hide', 'false');
                    return
                }

                const object = activeJob.job?.building
                if (!row || !object) {
                    row.attr('data-hide', 'true')
                    return
                }

                const _result = object.customId?.toLowerCase()
                    .includes(value) || object.name?.toLowerCase()
                    .includes(value) || object.shortName?.toLowerCase()
                    .includes(value) || activeJob.job?.customId?.toLowerCase()
                    .includes(value)
                if (row.attr('data-parent-group-id')) {
                    if (!visibleGroups.get(groupId)) {
                        if (_result) {
                            visibleGroups.set(groupId, true);
                            group.addClass(openedGroupClass);
                            group.attr('data-hide', 'false');
                        } else {
                            group.removeClass(openedGroupClass);
                            group.attr('data-hide', 'true');
                        }
                        $(`.calendar-week-view-row-empty[data-parent-group-id=${groupId}]`)
                            .attr('data-hide', `${!_result}`);
                    }
                }
                row.attr('data-hide', `${!_result}`)
            })
        } else if (type === 'person') {
            if (!expandState[2]) {
                CalendarPersistence.setExpandState(2)
                this.updateTableExpandState()
            }

            this.workerRows.forEach(item => {
                const user = item.user
                const row = item.row
                if (user.id?.toString()
                    .includes(value) || user.firstname?.toLowerCase()
                    .includes(value) || user.lastname?.toLowerCase()
                    .includes(value) || user.name?.toLowerCase()
                    .includes(value) || user.customId?.toLowerCase()
                    .includes(value) || user.comment?.toLowerCase()
                    .includes(value)) {
                    row.dataset.hide = 'false'
                } else if (value.length === 0) {
                    row.dataset.hide = 'false'
                } else {
                    row.dataset.hide = 'true'
                }
            })
        }
    }

    private getSearchWorkerFilterValue(): string {
        let searchField = this.getSearchWorkerFilter();
        return !searchField[0] ? "" : searchField.val() + "";
    }

    private getSearchWorkerFilter() {
        return $('#calendar-week-view-search-person');
    }

    private getSearchJobFilterValue(): string {
        let searchField = this.getSearchJobFilter();
        return !searchField[0] ? "" : searchField.val() + "";
    }

    private getSearchJobFilter() {
        return $('#calendar-week-view-search-object');
    }

    private createSearchFilter(target: JQuery, type: 'object' | 'person',
                               value: string) {
        const alreadyExist = target.find('.search')
        if (alreadyExist[0]) {
            return
        }
        const searchField = createInputElement('search', 'Suche')
        searchField.val(value);

        searchField.attr('id', `calendar-week-view-search-${type}`)
        searchField.attr('data-type', `${type}`)
        searchField.addClass('form-control')
        searchField.attr('data-translation-key', `form.field.search.${type}`)
        const search = addDiv(target, searchField)
        search.addClass('search')
        search.attr('data-non-translate', 'true')
        onKeyUp(searchField, this.onFilterType)
        onSearch(searchField, this.onFilterType)
    }

    private createTimeLine(target: JQuery, isMa: boolean, fillDays: boolean) {
        let filterJob = this.getSearchJobFilterValue();
        let filterWorker = this.getSearchWorkerFilterValue();
        target.empty()
        const _timeline = gt('page.calendar.table.timeline')
        const _head = gt('page.calendar.table.head')

        if (isMa) {
            const thColumn1Header = addTh(target, '')
            thColumn1Header.addClass('calendar-week-view-timeline-element')
            thColumn1Header.addClass('calendar-week-view-timeline-header-base')
            setColspan(thColumn1Header, 3)

            this.createSearchFilter($('#calendar-week-view-header-ma-search'),
                'person', filterWorker)
        } else {
            target.attr('data-translation-key-group',
                'page.calendar.table.head')
            const thColumn1Header = addTh(target, null,
                'calendar-week-view-timeline-search')
            thColumn1Header.attr('data-non-translate', 'true')
            thColumn1Header.addClass('calendar-week-view-timeline-element')
            thColumn1Header.addClass('calendar-week-view-timeline-header-base')
            thColumn1Header.addClass('calendar-week-view-timeline-object')
            this.createSearchFilter(thColumn1Header, 'object', filterJob)

            const thColumn2Header = addTh(target, _head.at(0) ?? 'LEISTUNG')
            thColumn2Header.addClass('calendar-week-view-timeline-element')
            thColumn2Header.addClass('calendar-week-view-timeline-header-base')
            thColumn2Header.addClass('calendar-week-view-timeline-leistung')
            setColspan(thColumn2Header, 2)
        }

        let dayCount = 0
        if (fillDays) {
            this.days = []
        }
        for (let date = this.weekStartDay; moment(date)
            .isSameOrBefore(moment(this.weekEndDay)); date = addDays(date, 1)) {
            //print day
            this.createTimeLineDay(date, target)
            dayCount++
            if (fillDays) {
                this.days.push(convertDateToDayOfWeek(date))
            }
        }

        //repeating header bar
        setColspan($('#calendar-week-view-header-title-' + RowType.repeating),
            dayCount + 1)
        //once header bar
        setColspan($('#calendar-week-view-header-title-' + RowType.once),
            dayCount + 1)
        //ma header bar
        setColspan($('#calendar-week-view-header-title-' + RowType.ma),
            dayCount + 1)
    }

    private createTimeLineDay(date: Date, targetTimeLine: JQuery) {
        let day = convertDateToDayOfWeek(date)

        //create day
        let dayTh = addTh(targetTimeLine,
            gt(day) + ', ' + convertDateToDateStrNoYear(date))
        dayTh.addClass('calendar-week-view-timeline-element')
        dayTh.addClass('calendar-week-view-timeline-element-time')
        /*
        onClick(dayTh, () =>
           //TODO  loadCalendarDayView(date)
        )
        */
    }

    private backBufferActive = false;
    private backBufferContainer = new HashMap<JQuery>();

    private startBackBuffers() {
        this.backBufferActive = true;
        this.backBufferContainer = new HashMap<JQuery>();
    }

    private showBackBuffers() {
        this.showBackBuffer(RowType.repeating);
        this.showBackBuffer(RowType.once);
        this.showBackBuffer(RowType.ma);

        //clear backBuffer
        this.backBufferActive = false;
        this.backBufferContainer = new HashMap<JQuery>();
    }

    private showBackBuffer(type: RowType) {
        let rowContainter = this.backBufferContainer.get(type);
        if (rowContainter) {
            $('#calendar-week-view-rows-' + type).replaceWith(rowContainter);
        } else {
            $('#calendar-week-view-rows-' + type).empty();
        }
    }

    private getRowsHtml(type: RowType): JQuery {
        if (this.backBufferActive) {
            let rowContainter = this.backBufferContainer.get(type);
            if (!rowContainter) {
                rowContainter = this.createRowsHtmlClone(type);
                this.backBufferContainer.put(type, rowContainter);
            }
            return rowContainter;
        } else {
            return $('#calendar-week-view-rows-' + type)
        }
    }

    private createRowsHtmlClone(type: RowType): JQuery {
        let clone = $('#calendar-week-view-rows-' + type).clone(false);
        clone.empty();
        return clone;
    }


    private printObjektAndLeistung(activeJob: IActiveJobDto) {
        let row = this.getJobRow(activeJob)

        if (row.children().length > 0) {
            return
        }

        //Objekt
        this.createObjektElement(row, activeJob)

        //Leistung
        this.createLeistungsElement(row, activeJob)
    }

    private printGroup(group: IJobGroupDto, jobIdList: number[]) {
        let row = this.getJobGroupRow(group);

        if (row.children().length > 0) {
            return
        }

        //Group
        const trName = this.createGroupElement(row, group, jobIdList);

        this.addEmptyRow(row, group);

        onClick(trName, () => {
            if (this.openGroups.contains(group.id!)) {
                this.openGroups.remove(group.id)
                this.openGroup(row, group.id!, false);
            } else {
                this.openGroups.put(group.id, row);
                this.openGroup(row, group.id!, true);
            }
        });
    }

    public clearOpenGroups() {
        this.openGroups.forEachWithKeys(
            openGroup => this.openGroup(openGroup[1], openGroup[0] as number,
                false));

        this.openGroups = new HashMap<JQuery<HTMLElement>>();

    }

    private openGroup(row: JQuery<HTMLElement>, groupId: number,
                      open: boolean) {
        console.log("OPEN: " + open + " group: " + groupId);
        const jobRowClass = '.calendar-week-view-row-job';
        const emptyRowClass = '.calendar-week-view-row-empty';
        const groupOpenedClass = 'calendar-week-view-row-group-opened';

        row.removeClass(groupOpenedClass);
        if (open) {
            row.addClass(groupOpenedClass);
        }

        $(`${jobRowClass}[data-parent-group-id=${groupId}], ${emptyRowClass}[data-parent-group-id=${groupId}]`)
            .attr('data-hide', open ? 'false' : 'true');
    }

    private getJobRow(activeJob: IActiveJobDto): JQuery {
        if (!activeJob.job) {
            throw new Error()
        }
        const {job} = activeJob;
        const {id, jobType, sorting} = job;
        let rowType = convertJobTypeToRowType(jobType)
        let rows = this.getRowsHtml(rowType)
        let row = this.rowsByJobId.get(id)
        if (!row) {
            row = addTr(rows, null, 'calendar-week-view-row-job',
                [{name: 'data-job-id', value: id as number},
                    {name: 'data-hide', value: 'false'}])
            this.rowsByJobId.put(id, row)
        }
        return row
    }

    private getJobGroupRow(jobGroup: IJobGroupDto): JQuery {
        if (!jobGroup.id) {
            throw new Error()
        }
        let rowType = mustNotNull(jobGroup.type).toLowerCase();
        let rows = this.getRowsHtml(rowType as RowType)
        let row = this.rowsByJobId.get(jobGroup.id)
        if (!row) {
            row = addTr(rows, null, 'calendar-week-view-row-group',
                {name: 'data-group-id', value: jobGroup.id})
            this.rowsByJobId.put(jobGroup.id, row)
        }
        return row
    }

    private addEmptyRow(rows: JQuery, group: IJobGroupDto) {
        const emptyRow = addTrAfter(rows, null, null,
            'calendar-week-view-row-empty',
            [{name: 'data-parent-group-id', value: getValueOrError(group.id)},
                {name: 'data-hide', value: 'true'},]);
        let td = addTd(emptyRow);
        setColspan(td, 8);
    }

    private getChildJobRows(jobIds: number[] | undefined | null,
                            group: IJobGroupDto, table: JQuery) {
        if (jobIds && jobIds.length) {
            jobIds.map((jobId: number) => {
                const jobRow = addTr(table, null, 'calendar-week-view-row-job',
                    [{name: 'data-job-id', value: jobId}, {
                        name: 'data-parent-group-id',
                        value: getValueOrError(group.id)
                    }, {name: 'data-hide', value: 'true'},]);

                this.rowsByJobId.put(jobId, jobRow);
            });
        }
    }

    private createObjektElement(row: JQuery, activeJob: IActiveJobDto) {
        if (!activeJob.job) {
            throw new Error()
        }
        let td = addTd(row)
        let divOuter = addElement(td, 'div')
        divOuter.addClass('comment-row')
        const phone = activeJob.job.contact?.address?.phone2 || activeJob.job.contact?.address?.phone
        //TODO to slow
        //divOuter.attr('data-type', 'tooltip');
        /*
        divOuter.attr('data-content',
            `Auftraggeber: \n ${activeJob.job.building.customer?.name}  \n\nAnsprechpartner: \n ${activeJob.job.customer?.mainContactPerson?.firstname} ${activeJob.job.customer?.mainContactPerson?.lastname} \n ${phone}`);
       */
        setToolTip(divOuter,
            `Auftraggeber: \n ${activeJob.job.building.customer?.name}  \n\nAnsprechpartner: \n ${activeJob.job.customer?.mainContactPerson?.firstname} ${activeJob.job.customer?.mainContactPerson?.lastname} \n ${phone}`)

        let div = addElement(divOuter, 'div')
        div.addClass(
            'box-comment calendar-week-view-objekt-container draggable')

        let description = addElement(div, 'span')

        let building = activeJob.job.building
        let objectCustomId = addElement(description, 'div',
            'ObNr: ' + toShortString(building.customId, 15))
        objectCustomId.addClass('calendar-week-view-objekt-custom-id')

        let objectName = this.getObjectName(building)
        let objectNameHtml = addElement(description, 'div',
            toShortString(objectName, 15))
        objectNameHtml.addClass('calendar-week-view-objekt-name')

        // Tooltip.initElements()
    }

    private createGroupElement(row: JQuery, group: IJobGroupDto,
                               jobIdList?: number[]): JQuery {
        const {id, type, name, jobIds} = group;
        if (!id) {
            throw new Error()
        }
        const jobsCount = jobIds ? jobIds.filter(
                (jobId: number) => jobIdList && jobIdList.includes(jobId)).length :
            0;
        const isRepeatableJob = type === JobGroupType.REPEATING;

        let fullWidthCell = addTd(row);
        setColspan(fullWidthCell, 10);
        let table = addTable(fullWidthCell, null,
            'calendar-week-view-group-table');
        let trName = addTr(table, null, 'calendar-week-view-row-group-name');
        let td = addTd(trName, null, null,
            'calendar-week-view-group-table-cell');
        setColspan(td, 3);
        this.createEmptyCellsElement(trName);

        //Render jobs assigned to group
        this.getChildJobRows(jobIds, group, table);

        let divOuter = addElement(td, 'div');
        divOuter.addClass('comment-row');
        let container = addElement(divOuter, 'div');
        container.addClass(
            'box-comment calendar-week-view-objekt-container calendar-week-view-objekt-group-container');
        let groupInfo = addElement(container, 'div');
        groupInfo.addClass('calendar-week-view-group-info');
        let groupImageWrapper = addElement(groupInfo, 'div');
        groupImageWrapper.addClass('calendar-week-view-group-image-wrapper');
        let groupImage = addElement(groupImageWrapper, 'img');
        groupImage.addClass('calendar-week-view-group-image')
            .attr('src', isRepeatableJob ? 'assets/icons/ic_folder_blue.svg' :
                'assets/icons/ic_folder_orange.svg');
        let groupImageOpened = addElement(groupImageWrapper, 'img');
        groupImageOpened.addClass('calendar-week-view-group-image-opened')
            .attr('src',
                isRepeatableJob ? 'assets/icons/ic_folder_blue_open.svg' :
                    'assets/icons/ic_folder_orange_open.svg');
        let elementsCount = addElement(groupImageWrapper, 'div', jobsCount);
        elementsCount.addClass(
            `calendar-week-view-group-elements-count calendar-week-view-group-elements-count-${mustNotNull(
                type).toLowerCase()}`);
        let groupName = addElement(groupInfo, 'div', name);
        groupName.addClass('calendar-week-view-group-name');
        let groupDelete = addElement(container, 'span');
        groupDelete.addClass(
            `calendar-week-view-group-delete calendar-week-view-group-delete-${mustNotNull(
                type).toLowerCase()}`);
        const calendarPrefix = '#calendar-week-view-rows-';
        const jobRowClass = '.calendar-week-view-row-job';
        const emptyRowClass = '.calendar-week-view-row-empty';
        onClick(groupDelete, () => {
            if (!confirmYesNo("Ordner wirklich löschen?")) {
                return
            }
            if (groupDelete && id) {
                jobGroupDataProvider.deleteGroup(id,
                    () => globalstate.refresh(() => {
                        this.refresh(false)
                    }));
            }
        })
        let groupDeleteIcon = addElement(groupDelete, 'i')
            .addClass('fas i-Close');
        groupDeleteIcon.addClass('fas i-Close');

        return trName;
    }

    private createEmptyCellsElement(row: JQuery) {
        let emptyCell = addTd(row, '<div class="comment-row"></div>');
        emptyCell.addClass('empty-dark-row empty-dark-row-group');
        setColspan(emptyCell, 7);
    }

    private getObjectName(building: IBuildingDto): string | null {
        let objectName = building.name
        if (building.shortName && building.shortName.trim().length > 0) {
            objectName = building.shortName
        }
        return objectName
    }

    private createLeistungsElement(row: JQuery, activeJob: IActiveJobDto) {
        let td = addTd(row)
        setColspan(td, 2)
        let divOuter = addElement(td, 'div')
        divOuter.addClass('comment-row')
        divOuter.addClass('comment-row-tasks')

        let div = addElement(divOuter, 'div')
        div.addClass(
            'box-comment calendar-week-view-leistung-container draggable')

        const convertDay = (str: Date) => {
            const date = new Date(str);
            const mnth = ("0" + (date.getMonth() + 1)).slice(-2);
            const day = ("0" + date.getDate()).slice(-2);
            return [day, mnth, date.getFullYear()].join(".");
        }
        const startDate = activeJob.job?.startDate && convertDay(
            activeJob?.job?.startDate)
        const endDate = 'nicht definiert' || activeJob.job?.startDate && convertDay(
            activeJob?.job?.startDate)
        let description = addSpan(div)
        let taskName = '&nbsp;'
        const tasks = activeJob.job!.tasks;
        if (tasks && tasks?.length > 0) {
            taskName = activeJob.job!.tasks.map((task) => task.shortName)
                .join(', ')
        }
        let leistungName = addElement(description, 'div',
            'ANr: ' + toShortString(mustNotNull(activeJob.job).customId, 15))
        leistungName.addClass('calendar-week-view-leistung-name')
        let jobComment = addElement(description, 'div',
            toShortString(taskName, 15))
        jobComment.addClass('calendar-week-view-job-comment')
        const tolltipTaskName = tasks?.map((task) => task.name)
        const taskId = tasks?.map((task, index) => [task.customId,
            tolltipTaskName && tolltipTaskName[index]]);
        let activeJobTaskName = taskId?.map((name) => name.join(' '))

        //edit job btn
        let editSpan = addElement(div, 'span')
        editSpan.addClass('calendar-week-view-objekt-edit edit-pen')
        let editIcon = addElement(editSpan, 'span')
        editIcon.addClass('fas fa-pen')
        onClick(editIcon, () => {
            //CHANGED: auftragModal.openDialogForEdit(activeJob.job)
            newOrderModal.openDialogForEdit(activeJob.job)
        })

        //TODO to slow
        /*
        divOuter.attr('data-type', 'tooltip');
        divOuter.attr('data-content',
            `Auftraggebeggin: ${startDate} \nAuftragende: ${endDate} \n\n${activeJobTaskName?.join(
                '\n')}`)

         */
        setToolTip(divOuter,
            `Auftraggebeggin: ${startDate} \nAuftragende: ${endDate} \n\n${activeJobTaskName?.join(
                '\n')}`);
    }

    private addAwayTypeClass(type: AWAY_TYPE | null) {
        type = type ? type : AWAY_TYPE.HOLIDAY;
        switch (type) {
            case AWAY_TYPE.HOLIDAY:
                return 'away-type-holiday';
            case AWAY_TYPE.ILLNESS:
                return 'away-type-holiday-illness';
            case AWAY_TYPE.NOT_AVAILABLE:
                return 'away-type-not-available';
        }
    }


    private addMaRow(ma: IUserDto, activeJobs: IActiveJobDto[]) {
        const key: string = `${ma.id}`
        let row = addTr(this.getRowsHtml(RowType.ma))
        row.attr('data-hide', 'false')

        if (!ma.active) {
            return
        }

        if (!ma.roles?.includes(Roles.WORKER)) {
            return
        }

        this.workerRows.set(key, {row: row[0], user: ma})

        //### name
        let maNameTd = addTd(row)
        onClick(maNameTd, () => {
            loadMaFormular(ma)
        })
        setToolTip(maNameTd, 'Mitarbeiter bearbeiten')
        let maNameDiv = addDiv(maNameTd)
        maNameDiv.addClass(
            'box-comment calendar-week-view-objekt-container full-width clickable')
        let maNameSpan = addSpan(maNameDiv)
        let maCustomIdDiv = addDiv(maNameSpan,
            toShortString('PN: ' + toStringOrEmpty(ma.customId), 15))
        maCustomIdDiv.addClass('calendar-week-view-objekt-custom-id')
        let maShortNameDiv = addDiv(maNameSpan,
            toShortString(userDataProvider.getShortRealName(ma), 15))
        maShortNameDiv.addClass('calendar-week-view-objekt-name')

        //### booked time
        let maBookedTimeDiv = addDiv(addTd(row))
        maBookedTimeDiv.addClass('tags-btns')
        let maBookedTimeDiv2 = addDiv(maBookedTimeDiv)
        maBookedTimeDiv2.addClass('tag-blue-dark')

        //### week work time
        let maWeekWorkTimeTimeDiv = addDiv(addTd(row))
        maWeekWorkTimeTimeDiv.addClass('tags-btns')
        let weekWorkTime = UserDto.setterWorkHoursPerWeek.toView(ma)
        let maWeekWorkTimeTimeDiv2 = addDiv(maWeekWorkTimeTimeDiv,
            toFixedNumber(weekWorkTime))
        maWeekWorkTimeTimeDiv2.addClass('tag-blue-light')

        let activeJobsForMa = activeJobs.filter(
            activeJob => activeJob.activeUserJobs?.find(
                aUJ => aUJ.userId === ma.id))

        let activeJobsForMaSortedByDay = new HashMapWithArray<IActiveJobDto>()
        activeJobsForMa.forEach(activeJob => {
            let dayOfWeek = convertDateToDayOfWeek(mustNotNull(activeJob.date))
            activeJobsForMaSortedByDay.putArray(dayOfWeek, activeJob)
        })

        let workTimeAll = 0
        this.days.forEach(day => {
            const currentDate = getDayInWeekFromDays(this.weekStartDay, day);
            let activeJobsForDay = activeJobsForMaSortedByDay.get(day);
            let td = addTd(row);
            let workTimeDay = 0;
            const awayForThisDay = ma.aways && ma.aways.find(
                away => getDaysBetweenDates(away.startDate!, away.endDate!)
                    .find(day => TimeTools.isSameDay(day, currentDate)));
            if (awayForThisDay) {
                td.addClass(`away-type ${this.addAwayTypeClass(
                    awayForThisDay.awayType)} clickable`);
                td.text(workerAwayModal.convertAwayTypeFromAPIType(
                    awayForThisDay.awayType));
                onClick(td, () => {
                    handleWorkerAwayModalOpenForWeek(this.weekStartDay, day, ma,
                        awayForThisDay, () => this.refresh());
                });
            } else {
                if (activeJobsForDay && activeJobsForDay.length > 0) {
                    let divTag = addDiv(td);
                    divTag.addClass('hotel-detail');
                    divTag.css('height', '100%');
                    let allTimeDiv = addDiv(divTag);
                    allTimeDiv.addClass('hotel-detail-hour');
                    let jobListDiv = addDiv(divTag);
                    jobListDiv.addClass('hotel-detail-list');
                    activeJobsForDay?.forEach(activeJob => {
                        if (!activeJob.job) {
                            throw new Error()
                        }
                        let aUJ = activeJob.activeUserJobs?.find(
                            aUJ => aUJ.userId === ma.id);
                        if (aUJ) {
                            let workTime = convertDateToTimeNumber(aUJ.endTime,
                                true) - convertDateToTimeNumber(aUJ.startTime);
                            if (activeJob.withAway) {
                                workTime += toNumber(aUJ.awayHours)
                            }
                            if (activeJob.withWayBack) {
                                workTime += toNumber(aUJ.wayBackHours)
                            }
                            workTimeAll += workTime;
                            workTimeDay += workTime;
                            let objectName = this.getObjectName(
                                activeJob.job.building);
                            let jobSpan = addSpan(jobListDiv, toShortString(
                                toStringOrEmpty(
                                    activeJob.job.building.customId) + ' ' + objectName,
                                15));
                            jobSpan.addClass('clickable');
                            onClick(jobSpan, () => {
                                handleWorkerAwayModalOpenForWeek(
                                    this.weekStartDay, day, ma, null,
                                    () => this.refresh());
                            });
                        }
                    });
                    allTimeDiv.text(toHours(workTimeDay))
                } else {
                    td.css('text-align', 'center');
                    td.css('border', '1px solid #fff');
                    let possibleWorkTime = userDataProvider.getPossibleWorkTime(
                        ma, day);
                    if (possibleWorkTime.sumTime > 0) {
                        td.css('background-color', '#DEF2C7');
                    } else {
                        td.css('background-color', '#C1C4C5');
                    }
                    td.text(toHours(possibleWorkTime.sumTime));
                    td.addClass('clickable');
                    onClick(td, () => {
                        handleWorkerAwayModalOpenForWeek(this.weekStartDay, day,
                            ma, null, () => this.refresh());
                    });
                }
            }
        });
        maBookedTimeDiv2.text(toFixedNumber(workTimeAll))

        if (workTimeAll > weekWorkTime) {
            maBookedTimeDiv2.css('color', 'red')
        }
    }
}

export const calendarWeekView = new CalendarWeekView()
