import {CSS_DISABLED} from '../consties'
import {convertTimeNumberToTimeStr, ICallback, toStringOrEmpty} from './tools'
import {DayOfWeeks} from '../model/dayofweek'
import {gt} from './translation'
import {data} from "jquery";

export const HTML_PERSON = '&#128100;'
export const HTML_WHITESPACE = '&nbsp;'
export const HTML_BLEISTIFT = '&#128393;'
export const HTML_DELETE = '&#88;'
export const HTML_MAIL = '&#9993;'

interface IDataAttribute {
    name: string;
    value: string | number
}

export function createTemplateStr(htmlId: string): string {
    return $('#' + htmlId)
        .html()
        .trim()
}

export function createELementFromTemplateStr(templateStr: string): JQuery {
    return $(templateStr)
}

export function createElementFromTemplate(htmlId: string): JQuery {
    let templateStr = createTemplateStr(htmlId);
    return createELementFromTemplateStr(templateStr)
}


export function findTemplateAndSetText(parent: JQuery, htmlId: string,
                                       html: any): JQuery {
    let target = findTemplate(parent, htmlId)
    target.text(html)
    return target
}

export function findTemplateAndSetHtml(parent: JQuery, htmlId: string,
                                       html: any): JQuery {
    let target = findTemplate(parent, htmlId)
    target.html(html)
    return target
}

export function findTemplateAndSetValue(parent: JQuery, htmlId: string,
                                        value: any): JQuery {
    let target = findTemplate(parent, htmlId)
    target.val(value)
    return target
}

export function findTemplateAndSetChecked(parent: JQuery, htmlId: string,
                                          checked: boolean): JQuery {
    let target = findTemplate(parent, htmlId)
    setChecked2(target, checked)
    return target
}

export function setChecked2(target: JQuery,
                            checked: boolean | null | undefined = true,
                            triggerEvent = false) {
    target.prop('checked', !!checked)
    if (triggerEvent) {
        target.trigger('change')
    }
}

export function isChecked(target: JQuery | string) {
    if (typeof target === 'string') {
        target = $(target)
    }
    return target.prop('checked')
}

export function isSelected(target: JQuery) {
    return target.prop('selected')
}

export function setSelected(target: JQuery, selected: boolean = true) {
    return target.prop('selected', selected)
}

export function findTemplateAndSetDataBsContent(parent: JQuery, htmlId: string,
                                                content: string): JQuery {
    let target = findTemplate(parent, htmlId)
    target.prop('data-bs-content', content)
    return target
}

export function findTemplateAndSetTooltip(parent: JQuery, htmlId: string,
                                          content: string | null): JQuery | null {
    let target = findTemplate(parent, htmlId)
    if (!content || content.trim().length < 1) {
        target.remove()
        return null
    }
    target.attr('data-content', content)
    return target
}

export function findTemplateAndSetDataBsContentOrRemoveIfNull(parent: JQuery,
                                                              htmlId: string,
                                                              content: string | null): JQuery | null {
    let target = findTemplate(parent, htmlId)
    if (!content || content.trim().length < 1) {
        target.remove()
        return null
    }
    target.attr('data-bs-content', content)
    return target
}

export function findTemplateAndSetOnClick(parent: JQuery, htmlId: string,
                                          onClick: Function): JQuery {
    let target = findTemplate(parent, htmlId)
    setOnClick(target, onClick)
    return target
}

export function setOnClick(target: JQuery, onClick: Function) {
    target.on('click', () => onClick())
}

export function findTemplate(parent: JQuery, htmlId: string): JQuery {
    return parent.find('#' + htmlId)
}

export function findTemplateAndRemove(parent: JQuery, htmlId: string): JQuery {
    let target = parent.find('#' + htmlId)
    target.remove()
    return target
}

export function setHtmlId(element: JQuery, id: any) {
    element.attr('id', id)
}

export function setText(element: JQuery, query: string, value: any): JQuery {
    const target = element.find(query);
    target.text(toStringOrEmpty(value));
    return target;
}


export function setToolTip(element: JQuery, toolTop: string) {
    element.attr('title', toolTop)
}

export function getHtmlId(element: JQuery): string | null | undefined {
    if (typeof element.attr !== 'function') {
        return undefined
    }
    return element.attr('id')
}

export function setDisabledVisual(target: JQuery, isActive: boolean) {
    target.removeClass(CSS_DISABLED)
    if (!isActive) {
        target.addClass(CSS_DISABLED)
    }
}

export function addExtendedOption(target: JQuery, value: string | number | null,
                                  name: string | null, customId: string | null,
                                  shortName: string | null | undefined,
                                  selected = false): JQuery {
    let option = $('<option>')
    const _customId = customId ? customId : value
    const _shortName = shortName ? `(${shortName}) ` : ''
    const _fullname = `${_customId} ${_shortName}${name}`
    if (selected) {
        option.attr('selected', 'true')
    }
    option.val(value + '')
    option.html(_fullname)

    if (customId && shortName) {
        option.attr('data-short', `[${customId}] ${shortName}`)
    } else if (customId && !shortName) {
        option.attr('data-short', `[${customId}]`)
    } else if (!customId && shortName) {
        option.attr('data-short', `${shortName}`)
    } else {
        option.attr('data-short', `${name}`)
    }

    target.append(option)
    return option
}

export function addMultiSelect(target: JQuery): JQuery {
    const select = $('<select>')
    select.attr('multiple', 'true')
    target.append(select)
    return select
}

export function addOption(target: JQuery, value: any, html: any,
                          selected?: boolean): JQuery {
    let option = $('<option>')
    option.val(value + '')
    option.html(html)
    if (selected) {
        option.attr('selected', 'true')
    }
    target.append(option)
    return option
}

export function addEmptyOption(target: JQuery): JQuery {
    const option = $('<option>')
    option.val(`0`)
    option.attr('selected', 'true')
    option.attr('disabled', 'true')
    option.attr('hidden', 'true')

    target.append(option)
    return option
}

export function addOptionForDatalist(target: JQuery, value: any, label: string,
                                     selected?: boolean): JQuery {
    let option = $('<option>')
    option.val(label + '')
    option.attr('data-value', value)
    if (selected) {
        option.attr('selected', 'true')
    }
    target.append(option)
    return option
}

export function findAndSelectOption(select: JQuery, value: string) {
    select.find(`option[value="${value}"]`).attr('selected', 'true')
}

export function findSelectOption(select: JQuery,
                                 datalist: JQuery): string | undefined {
    let label = select.val()
    let option = datalist.find("option[value='" + label + "']")
    if (option.length > 0) {
        return option.attr('data-value') ? option.attr('data-value') :
            option.attr('value')
    } else {
        return undefined
    }
}

export function findDatalistOption(datalist: JQuery,
                                   value: string): JQuery<HTMLElement> {
    const primary = datalist.find(`option[data-value="${value}"]`)
        .attr('selected', 'true')
    const alt = datalist.find(`option[value="${value}"]`)
        .attr('selected', 'true')
    const option = primary ? primary : alt
    return option
}

export function findCustomDatalistOption(datalist: JQuery): JQuery<HTMLElement> {
    const option = datalist.find(`option[selected]`)
    return option
}

export function findCustomSelectOption(select: JQuery): string | null {
    const _select = select[0]
    const option = _select.querySelector(`option[selected]`)
    if (!option) return null
    const _content = option.textContent ? option.textContent.trim() : ''
    return option.getAttribute('value') ? option.getAttribute('value') :
        _content
}

export function addLi(target: JQuery, html: any = '',
                      id: string | null = null): JQuery {
    return addElement(target, 'li', html, id)
}

export function addTh(target: JQuery, html: any = '',
                      id: string | null = null): JQuery {
    return addElement(target, 'th', html, id)
}

export function addTd(target: JQuery, html: any = '', id: string | null = null,
                      className?: string): JQuery {
    return addElement(target, 'td', html, id, className)
}

export function addDiv(target: JQuery, html: any = '',
                       id: string | null = null): JQuery {
    return addElement(target, 'div', html, id)
}

export function addSpan(target: JQuery, html: any = '',
                        id: string | null = null): JQuery {
    return addElement(target, 'span', html, id)
}

export function addTable(target: JQuery, id: string | null = null,
                         className?: string,
                         dataAttribute?: IDataAttribute | IDataAttribute[]): JQuery {
    return addElement(target, 'table', '', id, className, dataAttribute)
}

export function addTr(target: JQuery, id: string | null = null,
                      className?: string,
                      dataAttribute?: IDataAttribute | IDataAttribute[]): JQuery {
    return addElement(target, 'tr', '', id, className, dataAttribute)
}

export function addTrBefore(target: JQuery, html?: any,
                            id: string | null = null, className?: string,
                            dataAttribute?: IDataAttribute | IDataAttribute[]): JQuery {
    return addElementBefore((target[0] as any), 'tr', html, id, className,
        dataAttribute)
}

export function addTrAfter(target: JQuery, html?: any, id: string | null = null,
                           className?: string,
                           dataAttribute?: IDataAttribute | IDataAttribute[]): JQuery {
    return addElementAfter((target[target.length - 1] as any), 'tr', html, id,
        className, dataAttribute)
}

export function createHtmlElement(tag: string, html: any = '',
                                  id: string | null = null, className?: string,
                                  dataAttribute?: IDataAttribute | IDataAttribute[]) {
    let child = $('<' + tag + '>')
    if (html !== null && html !== undefined) {
        child.html(html)
    }
    if (id) {
        setHtmlId(child, id)
    }
    if (className) {
        child.addClass(className);
    }
    if (dataAttribute) {
        if (Array.isArray(dataAttribute)) {
            dataAttribute.map((attribute: IDataAttribute) => {
                child.attr(attribute.name, attribute.value);
            });
        } else {
            child.attr(dataAttribute.name, dataAttribute.value);
        }
    }
    return child
}

export function createInputElement(type = 'text', placeholder = '') {
    const input = $(`<input>`)
    input.attr('type', type)
    input.attr('placeholder', placeholder)
    return input
}

export function addElement(target: JQuery, tag: string, html: any = '',
                           id: string | null = null, className?: string,
                           dataAttribute?: IDataAttribute | IDataAttribute[]): JQuery {
    let child = createHtmlElement(tag, html, id, className, dataAttribute)
    target.append(child)
    return child
}

export function addElementBefore(target: JQuery, tag: string, html: any = '',
                                 id: string | null = null, className?: string,
                                 dataAttribute?: IDataAttribute | IDataAttribute[]): JQuery {
    let child = createHtmlElement(tag, html, id, className, dataAttribute);
    $(target).before($(child[0]));
    return child;
}

export function addElementAfter(target: JQuery, tag: string, html: any = '',
                                id: string | null = null, className?: string,
                                dataAttribute?: IDataAttribute | IDataAttribute[]): JQuery {
    let child = createHtmlElement(tag, html, id, className, dataAttribute);
    $(target).after($(child[0]));
    return child;
}

export function setColspan(target: JQuery, colspan: number) {
    target.attr('colspan', colspan)
}

export function onSelectChange(target: JQuery,
                               callback: ICallback<JQuery.TriggeredEvent, void>) {
    onOff(target, 'selectChanged', callback)
}

export function onSelectCleared(target: JQuery,
                                callback: ICallback<JQuery.TriggeredEvent, void>) {
    onOff(target, 'clearField', callback)
}

export function onChange(target: JQuery,
                         callback: ICallback<JQuery.TriggeredEvent, void>) {
    onOff(target, 'change', callback)
}

export function onClick(target: JQuery,
                        callback: ICallback<JQuery.TriggeredEvent, void>) {
    onOff(target, 'click', callback)
}

export function onClickRemove(target: JQuery) {
    target.off('click')
}

export function onFocus(target: JQuery,
                        callback: ICallback<JQuery.TriggeredEvent, void>) {
    onOff(target, 'focus', callback)
}

export function onEnter(target: JQuery,
                        callback: ICallback<JQuery.TriggeredEvent, void>) {
    onKeyUp(target, callback, 13)
}

export function onSearch(target: JQuery,
                         callback: ICallback<JQuery.TriggeredEvent, void>) {
    onOff(target, 'search', callback)
}

export function onKeyUp(target: JQuery,
                        callback: ICallback<JQuery.TriggeredEvent, void>,
                        targetKey: number | null = null) {
    onOff(target, 'keyup', event => {
        if (targetKey) {
            if (targetKey === event.which) {
                callback(event)
            }
        } else {
            callback(event)
        }
    })
}

export function onOff(target: JQuery, handlerId: string,
                      callback: ICallback<JQuery.TriggeredEvent, void>) {
    target.off(handlerId)
    target.on(handlerId, event => callback(event))
}

export function setWidth(element: JQuery, witdhInPx: number) {
    element.css('width', witdhInPx + 'px')
    element.css('max-width', witdhInPx + 'px')
    element.css('min-width', witdhInPx + 'px')
}

export function selectOption(target: JQuery, value: any, triggerEvent = false) {
    target.find('option').attr('selected', null)
    //target.find(`option[value="${value}"]`).attr('selected', null)
    target.val(value)
    if (triggerEvent) {
        target.trigger('change')
    }
}

export function setDisable(target: JQuery, disable: boolean = true) {
    if (disable) {
        target.attr('disabled', 'disabled')
        target.addClass('disabled')
    } else {
        target.attr('disabled', null)
        target.removeClass('disabled')
    }
}

export function fillSelectWithTimes(target: JQuery, start: number, end: number,
                                    step: number,
                                    filter: ICallback<number, boolean> | null = null,
                                    converterForLabel: ICallback<number, string> | null = null) {
    target.empty()
    for (let time = start; time <= end; time += step) {
        if (filter && !filter(time)) {
            continue;
        }
        let label
        if (converterForLabel) {
            label = converterForLabel(time);
        }
        if (!label) {
            label = convertTimeNumberToTimeStr(time);
        }
        addOption(target, time, label)
    }
}

export function fillSelectWithDaysOfWeek(target: JQuery) {
    target.empty()
    DayOfWeeks.forEach(dayOfWeek => {
        addOption(target, dayOfWeek, gt(dayOfWeek))
    })
}
