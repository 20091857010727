import {
    AbstractWithIdAndNameAndAddressDto,
    AbtractDataProviderWithName,
    IAbstractWithIdAndNameAndAddressDto
} from './model_base'
import {REST_CONTROLLER} from '../tools/restConsties'
import {globalstate} from "./globalstate";

export interface ICompanyDto extends IAbstractWithIdAndNameAndAddressDto {
    taxId: string | null
    contactPersonFirstName: string | null
    contactPersonLastName: string | null

    iban: string | null
    bankName: string | null
    bic: string | null
    paypalEmail: string | null
}

export class CompanyDto extends AbstractWithIdAndNameAndAddressDto implements ICompanyDto {
    taxId: string | null = null
    contactPersonFirstName: string | null = null
    contactPersonLastName: string | null = null

    iban: string | null = null
    bankName: string | null = null
    bic: string | null = null
    paypalEmail: string | null = null
}

export class CompanyDataProvider extends AbtractDataProviderWithName<ICompanyDto> {
    constructor() {
        super(REST_CONTROLLER.COMPANY)
    }

    createDto(): ICompanyDto {
        return new CompanyDto()
    }

    protected getUserGlobalState(): boolean {
        return false;
    }
}

export const companyDataProvider = new CompanyDataProvider()
globalstate.companyDataProvider=companyDataProvider
