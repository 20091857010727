import {AbstractDtoFormular} from './formular'
import {
	CustomerContactPersonDataProvider,
	customerDataProvider,
	CustomerDataProvider,
	CustomerDto,
	ICustomerDto
} from '../../model/customer'
import {actionsInternal} from '../page_manager'
import {BuildingsFormular} from './buildingsFormular'
import {ContactFormular} from './contactFormular'
import {gt} from '../../tools/translation'
import {getValueOrError} from '../../tools/tools'

export class AuftraggeberFormular extends AbstractDtoFormular<ICustomerDto, CustomerDataProvider> {
	constructor() {
		super(customerDataProvider, 'neuer Auftraggeber', 'button.new.client')
	}

	public static createSubForms(customer: ICustomerDto) {
		BuildingsFormular.create(customer)
		ContactFormular.create(customer)
	}

	protected getTitle(dto: ICustomerDto): string {
		return 'Auftraggeber'
	}

	protected customFill() {
		this.addCustomBtnSpacer()
		this.addCustomBtn(
			gt('page.formular.title.ansprechpartner') ?? 'Ansprechpartner',
			() => {
				if (!this.currentDto.id) {
					return
				}
				AuftraggeberFormular.createSubForms(this.currentDto)
				ContactFormular.instance.fill()
			},
			'page.auftraggeberFormular.contactPerson'
		)
		this.addCustomBtn(
			gt('page.auftraggeberObjekteFormular.name') ?? 'Objekte',
			() => {
				if (!this.currentDto.id) {
					return
				}
				AuftraggeberFormular.createSubForms(this.currentDto)
				BuildingsFormular.instance.fill()
			},
			'page.auftraggeberObjekteFormular.name'
		)
	}

	private bindContactFields(section: JQuery<HTMLElement>) {
		const _contact = gt('page.auftraggeberFormular.fields.mainContact')
		section = this.addSectionTitle(gt('page.formular.title.mainContact') ?? 'Hauptkontakt')
		this.bindValueToRadioGroupAndAddToView(
			CustomerDto.setterMainContactPersonGender,
			section,
			gt('page.formular.title.gender') ?? 'Geschlecht',
			''
		)
		this.bindSimpleValueToInputFieldAndAddToView(CustomerDto.setterMainContactPersonFirstName, section, _contact.at(0) ?? 'Vorname')

		this.bindSimpleValueToInputFieldAndAddToView(CustomerDto.setterMainContactPersonLastName, section, _contact.at(1) ?? 'Nachname')

		this.bindSimpleValueToInputFieldAndAddToView(
			CustomerDto.setterMainContactPersonPosition,
			section,
			_contact.at(2) ?? 'Position / Abteilung'
		)

		this.bindSimpleValueToInputFieldAndAddToView(CustomerDto.setterMainContactPersonComment, section, _contact.at(3) ?? 'Infos')

		this.bindSimpleValueToInputFieldAndAddToView(CustomerDto.setterMainContactPersonPhone, section, _contact.at(5) ?? 'Festnetz')
		this.bindSimpleValueToInputFieldAndAddToView(CustomerDto.setterMainContactPersonPhone2, section, _contact.at(6) ?? 'Mobil')
		this.bindSimpleValueToInputFieldAndAddToView(CustomerDto.setterMainContactPersonEmail, section, _contact.at(7) ?? 'Email', null)

		this.bindSimpleValueToInputFieldAndAddToView(CustomerDto.setterMainContactPersonFax, section, _contact.at(8) ?? 'Fax', null)
	}

	private getMainContactPerson(dto: ICustomerDto, section: JQuery<HTMLElement>): void {
		const contactId = dto.mainContactPerson ? dto.mainContactPerson.id : null
		if (contactId) {
				const mainContactPersonProvider = new CustomerContactPersonDataProvider(getValueOrError(dto.id))
			mainContactPersonProvider.load(contactId, contact => {
				if (contact) {
					dto.mainContactPerson = contact
					this.bindContactFields(section)
				}
			})
		} else {
			this.bindContactFields(section)
		}
	}

	protected bindElementToView(dto: ICustomerDto): void {
		let section = this.addSectionTitle(gt('page.formular.title.general') ?? 'Allgemein')
		const _general = gt('page.auftraggeberFormular.fields.general')

		const _address = gt('page.auftraggeberFormular.fields.billingAddress')

		this.bindValueToRadioGroupAndAddToView(CustomerDto.setterCustomerType, section, _general.at(0) ?? 'Art')
		this.bindSimpleValueToInputFieldAndAddToView(CustomerDto.setterCustomId, section, _general.at(1) ?? 'KdN')
		this.bindSimpleValueToInputFieldAndAddToView(CustomerDto.setterName, section, _general.at(2) ?? 'Name')
		this.bindSimpleValueToInputFieldAndAddToView(CustomerDto.setterWebsite, section, _general.at(3) ?? 'Website')
		this.bindSimpleValueToInputFieldAndAddToView(CustomerDto.setterTaxId, section, _general.at(4) ?? 'Ust-Id.')
		this.bindSimpleValueToInputFieldAndAddToView(CustomerDto.setterComment, section, _general.at(5) ?? 'Infos')

		this.getMainContactPerson(dto, section)

		section = this.addSectionTitle(gt('page.formular.title.billingAddress') ?? 'Rechnungsadresse')
		this.bindSimpleValueToInputFieldAndAddToView(CustomerDto.setterStreetAndNr, section, _address.at(0) ?? 'Straße / HausNr.')
		this.bindSimpleValueToInputFieldAndAddToView(CustomerDto.setterZipCode, section, _address.at(1) ?? 'PLZ')
		this.bindSimpleValueToInputFieldAndAddToView(CustomerDto.setterCity, section, _address.at(2) ?? 'Stadt')
		this.bindSimpleValueToInputFieldAndAddToView(CustomerDto.setterCountry, section, _address.at(3) ?? 'Land')
	}

	protected createNewDto(): ICustomerDto {
		return new CustomerDto()
	}

	protected canBeDeleted(dto: ICustomerDto): boolean {
		return true
	}

	protected handleSwitchToTblView() {
		actionsInternal.loadAuftraggeber()
	}
}

export const auftraggeberFormular = new AuftraggeberFormular()
