function getAllMethods(instance: any, clazz: any) {
	return Object.getOwnPropertyNames(clazz.prototype).filter(name => {
		let method = instance[name]
		return method instanceof Function && method !== clazz
	})
}

export function bindAllFktToClass(instance: any, clazz: any | null = null) {
	clazz = clazz ? clazz : instance.constructor
	getAllMethods(instance, clazz).forEach(mtd => {
		instance[mtd] = instance[mtd].bind(instance)
	})

	let parentClass = Object.getPrototypeOf(clazz)
	if (parentClass && parentClass !== clazz && typeof parentClass.prototype !== 'undefined') {
		bindAllFktToClass(instance, parentClass)
	}
}
