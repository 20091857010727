import $ from 'jquery'
import 'bootstrap'
import Cookies from 'js-cookie'
import {ICallback, mustNotNull, nN} from './tools'
import {globalErrorHandler, IErrorHandler} from './error_handler'
import {format} from './StringTools'
import {IUserDto} from '../model/user'
import {popupService} from './popups'
import {
    isOnlineFlag, REST_CONTROLLER, REST_METHODS, REST_OPERATION, setOnline
} from './restConsties'
import {loadLogin} from '../pages/page_manager'
import {CONSOLE_DEBUG} from '../consties'
import {globalstate} from "../model/globalstate";
import {downloadFile, DownloadFileTypes} from "./file_tools";

const LOCAL_SERVER = false
const Mirabella_Version = window.location.hostname.toLowerCase()
    .includes('mirabella')
const Test2020_Version = window.location.hostname.toLowerCase()
    .includes('test2022')
const Dev2_Version = window.location.hostname.toLowerCase()
    .includes('dev2.')
const T_BASE_URL = (() => {
    if (LOCAL_SERVER) {
        return 'http://localhost:8088';
    }
    if (Mirabella_Version) {
        return 'https://tim-pro.de:9088';
    }
    if (Test2020_Version) {
        return 'https://tim-pro.de:8088';
    }
    if (Dev2_Version) {
        return 'https://tim-pro.de:10088';
    }
    return 'https://tim-pro.de:7000';
})();

const ORGIN = (() => {
    if (LOCAL_SERVER) {
        return 'http://localhost:3000';
    }
    if (Mirabella_Version) {
        return 'mirabella.app.tim-dienstplaner.de';
    }
    if (Test2020_Version) {
        return 'test2022.app.tim-dienstplaner.de';
    }
    if (Dev2_Version) {
        return 'dev2.app.tim-dienstplaner.de';
    }

    return 'dev.app.tim-dienstplaner.de';
})();

export class UrlParameter {
}

export class IdParameter extends UrlParameter {
    public id: number | null

    constructor(id: number | null) {
        super()
        this.id = id
    }
}

export class ParentIdParameter extends UrlParameter {
    public parentId: number | null

    constructor(parentId: number | null) {
        super()
        this.parentId = parentId
    }
}

export class ParentChildIdContainer extends IdParameter {
    public parentId: number

    constructor(parentId: number, childId: number | null) {
        super(childId)
        this.parentId = parentId
    }
}

class LoginRequestDto {
    public login: string
    public password: string
    public companyFlag: string

    constructor(login: string, password: string, companyFlag: string) {
        this.login = login
        this.password = password
        this.companyFlag = companyFlag
    }
}

interface ILoginResultDto {
    ok: boolean
    user: IUserDto
    token: string
}

let loginWithToken: string | null = null
let currentLogin: string | null = null

export function clearLoginParams() {
    loginWithToken = null
    currentLogin = null
    Cookies.remove('companyFlag')
    Cookies.remove('loginToken')
}

export function setCompanyFlag(companyId: string) {
    Cookies.set('companyFlag', companyId)
}

export function getCompanyFlag(): string {
    return Cookies.get('companyFlag') || ""
}

class PasswordLostRequest {
    private userName: string

    constructor(userName: string) {
        this.userName = userName
    }
}

export function passwordReset(login: string, companyFlagNew: string) {
    clearLoginParams();
    setCompanyFlag(companyFlagNew)
    executeRestCall(REST_CONTROLLER.PASSWORD_RESET, REST_OPERATION.NONE,
        REST_METHODS.POST, nullJsonObjectConverter, null, null,
        new PasswordLostRequest(login))
    popupService.info('Temporäres Password wurde angefordert')
}

export function checkIsOnline(callback: ICallback<boolean, void>) {
    executeRestCall(REST_CONTROLLER.USER, REST_OPERATION.STATUS,
        REST_METHODS.GET, nullJsonObjectConverter, response => {
            setOnline(response === 'ONLINE')
            callback(isOnlineFlag)
        }, response => {
            if (CONSOLE_DEBUG) console.log('Login failed:', response)
            setOnline(false);
            callback(false)
        })
}

export function login(login: string, password: string, companyFlag: string,
                      callback: ICallback<boolean, void>) {
    setOnline(false);
    let loginData: LoginRequestDto = new LoginRequestDto(login, password,
        companyFlag)

    executeRestCall(REST_CONTROLLER.LOGIN, REST_OPERATION.NONE,
        REST_METHODS.GET, nullJsonObjectConverter, () => {
            callback(isOnlineFlag)
        }, null, loginData)
}

export function logout(callback: ICallback<boolean, void> | null = null) {
    setOnline(false);
    if (CONSOLE_DEBUG) console.log('Logout')
    loadLogin()
    nN(callback, true)
}

export function executeRestCallJson<RESULT>(controller: REST_CONTROLLER,
                                            operation: REST_OPERATION,
                                            method: REST_METHODS,
                                            converter: IJsonToObjectConverter<RESULT> | null = null,
                                            success: ICallback<RESULT, void> | null = null,
                                            errorHandler: IErrorHandler | null = null,
                                            data: any | null = null,
                                            urlParameter: UrlParameter = {}) {
    return executeRestCall(controller, operation, method, converter,
        response => {
            nN(success, response)
        }, errorHandler, data, urlParameter)
}

export async function executeRestCallPrintResult<TARGET>(controller: REST_CONTROLLER,
                                                         operation: REST_OPERATION,
                                                         method: REST_METHODS,
                                                         converter: IJsonToObjectConverter<TARGET>,
                                                         success: ICallback<string, void> | null = null,
                                                         errorHandler: IErrorHandler | null = null,
                                                         data: any | null = null,
                                                         urlParameter: UrlParameter = {}) {
    executeRestCall(controller, operation, method, converter, result => {
        if (CONSOLE_DEBUG) console.log('RESULT: ', result)
    }, result => {
        if (CONSOLE_DEBUG) console.log('RESULT: ', result)
    }, data, urlParameter)
}

export interface IJsonToObjectConverter<TARGET> {
    convert(source: TARGET | null): TARGET | null
}

export class ArrayJsonObjectConverter<TARGET> implements IJsonToObjectConverter<TARGET[]> {
    private converter: IJsonToObjectConverter<TARGET>

    constructor(converter: IJsonToObjectConverter<TARGET>) {
        this.converter = converter
    }

    convert(sourceArray: TARGET[]): TARGET[] {
        if (!sourceArray) {
            return []
        }
        return mustNotNull(arrayConvert(this.converter, sourceArray))
    }
}

export const arrayConvert = <TARGET>(converter: IJsonToObjectConverter<TARGET>,
                                     sourceArray: TARGET[] | null): TARGET[] | null => {
    if (!sourceArray) {
        return null
    }
    return sourceArray.filter(source => source)
        .map(source => mustNotNull(converter.convert(source)))
}

export const nullJsonObjectConverter: IJsonToObjectConverter<any> = {
    convert(source: any): any {
        return source
    }
}

export async function executeRestCall<TARGET>(controller: REST_CONTROLLER,
                                              operation: REST_OPERATION,
                                              method: REST_METHODS,
                                              converter: IJsonToObjectConverter<TARGET> | null = null,
                                              success: ICallback<any, void> | null = null,
                                              errorHandler: IErrorHandler | null = null,
                                              data: any | null = null,
                                              urlParameter: UrlParameter = {}) {
    let isLoginRequest = data instanceof LoginRequestDto
    if (isLoginRequest) {
        globalstate.clearCache();
    }
    let url = T_BASE_URL + controller + operation
    url = format(url, urlParameter)

    let ajaxSettings: JQueryAjaxSettings = {
        url,
        type: method,
        data: JSON.stringify(data),
        processData: false,
        crossDomain: true,
        cache: false,
        contentType: 'application/json',
        success: result => {
            //save JSESSIONID
            if (isLoginRequest) {
                let loginResultDto = result as ILoginResultDto
                loginWithToken = btoa(
                    'token_' + loginResultDto.user.name + '_' + getCompanyFlag() + ':' + result.token)
                Cookies.set('loginToken', loginWithToken)
                setOnline(loginResultDto.ok)
            }
            if (converter) {
                result = converter.convert(result)
            }
            nN(success, result)
        },
        error: result => {
            if (result.status === 401 || result.status === 403) {
                setOnline(false);
                loadLogin();
            }
            if (CONSOLE_DEBUG) {
                console.log('Error Response:')
                console.dir(result)
                console.log('Data:')
                console.dir(data)
                result.then(t => console.log(t))
            }
            if (errorHandler) {
                errorHandler(result)
            } else {
                globalErrorHandler(result)
            }
        }
    }

    loginWithToken = loginWithToken || Cookies.get('loginToken') || '';
    let companyFlag = getCompanyFlag();

    //for login only
    let loginWithPass = "";
    if (isLoginRequest) {
        let loginData = data as LoginRequestDto
        loginWithPass = btoa(loginData.login + ':' + loginData.password)
        currentLogin = loginData.login
        companyFlag = loginData.companyFlag
        ajaxSettings.data = undefined
    }

    if (!companyFlag) {
        // prevent converting companyFlag = (null or undefine) to string
        companyFlag = ''
    }

    if (loginWithToken || loginWithPass.length > 0) {
        ajaxSettings.headers = {
            'Access-Control-Allow-Origin': ORGIN,
            'Authorization': 'Basic ' + (loginWithPass.length > 0 ?
                loginWithPass : loginWithToken),
            'Access-Control-Allow-Credentials': 'true',
            'tim-company': companyFlag
        }
        setCompanyFlag(companyFlag)
    } else {
        ajaxSettings.headers = {
            'Access-Control-Allow-Origin': ORGIN, 'tim-company': companyFlag
        }
    }

    //execute REST
    return $.ajax(ajaxSettings)
}

export const openInNewTab = async (url: string,
                                   headers: Headers | null = null) => {
    url = T_BASE_URL + url

    const myHeaders = new Headers()
    myHeaders.append('Access-Control-Allow-Origin', ORGIN)
    myHeaders.append('Authorization', 'Basic ' + loginWithToken)
    myHeaders.append('Access-Control-Allow-Credentials', 'true')
    myHeaders.append('tim-company', getCompanyFlag())

    if (headers) {
        headers.forEach((value, key) => {
            myHeaders.append(key, value)
        })
    }

    // Change this to use your HTTP client
    fetch(url, {headers: myHeaders}) // FETCH BLOB FROM IT
        .then(response => response.blob())
        .then(blob => {
            // RETRIEVE THE BLOB AND CREATE LOCAL URL
            var _url = window.URL.createObjectURL(blob)
            window.open(_url, '_blank')?.focus() // window.open + focus
        })
        .catch(err => {
            if (CONSOLE_DEBUG) console.log(err)
        })
}

export const downloadFileFromServer = async (url: string,
                                             headers: Headers | null = null,
                                             filename: string,
                                             type: DownloadFileTypes) => {
    url = T_BASE_URL + url

    const myHeaders = new Headers()
    myHeaders.append('Access-Control-Allow-Origin', ORGIN)
    myHeaders.append('Authorization', 'Basic ' + loginWithToken)
    myHeaders.append('Access-Control-Allow-Credentials', 'true')
    myHeaders.append('tim-company', getCompanyFlag())

    if (headers) {
        headers.forEach((value, key) => {
            myHeaders.append(key, value)
        })
    }

    // Change this to use your HTTP client
    fetch(url, {headers: myHeaders}) // FETCH BLOB FROM IT
        .then(response => response.blob())
        .then(blob => {
            blob.text().then(content => downloadFile(filename, type, content));
        })
        .catch(err => {
            if (CONSOLE_DEBUG) console.log(err)
        })
}