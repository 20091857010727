import moment from "moment";
import {
    convertDateToInputFormat, convertTimeNumberToTimeStr, getDaysBetweenDates
} from "./tools";

export class TimeTools {
    public static isDateBetween(startDate: Date, endDate: Date,
                                date: Date): boolean {
        let found = getDaysBetweenDates(startDate, endDate)
            .find(day => TimeTools.isSameDay(day, date));
        return found !== undefined;

    }

    public static isDateTimeBetween(startDate: Date, endDate: Date,
                                    date: Date): boolean {
        return moment(date).isBetween(startDate, endDate);
    }

    public static isSameDay(date: Date, date2: Date): boolean {
        return moment(date).startOf("day").isSame(moment(date2).startOf("day"));
    }

    public static setTime(date: Date, time: String): Date {
        return moment(convertDateToInputFormat(date) + " " + time).toDate();
    }

    public static getTimeNumberFromDate(date: Date,
                                        endTime: boolean = false): number {
        let hours = date.getHours();
        if (endTime && hours == 0) {
            hours = 24;
        }
        return hours + (date.getMinutes() / 60);
    }

    public static getTimeStrFromDate(date: Date): string {
        return date.getHours() + ":" + date.getMinutes();
    }

    public static getTimeStrFromNumber(timeNumber: number): string {
        return convertTimeNumberToTimeStr(timeNumber);
    }

    public static diffDays(date1: Date, date2: Date): number {
        const diffTime = Math.abs(date2.getTime() - date1.getTime());
        return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    }
}