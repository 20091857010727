import {
    AbstractWithIdAndNameDto,
    AbtractDataProviderWithName,
    IAbstractWithIdAndNameDto
} from './model_base'
import {addExtendedOption, addOption} from '../tools/templateTools'
import {REST_CONTROLLER} from '../tools/restConsties'
import {CONSOLE_DEBUG} from '../consties'
import {globalstate} from "./globalstate";

export interface ITaskTypeDto extends IAbstractWithIdAndNameDto {
}

export class TaskTypeDto extends AbstractWithIdAndNameDto implements ITaskTypeDto {
}

export class TaskTypeDataProvider extends AbtractDataProviderWithName<ITaskTypeDto> {
    constructor() {
        super(REST_CONTROLLER.TASK_TYPE)
    }

    protected getUserGlobalState(): boolean {
        return true;
    }

    public fillSelectWithTaskTypes(target: JQuery,
                                   emptyOption: boolean = false) {
        target.empty()
        if (emptyOption) {
            addOption(target, -1, '')
        }

        if (CONSOLE_DEBUG) {
            console.dir(this.loadListAllCache)
        }

        this.loadListAllCache?.content?.forEach(taskType => {
            addExtendedOption(target, taskType.id, taskType.name,
                taskType.customId, taskType.shortName)
        })
    }

    createDto(): ITaskTypeDto {
        return new TaskTypeDto()
    }
}

export const taskTypeDataProvider = new TaskTypeDataProvider()
globalstate.taskTypeDataProvider=taskTypeDataProvider
