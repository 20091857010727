import {createNewFromTemplate} from '../../tools/templates'
import {onClick, setChecked2, setHtmlId} from '../../tools/templateTools'
import {CalendarSettingsDto} from '../../model/calendar_settings'
import {TIME_5MIN_IN_H} from '../../consties'
import {AbstractCalendarSettingsPage} from './abstract_settings_page'

const TemplateId_StepSelection = 'settings-calendar-step-selection-template'

export class KalendarPage extends AbstractCalendarSettingsPage {
	private calendarSettings = new CalendarSettingsDto()

	constructor() {
		super('settings-calendar', [TemplateId_StepSelection])
	}

	public customFill(target: JQuery, calendarSettings: CalendarSettingsDto) {
		this.calendarSettings = calendarSettings

		//### steps
		this.getStepSelectionContainerHtml().empty()
		this.createStepHtml('step-selection-checkbox-5min', '5 min', TIME_5MIN_IN_H)
		this.createStepHtml('step-selection-checkbox-15min', '15 min', 0.25)
		this.createStepHtml('step-selection-checkbox-30min', '30 min', 0.5)
		this.createStepHtml('step-selection-checkbox-1h', '1 h', 1)
		//this.createStepHtml("step-selection-checkbox-24h", "24 h", 24);

		//### time in decimal
		setChecked2(this.getDecimalRadioHtml(), this.calendarSettings.showTimeInDecimal)
		onClick(this.getDecimalRadioHtml(), () => {
			this.calendarSettings.showTimeInDecimal = true
		})
		this.getDecimalRadioHtml()
		setChecked2(this.getTimeRadioHtml(), !this.calendarSettings.showTimeInDecimal)
		onClick(this.getTimeRadioHtml(), () => {
			this.calendarSettings.showTimeInDecimal = false
		})
	}

	protected save() {
		let dto = new CalendarSettingsDto()
		dto.id = -1
		dto.stepsInH = this.calendarSettings.stepsInH
		dto.showTimeInDecimal = this.calendarSettings.showTimeInDecimal
		this.dataProvider.save(dto)
	}

	private createStepHtml(htmlId: string, label: string, stepsInH: number) {
		let stepHtml = createNewFromTemplate(TemplateId_StepSelection)
		let checkBoxHtml = stepHtml.find('.' + this.htmlIdPrefix + 'step-selection-checkbox')
		setChecked2(checkBoxHtml, this.calendarSettings.stepsInH === stepsInH)
		setHtmlId(checkBoxHtml, this.htmlIdPrefix + htmlId)
		onClick(checkBoxHtml, () => {
			this.calendarSettings.stepsInH = stepsInH
		})
		let labelHtml = stepHtml.find('.' + this.htmlIdPrefix + 'step-selection-label')
		labelHtml.text(label)
		labelHtml.prop('for', htmlId)
		this.getStepSelectionContainerHtml().append(stepHtml)
	}

	private getStepSelectionContainerHtml(): JQuery {
		return this.getHtml('step-selection-container')
	}

	private getStepAndDecimalSaveBtnHtml(): JQuery {
		return this.getHtml('step-and-decimal-save-btn')
	}

	private getDecimalRadioHtml(): JQuery {
		return this.getHtml('decimal-radio')
	}

	private getTimeRadioHtml(): JQuery {
		return this.getHtml('time-radio')
	}
}

export const kalendarPage = new KalendarPage()
