export const CONSOLE_DEBUG = true
export const DEBUG_PAGE = ''
export const PAGE_LOGIN = 'login'
export const PAGE_REGISTER = 'register'
export const PAGE_PASSWORT_VERGESSEN = 'passwort_vergessen'
export const PAGE_DASHBOARD = 'dashboard'
export const PAGE_IMPRESSUM = 'impressum'
export const PAGE_AGB = 'agb'
export const PAGE_DATENSCHUTZ = 'datenschutz'

//### Dashboard ###
export const PAGE_EINSTELLUNGEN = 'einstellungen'
export const PAGE_MITARBEITER = 'mitarbeiter'
export const PAGE_MITARBEITER_GRUPPEN = 'mitarbeitergruppen'
export const PAGE_AUFTRAGGEBER = 'auftraggeber'
export const PAGE_GEBAEUDE = 'buildings'
export const PAGE_EINSAETZE = 'einsaetze'
export const PAGE_AUSWERTUNG = 'evaluations'
export const PAGE_URLAUB = 'urlaub'

//### FORMULARE ###
export const PAGE_MITARBEITER_FORMULAR = 'mitarbeiterFormular'
export const PAGE_AUFTRAGGEBER_FORMULAR = 'auftraggeberFormular'
export const PAGE_AUFTRAGGEBER_OBJEKTE_FORMULAR = 'auftraggeberObjekteFormular'
export const PAGE_AUFTRAGGEBER_KONTAKTE_FORMULAR = 'auftraggeberKontakteFormular'

//### Kalendar ###
export const PAGE_CALENDAR_DAY_VIEW = 'calendar_day_view'
export const PAGE_CALENDAR_WEEK_VIEW = 'calendar_week_view'

//### Einstellungen ###
export const PAGE_BACKUP = 'einstellungen/backup'
export const PAGE_EINSATZ_ARCHIV = 'einstellungen/einsatz_archiv'
export const PAGE_EXPORT_IMPORT = 'einstellungen/export_import'
export const PAGE_TUTORIALS = 'einstellungen/tutorials'

//einstellungen/firmendaten
export const PAGE_FIRMENDATEN = 'einstellungen/firmendaten'
export const PAGE_BANKVERBINDUNG = 'einstellungen/bankverbindung'
export const PAGE_RECHNUNGEN = 'einstellungen/rechnungen'
export const PAGE_ACCOUNT_VERWALTEN = 'einstellungen/account_verwalten'
//einstellungen/profil
export const PAGE_PROFIL = 'einstellungen/profil'
export const PAGE_PASSWORT_AENDERN = 'einstellungen/passwort_aendern'
//einstellungen/kalendar
export const PAGE_EINSTELLUNGEN_KALENDAR = 'einstellungen/kalendar'
export const PAGE_EINSTELLUNGEN_TAGESANSICHT = 'einstellungen/tagesansicht'
export const PAGE_EINSTELLUNGEN_WOCHENANSICHT = 'einstellungen/wochenansicht'
//einstellungen/leistungsart
export const PAGE_EINSTELLUNGEN_LEISTUNGSART = 'einstellungen/leistungsart'
export const PAGE_EINSTELLUNGEN_LAGE = 'einstellungen/lage'

export const CSS_DISABLED = 'disabled'

export const HTML_ROOT_ID = 'root'

export const TIME_5MIN_IN_H = 0.083

export const NO_CACHE = true
export const FILE_VERSION = NO_CACHE ? '' + new Date().getTime() : 'v1'
