import {
    isChecked, onChange, onClick, onFocus, setChecked2
} from '../../../tools/templateTools'
import {Events, initCalendar} from '../../../tools/calendar_widget'
import {
    AWAY_TYPE,
    IWorkerAwayDto,
    WorkerAway,
    WorkerAwayDataProvider,
    workerAwayDataProvider
} from "../../../model/worker_away";
import {
    convertDateToTimeStr,
    convertDateToUnixFormat,
    convertDateToYearMonthDayFormat,
    convertTimeStrToTimeNumber,
    getDayInWeekFromDays,
    mustNotNull,
    nN,
    setChecked
} from "../../../tools/tools";
import {AbstractEditModal} from "../abstract_edit_modal";
import {isEngLang} from "../../../tools/translation";
import {IUserDto, userDataProvider} from "../../../model/user";
import {toShortString} from "../../../tools/StringTools";
import {calendarWeekView} from "../../calendar/calendar_week_view";
import {TimeTools} from "../../../tools/time_tools";
import {globalstate} from "../../../model/globalstate";
import {DayOfWeek} from "../../../model/dayofweek";

export class WorkerAwayModal extends AbstractEditModal<IWorkerAwayDto, WorkerAwayDataProvider> {
    private static instance: WorkerAwayModal;
    workerName?: string;
    workerId?: number | null
    closeCallback: Function | null = null;

    constructor() {
        super((WorkerAwayDataProvider as any), 'worker-away');
        if (WorkerAwayModal.instance) {
            return WorkerAwayModal.instance
        }
        return this;
    }

    protected getHtmlDir(): string {
        return 'kalendar';
    }

    protected createNewDto(): IWorkerAwayDto {
        return new WorkerAway();
    }

    public setValues(date: Date | null, user: IUserDto,
                     away: IWorkerAwayDto | null,
                     closeCallback: Function | null = null) {
        this.closeCallback = closeCallback;
        this.dto = away ? away : this.createNewDto();
        this.setDefaultDate({
            startDate: away ? mustNotNull(away.startDate) : mustNotNull(date),
            endDate: away ? mustNotNull(away.endDate) : mustNotNull(date)
        });
        this.setWorkerName(
            toShortString(userDataProvider.getShortRealName(user), 15));
        this.workerId = user.id;
        if (away) {
            setChecked2(this.getFullDayCheckbox(), away.fullDay, true)
            this.toggleTimeView(away.fullDay);
            this.getAwayType(mustNotNull(away.awayType)).prop('checked', true);
            this.getDeleteBtnHtml().parent().removeClass('hidden');
            if (!away.fullDay) {
                this.getStartTime().val(convertDateToTimeStr(away.startDate));
                this.getEndTime().val(convertDateToTimeStr(away.endDate));
            }
        } else {
            setChecked2(this.getFullDayCheckbox(), true)
        }
        this.handleFullDayCheckboxChange()
    }

    public setDefaultDate(date: { startDate: Date; endDate: Date }) {
        this.getStartCalendarHtml()
            .dispatchEvent(new CustomEvent(Events.DATE_SELECTED, {
                detail: {date: date.startDate}
            }));
        this.getEndCalendarHtml()
            .dispatchEvent(new CustomEvent(Events.DATE_SELECTED, {
                detail: {date: date.endDate}
            }));
    }

    public setWorkerName(name: string) {
        this.workerName = name;
    }

    protected postHtmlLoaded() {
        initCalendar('[data-type="calendar"]')
        onClick(this.getCloseBtnHtml(), () => {
            this.hideDialog();
        });
    }

    private clearFields() {
        const timeSelector = $('#worker-away .new-order__form-group__field');
        const hiddenClass = 'new-order__form-group__field-hidden';
        this.getFullDayCheckbox().prop('checked', false);
        timeSelector.removeClass(hiddenClass);
        this.getStartTime().val('00:00');
        this.getEndTime().val('00:00');
        this.getCheckedAwayType().prop('checked', false);
        this.dto = null;
    }

    private convertAwayTypeToAPIType(type: string) {
        switch (type) {
            case 'Urlaub':
            case 'Holiday':
                return AWAY_TYPE.HOLIDAY;
            case 'Krank':
            case 'Illness':
                return AWAY_TYPE.ILLNESS;
            case 'Abwesend':
            case 'Not Available':
                return AWAY_TYPE.NOT_AVAILABLE;
        }
    }

    public convertAwayTypeFromAPIType(type: AWAY_TYPE | null): string {
        mustNotNull(type)
        switch (type) {
            case AWAY_TYPE.HOLIDAY:
                return isEngLang() ? 'Urlaub' : 'Holiday';
            case AWAY_TYPE.ILLNESS:
                return isEngLang() ? 'Krank' : 'Illness';
            case AWAY_TYPE.NOT_AVAILABLE:
                return isEngLang() ? 'Abwesend' : 'Not Available';
        }
        return "????"
    }

    private convertAwayTypeToIdSuffix(type: AWAY_TYPE) {
        switch (type) {
            case AWAY_TYPE.HOLIDAY:
                return 'holiday';
            case AWAY_TYPE.ILLNESS:
                return 'illness';
            case AWAY_TYPE.NOT_AVAILABLE:
                return 'not-available';
        }
    }

    protected collectDataToDto(dto: IWorkerAwayDto): void {
        const isFullDayMode = this.getFullDayCheckbox().prop('checked');
        dto.fullDay = isFullDayMode;
        dto.startDate = convertDateToYearMonthDayFormat(
            this.getStartCalendarInput().val() as string);
        dto.endDate = convertDateToYearMonthDayFormat(
            this.getEndCalendarInput().val() as string);

        if (isFullDayMode) {
            dto.startDate = TimeTools.setTime(dto.startDate!, '00:00');
            dto.endDate = TimeTools.setTime(dto.endDate!, '23:59');
        } else {
            dto.startDate = TimeTools.setTime(dto.startDate!,
                this.getStartTime().val() as string);
            dto.endDate = TimeTools.setTime(dto.endDate!,
                this.getEndTime().val() as string);
        }
        const awayReason = this.getCheckedAwayType().next('label').text();
        dto.awayType = this.convertAwayTypeToAPIType(awayReason) as AWAY_TYPE;
    }

    private async save() {
        this.validateDate();
        this.validateReason();
        const isFormValid = this.validateDate() && this.validateReason();
        if (isFormValid) {
            if (this.dto === null) {
                this.dto = this.createNewDto()
            }
            this.collectDataToDto(this.dto);
            if (this.workerId) {
                workerAwayDataProvider.createAwayDate(this.workerId, this.dto,
                    () => this.closeAndCloseCallback());
            }
        }
    }

    private closeAndCloseCallback() {
        this.hideDialog();
        this.clearFields();
        globalstate.refresh(this.closeCallback);
    }

    private delete(id: number) {
        const answer = window.confirm('Wollen Sie es wirklich löschen?');
        if (answer) {
            workerAwayDataProvider.deleteAwayDate(mustNotNull(this.workerId),
                id, () => this.closeAndCloseCallback());
        }
    }

    public initHandlers() {
        const endCalendarInput = this.getEndCalendarInput();
        const endTime = this.getEndTime();
        const fieldsForRemoveError = [endCalendarInput.parents(
            '.worker-away_calendar-label'),
            endTime.parents('.new-order__form-group__field')];
        onChange(this.getFullDayCheckbox(), this.handleFullDayCheckboxChange);
        if (this.workerName) {
            $('.worker-away_worker-name').text(this.workerName);
        }
        onClick(this.getSaveBtnHtml(), this.save);
        onClick(this.getDeleteBtnHtml(),
            () => this.delete(mustNotNull(this.dto?.id)));
        onFocus(endCalendarInput, () => {
            this.removeErrorOnField(this.getEndDateColumn(),
                fieldsForRemoveError);
        });
        onFocus(endTime, () => {
            this.removeErrorOnField(this.getEndDateColumn(),
                fieldsForRemoveError);
        });
        onClick(this.getAwayReason(), () => {
            this.removeErrorOnField(this.getAwayReasons());
        });
    }

    private markErrorField(rowWithError: JQuery, fields?: JQuery[]) {
        if (fields) {
            fields.forEach(field => {
                field.addClass('error');
            });
        }
        rowWithError.find('.error-text').removeClass('hidden');
    }

    private removeErrorOnField(rowWithError: JQuery, fields?: JQuery[]) {
        if (fields) {
            fields.forEach(field => {
                if (field.hasClass('error')) {
                    field.removeClass('error');
                }
            });
        }
        rowWithError.find('.error-text').addClass('hidden');
    }

    private isDateRangeValid(startDate: number, endDate: number) {
        return endDate >= startDate;
    }

    private isTimeRangeValid(startTime: number, endTime: number) {
        const isFullDayMode = this.getFullDayCheckbox().prop('checked');
        return isFullDayMode ? true : endTime > startTime;
    }

    private validateDate() {
        const startDate = convertDateToUnixFormat(
            this.getStartCalendarInput().val() as string);
        const endDate = convertDateToUnixFormat(
            this.getEndCalendarInput().val() as string);
        const startTime = convertTimeStrToTimeNumber(
            this.getStartTime().val() as string);
        const endTime = convertTimeStrToTimeNumber(
            this.getEndTime().val() as string);
        const isFullDayMode = this.getFullDayCheckbox().prop('checked');
        const errorParents = [this.getEndCalendarInput()
            .parents('.worker-away_calendar-label'), ...isFullDayMode ? [] :
            [this.getEndTime().parents('.new-order__form-group__field')]];
        if (startDate && endDate) {
            this.isDateRangeValid(startDate, endDate) && this.isTimeRangeValid(
                startTime, endTime) ?
                this.removeErrorOnField(this.getEndDateColumn(), errorParents) :
                this.markErrorField(this.getEndDateColumn(), errorParents);
            return this.isDateRangeValid(startDate,
                endDate) && this.isTimeRangeValid(startTime, endTime);
        }
        return false;
    }

    private validateReason() {
        const isReasonChecked = this.getCheckedAwayType().length > 0;
        isReasonChecked ? this.removeErrorOnField(this.getAwayReasons()) :
            this.markErrorField(this.getAwayReasons());
        return isReasonChecked;
    }

    private toggleTimeView(isChecked: boolean | null) {
        const timeSelector = $('#worker-away .new-order__form-group__field');
        const hiddenClass = 'new-order__form-group__field-hidden';
        if (isChecked) {
            timeSelector.addClass(hiddenClass);
            this.removeErrorOnField(this.getEndDateColumn(),
                [this.getEndCalendarInput()
                    .parents('.worker-away_calendar-label'), this.getEndTime()
                    .parents('.new-order__form-group__field')]);
        } else {
            timeSelector.removeClass(hiddenClass);
        }
    }

    private handleFullDayCheckboxChange() {
        this.toggleTimeView(isChecked(this.getFullDayCheckbox()))
    }

    private getFullDayCheckbox(): JQuery {
        return $('#form-toggle_checkbox-full-day');
    }

    private getSaveBtnHtml(): JQuery {
        return $('#worker-away_save-btn');
    }

    private getDeleteBtnHtml(): JQuery {
        return $('#worker-away_delete-btn');
    }

    private getCloseBtnHtml(): JQuery {
        return $('#worker-away-close-btn');
    }

    private getEndDateColumn() {
        return $('#worker-away_column-end-date');
    }

    private getStartCalendarHtml() {
        return document.querySelector(
            '#worker-away_calendar-start') as HTMLElement;
    }

    private getEndCalendarHtml() {
        return document.querySelector(
            '#worker-away_calendar-end') as HTMLElement;
    }

    private getStartCalendarInput() {
        return $('#worker-away-ma-default-start');
    }

    private getEndCalendarInput() {
        return $('#worker-away-ma-default-end');
    }

    private getStartTime() {
        return $('#worker-away-ma-start-time');
    }

    private getEndTime() {
        return $('#worker-away-ma-end-time');
    }

    private getAwayType(awayType: AWAY_TYPE) {
        return $(`#worker-away_reason-${this.convertAwayTypeToIdSuffix(
                awayType)}`);
    }

    private getCheckedAwayType() {
        return $('.worker-away_reason-input:checked');
    }

    private getAwayReason() {
        return $('.worker-away_reason-button');
    }

    private getAwayReasons() {
        return $('#worker-away-reasons');
    }
}

export const workerAwayModal = new WorkerAwayModal();

export function handleWorkerAwayModalOpen(clickedDate: Date | null,
                                          ma: IUserDto,
                                          away: IWorkerAwayDto | null,
                                          closeCallback: Function | null) {
    workerAwayModal.showDialog(() => {
        workerAwayModal.setValues(clickedDate, ma, away, closeCallback);
        workerAwayModal.initHandlers();
    });
}

export function handleWorkerAwayModalOpenForWeek(weekStartDay: Date,
                                                 day: DayOfWeek, ma: IUserDto,
                                                 away: IWorkerAwayDto | null,
                                                 closeCallback: Function | null) {
    const clickedDate = getDayInWeekFromDays(weekStartDay, day);
    handleWorkerAwayModalOpen(clickedDate, ma, away, closeCallback);
}
