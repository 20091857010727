import {
    AbstractDto,
    AbstractWithIdAndNameAndAddressDto,
    AbtractDataProviderWithName,
    HTML_INPUT_TYPE,
    IAbstractDto,
    IAbstractWithIdAndNameAndAddressDto,
    IViewModelConverter,
    IViewModelConverterWithOptions,
    IViewModelConverterWithOptionsForArray
} from './model_base'
import {
    arrayConvert,
    executeRestCallJson,
    IJsonToObjectConverter,
    nullJsonObjectConverter
} from '../tools/rest'
import {DayOfWeek, toDateOrNull, toDayOfWeek} from './dayofweek'
import {
    convertDateToInputFormat,
    convertDateToTimeNumber,
    convertTimeStrToTimeNumber,
    toStringOrEmpty,
    ICallback,
    mustNotNull,
    nN,
    toBoolean,
    toNumber
} from '../tools/tools'
import {ICalendarSettingsDto} from './calendar_settings'
import {IActiveJobDto} from './active_job'
import {IActiveUserJobDto} from './active_user_job'
import {ITaskTypeDto, taskTypeDataProvider} from './tasktype'
import {NameAndId} from '../pages/formulare/formular'
import {userTypeDataProvider} from './usertype'
import {
    isOnline, REST_CONTROLLER, REST_METHODS, REST_OPERATION
} from '../tools/restConsties'
import {globalstate} from "./globalstate";
import {IWorkerAwayDto, workerAwayDataProvider} from "./worker_away";

export enum GenderEnum {
    MALE = 'MALE', FEMALE = 'FEMALE', DIVERSE = 'DIVERSE'
}

export const GenderNameId = [new NameAndId<GenderEnum>(GenderEnum.FEMALE,
    GenderEnum.FEMALE, true),
    new NameAndId<GenderEnum>(GenderEnum.MALE, GenderEnum.MALE, true),
    new NameAndId<GenderEnum>(GenderEnum.DIVERSE, GenderEnum.DIVERSE, true)]

export function toGender(key: any): GenderEnum {
    return GenderEnum[key as keyof typeof GenderEnum]
}

export enum Roles {
    ADMIN = 'ADMIN',
    CONTACT_PERSON = 'CONTACT_PERSON',
    WORKER = 'WORKER',
    TEAM_LEADER = 'TEAM_LEADER',
    MANAGER = 'MANAGER',
    ACCOUNTING = 'ACCOUNTING',
    API_ACCESS = 'API_ACCESS'
}

export const RolesNameId = [new NameAndId<Roles>(Roles.ADMIN, Roles.ADMIN,
    true), //new NameAndId<Roles>(Roles.TEAM_LEADER, Roles.TEAM_LEADER, true),
    //new NameAndId<Roles>(Roles.MANAGER, Roles.MANAGER, true),
    //new NameAndId<Roles>(Roles.ACCOUNTING, Roles.ACCOUNTING, true),
    new NameAndId<Roles>(Roles.WORKER, Roles.WORKER, true)]

export function toRole(key: any): Roles {
    return Roles[key as keyof typeof Roles]
}

export const RolesAll = [Roles.ADMIN, Roles.CONTACT_PERSON, Roles.WORKER,
    Roles.TEAM_LEADER, Roles.MANAGER, Roles.ACCOUNTING, Roles.API_ACCESS]

export const RolesWorker = [Roles.ADMIN, Roles.WORKER
    //Roles.TEAM_LEADER, Roles.MANAGER, Roles.ACCOUNTING
]

export interface IUserDto extends IAbstractWithIdAndNameAndAddressDto {
    passwordTempIsValidUntil: Date | null
    password: string | null

    title: string | null
    firstname: string | null
    lastname: string | null
    position: string | null

    roles: Roles[] | null

    vacationDays: number | null
    citizenship: string | null
    possibleWorkTimes: IWorkTimeDto[] | null
    userTypeId: number | null
    workHoursPerMonth: number | null
    gender: GenderEnum | null
    taskTypes: ITaskTypeDto[] | null
    canLogin: boolean | null
    startDate: Date | null
    endDate: Date | null
    mainContact: boolean | null
    aways?: IWorkerAwayDto[] | undefined

    languageKey: string
}

export class UserDto extends AbstractWithIdAndNameAndAddressDto implements IUserDto {
    public static setterLanguage: IViewModelConverter<IUserDto, string> = {
        getFieldName(): string {
            return 'languageKey'
        }, toView(dto: IUserDto): string {
            return dto.languageKey
        },

        fromView(dto: IUserDto, value: string): void {
            dto.languageKey = value.trim()
        },

        getHtmlType(): HTML_INPUT_TYPE {
            return HTML_INPUT_TYPE.select
        }
    }
    public static setterPassword: IViewModelConverter<IUserDto, string> = {
        getFieldName(): string {
            return 'passwordNew'
        }, toView(dto: IUserDto): string {
            return ''
        },

        fromView(dto: IUserDto, value: string): void {
            dto.password = value.trim()
            dto.password = dto.password.length === 0 ? null : dto.password
        },

        getHtmlType(): HTML_INPUT_TYPE {
            return HTML_INPUT_TYPE.password
        }
    }
    public static setterTitle: IViewModelConverter<IUserDto, string> = {
        getFieldName(): string {
            return 'title'
        }, toView(dto: IUserDto): string {
            return toStringOrEmpty(dto.title)
        },

        fromView(dto: IUserDto, value: string): void {
            dto.title = value
        },

        getHtmlType(): HTML_INPUT_TYPE {
            return HTML_INPUT_TYPE.text
        }
    }
    public static setterFirstname: IViewModelConverter<IUserDto, string> = {
        getFieldName(): string {
            return 'firstname'
        }, toView(dto: IUserDto): string {
            return toStringOrEmpty(dto.firstname)
        },

        fromView(dto: IUserDto, value: string): void {
            dto.firstname = value
        },

        getHtmlType(): HTML_INPUT_TYPE {
            return HTML_INPUT_TYPE.text
        }
    }
    public static setterLastname: IViewModelConverter<IUserDto, string> = {
        getFieldName(): string {
            return 'lastname'
        }, toView(dto: IUserDto): string {
            return toStringOrEmpty(dto.lastname)
        },

        fromView(dto: IUserDto, value: string): void {
            dto.lastname = value
        },

        getHtmlType(): HTML_INPUT_TYPE {
            return HTML_INPUT_TYPE.text
        }
    }
    public static setterPosition: IViewModelConverter<IUserDto, string> = {
        getFieldName(): string {
            return 'position'
        }, toView(dto: IUserDto): string {
            return toStringOrEmpty(dto.position)
        },

        fromView(dto: IUserDto, value: string): void {
            dto.position = value
        },

        getHtmlType(): HTML_INPUT_TYPE {
            return HTML_INPUT_TYPE.text
        }
    }
    public static setterUserTypeId: IViewModelConverterWithOptions<IUserDto, number | null> = {
        getFieldName(): string {
            return 'userTypeId'
        }, toView(dto: IUserDto): number | null {
            return dto.userTypeId
        },

        fromView(dto: IUserDto, value: number | null): void {
            dto.userTypeId = value
        },

        getHtmlType(): HTML_INPUT_TYPE {
            return HTML_INPUT_TYPE.radio
        },

        getOptions(): NameAndId<number | null>[] {
            return mustNotNull(userTypeDataProvider.loadListAllCache?.content)
                .map(
                    userType => new NameAndId<number | null>(userType.name + '',
                        userType.id, userType.active !== false))
        }
    }
    public static setterGender: IViewModelConverterWithOptions<IUserDto, GenderEnum | null> = {
        getFieldName(): string {
            return 'gender'
        }, toView(dto: IUserDto): GenderEnum | null {
            return dto.gender ? dto.gender : null
        },

        fromView(dto: IUserDto, value: GenderEnum | null): void {
            dto.gender = value
        },

        getHtmlType(): HTML_INPUT_TYPE {
            return HTML_INPUT_TYPE.radio
        },

        getOptions(): NameAndId<GenderEnum>[] {
            return GenderNameId
        }
    }
    public static setterVacationDays: IViewModelConverter<IUserDto, number> = {
        getFieldName(): string {
            return 'vacationDays'
        }, toView(dto: IUserDto): number {
            return toNumber(dto.vacationDays)
        },

        fromView(dto: IUserDto, value: number): void {
            dto.vacationDays = toNumber(value)
        },

        getHtmlType(): HTML_INPUT_TYPE {
            return HTML_INPUT_TYPE.number
        }
    }
    public static setterCitizenship: IViewModelConverter<IUserDto, string> = {
        getFieldName(): string {
            return 'citizenship'
        }, toView(dto: IUserDto): string {
            return toStringOrEmpty(dto.citizenship)
        },

        fromView(dto: IUserDto, value: string): void {
            dto.citizenship = value
        },

        getHtmlType(): HTML_INPUT_TYPE {
            return HTML_INPUT_TYPE.text
        }
    }
    public static setterRoles: IViewModelConverterWithOptionsForArray<IUserDto, Roles> = {
        getFieldName(): string {
            return 'roles'
        },

        toView(dto: IUserDto): Roles[] {
            return dto.roles ? dto.roles : []
        },

        fromView(dto: IUserDto, value: Roles[]): void {
            dto.roles = value
        },

        getHtmlType(): HTML_INPUT_TYPE {
            return HTML_INPUT_TYPE.checkbox
        },

        getOptions(): NameAndId<Roles>[] {
            return RolesNameId
        }, equals(a: Roles, b: Roles): boolean {
            return a === b
        }
    }
    public static setterWorkHoursPerWeek: IViewModelConverter<IUserDto, number> = {
        getFieldName(): string {
            return 'workHoursPerWeek'
        }, toView(dto: IUserDto): number {
            return toNumber(dto.workHoursPerMonth) / 4.33
        },

        fromView(dto: IUserDto, value: number): void {
            dto.workHoursPerMonth = value * 4.33
        },

        getHtmlType(): HTML_INPUT_TYPE {
            return HTML_INPUT_TYPE.number
        }
    }
    public static setterWorkHoursPerMonth: IViewModelConverter<IUserDto, number> = {
        getFieldName(): string {
            return 'workHoursPerMonth'
        }, toView(dto: IUserDto): number {
            return toNumber(dto.workHoursPerMonth)
        },

        fromView(dto: IUserDto, value: number): void {
            dto.workHoursPerMonth = value
        },

        getHtmlType(): HTML_INPUT_TYPE {
            return HTML_INPUT_TYPE.number
        }
    }
    public static setterTaskTypes: IViewModelConverterWithOptionsForArray<IUserDto, ITaskTypeDto> = {
        getFieldName(): string {
            return 'taskTypes'
        }, toView(dto: IUserDto): ITaskTypeDto[] {
            return dto.taskTypes ? dto.taskTypes : []
        },

        fromView(dto: IUserDto, value: ITaskTypeDto[]): void {
            dto.taskTypes = value
        },

        getHtmlType(): HTML_INPUT_TYPE {
            return HTML_INPUT_TYPE.checkbox
        },

        getOptions(): NameAndId<ITaskTypeDto>[] {
            return mustNotNull(taskTypeDataProvider.loadListAllCache?.content)
                .map(taskType => new NameAndId(taskType.name + '', taskType,
                    !(taskType.active === false)))
        },

        equals(a: ITaskTypeDto, b: ITaskTypeDto): boolean {
            return a.id === b.id
        }
    }
    public static setterMainContact: IViewModelConverter<IUserDto, boolean> = {
        getFieldName(): string {
            return 'mainContact'
        }, toView(dto: IUserDto): boolean {
            return toBoolean(dto.mainContact)
        },

        fromView(dto: IUserDto, value: boolean): void {
            dto.mainContact = value
        },

        getHtmlType(): HTML_INPUT_TYPE {
            return HTML_INPUT_TYPE.checkbox
        }
    }
    public static setterCanLogin: IViewModelConverter<IUserDto, boolean> = {
        getFieldName(): string {
            return 'canLogin'
        }, toView(dto: IUserDto): boolean {
            return toBoolean(dto.canLogin)
        },

        fromView(dto: IUserDto, value: boolean): void {
            dto.canLogin = value
        },

        getHtmlType(): HTML_INPUT_TYPE {
            return HTML_INPUT_TYPE.checkbox
        }
    }
    public static setterStartDate: IViewModelConverter<IUserDto, string> = {
        getFieldName(): string {
            return 'startDate'
        }, toView(dto: IUserDto): string {
            if (dto.startDate) {
                return convertDateToInputFormat(dto.startDate)
            } else {
                return ''
            }
        },

        fromView(dto: IUserDto, value: string): void {
            if (value.length > 0) {
                dto.startDate = new Date(value)
            } else {
                dto.startDate = new Date('1970-01-01')
            }
        },

        getHtmlType(): HTML_INPUT_TYPE {
            return HTML_INPUT_TYPE.date
        }
    }
    public static setterEndDate: IViewModelConverter<IUserDto, string> = {
        getFieldName(): string {
            return 'endDate'
        }, toView(dto: IUserDto): string {
            if (dto.endDate) {
                return convertDateToInputFormat(dto.endDate)
            } else {
                return ''
            }
        },

        fromView(dto: IUserDto, value: string): void {
            if (value.length > 0) {
                dto.endDate = new Date(value)
            } else {
                dto.endDate = new Date('1970-01-01')
            }
        },

        getHtmlType(): HTML_INPUT_TYPE {
            return HTML_INPUT_TYPE.date
        }
    }
    passwordTempIsValidUntil: Date | null = null
    password: string | null = null
    title: string | null = null
    firstname: string | null = null
    lastname: string | null = null
    position: string | null = null
    vacationDays: number | null = null
    citizenship: string | null = null
    possibleWorkTimes: IWorkTimeDto[] | null = null
    userTypeId: number | null = null
    workHoursPerMonth: number | null = null
    roles: Roles[] | null = null
    gender: GenderEnum | null = null
    taskTypes: ITaskTypeDto[] | null = null
    mainContact: boolean | null = null
    canLogin: boolean | null = null
    startDate: Date | null = null
    endDate: Date | null = null
    languageKey: string = 'de'
}

export interface IWorkTimeDto extends IAbstractDto {
    dayOfWeek: DayOfWeek
    startTime: string | null
    endTime: string | null
}

export const workTimeConverter: IJsonToObjectConverter<IWorkTimeDto> = {
    convert(source: IWorkTimeDto | null): IWorkTimeDto | null {
        if (source) {
            source.dayOfWeek = toDayOfWeek(source.dayOfWeek)
        }
        return source
    }
}

export class WorkTimeDto extends AbstractDto implements IWorkTimeDto {
    dayOfWeek: DayOfWeek
    startTime: string | null = null
    endTime: string | null = null

    constructor(dayOfWeek: DayOfWeek, startTime: string | null = null,
                endTime: string | null = null) {
        super()
        this.dayOfWeek = dayOfWeek
        this.startTime = startTime
        this.endTime = endTime
    }
}

export class ChangePasswordOwnRequestDto {
    public oldPassword: string
    public newPassword1: string
    public newPassword2: string

    constructor(oldPassword: string, newPassword1: string,
                newPassword2: string) {
        this.oldPassword = oldPassword
        this.newPassword1 = newPassword1
        this.newPassword2 = newPassword2
    }
}

export class UserDayPossibleWorkTime {
    public startHour: number
    public endHour: number

    //calculated
    public sumTime: number

    constructor(startHour: number, endHour: number) {
        this.startHour = startHour
        this.endHour = endHour
        this.sumTime = endHour - startHour
    }
}

export class UserDayWorkTime {
    public bookedTime: number

    //calculated
    public freeTime: number

    constructor(sumTime: number, bookedTime: number) {
        this.bookedTime = bookedTime
        this.freeTime = sumTime - bookedTime
    }
}

export class UserDayJob {
    public activeJob: IActiveJobDto
    public activeUserJob: IActiveUserJobDto
    public user: IUserDto

    constructor(activeJob: IActiveJobDto, activeUserJob: IActiveUserJobDto,
                user: IUserDto) {
        this.activeJob = activeJob
        this.activeUserJob = activeUserJob
        this.user = user
    }
}

export class WorkTimeContainer {
    public dayJob: UserDayJob
    public workTime: number

    constructor(dayJob: UserDayJob, workTime: number) {
        this.dayJob = dayJob
        this.workTime = workTime
    }
}

export class UserDataProvider extends AbtractDataProviderWithName<IUserDto> {
    constructor() {
        super(REST_CONTROLLER.USER)
    }

    protected getUserGlobalState(): boolean {
        return true;
    }

    convert(source: IUserDto): IUserDto {
        if (source) {
            source.passwordTempIsValidUntil = toDateOrNull(
                source.passwordTempIsValidUntil)
            source.possibleWorkTimes = arrayConvert(workTimeConverter,
                source.possibleWorkTimes)
            source.taskTypes = arrayConvert(taskTypeDataProvider,
                source.taskTypes)
            source.startDate = toDateOrNull(source.startDate)
            source.endDate = toDateOrNull(source.endDate)
            source.gender = toGender(source.gender)
            source.roles = source.roles ?
                source.roles?.map(value => toRole(value)) : null
            source.aways = source.aways?.map(
                away => workerAwayDataProvider.convert(away));
        }
        return super.convert(source)
    }

    public getDayJobs(ma: IUserDto,
                      activeJobDtos: IActiveJobDto[]): UserDayJob[] {
        let dayJobs: UserDayJob[] = []
        activeJobDtos.forEach(activeJobDto => {
            if (!activeJobDto.activeUserJobs) {
                return false
            }
            let activeUserJob = activeJobDto.activeUserJobs.filter(
                aUJ => aUJ.userId === ma.id)
            if (activeUserJob.length <= 0) {
                return
            }
            dayJobs.push(new UserDayJob(activeJobDto, activeUserJob[0], ma))
        })
        return dayJobs
    }

    public getPossibleWorkTime(user: IUserDto,
                               day: DayOfWeek): UserDayPossibleWorkTime {
        let possibleWorkTimes = user.possibleWorkTimes?.filter(
            pWT => pWT.dayOfWeek === day)
        possibleWorkTimes = possibleWorkTimes ? possibleWorkTimes : []

        let endTime = 0
        let startTime = 0
        if (possibleWorkTimes.length > 0) {
            let possibleWorkTime = possibleWorkTimes[0]
            if (possibleWorkTime.endTime && possibleWorkTime.startTime) {
                endTime = convertTimeStrToTimeNumber(possibleWorkTime.endTime, true)
                startTime = convertTimeStrToTimeNumber(
                    possibleWorkTime.startTime)
            }
        }
        return new UserDayPossibleWorkTime(startTime, endTime)
    }

    public getWorkTime(minCalendarHour: number, maxCalendarHour: number,
                       userDayPossibleWorkTime: UserDayPossibleWorkTime,
                       user: IUserDto, day: DayOfWeek,
                       calendarOptionsDto: ICalendarSettingsDto,
                       dayJobs: UserDayJob[],
                       workTimeHandler: ICallback<WorkTimeContainer, void> | null = null,
                       freeTimeHandler: Function | null = null,
                       blockedTimeHandler: Function | null = null): UserDayWorkTime {
        let bookedTime = 0
        //### timeline
        for (let hour = minCalendarHour; hour < maxCalendarHour; hour += toNumber(
            calendarOptionsDto.stepsInH)) {
            let dayJobsFiltered = dayJobs.filter(
                dayJob => convertDateToTimeNumber(
                    dayJob.activeUserJob.startTime) === hour)
            if (dayJobsFiltered.length > 0) {
                let dayJob = dayJobsFiltered[0]

                let startTime = convertDateToTimeNumber(
                    dayJob.activeUserJob.startTime)
                let endTime = convertDateToTimeNumber(
                    dayJob.activeUserJob.endTime, true)
                let diffTime = endTime - startTime
                hour += diffTime - toNumber(calendarOptionsDto.stepsInH)
                bookedTime += diffTime
                if (workTimeHandler) {
                    workTimeHandler(new WorkTimeContainer(dayJob, diffTime))
                }
            } else if (hour >= userDayPossibleWorkTime.startHour && hour < userDayPossibleWorkTime.endHour) {
                nN(freeTimeHandler)
            } else {
                nN(blockedTimeHandler)
            }
        }

        return new UserDayWorkTime(userDayPossibleWorkTime.sumTime, bookedTime)
    }

    public changePassword(dto: ChangePasswordOwnRequestDto,
                          callback: ICallback<string, void> | null = null) {
        if (!isOnline()) {
            return
        }
        executeRestCallJson(this.controller, REST_OPERATION.CHANGE_PASSWORD,
            REST_METHODS.PUT, nullJsonObjectConverter, (message: string) => {
                nN(callback, message)
            }, null, dto)
    }

    getShortName(user: IUserDto | null): string {
        if (!user) {
            return ''
        }

        let result = ''
        if (user.lastname) {
            result += user.lastname
        }
        if (user.lastname && user.firstname) {
            result += ', '
        }
        if (user.firstname) {
            result += user.firstname
        }
        return result
    }

    getShortRealName(user: IUserDto | null): string {
        if (!user) {
            return ''
        }
        let result = ''
        if (user.title) {
            result += user.title + ' '
        }
        if (user.firstname) {
            result += user.firstname
        }
        if (user.lastname && user.firstname) {
            result += ' '
        }
        if (user.lastname) {
            result += user.lastname
        }
        return result
    }

    public getFullName(user: IUserDto | null,
                       blankValue: string = '???'): string {
        if (!user) {
            return ''
        }
        let result = ''
        if (user.title) {
            result += user.title + ' '
        }
        if (user.lastname) {
            result += user.lastname
        }
        if (user.lastname && user.firstname) {
            result += ', '
        }
        if (user.firstname) {
            result += user.firstname
        }
        return result ? result : blankValue
    }

    public isRoles(user: IUserDto, expectedRole: string): boolean {
        if (!user.roles) {
            return false
        }

        for (const key in user.roles) {
            if (user.roles[key] === expectedRole) {
                return true
            }
        }

        return false
    }

    createDto(): IUserDto {
        return new UserDto()
    }

}

export const userDataProvider = new UserDataProvider()
globalstate.userDataProvider = userDataProvider
