export class ListPagination {
	protected limit: number = 50
	protected page: number = 1
	protected list: Array<any> = []
	protected results: {next: {}; prev: {}; list: Array<any>} = {next: {}, prev: {}, list: []}

	public isEmpty(): boolean {
		return this.list.length === 0
	}

	protected generateButtons(parent: Element) {
		const pagesElem = parent.querySelector('#pages')
		if (pagesElem) {
			pagesElem.innerHTML = ''

			const _count = this.list.length
			const _pages = this.limit < _count ? Math.ceil(_count / this.limit) : 1

			const btns: Array<HTMLButtonElement> = []

			const clearButtons = () => btns.forEach(btn => btn.classList.toggle('current', false))

			const onButtonClick = (btn: HTMLButtonElement, page: number) => {
				this.setPage(page)
				btn.classList.toggle('current', true)
				document.dispatchEvent(new Event('updatePage'))
			}

			for (let x = 1; x < _pages + 1; x++) {
				const btn = document.createElement('button')
				btn.textContent = `${x}`
				if (x === 1) {
					btn.classList.add('current')
				}
				btns.push(btn)

				btn.addEventListener('click', () => {
					clearButtons()
					onButtonClick(btn, x)
				})
				pagesElem.append(btn)
			}
		}
	}

	public initElements() {
		const parent = document.querySelector('#pagination')

		if (parent) {
			const findLimitElem = parent.querySelector('#limit-select')

			if (findLimitElem) {
				const limitElem = findLimitElem as HTMLSelectElement
				limitElem.addEventListener('change', () => {
					const perPage = parseInt(limitElem.value)
					this.setPage(1)
					this.setLimit(perPage)
					this.generateButtons(parent)
					document.dispatchEvent(new Event('updatePage'))
				})
			}
			this.generateButtons(parent)
		}
	}

	public getLimit(): number {
		return this.limit
	}

	public getPage(): number {
		return this.page
	}

	public setLimit(limit: number): void {
		this.limit = limit
	}

	public setPage(page: number): void {
		this.page = page
	}

	public saveList(list: Array<any>) {
		this.list = list
	}

	public getFullList() {
		return this.list
	}

	public paginatedResult(page: number = this.page) {
		const startIndex = (page - 1) * this.limit
		const endIndex = page * this.limit
		const list = this.list.slice(startIndex, endIndex)

		this.results = {
			next: {},
			prev: {},
			list: list
		}

		if (endIndex < this.list.length) {
			this.results.next = {
				page: page + 1,
				limit: this.limit
			}
		}

		if (startIndex > 0) {
			this.results.prev = {
				page: page - 1,
				limit: this.limit
			}
		}

		return this.results
	}
}
