import './index.css'
import './scss/addons.scss'
import 'bootstrap'
import {loadTranslations} from './lib/tools/translation'
import {loadCurrentPage} from './lib/pages/page_manager'
import moment from 'moment'

//#### START ####
// eslint-disable-next-line no-extend-native
Date.prototype.toJSON = function () {
	return moment(this).format('YYYY-MM-DDTHH:mm:ss')
}
loadTranslations(loadCurrentPage)
