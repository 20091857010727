import {
    AbstractDto, AbtractDataProvider, CachedDto, IAbstractDto, IBooleanDto
} from './model_base'
import {
    arrayConvert,
    executeRestCallJson,
    IJsonToObjectConverter,
    nullJsonObjectConverter
} from '../tools/rest'
import {
    IJobDto, IWorkWindowDto, jobDataProvider, workWindowConverter
} from './job'
import {ITaskTypeDto, taskTypeDataProvider} from './tasktype'
import {activeUserJobConverter, IActiveUserJobDto} from './active_user_job'
import {
    convertDateToDateStr,
    convertDateToInputFormat,
    convertDateToTimeNumber,
    HashMap,
    HashMapWithArray,
    ICallback,
    IMap,
    mustNotNull,
    newDateOrNull,
    nN,
    runAsync
} from '../tools/tools'
import {IUserDto} from './user'
import {
    REST_CONTROLLER, REST_METHODS, REST_OPERATION
} from '../tools/restConsties'
import moment from "moment/moment";
import {globalstate} from "./globalstate";
import {ICustomerDto} from "./customer";
import {IBuildingDto} from "./building";

export interface IActiveJobDto extends IAbstractDto {
    job: IJobDto | null
    orgWorkWindow: IWorkWindowDto | null
    contactPersonId: number | null
    tasks: ITaskTypeDto[] | null
    activeUserJobs: IActiveUserJobDto[] | null
    activeUserJobsTemp: IActiveUserJobDto[] | null //only for client use, it will be overwritten!
    defaultActiveUserJobs: IActiveUserJobDto[] | null
    date: Date | null
    workWindowStart: string | null
    workWindowEnd: string | null
    workHours: number | null
    location1: string | null
    location2: string | null
    billed: boolean | null
    billNumber: string | null
    relatedActiveJobsKey: string | null
    withAway: boolean | null
    withWayBack: boolean | null
    hasDifferentToTheOriginalJob: boolean | null

    //temp
    customerTemp: ICustomerDto | null //only for client use, it will be overwritten!
    buildingTemp: IBuildingDto | null //only for client use, it will be overwritten!
    trafficTimeTemp: number | null //only for client use, it will be overwritten!
    workHoursBookedTemp: number | null //only for client use, it will be overwritten!
}

export class ActiveJobDto extends AbstractDto implements IActiveJobDto {
    job: IJobDto | null = null
    orgWorkWindow: IWorkWindowDto | null = null
    contactPersonId: number | null = null
    tasks: ITaskTypeDto[] | null = null
    activeUserJobs: IActiveUserJobDto[] | null = null
    activeUserJobsTemp: IActiveUserJobDto[] | null = null //only for client use, it will be overwritten!
    defaultActiveUserJobs: IActiveUserJobDto[] | null = null
    date: Date | null = null
    workWindowStart: string | null = null
    workWindowEnd: string | null = null
    workHours: number | null = null
    location1: string | null = null
    location2: string | null = null
    billed: boolean | null = null
    billNumber: string | null = null
    relatedActiveJobsKey: string | null = null
    withAway: boolean | null = null
    withWayBack: boolean | null = null
    hasDifferentToTheOriginalJob: boolean | null = null

    //temp
    customerTemp: ICustomerDto | null = null //only for client use, it will be overwritten!
    buildingTemp: IBuildingDto | null = null //only for client use, it will be overwritten!
    trafficTimeTemp: number | null = null //only for client use, it will be overwritten!
    workHoursBookedTemp: number | null = null //only for client use, it will be overwritten!

    public calculateBookedTime(): number {
        let workHoursBooked = 0;
        this.activeUserJobs?.forEach(aUJ => {
            let startTime = convertDateToTimeNumber(aUJ.startTime)
            let endTime = convertDateToTimeNumber(aUJ.endTime, true)
            workHoursBooked += endTime - startTime

        });
        return workHoursBooked;
    }
}

class FindActiveJobsRequest {
    date: Date
    endDate: Date | null = null
    compact: boolean | null = false;
    hash: number | null = null;

    constructor(date: Date, endDate: Date | null = null,
                compact: boolean | null = false, hash: number | null = null) {
        this.date = date
        this.endDate = endDate
        this.compact = compact
        this.hash = hash
    }
}

export interface IFindActiveJobsResponse {
    jobId: number | null
    date: Date
    endDate: Date | null
    activeJobDtos: IActiveJobDto[]
    jobs: IMap<IJobDto>
    useCache: boolean | null
    hash: number | null
}

export const findActiveJobsResponseConverter: IJsonToObjectConverter<CachedDto<IFindActiveJobsResponse>> = {
    convert(source: CachedDto<IFindActiveJobsResponse>): CachedDto<IFindActiveJobsResponse> {
        source.content.date = new Date(source.content.date)
        source.content.endDate = newDateOrNull(source.content.endDate)
        if (source.useCache) {
            let cached = activeJobDataProvider.getCached(source.content.date,
                source.content.endDate);
            if (!cached) {
                throw new Error("Cache missed!")
            }
            source = cached
        } else {
            for (const jobsKey in source.content.jobs) {
                source.content.jobs[jobsKey] = jobDataProvider.convert(
                    source.content.jobs[jobsKey])
                jobDataProvider.addToCache(source.content.jobs[jobsKey])
            }
            source.content.activeJobDtos = mustNotNull(
                arrayConvert(activeJobDataProvider,
                    source.content.activeJobDtos))
            source.content.activeJobDtos.forEach(activeJob => {
                activeJobDataProvider.addToCache(activeJob)
                if (activeJob.job?.useOnlyId && activeJob.job?.id) {
                    activeJob.job = source.content.jobs[activeJob.job.id]
                }
                if (activeJob.tasks) {
                    let newTasks: ITaskTypeDto[] = []
                    activeJob.tasks.forEach(task => {
                        if (task.id) {
                            const taskFromCache = taskTypeDataProvider.getFromAll(
                                task.id)
                            if (taskFromCache) {
                                task = taskFromCache;
                            }
                        }
                        newTasks.push(task)
                    })
                    activeJob.tasks = newTasks;
                }
            });
            activeJobDataProvider.setCached(source.content.date,
                source.content.endDate, source)

            let activeJobDtoByDays = new HashMapWithArray<IActiveJobDto>();
            source.content.activeJobDtos.forEach(activeJobDto => {
                let dateStr = convertDateToInputFormat(activeJobDto.date);
                activeJobDtoByDays.putArray(dateStr, activeJobDto);
            })

            if (source.content.endDate) {
                runAsync(() => {
                    //create day cache
                    activeJobDtoByDays.forEach(activeJobDtoByDay => {
                        let cache = new CachedDto<IFindActiveJobsResponse>({
                            jobId: null,
                            date: activeJobDtoByDay[0].date!,
                            endDate: null,
                            activeJobDtos: activeJobDtoByDay,
                            jobs: source.content.jobs,
                            useCache: true,
                            hash: source.hash
                        });
                        activeJobDataProvider.setCached(cache.content.date,
                            cache.content.endDate, cache)
                    })
                });
            }

        }
        source.lastUse = Date.now()
        return source
    }
}

export class StoreDefaultActiveJobUserRequest {
    jobId: number
    start: Date
    end: Date | null
    activeUserJobDtos: IActiveUserJobDto[]

    constructor(jobId: number, start: Date, end: Date | null,
                activeUserJobDtos: IActiveUserJobDto[]) {
        this.jobId = jobId
        this.start = start
        this.end = end
        this.activeUserJobDtos = activeUserJobDtos
    }
}

export class DeleteFrom {
    jobId: number
    start: Date

    constructor(jobId: number, start: Date) {
        this.jobId = jobId
        this.start = start
    }
}

export class ActiveJobDataProvider extends AbtractDataProvider<IActiveJobDto> {
    constructor() {
        super(REST_CONTROLLER.ACTIVE_JOB)
        this.startRefreshCache();
    }

    protected getUserGlobalState(): boolean {
        return false;
    }

    public deleteFrom(request: DeleteFrom,
                      success: ICallback<IBooleanDto, void> | null = null) {
        executeRestCallJson(this.controller, REST_OPERATION.DELETE_FROM,
            REST_METHODS.POST, nullJsonObjectConverter, success, null, request)
    }

    convert(source: IActiveJobDto): ActiveJobDto {
        source = mustNotNull(super.convert(source))
        source.date = new Date(mustNotNull(source.date))

        source.job = mustNotNull(
            jobDataProvider.convert(mustNotNull(source.job)))
        source.orgWorkWindow = workWindowConverter.convert(source.orgWorkWindow)
        source.activeUserJobs = arrayConvert(activeUserJobConverter,
            source.activeUserJobs)
        source.defaultActiveUserJobs = arrayConvert(activeUserJobConverter,
            source.defaultActiveUserJobs)
        return Object.assign(new ActiveJobDto(), source);
    }

    public saveDefaults(request: StoreDefaultActiveJobUserRequest,
                        success: ICallback<IBooleanDto, void> | null = null) {
        executeRestCallJson(this.controller, REST_OPERATION.DEFAULT,
            REST_METHODS.POST, nullJsonObjectConverter, success, null, request)
    }

    public loadListAtDate(date: Date,
                          success: ICallback<IFindActiveJobsResponse, void> | null,
                          forceRefresh: boolean = false,
                          upateLastUse: boolean = true) {

        globalstate.executeIfReady(() => {
            let cached = this.getCached(date, null);
            let timeout = moment(Date.now()).subtract(60, "seconds")
            if (forceRefresh || !cached || timeout.isSameOrAfter(
                cached?.time)) {
                executeRestCallJson(this.controller, REST_OPERATION.FIND,
                    REST_METHODS.POST, findActiveJobsResponseConverter,
                    (result) => {
                        nN(success, result.content);
                    }, null, new FindActiveJobsRequest(date, null, true,
                        cached ? cached.hash : null))
            } else {
                //use local cache
                if (upateLastUse) {
                    cached.lastUse = Date.now()
                }
                nN(success, cached.content)
            }
        })


    }


    private cache = new HashMap<CachedDto<IFindActiveJobsResponse>>();

    public clearCache() {
        this.cache = new HashMap();
    }

    public getCached(date: Date,
                     endDate: Date | null): CachedDto<IFindActiveJobsResponse> | null {
        return this.cache.get(this.getCacheId(date, endDate));
    }

    public setCached(date: Date, endDate: Date | null,
                     response: CachedDto<IFindActiveJobsResponse>) {
        response.time = Date.now()
        return this.cache.put(this.getCacheId(date, endDate), response);
    }

    private getCacheId(date: Date, endDate: any) {
        return convertDateToDateStr(date) + "_" + convertDateToDateStr(endDate);
    }


    public loadListBetweenDate(date: Date, endDate: Date,
                               success: ICallback<IFindActiveJobsResponse, any> | null = null,
                               forceRefresh: boolean = true,
                               upateLastUse: boolean = true) {
        globalstate.executeIfReady(() => {
            let cached = this.getCached(date, endDate);
            let timeout = moment(Date.now()).subtract(60, "seconds")
            if (forceRefresh || !cached || timeout.isSameOrAfter(
                cached?.time)) {
                executeRestCallJson(this.controller, REST_OPERATION.FIND,
                    REST_METHODS.POST, findActiveJobsResponseConverter,
                    (response) => {
                        nN(success, response.content)
                    }, () => {
                    }, new FindActiveJobsRequest(date, endDate, true,
                        cached ? cached.hash : null))
            } else {
                //use local cache
                if (upateLastUse) {
                    cached.lastUse = Date.now()
                }
                nN(success, cached.content)
            }
        })
    }

    public startRefreshCache() {
        this.refreshCache();
        setTimeout(() => this.startRefreshCache(), 30 * 1000);
    }


    private lastRefresh = moment(Date.UTC(1970, 0, 0));

    public refreshCache(forceRefresh: boolean = true) {
        let timeout = moment(Date.now()).subtract(15, "seconds")
        if (!forceRefresh && this.lastRefresh.isSameOrAfter(timeout)) {
            return;
        }
        this.lastRefresh = moment(Date.now());
        const sortedCache = this.cache.toArray().sort((a, b) => {
            let aa = moment(a.lastUse);
            let bb = moment(b.lastUse);
            return aa.isSameOrBefore(bb) ? 1 : -1
        })
        /*
        if (forceRefresh) {
            this.clearCache();
        }
        */
        const maxRefreshCount = 10;
        const cacheCount = sortedCache.length > maxRefreshCount ?
            maxRefreshCount : sortedCache.length
        let timeoutLastUse = moment(Date.now()).subtract(1, "minute")
        for (let i = 0; i < cacheCount; i++) {
            const cached = sortedCache[i];
            if (cached.content.endDate && moment(cached.lastUse)
                .isAfter(timeoutLastUse)) {
                this.loadListBetweenDate(cached.content.date,
                    cached.content.endDate, null, forceRefresh, false)
            }
        }
    }

    existUserInUserList(user: IUserDto, activeJob: IActiveJobDto) {
        return activeJob.activeUserJobs?.find(oldUser => {
            return oldUser.userId === user.id
        })
    }

    existUserInUserDefaultList(user: IUserDto, activeJob: IActiveJobDto) {
        return activeJob.defaultActiveUserJobs?.find(oldUser => {
            return oldUser.userId === user.id
        })
    }

    removeUser(user: IUserDto, activeJob: IActiveJobDto) {
        if (!activeJob.activeUserJobs) {
            return
        }

        let result = activeJob.activeUserJobs?.filter(oldUser => {
            return oldUser.userId !== user.id
        })
        if (!result) {
            throw Error('That could not happend!')
        }
        activeJob.activeUserJobs = result
    }

    getShortName(activeJob: IActiveJobDto): string {
        if (!activeJob.job) {
            return '???'
        }
        if (!activeJob.job.customId) {
            // @ts-ignore
            return activeJob.job.building.name
        } else {
            return '(' + activeJob.job.customId + ') ' + activeJob.job.building.name
        }
    }

    createDto(): IActiveJobDto {
        return new ActiveJobDto()
    }
}

export const activeJobDataProvider = new ActiveJobDataProvider()
globalstate.activeJobDataProvider = activeJobDataProvider;