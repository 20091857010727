class Store {
	private static instance: Store
	private key: string

	constructor(key: string = 'prevent') {
		this.key = key

		if (Store.instance) {
			return Store.instance
		}

		Store.instance = this
	}

	private get(): string {
		const _session: Storage = window.sessionStorage
		return _session.getItem(this.key) ?? 'false'
	}

	private set(value: string): void {
		const _session: Storage = window.sessionStorage
		return _session.setItem(this.key, value)
	}

	public getState(): boolean {
		return this.get() === 'false'
	}

	public setState(state: boolean = false): void {
		return this.set(`${state}`)
	}
}

export const preventStore = new Store('preventClose')

export const preventCloseInit = () => {
	false &&
		document.addEventListener(
			'openModal',
			() => {
				window.onbeforeunload = function () {
					return ''
				}
			},
			{passive: true}
		)

	document.addEventListener(
		'closeModal',
		() => {
			window.onbeforeunload = null
		},
		{passive: true}
	)
}
