import Cookies from "js-cookie";
import {PAGE_LOGIN} from "../consties";
import {actionsInternal} from "../pages/page_manager";
import {globalstate} from "../model/globalstate";
import {clearLoginParams} from "./rest";
import {activeJobDataProvider} from "../model/active_job";

export enum REST_METHODS {
    POST = 'POST', GET = 'GET', DELETE = 'DELETE', PUT = 'PUT', PATCH = 'PATCH'
}

export enum REST_CONTROLLER {
    GLOBAL_STATE = '/state',
    PASSWORD_RESET = '/1/pwslost',
    BACKUP = '/backup',
    LOGIN = '/1/login',
    REGISTER = '/1/register',
    LOGOUT = '/logout',
    USER = '/user/1',
    TASK_TYPE = '/tasktype/1',
    TASK_TYPE_ABBREVIATION = '/tasktype/abbreviation/1',
    USER_GROUP = '/usergroup/1',
    JOB = '/job/1',
    JOB_GROUP = '/job/1/group/1',
    ACTIVE_JOB = '/activeJobs/1',
    USER_TYPE = '/usertype/1',
    BUILDING = '/building/1',
    CUSTOMER = '/customer/1',
    CUSTOMER_CONTACT_PERSON = '/customer/contact/1/{parentId}',
    CUSTOMER_BUILDING_ALT = '/customer/building/1',
    CUSTOMER_BUILDING = '/customer/building/1/{parentId}',
    TRANSLATION = '/translation.json',
    CALENDAR_SETTINGS = '/settings/calendar/1',
    COMPANY = '/company/1'
}

export enum REST_OPERATION {
    NONE = '',
    CREATE_IF_NEEDED = '/create/ifNeeded',
    SAVE = '/',
    GET = '/{id}',
    DELETE = '/{id}',
    ALL_MANAGED_BASIC = '/allmanaged',
    ALL_MANAGED = '/allmanaged?compact={compact}&compact2={compact2}',
    ALL_MANAGED_CACHED = '/allmanaged/cached?compact={compact}&compact2={compact2}&hash={hash}',
    ALL = '/all?compact={compact}&compact2={compact2}',
    ALL_CACHED = '/all/cached?compact={compact}&compact2={compact2}&hash={hash}',
    ALL_INTERNAL_MANAGED = '/internal/allmanaged?compact={compact}&compact2={compact2}',
    ALL_INTERNAL_MANAGED_CACHED = '/internal/allmanaged/cached?compact={compact}&compact2={compact2}&hash={hash}',
    ALL_MEMBERS = '/member/{id}?compact={compact}&compact2={compact2}',
    ACTIVE = '/active',
    MEMBER = '/member/{parentId}/{id}',
    MAIN_CONTACT = '/mainContact',
    FIND = '/find',
    STATUS = '/status',
    DEFAULT = '/default',
    DELETE_FROM = '/deleteFrom',
    CURRENT = '/current',
    CHANGE_PASSWORD = '/changeownpassword',
    SORTING = '/sorting',
    AWAY = '/away/{userId}',
    AWAY_DELETE = '/away/{userId}/{awayId}'
}

export const loginTokenCookies = Cookies.get('loginToken');

export let isOnlineFlag = loginTokenCookies ? !!loginTokenCookies : false;

export function setOnline(online: boolean) {
    if (!online) {
        globalstate.clearCache();
        clearLoginParams();
    }
    isOnlineFlag = online
}

export function isOnline(): boolean {
    return isOnlineFlag
}
