import {AbstractEditModal} from '../abstract_edit_modal'
import {
    activeJobDataProvider, ActiveJobDataProvider, ActiveJobDto, IActiveJobDto
} from '../../../model/active_job'
import {CustomerBuildingDataProvider} from '../../../model/customer'
import {
    collectSelected,
    confirmYesNo,
    convertTimeNumberToTimeStr,
    convertTimeStrToTimeNumber,
    toStringOrEmpty,
    handleError,
    HashMap,
    setSelectedOptions,
    decimalHourToNumber,
    toDecimalHour
} from '../../../tools/tools'
import {
    addEmptyOption, addOption, isChecked, onChange, onClick, setChecked2
} from '../../../tools/templateTools'
import {taskTypeDataProvider} from '../../../model/tasktype'
import {calendarSettingsDataProvider} from '../../../model/calendar_settings'
import {refreshUIAfterJobChanged} from './calendar_tools'
import {initMultiSelect} from '../../../tools/multi_select'
import {IUserDto, userDataProvider} from '../../../model/user'
import {getMainPhone} from '../../../model/model_base'
import {gt} from '../../../tools/translation'
import {Events} from '../../../tools/calendar_widget'
import {newOrderModal} from './new-order'

export class ActiveJobModal extends AbstractEditModal<IActiveJobDto, ActiveJobDataProvider> {
    private static instance: ActiveJobModal
    private customerBuildingDataProvider: CustomerBuildingDataProvider | null = null
    private contacts = new HashMap<IUserDto>()

    constructor() {
        super(activeJobDataProvider, 'active-job')
        if (ActiveJobModal.instance) {
            return ActiveJobModal.instance
        }
        return this
    }

    protected getHtmlDir(): string {
        return 'kalendar'
    }

    protected createNewDto(): IActiveJobDto {
        return new ActiveJobDto()
    }

    protected fillDefaults(callback: Function) {
        this.getTitleHtml().text(gt('active-job.title.edit'))
        calendarSettingsDataProvider.fillSelectWithTimes(
            this.getWorkWindowStartHtml(), 0, 24)
        calendarSettingsDataProvider.fillSelectWithTimes(
            this.getWorkWindowEndHtml(), 0, 24)
        calendarSettingsDataProvider.fillSelectWithTimes(this.getWorkHourHtml(),
            0, 5)

        taskTypeDataProvider.fillSelectWithTaskTypes(
            this.getTaskTypeSelectHtml())
        super.fillDefaults(callback)
        initMultiSelect(this.getTaskTypeSelectHtml())
    }

    protected collectDataToDto(dto: IActiveJobDto) {
        const _date = `${this.getDateHtml().val()}`
        dto.tasks = collectSelected(this.getTaskTypeSelectHtml(),
            taskTypeDataProvider.createDto)
        dto.comment = this.getCommentHtml().val() + ''
        dto.date = _date.length > 0 ?
            new Date(_date.split('.').reverse().join('/')) : new Date()
        dto.workWindowStart = convertTimeNumberToTimeStr(
            this.getWorkWindowStartHtml().val())
        dto.workWindowEnd = convertTimeNumberToTimeStr(
            this.getWorkWindowEndHtml().val())
        dto.workHours = decimalHourToNumber(this.getWorkHourHtml().val() + '')
        dto.billed = isChecked(this.getBilledHtml())
        dto.billNumber = this.getBillNumberHtml().val() + ''
    }

    private handleContactChange() {
        const contactId = this.getCustomerContactSelectHtml().val()
        const contact = contactId ? this.contacts.get(contactId) : false
        if (contact) {
            //contact selected
            this.fillContactFields(contact)
        } else {
            //contact deselected
            this.clearContactFields()
        }
    }

    private fillContactFields(contact: IUserDto) {
        this.getCustomerContactPhoneHtml()
            .val(toStringOrEmpty(getMainPhone(contact.address)))
        this.getCustomerContactEmailHtml()
            .val(toStringOrEmpty(contact.address?.email))
    }

    private clearContactFields() {
        this.getCustomerContactPhoneHtml().val('')
        this.getCustomerContactEmailHtml().val('')
    }

    private setDisabled(target: JQuery, disable: boolean = true,
                        parent: boolean = false) {
        const _parent = target[0].parentElement

        if (!parent) {
            if (disable) {
                target.attr('disabled', 'disabled')
            } else {
                target.attr('disabled', null)
            }
        } else if (_parent) {
            const _label = _parent.parentElement
            _parent.dataset.disabled = `${disable}`

            if (_label) {
                _label.dataset.disabled = `${disable}`
            }
        }
    }

    private setDisableNotChangeableValues(disable: boolean) {
        this.setDisabled(this.getOrderIdHtml(), disable, true)
        this.setDisabled(this.getOrderJarhCheckboxHtml(), disable, true)
        this.setDisabled(this.getCustomerSelectHtml(), disable, true)
        this.setDisabled(this.getCustomerNameHtml(), disable, true)
        this.setDisabled(this.getCustomerContactPhoneHtml(), disable, true)
        this.setDisabled(this.getCustomerContactEmailHtml(), disable, true)

        this.setDisabled(this.getBuildingNameHtml(), disable, true)
        this.setDisabled(this.getBuildingStreetHtml(), disable, true)
        this.setDisabled(this.getBuildingZipCodeHtml(), disable, true)
        this.setDisabled(this.getBuildingCityHtml(), disable, true)
    }

    protected fillFromDto(dto: IActiveJobDto, isCopyOrder?: boolean) {
        setChecked2(this.getRhytmusCheckbox(), true)
        this.getTitleHtml().text(gt('active-job.title.edit'))
        if (!dto.job || !dto.job.customer || !dto.job.customer.id) {
            handleError('Customer not set!')
            return
        }
        setChecked2(this.getOrderJarhCheckboxHtml(), !!dto.billed)
        this.getOrderIdHtml().val(isCopyOrder ? "" : toStringOrEmpty(dto.job.customId))
        this.getCustomerContactSelectHtml().empty()
        addEmptyOption(this.getCustomerContactSelectHtml())
        const _contact = dto.job.contact
        dto.job.customer.contactPersons?.forEach(contact => {
            const _selected = !!_contact && _contact.id === contact.id
            addOption(this.getCustomerContactSelectHtml(), contact.id,
                userDataProvider.getFullName(contact), _selected)

            if (_selected) {
                this.fillContactFields(contact)
            }
        })

        calendarSettingsDataProvider.fillSelectWithFloorNames(
            this.getFloorSelectionHtml())
        calendarSettingsDataProvider.fillSelectWithPositionNames(
            this.getPositionSelectionHtml())

        onChange(this.getCustomerContactSelectHtml(), this.handleContactChange)
        if (isCopyOrder) {
            this.setDisableNotChangeableValues(false)
        } else {
            this.setDisableNotChangeableValues(true)
        }
        //#### readonly
        const customerId = dto.job.customer.customId ?? `${dto.job.customer.id}`
        this.customerBuildingDataProvider = new CustomerBuildingDataProvider(
            dto.job.customer.id)
        this.getCustomerSelectHtml()
            .val(`(${customerId}) ${dto.job.customer.name}`)
        this.getCustomerNameHtml().val(`${dto.job.customer.name}`)
        this.getBuildingNameHtml().val(
            this.customerBuildingDataProvider.getFullName(dto.job.building))
        this.getBuildingStreetHtml()
            .val(toStringOrEmpty(dto.job.building.address?.streetAndNr))
        this.getBuildingZipCodeHtml()
            .val(toStringOrEmpty(dto.job.building.address?.zipCode))
        this.getBuildingCityHtml()
            .val(toStringOrEmpty(dto.job.building.address?.city))

        //### changeable
        this.getCommentHtml().val(toStringOrEmpty(dto.comment))
        if (dto.date) {
            this.getDateCalendar().dispatchEvent(
                new CustomEvent(Events.DATE_SELECTED,
                    {detail: {date: dto.date}}))
        }
        this.getWorkWindowStartHtml()
            .val(convertTimeStrToTimeNumber(dto.workWindowStart))
        this.getWorkWindowEndHtml()
            .val(convertTimeStrToTimeNumber(dto.workWindowEnd, true))
        this.getWorkHourHtml().val(toDecimalHour(dto.workHours))
        setChecked2(this.getBilledHtml(), dto.billed)
        this.getBillNumberHtml().val(toStringOrEmpty(dto.billNumber))

        setSelectedOptions(this.getTaskTypeSelectHtml(), dto.tasks)

        //### actions
        onClick(this.getDeleteBtnHtml(), () => {
            if (dto.id && confirmYesNo('Diesen Einsatz wirklich löschen?')) {
                activeJobDataProvider.delete(dto.id, () => {
                    this.hideDialog()
                })
            }
        })

        onClick(this.getSaveBtnHtml(), () => this.saveToServer())
        onClick(this.getSaveAsNewBtnHtml(), () => {
            dto.id = null;
            dto.customId = null;
            this.saveToServer()
        })
        onClick(this.getSaveAsNewOrderBtnHtml(), () => {
            this.hideDialog()
            newOrderModal.openDialogForNew(this.dto?.job, true)
        })
        // onClick(this.getPdfBtnHtml(), () => this.saveToServer())

        onClick($('#active-job-close-button'), () => {
            this.hideDialog()
        })
    }

    protected postCloseDialog() {
        if (this.hasChanged) {
            refreshUIAfterJobChanged(true);
        }
    }

    private getTitleHtml(): JQuery {
        return $('#active-job-title')
    }

    private getRhytmusCheckbox(): JQuery {
        return $('#active-job-rhythmus')
    }

    private getDeleteBtnHtml(): JQuery {
        return $('#active-job-delete-button')
    }

    private getSaveBtnHtml(): JQuery {
        return $('#active-job-save-button')
    }

    private getSaveAsNewBtnHtml(): JQuery {
        return $('#active-job-save-for-new-button')
    }

    private getSaveAsNewOrderBtnHtml(): JQuery {
        return $('#active-job-save-for-new-order-button')
    }

    private getPdfBtnHtml(): JQuery {
        return $('#active-job-pdf-button')
    }

    private getOrderIdHtml(): JQuery {
        return $('#active-job-id')
    }

    private getOrderJarhCheckboxHtml(): JQuery {
        return $('#active-job-jahr-checkbox')
    }

    private getCustomerSelectHtml(): JQuery {
        return $('#active-job-customer-select')
    }

    private getCustomerNameHtml(): JQuery {
        return $('#active-job-customer-name')
    }

    private getCustomerContactSelectHtml(): JQuery {
        return $('#active-job-customer-contact-select')
    }

    private getCustomerContactPhoneHtml(): JQuery {
        return $('#active-job-customer-contact-phone')
    }

    private getCustomerContactEmailHtml(): JQuery {
        return $('#active-job-customer-contact-email')
    }

    private getTaskTypeSelectHtml(): JQuery {
        return $('#active-job-task-type-select')
    }

    private getBuildingNameHtml(): JQuery {
        return $('#active-job-customer-object-select')
    }

    private getBuildingStreetHtml(): JQuery {
        return $('#active-job-customer-object-street')
    }

    private getBuildingZipCodeHtml(): JQuery {
        return $('#active-job-customer-object-zip')
    }

    private getBuildingCityHtml(): JQuery {
        return $('#active-job-customer-object-city')
    }

    private getCommentHtml(): JQuery {
        return $('#active-job-comment')
    }

    private getFloorSelectionHtml() {
        return $('#active-job-floor-selection')
    }

    private getPositionSelectionHtml() {
        return $('#active-job-position-selection')
    }

    private getDateCalendar() {
        return document.querySelector(
            '#active-job-date-calendar') as HTMLElement
    }

    private getDateHtml(): JQuery {
        return $('#active-job-date')
    }

    private getWorkWindowStartHtml(): JQuery {
        return $('#active-job-workwindow-start')
    }

    private getWorkWindowEndHtml(): JQuery {
        return $('#active-job-workwindow-end')
    }

    private getWorkHourHtml(): JQuery {
        return $('#active-job-worktime')
    }

    private getBilledHtml(): JQuery {
        return $('#active-job-billed')
    }

    private getBillNumberHtml(): JQuery {
        return $('#active-job-bill-number')
    }
}

export const activeJobModal = new ActiveJobModal()
