import {HashMap, ICallback} from './tools'
import {getHtmlId, setHtmlId} from './templateTools'
import {FILE_VERSION} from '../consties'

let templatesCache = new HashMap<JQuery>()
let loadedTemplateSources = new HashMap<boolean>()

export function createNewFromTemplate(key: string, translationKey?: string): JQuery {
	let template = templatesCache.get(key)
	if (template && template.length > 0) {
		//provide new element
		const _clone = template.clone()
		if (translationKey) {
			_clone.attr('data-translation-key', translationKey)
		}
		return _clone
	}
	throw Error('Template not found: ' + key)
}

export function loadTemplates(path: string, callback: ICallback<void, void>) {
	//already loaded
	if (loadedTemplateSources.get(path)) {
		callback()
		return
	}
	loadedTemplateSources.put(path, true)

	//load new templates
	$.get(path + '.html?' + FILE_VERSION, data => {
		//store new templates
		let templateNewLoaded = $(data)
		for (let i = 0; i < templateNewLoaded.length; i++) {
			let templateElement = $(templateNewLoaded[i])
			let htmlId = getHtmlId(templateElement)
			if (htmlId) {
				setHtmlId(templateElement, null)
				templatesCache.put(htmlId, templateElement)
			}
		}
		callback()
	})

	//load css
	$('head').append($('<link href="' + path + '.css" rel="stylesheet">'))
}

export function scanForTemplates(keys: string[]) {
	keys
		.filter(key => !templatesCache.contains(key))
		.forEach(key => {
			let newTemplate = $('#' + key)
			if (!newTemplate || newTemplate.length === 0) {
				throw Error('Found template not: ' + key)
			}
			if (newTemplate.length > 1) {
				throw Error('Found more then ONE template: ' + key)
			}
			newTemplate.remove()

			let template = templatesCache.get(key)
			if (template) {
				return
			}

			if (newTemplate) {
				newTemplate = newTemplate.clone()
				setHtmlId(newTemplate, null)
				templatesCache.put(key, newTemplate)
			}
		})
}
