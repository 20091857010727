import {AbstractSettingWithBindingsPage, DataToHtmlBinding} from './abstract_settings_page'
import {companyDataProvider, CompanyDataProvider, CompanyDto, ICompanyDto} from '../../model/company'

export class CompanyPage extends AbstractSettingWithBindingsPage<ICompanyDto, CompanyDataProvider> {
	constructor() {
		super('settings-company', companyDataProvider, [
			new DataToHtmlBinding('name', 'name', false),
			new DataToHtmlBinding('street', 'streetAndNr', true),
			new DataToHtmlBinding('zipcode', 'zipCode', true),
			new DataToHtmlBinding('city', 'city', true),
			new DataToHtmlBinding('tax-id', 'taxId', false),
			new DataToHtmlBinding('contact-firstname', 'contactPersonFirstName', false),
			new DataToHtmlBinding('contact-lastname', 'contactPersonLastName', false),

			new DataToHtmlBinding('phone-1', 'phone', true),
			new DataToHtmlBinding('phone-2', 'phone2', true),
			new DataToHtmlBinding('fax', 'fax', true),
			new DataToHtmlBinding('email', 'email', true)
		])
	}

	public customFill2(target: JQuery, loadedCompany: ICompanyDto) {}

	protected createDto(): ICompanyDto {
		return new CompanyDto()
	}
}

export const companyPage = new CompanyPage()
