import Cookies from 'js-cookie'
import {onClick} from '../../tools/templateTools'
import {AbstractModal} from './abstract_modal'

export class CookieBanner extends AbstractModal {
	private static instance: CookieBanner
	constructor() {
		super('cookie-banner')
		if (CookieBanner.instance) {
			return CookieBanner.instance
		}
		return this
	}

	private getBannerHtml(): JQuery {
		return $('#cookie-banner')
	}

	private getCloseBtnHtml(): JQuery {
		return $('#cookie-close-btn')
	}

	private toggleBannerVisibility(state?: boolean): void {
		const _banner = this.getBannerHtml()
		if (_banner) {
			if (typeof state === 'undefined') {
				const _state = _banner.attr('data-visibility') ?? 'false'
				_banner.attr('data-visibility', _state === 'false' ? 'true' : 'false')
			} else {
				_banner.attr('data-visibility', `${state}`)
			}
		}
	}

	private acceptCookies(): void {
		Cookies.set('cookies', 'accepted')
	}

	protected postHtmlLoaded(): void {
		document.dispatchEvent(new Event('handlePageLoaded'))
		setTimeout(() => {
			this.toggleBannerVisibility(true)
		}, 250)
		onClick(this.getCloseBtnHtml(), () => {
			this.acceptCookies()
			this.toggleBannerVisibility()
		})
	}
}

export const cookieBanner = new CookieBanner()
