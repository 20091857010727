import {fillSelectWithDaysOfWeek, onChange} from '../../tools/templateTools'
import {CalendarSettingsDto} from '../../model/calendar_settings'
import {toDayOfWeek} from '../../model/dayofweek'
import {AbstractCalendarSettingsPage} from './abstract_settings_page'

export class WochenansichtPage extends AbstractCalendarSettingsPage {
	private calendarSettings = new CalendarSettingsDto()

	constructor() {
		super('settings-week-view')
	}

	public customFill(target: JQuery, calendarSettingsDto: CalendarSettingsDto) {
		this.calendarSettings = calendarSettingsDto

		onChange(this.getStartDayHtml(), () => {
			this.calendarSettings.weekStartDay = toDayOfWeek(this.getStartDayHtml().val())
		})
		onChange(this.getEndDayHtml(), () => {
			this.calendarSettings.weekEndDay = toDayOfWeek(this.getEndDayHtml().val())
		})

		fillSelectWithDaysOfWeek(this.getStartDayHtml())
		fillSelectWithDaysOfWeek(this.getEndDayHtml())

		this.getStartDayHtml().val(this.calendarSettings.weekStartDay + '')
		this.getEndDayHtml().val(this.calendarSettings.weekEndDay + '')
	}

	protected save() {
		let dto = new CalendarSettingsDto()
		dto.id = -1
		dto.weekStartDay = this.calendarSettings.weekStartDay
		dto.weekEndDay = this.calendarSettings.weekEndDay
		this.dataProvider.save(dto)
	}

	private getStartDayHtml(): JQuery {
		return this.getHtml('start-day')
	}

	private getEndDayHtml(): JQuery {
		return this.getHtml('end-day')
	}
}

export const wochenansichtPage = new WochenansichtPage()
