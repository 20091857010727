import {
	AbstractWithIdAndNameDto,
	AbtractDataProviderWithName,
	IAbstractWithIdAndNameDto
} from './model_base'
import {addOption} from '../tools/templateTools'
import {REST_CONTROLLER} from '../tools/restConsties'
import {globalstate} from "./globalstate";

export interface ITaskTypeAbbreviationDto extends IAbstractWithIdAndNameDto {
	abbreviation: string
}

export class TaskTypeAbbreviationDto extends AbstractWithIdAndNameDto implements ITaskTypeAbbreviationDto {
	abbreviation = ''
}

export class TaskTypeAbbreviationDataProvider extends AbtractDataProviderWithName<ITaskTypeAbbreviationDto> {
	constructor() {
		super(REST_CONTROLLER.TASK_TYPE_ABBREVIATION)
	}

	protected getUserGlobalState(): boolean {
		return true;
	}

	public fillSelectWithTaskTypes(target: JQuery, emptyOption: boolean = false) {
		target.empty()
		if (emptyOption) {
			addOption(target, -1, '')
		}
		this.loadListAllCache?.content?.forEach(taskType => {
			addOption(target, taskType.abbreviation, taskType.name)
		})
	}

	createDto(): ITaskTypeAbbreviationDto {
		return new TaskTypeAbbreviationDto()
	}
}

export const taskTypeAbbreviationDataProvider = new TaskTypeAbbreviationDataProvider()
globalstate.taskTypeAbbreviationDataProvider=taskTypeAbbreviationDataProvider
