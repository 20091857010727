import {AbstractModal} from './abstract_modal'
import {addOption, onClick} from '../../tools/templateTools'
import {ICallback, toNumber} from '../../tools/tools'
import {getShortNameFromWorker, IActiveUserJobDto} from '../../model/active_user_job'

export class SelectMaForEinsatzReport extends AbstractModal {
	private static instance: SelectMaForEinsatzReport
	constructor() {
		super('modal-selectMaForEinsatzReport')
		if (SelectMaForEinsatzReport.instance) {
			return SelectMaForEinsatzReport.instance
		}
		return this
	}

	public showDialogWithData(aUJs: IActiveUserJobDto[], callback: ICallback<number, any>) {
		let selectHtml = $('#modal-selectMaForEinsatzReport-ma-select')
		selectHtml.empty()
		aUJs.forEach(aUJ => {
			addOption(selectHtml, aUJ.userId, getShortNameFromWorker(aUJ))
		})

		onClick(this.getSubmitButton(), () => {
			callback(toNumber(selectHtml.val()))
			this.hideDialog()
		})

		//Button
		onClick(this.getCancelButton(), () => {
			this.hideDialog()
		})

		document.dispatchEvent(new Event('handlePageLoaded'))
		this.showDialog()
	}

	private getSubmitButton = (): JQuery => $('#modal-selectMaForEinsatzReport-submit-bnt')
	private getCancelButton = (): JQuery => $('#modal-selectMaForEinsatzReport-job-cancel-btn')
}

export const selectMaForEinsatzReport = new SelectMaForEinsatzReport()
