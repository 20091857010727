import {CONSOLE_DEBUG} from '../consties'
import {popupService} from './popups'

export interface IErrorHandler {
	(errorObject: any): void
}

export const globalErrorHandler: IErrorHandler = (errorObject: any) => {
	if (CONSOLE_DEBUG) console.trace('ERROR: ', errorObject)
	popupService.error('ERROR: ' + JSON.stringify(errorObject))
}
