import {AbstractClass} from '../../tools/abstract_class'
import {onChange, onClick} from '../../tools/templateTools'
import {scanForTemplates} from '../../tools/templates'
import {
    AbtractDataProvider,
    AddressDto,
    IAbstractDto,
    IAbstractWithIdAndNameAndAddressDto
} from '../../model/model_base'
import {
    calendarSettingsDataProvider,
    CalendarSettingsDataProvider,
    ICalendarSettingsDto
} from '../../model/calendar_settings'
import {toStringOrEmpty, ICallback, toNumber} from '../../tools/tools'
import {globalstate} from "../../model/globalstate";


export abstract class AbstractSettingsPage<DTO extends IAbstractDto, PROVIDER extends AbtractDataProvider<DTO>> extends AbstractClass {
    protected htmlIdPrefix: string
    protected dataProvider: PROVIDER
    private templateIds: string[]

    constructor(htmlIdPrefix: string, dataProvider: PROVIDER,
                templateIds: string[] = []) {
        super()
        this.templateIds = templateIds
        this.htmlIdPrefix = htmlIdPrefix + '-'
        this.dataProvider = dataProvider
    }

    public fill(target: JQuery) {
        scanForTemplates(this.templateIds)

        onClick(this.getSaveBtnHtml(), this.save)
        globalstate.refresh(() => {
            this.customFill(target, this.dataProvider.current.content)
        })
    }

    protected abstract customFill(target: JQuery, dto: DTO): void

    protected abstract save(): void

    protected getHtml(htmlIdPostFix: string): JQuery {
        return $('#' + this.htmlIdPrefix + htmlIdPostFix)
    }

    protected getSaveBtnHtml(): JQuery {
        return this.getHtml('save-btn')
    }
}

export abstract class AbstractCalendarSettingsPage extends AbstractSettingsPage<ICalendarSettingsDto, CalendarSettingsDataProvider> {
    constructor(htmlIdPrefix: string, templateIds: string[] = []) {
        super(htmlIdPrefix, calendarSettingsDataProvider, templateIds)
    }
}

export class DataToHtmlBinding {
    public htmlId: string
    public dtoId: string
    public isAddress: boolean

    constructor(htmlId: string, dtoId: string, isAddress: boolean = false) {
        this.htmlId = htmlId
        this.dtoId = dtoId
        this.isAddress = isAddress
    }
}

export abstract class AbstractSettingWithBindingsPage<DTO extends IAbstractDto, PROVIDER extends AbtractDataProvider<DTO>> extends AbstractSettingsPage<DTO, PROVIDER> {
    private dataBindings: DataToHtmlBinding[]
    private currentId: number = -1

    constructor(htmlIdPrefix: string, dataProvider: PROVIDER,
                dataBindings: DataToHtmlBinding[], templateIds: string[] = []) {
        super(htmlIdPrefix, dataProvider, templateIds)
        this.dataBindings = dataBindings
    }

    public customFill(target: JQuery, loadedDto: DTO) {
        this.currentId = toNumber(loadedDto.id)

        //editable fields
        this.dataBindings.forEach(value => {
            this.bindToHml(loadedDto, value.htmlId, value.dtoId,
                value.isAddress)
        })

        this.customFill2(target, loadedDto)
    }

    protected abstract createDto(): DTO

    protected abstract customFill2(target: JQuery, dto: DTO): void

    protected save(callback?: ICallback<number, void>) {
        const _cb = callback ? callback : null
        let dto = this.createDto()
        dto.id = this.currentId
        this.dataBindings.forEach(value => {
            if (value.isAddress) {
                let dtoWithAddress = this.convertToWithAddress(dto)
                if (!dtoWithAddress.address) {
                    dtoWithAddress.address = new AddressDto()
                }
                dtoWithAddress.address[value.dtoId] = toStringOrEmpty(
                    this.getHtml(value.htmlId).val())
            } else {
                // @ts-ignore
                dto[value.dtoId] = toStringOrEmpty(
                    this.getHtml(value.htmlId).val())
            }
        })
        this.dataProvider.save(dto, _cb)
    }

    protected bindToHml(current: DTO, htmlId: string, valueName: string,
                        isInAddress: boolean = false) {
        let htmlElement = this.getHtml(htmlId)
        if (isInAddress) {
            let dtoWithAddress = this.convertToWithAddress(current)
            if (!dtoWithAddress.address) {
                throw Error('Address not set!')
            }
            htmlElement.val(toStringOrEmpty(dtoWithAddress.address[valueName]))
        } else {
            htmlElement.val(toStringOrEmpty(current[valueName]))
        }
        onChange(htmlElement, () => {
            if (isInAddress) {
                let dtoWithAddress = this.convertToWithAddress(current)
                if (!dtoWithAddress.address) {
                    throw Error('Address not set!')
                }
                dtoWithAddress.address[valueName] = toStringOrEmpty(
                    htmlElement.val())
            } else {
                // @ts-ignore
                current[valueName] = toStringOrEmpty(htmlElement.val())
            }
        })
    }

    private convertToWithAddress(dto: DTO): IAbstractWithIdAndNameAndAddressDto {
        // @ts-ignore
        return dto as IAbstractWithIdAndNameAndAddressDto
    }
}
