import {toStringOrEmpty} from "./tools";

export enum DownloadFileTypes {
    CSV = "text/csv", TXT = "text/plain"
}

export function csvStr(str:string|null):string{
    return toStringOrEmpty(str).replaceAll(";",",").replaceAll("\n"," ");
}

export function downloadFile(filename: string, type: DownloadFileTypes,
                             content: string) {
    const downlodFileTag = document.createElement('a');
    downlodFileTag.setAttribute('href',
        'data:' + type + ';charset=utf-8,' + encodeURIComponent(content));
    downlodFileTag.setAttribute('download', filename);

    downlodFileTag.style.display = 'none';
    document.body.appendChild(downlodFileTag);

    downlodFileTag.click();

    document.body.removeChild(downlodFileTag);
}