import {IUserDto, UserDataProvider, userDataProvider, UserDto} from '../../model/user'
import {AbstractSettingWithBindingsPage, DataToHtmlBinding} from './abstract_settings_page'
import {toStringOrEmpty} from '../../tools/tools'
import {gt} from '../../tools/translation'
import {onClick} from '../../tools/templateTools'

export class ProfilPage extends AbstractSettingWithBindingsPage<IUserDto, UserDataProvider> {
	constructor() {
		super('settings-profil', userDataProvider, [
			new DataToHtmlBinding('languageKey', 'languageKey', false),
			new DataToHtmlBinding('login', 'name', false),
			new DataToHtmlBinding('firstname', 'firstname', false),
			new DataToHtmlBinding('lastname', 'lastname', false),

			new DataToHtmlBinding('phone1', 'phone', true),
			new DataToHtmlBinding('phone2', 'phone2', true),
			new DataToHtmlBinding('fax', 'fax', true),
			new DataToHtmlBinding('email', 'email', true),
			new DataToHtmlBinding('street', 'streetAndNr', true),
			new DataToHtmlBinding('zipcode', 'zipCode', true),
			new DataToHtmlBinding('city', 'city', true)
		])
	}

	public customFill2(target: JQuery, loadedUser: IUserDto) {
		//custom id
		this.getHtml('custom-id').val(toStringOrEmpty(loadedUser.customId))
		onClick(this.getSaveBtnHtml(), this.onSaveClick)
		//roles
		let roleStr = ''
		loadedUser.roles?.forEach(role => {
			if (roleStr.length > 0) {
				roleStr += ', '
			}
			roleStr += gt(role)
		})
		this.getHtml('roles').val(roleStr)
	}

	protected onSaveClick(): void {
		this.save(() => {
			document.dispatchEvent(new Event('settingsChanged'))
		})
	}

	protected createDto(): IUserDto {
		return new UserDto()
	}
}

export const profilPage = new ProfilPage()
