import {
	GenderEnum,
	IUserDto,
	Roles,
	RolesAll,
	RolesWorker,
	UserDataProvider,
	userDataProvider,
	UserDto,
	WorkTimeDto
} from '../../model/user'
import $ from 'jquery'
import 'bootstrap'
import {
	collectSelected,
	convertTimeNumberToTimeStr,
	convertTimeStrToTimeNumber,
	getAsNumber,
	getInputValAsString,
	roundTo2,
	setSelectedOptions,
	setValOrEmpty
} from '../../tools/tools'
import {DayOfWeek, DayOfWeeks} from '../../model/dayofweek'
import {userTypeDataProvider} from '../../model/usertype'
import {AbstractEditModal} from './abstract_edit_modal'
import {AddressDto} from '../../model/model_base'
import {mitarbeiterListPage} from '../list/mitarbeiter_list_page'
import {calendarSettingsDataProvider} from '../../model/calendar_settings'
import {taskTypeDataProvider} from '../../model/tasktype'
import {isChecked, setChecked2} from '../../tools/templateTools'
import {CONSOLE_DEBUG} from '../../consties'

//modal
const HTML_ID_MITARBEITER_MODAL = 'modal-mitarbeiter'
const HTML_ID_MITARBEITER_MODAL_GENDER = HTML_ID_MITARBEITER_MODAL + '-gender-' //+ GenderEnum
const HTML_ID_MITARBEITER_MODAL_CUSTOMER_ID = HTML_ID_MITARBEITER_MODAL + '-customerId'
const HTML_ID_MITARBEITER_MODAL_VACTION_DAYS = HTML_ID_MITARBEITER_MODAL + '-vacation-days'
const HTML_ID_MITARBEITER_MODAL_CITIZEN_SHIP = HTML_ID_MITARBEITER_MODAL + '-citizen-ship'
const HTML_ID_MITARBEITER_MODAL_NAME = HTML_ID_MITARBEITER_MODAL + '-name'
const HTML_ID_MITARBEITER_MODAL_FIRSTNAME = HTML_ID_MITARBEITER_MODAL + '-firtname'
const HTML_ID_MITARBEITER_MODAL_LASTNAME = HTML_ID_MITARBEITER_MODAL + '-lastname'
const HTML_ID_MITARBEITER_MODAL_STREET_AND_NR = HTML_ID_MITARBEITER_MODAL + '-street-and-nr'
const HTML_ID_MITARBEITER_MODAL_ZIPCODE = HTML_ID_MITARBEITER_MODAL + '-zipcode'
const HTML_ID_MITARBEITER_MODAL_CITY = HTML_ID_MITARBEITER_MODAL + '-city'
const HTML_ID_MITARBEITER_MODAL_EMAIL = HTML_ID_MITARBEITER_MODAL + '-email'
const HTML_ID_MITARBEITER_MODAL_PHONE = HTML_ID_MITARBEITER_MODAL + '-phone'
const HTML_ID_MITARBEITER_MODAL_COUNTRY = HTML_ID_MITARBEITER_MODAL + '-country'
const HTML_ID_MITARBEITER_MODAL_ROLES = HTML_ID_MITARBEITER_MODAL + '-roles'
const HTML_ID_MITARBEITER_MODAL_USER_TYPE = HTML_ID_MITARBEITER_MODAL + '-user-type'
const HTML_ID_MITARBEITER_MODAL_WORK_HOURS_PER_WEEK = HTML_ID_MITARBEITER_MODAL + '-work-hours-per-week'
const HTML_ID_MITARBEITER_MODAL_WORK_HOURS_PER_MONTH = HTML_ID_MITARBEITER_MODAL + '-work-hours-per-month'
const HTML_ID_MITARBEITER_MODAL_POSSIBLE_WORK_HOURS = HTML_ID_MITARBEITER_MODAL + '-possible-work-hour-' //+DayOfWeek
const HTML_ID_MITARBEITER_MODAL_POSSIBLE_WORK_HOURS_STARTTIME = '-startTime'
const HTML_ID_MITARBEITER_MODAL_POSSIBLE_WORK_HOURS_ENDTIME = '-endTime'
const HTML_ID_MITARBEITER_MODAL_COMMENT = HTML_ID_MITARBEITER_MODAL + '-comment'

export class MitarbeiterModal extends AbstractEditModal<IUserDto, UserDataProvider> {
	private static instance: MitarbeiterModal
	constructor() {
		super(
			userDataProvider,
			HTML_ID_MITARBEITER_MODAL,
			[
				HTML_ID_MITARBEITER_MODAL_GENDER + 'MALE',
				HTML_ID_MITARBEITER_MODAL_GENDER + 'FEMALE',
				HTML_ID_MITARBEITER_MODAL_GENDER + 'DIVERSE'
			],
			[HTML_ID_MITARBEITER_MODAL_ROLES]
		)

		if (MitarbeiterModal.instance) {
			return MitarbeiterModal.instance
		}
		return this
	}

	protected collectDataToDto(dto: IUserDto): void {
		//Password
		let newPassword = getInputValAsString('modal-mitarbeiter-new-password')
		if (newPassword.trim().length > 0) {
			dto.password = newPassword
		}

		dto.canLogin = isChecked(this.getCanLoginHtml())

		dto.name = getInputValAsString(HTML_ID_MITARBEITER_MODAL_NAME)
		dto.firstname = getInputValAsString(HTML_ID_MITARBEITER_MODAL_FIRSTNAME)
		dto.lastname = getInputValAsString(HTML_ID_MITARBEITER_MODAL_LASTNAME)
		dto.customId = getInputValAsString(HTML_ID_MITARBEITER_MODAL_CUSTOMER_ID)
		dto.vacationDays = getAsNumber(HTML_ID_MITARBEITER_MODAL_VACTION_DAYS)
		dto.citizenship = getInputValAsString(HTML_ID_MITARBEITER_MODAL_CITIZEN_SHIP)
		dto.comment = getInputValAsString(HTML_ID_MITARBEITER_MODAL_COMMENT)

		//address
		if (!dto.address) {
			dto.address = new AddressDto()
		}
		dto.address.streetAndNr = getInputValAsString(HTML_ID_MITARBEITER_MODAL_STREET_AND_NR)
		dto.address.zipCode = getInputValAsString(HTML_ID_MITARBEITER_MODAL_ZIPCODE)
		dto.address.city = getInputValAsString(HTML_ID_MITARBEITER_MODAL_CITY)
		dto.address.phone = getInputValAsString(HTML_ID_MITARBEITER_MODAL_PHONE)
		dto.address.email = getInputValAsString(HTML_ID_MITARBEITER_MODAL_EMAIL)
		dto.address.country = getInputValAsString(HTML_ID_MITARBEITER_MODAL_COUNTRY)

		//possible work times
		dto.possibleWorkTimes = []
		DayOfWeeks.forEach(dayOfWeek => {
			let possibleWorkTimes = new WorkTimeDto(dayOfWeek)
			var startId = this.getPossibleWorkTimeId(dayOfWeek, HTML_ID_MITARBEITER_MODAL_POSSIBLE_WORK_HOURS_STARTTIME)
			if (CONSOLE_DEBUG) console.log(startId)
			possibleWorkTimes.startTime = convertTimeNumberToTimeStr($('#' + startId).val())
			if (CONSOLE_DEBUG) console.log(possibleWorkTimes.startTime)
			var endId = this.getPossibleWorkTimeId(dayOfWeek, HTML_ID_MITARBEITER_MODAL_POSSIBLE_WORK_HOURS_ENDTIME)
			possibleWorkTimes.endTime = convertTimeNumberToTimeStr($('#' + endId).val())
			dto?.possibleWorkTimes?.push(possibleWorkTimes)
		})

		//UserType
		dto.userTypeId = $('#' + HTML_ID_MITARBEITER_MODAL_USER_TYPE).val() as number

		//WorkHour
		dto.workHoursPerMonth = getAsNumber(HTML_ID_MITARBEITER_MODAL_WORK_HOURS_PER_MONTH)

		//Gender
		if ($('#' + HTML_ID_MITARBEITER_MODAL_GENDER + 'MALE').prop('checked') as boolean) {
			dto.gender = GenderEnum.MALE
		} else if ($('#' + HTML_ID_MITARBEITER_MODAL_GENDER + 'FEMALE').prop('checked') as boolean) {
			dto.gender = GenderEnum.FEMALE
		} else if ($('#' + HTML_ID_MITARBEITER_MODAL_GENDER + 'DIVERSE').prop('checked') as boolean) {
			dto.gender = GenderEnum.DIVERSE
		}

		//Roles
		dto.roles = []
		RolesWorker.forEach(role => {
			if ($('#' + HTML_ID_MITARBEITER_MODAL_ROLES + " option[value='" + role + "']").prop('selected') as boolean) {
				dto?.roles?.push(role)
			}
		})

		dto.taskTypes = collectSelected(this.getTaskTypesHtml(), taskTypeDataProvider.createDto)
	}

	protected fillFromDto(dto: IUserDto) {
		//taskTypes
		setSelectedOptions(this.getTaskTypesHtml(), dto.taskTypes)

		setChecked2(this.getCanLoginHtml(), dto.canLogin)

		setValOrEmpty(HTML_ID_MITARBEITER_MODAL_NAME, dto.name)
		setValOrEmpty(HTML_ID_MITARBEITER_MODAL_FIRSTNAME, dto.firstname)
		setValOrEmpty(HTML_ID_MITARBEITER_MODAL_LASTNAME, dto.lastname)
		setValOrEmpty(HTML_ID_MITARBEITER_MODAL_CUSTOMER_ID, dto.customId)
		setValOrEmpty(HTML_ID_MITARBEITER_MODAL_VACTION_DAYS, dto.vacationDays)
		setValOrEmpty(HTML_ID_MITARBEITER_MODAL_CITIZEN_SHIP, dto.citizenship)
		setValOrEmpty(HTML_ID_MITARBEITER_MODAL_COMMENT, dto.comment)

		//address
		if (dto.address) {
			setValOrEmpty(HTML_ID_MITARBEITER_MODAL_STREET_AND_NR, dto.address.streetAndNr)
			setValOrEmpty(HTML_ID_MITARBEITER_MODAL_ZIPCODE, dto.address.zipCode)
			setValOrEmpty(HTML_ID_MITARBEITER_MODAL_CITY, dto.address.city)
			setValOrEmpty(HTML_ID_MITARBEITER_MODAL_PHONE, dto.address.phone)
			setValOrEmpty(HTML_ID_MITARBEITER_MODAL_EMAIL, dto.address.email)
			setValOrEmpty(HTML_ID_MITARBEITER_MODAL_COUNTRY, dto.address.country)
		}

		//possible work times
		if (dto.possibleWorkTimes) {
			dto.possibleWorkTimes.forEach(possibleWorkTime => {
				var startTime = possibleWorkTime.startTime
				if (startTime) {
					var startId = this.getPossibleWorkTimeId(possibleWorkTime.dayOfWeek, HTML_ID_MITARBEITER_MODAL_POSSIBLE_WORK_HOURS_STARTTIME)
					$('#' + startId).val(convertTimeStrToTimeNumber(startTime))
				}

				var endTime = possibleWorkTime.endTime
				if (endTime) {
					var endId = this.getPossibleWorkTimeId(possibleWorkTime.dayOfWeek, HTML_ID_MITARBEITER_MODAL_POSSIBLE_WORK_HOURS_ENDTIME)
					$('#' + endId).val(convertTimeStrToTimeNumber(endTime, true))
				}
			})
		}

		//UserType
		$('#' + HTML_ID_MITARBEITER_MODAL_USER_TYPE).val(dto.userTypeId ? dto.userTypeId : '')

		//WorkHour
		setValOrEmpty(HTML_ID_MITARBEITER_MODAL_WORK_HOURS_PER_MONTH, dto.workHoursPerMonth)
		this.setMonthToWeekWorkHour()

		//Gender
		$('#' + HTML_ID_MITARBEITER_MODAL_GENDER + dto.gender).prop('checked', true)
		//Roles
		RolesAll.forEach(role => {
			$('#' + HTML_ID_MITARBEITER_MODAL_ROLES + " option[value='" + role + "']").prop('selected', false)
		})
		$.each(dto.roles, (i, e) => {
			$('#' + HTML_ID_MITARBEITER_MODAL_ROLES + " option[value='" + e + "']").prop('selected', true)
		})
	}

	protected validateBeforeSave(dto: IUserDto): boolean {
		//TODO valaidate
		return true
	}

	protected createNewDto(): IUserDto {
		return new UserDto()
	}

	public setWeekToMonthWorkHour() {
		var weekHour = $('#' + HTML_ID_MITARBEITER_MODAL_WORK_HOURS_PER_WEEK).val() as number
		if (!(weekHour > 0)) {
			return
		}
		$('#' + HTML_ID_MITARBEITER_MODAL_WORK_HOURS_PER_MONTH).val(roundTo2(weekHour * 4.2))
	}

	public setMonthToWeekWorkHour() {
		var monthHour = $('#' + HTML_ID_MITARBEITER_MODAL_WORK_HOURS_PER_MONTH).val() as number
		if (!(monthHour > 0)) {
			return
		}
		$('#' + HTML_ID_MITARBEITER_MODAL_WORK_HOURS_PER_WEEK).val(roundTo2(monthHour / 4.2))
	}

	private getPossibleWorkTimeId(dayOfWeek: string, postFix: string) {
		return HTML_ID_MITARBEITER_MODAL_POSSIBLE_WORK_HOURS + dayOfWeek + postFix
	}

	private getCanLoginHtml(): JQuery {
		return $('#modal-mitarbeiter-can-login')
	}

	protected fillDefaults(callback: Function) {
		// ### DEFAULTS ###
		// TaskTypes
		taskTypeDataProvider.fillSelectWithTaskTypes(this.getTaskTypesHtml())
		$('#' + HTML_ID_MITARBEITER_MODAL_VACTION_DAYS).val(24)
		$('#' + HTML_ID_MITARBEITER_MODAL_CITIZEN_SHIP).val('DE')
		//Roles
		$('#' + HTML_ID_MITARBEITER_MODAL_ROLES + " option[value='" + Roles.WORKER + "']").prop('selected', true)
		//WorkHour
		$('#' + HTML_ID_MITARBEITER_MODAL_WORK_HOURS_PER_MONTH).val(168)
		this.setMonthToWeekWorkHour()

		DayOfWeeks.forEach(day => {
			var startId = this.getPossibleWorkTimeId(day, HTML_ID_MITARBEITER_MODAL_POSSIBLE_WORK_HOURS_STARTTIME)
			calendarSettingsDataProvider.fillSelectWithTimes($('#' + startId), 0, 24)

			var endId = this.getPossibleWorkTimeId(day, HTML_ID_MITARBEITER_MODAL_POSSIBLE_WORK_HOURS_ENDTIME)
			calendarSettingsDataProvider.fillSelectWithTimes($('#' + endId), 0, 24)

			if (day !== DayOfWeek.SUNDAY && day !== DayOfWeek.SATURDAY) {
				$('#' + startId).val(8)
				$('#' + endId).val(18)
			}
		})

		callback()
	}

	private getTaskTypesHtml(): JQuery {
		return $('#modal-mitarbeiter-tasktypes')
	}

	public fillRolesSelect() {
		$('#' + HTML_ID_MITARBEITER_MODAL_ROLES).empty()
		RolesWorker.forEach(role => {
			$('#' + HTML_ID_MITARBEITER_MODAL_ROLES).append("<option value='" + role + "'>" + role + '</option>')
		})
	}

	public fillUserTypes() {
		$('#' + HTML_ID_MITARBEITER_MODAL_USER_TYPE).empty()
		userTypeDataProvider.loadListAll(usertypes =>
			usertypes.forEach(usertype => {
				$('#' + HTML_ID_MITARBEITER_MODAL_USER_TYPE).append("<option value='" + usertype.id + "'>" + usertype.name + '</option>')
			}, true)
		)
	}

	protected postHtmlLoaded(): void {
		this.fillRolesSelect()
		this.fillUserTypes()
	}

	protected postSaveToServer() {
		mitarbeiterListPage.fillTable()
	}
}

export const mitarbeiterModal = new MitarbeiterModal()
