import {popupService} from '../tools/popups'
import {clearLoginParams, executeRestCall, getCompanyFlag, setCompanyFlag} from '../tools/rest'
import {REST_CONTROLLER, REST_METHODS, REST_OPERATION} from '../tools/restConsties'
import {IUserDto, userDataProvider} from '../model/user'
import {loadLogin} from './page_manager'

class RegisterRequest {
	private userName: string
	private password: string

	constructor(userName: string, password: string) {
		this.userName = userName
		this.password = password
	}
}

export function tryRegister() {
	clearLoginParams()
	let password1 = $('#register-password1').val() + ''
	let password2 = $('#register-password2').val() + ''

	if (password1 !== password2) {
		popupService.error('Passwörter sind nicht identisch!')
		return
	}

	let login = $('#register-username').val() + ''
	let companyId = $('#register-company-id').val() + ''

	setCompanyFlag(companyId.trim().toLowerCase())
	popupService.info('Registierung versendet, bitte warten!')

	executeRestCall<IUserDto>(
		REST_CONTROLLER.REGISTER,
		REST_OPERATION.NONE,
		REST_METHODS.POST,
		userDataProvider,
		user => {
			$('#register-password1').val('')
			$('#register-password2').val('')
			$('#register-username').val('')
			$('#register-company-id').val('')
			loadLogin(() => {
				let loginWithCompanyId = user.name + '@' + getCompanyFlag()
				$('#username').val(loginWithCompanyId)
				$('#current-password').val("")
				popupService.info('Erfolgreich registriert! Ihr Login ist: ' + loginWithCompanyId, 60)
			})
		},
		null,
		new RegisterRequest(login, password2)
	)
}
