import {AbstractModal} from '../abstract_modal'
import {activeJobDataProvider, DeleteFrom, IActiveJobDto} from '../../../model/active_job'
import {jobDataProvider, JobType} from '../../../model/job'
import {onClick} from '../../../tools/templateTools'
import {confirmYesNo} from '../../../tools/tools'
import {gt} from '../../../tools/translation'
import {encodeHtml} from "../../../tools/StringTools";

export class DeleteJobModal extends AbstractModal {
	private static instance: DeleteJobModal

	constructor() {
		super('modal-delete-job');
		if (DeleteJobModal.instance) {
			return DeleteJobModal.instance
		}
		return this
	}

	protected getHtmlDir(): string {
		return 'kalendar'
	}

	public showDialogWithData(activeJob: IActiveJobDto, callback: Function) {
		//Beschreibung
		const _objNr = gt('modal.delete-job.objnr')
		const _objName = gt('modal.delete-job.objname')
		const _aufNr = gt('modal.delete-job.aufnr')
		let fullJobComment = `${_objNr}.: ${encodeHtml(activeJob.job?.building.customId)} <br> ${encodeHtml(_objName)}: ${encodeHtml(activeJob.job?.building.name)}`

		if (activeJob.job?.customId) {
			fullJobComment = `${_aufNr}.: ${encodeHtml(activeJob.job?.customId)} <br> ${fullJobComment}`
		}

		//Button
		onClick($('#modal-delete-job-cancel-btn'), () => {
			this.hideDialog()
		})

		onClick($('#modal-delete-job-delete-full-btn'), () => {
			if (!activeJob.job || !activeJob.job.id) {
				return
			}
			if (confirmYesNo(gt('modal.delete-job.title') ?? 'Wirklich löschen?')) {
				jobDataProvider.delete(activeJob.job.id, () => {
					this.hideDialog()
					activeJobDataProvider.refreshCache(true)
					callback()
				})
			}
		})

		const _date = gt('modal.delete-job.date') ?? 'Datum'
		let dateComment = `${_date}: ${activeJob.date?.toLocaleDateString()}`
		let activeJobBtn = $('#modal-delete-job-delete-only-active-job-btn')
		let fromNowBtn = $('#modal-delete-job-delete-all-active-job-from-now-btn')
		if (false && activeJob.job?.jobType === JobType.ONCE) {
			//TODO prüfen ob es überhaupt noch andere active jobs gibt
			fromNowBtn.hide()
			activeJobBtn.hide()
			fullJobComment += '<br>' + dateComment
		} else {
			$('#modal-delete-job-delete-only-active-job-comment').text(dateComment)
			$('#modal-delete-job-delete-all-active-job-from-now-comment').text(dateComment)

			activeJobBtn.show()
			onClick(activeJobBtn, () => {
				if (!activeJob.id) {
					return
				}
				if (confirmYesNo(gt('modal.delete-job.title') ?? 'Wirklich löschen?')) {
					activeJobDataProvider.delete(activeJob.id, () => {
						this.hideDialog()
						callback()
					})
				}
			})

			fromNowBtn.show()
			onClick(fromNowBtn, () => {
				if (!activeJob.job || !activeJob.job.id || !activeJob.date) {
					return
				}
				if (confirmYesNo(gt('modal.delete-job.title') ?? 'Wirklich löschen?')) {
					activeJobDataProvider.deleteFrom(new DeleteFrom(activeJob.job.id, activeJob.date), () => {
						this.hideDialog()
						callback()
					})
				}
			})
		}

		$('#modal-delete-job-delete-full-comment').html(fullJobComment)
		this.showDialog()
	}
}

export const deleteJobModal = new DeleteJobModal()
