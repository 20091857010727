import {
    CustomerDataProvider, customerDataProvider, CustomerType, ICustomerDto
} from '../../model/customer'
import {userDataProvider} from '../../model/user'
import {
    findTemplateAndSetOnClick, findTemplateAndSetText, onClick
} from '../../tools/templateTools'
import {AbstractListPageWithName} from './abstract_list_page'
import {auftraggeberEditModal} from '../modals/auftraggeber_edit_modal'
import {
    actionsInternal, loadAuftraggeberKontakteFormular, loadBuildings
} from '../page_manager'
import {AbstractEditModal} from '../modals/abstract_edit_modal'
import {downloadFileFromServer} from "../../tools/rest";
import {DownloadFileTypes} from "../../tools/file_tools";
import moment from "moment";

const AUFTRAGEBER_LIST_CONTAINER = 'auftraggeber-list-container'
const AUFTRAGEBER_LIST_TEMPLATE = 'auftraggeber-list-template'
const AUFTRAGEBER_LIST_ID = 'auftraggeber-list-id'
const AUFTRAGEBER_LIST_CUSTOM_ID = 'auftraggeber-list-customId'
const AUFTRAGEBER_LIST_NAME = 'auftraggeber-list-name'
const AUFTRAGEBER_LIST_CONTACT_FULLNAME = 'auftraggeber-list-contact-fullname'
const AUFTRAGEBER_LIST_CONTACT_PHONE = 'auftraggeber-list-contact-phone'
const AUFTRAGEBER_LIST_CONTACT_EMAIL = 'auftraggeber-list-contact-email'
const AUFTRAGEBER_LIST_CONTACT_ZIP_CODE = 'auftraggeber-list-contact-zipcode'
const AUFTRAGEBER_LIST_CONTACT_CITY = 'auftraggeber-list-contact-city'
const AUFTRAGEBER_LIST_CONTACT_STREET_AND_NR = 'auftraggeber-list-contact-streetAndNr'
const AUFTRAGEBER_LIST_IS_ACTIVE = 'auftraggeber-list-is-active'
const AUFTRAGEBER_LIST_EDIT_BTN = 'auftraggeber-list-edit-btn'
const AUFTRAGEBER_LIST_OBJECTS_BTN = 'auftraggeber-list-objects-btn'
const AUFTRAGEBER_LIST_CONTACTS_BTN = 'auftraggeber-list-contacts-btn'
const AUFTRAGEBER_LIST_DELETE_BTN = 'auftraggeber-list-delete-btn'

export class AuftraggeberListPage extends AbstractListPageWithName<ICustomerDto, CustomerDataProvider> {
    constructor() {
        super(customerDataProvider, AUFTRAGEBER_LIST_CONTAINER,
            AUFTRAGEBER_LIST_TEMPLATE, AUFTRAGEBER_LIST_ID)
    }

    protected getModal(): AbstractEditModal<ICustomerDto, CustomerDataProvider> {
        return auftraggeberEditModal
    }

    protected loadCurrentPage() {
        const results = this.pagination.paginatedResult()
        const dtos = results.list
        this.updateTable(dtos)
    }

    public fillTable() {
        this.loadList(dtos => {
            this.dtos = dtos
            this.pagination.setPage(1)
            this.pagination.saveList(dtos)
            this.pagination.initElements()
            this.loadCurrentPage()
        })

        document.addEventListener('searchEvent', () => {
            setTimeout(() => {
                this.loadCurrentPage()
            }, 250)
        })

        document.addEventListener('updatePage', () => {
            setTimeout(() => {
                this.loadCurrentPage()
            }, 150)
        })
    }

    protected fillTableEntry(customer: ICustomerDto, tableEntry: JQuery): void {
        this.onClickOpenEdit(
            findTemplateAndSetText(tableEntry, AUFTRAGEBER_LIST_CUSTOM_ID,
                customer.customId), customer)

        this.onClickOpenEdit(
            findTemplateAndSetText(tableEntry, AUFTRAGEBER_LIST_NAME,
                this.dataProvider.getFullName(customer)), customer)

        if (customer.customerType === CustomerType.PERSON) {
            if (customer.mainContactPerson) {
                findTemplateAndSetText(tableEntry, AUFTRAGEBER_LIST_NAME,
                    userDataProvider.getFullName(customer.mainContactPerson))
            } else {
                findTemplateAndSetText(tableEntry, AUFTRAGEBER_LIST_NAME, '')
            }
        } else {
            findTemplateAndSetText(tableEntry, AUFTRAGEBER_LIST_NAME,
                this.dataProvider.getFullName(customer))
        }

        if (customer.mainContactPerson) {
            findTemplateAndSetText(tableEntry,
                AUFTRAGEBER_LIST_CONTACT_FULLNAME,
                userDataProvider.getFullName(customer.mainContactPerson, ''))
            findTemplateAndSetText(tableEntry, AUFTRAGEBER_LIST_CONTACT_PHONE,
                customer.mainContactPerson.address?.phone)
            findTemplateAndSetText(tableEntry, AUFTRAGEBER_LIST_CONTACT_EMAIL,
                customer.mainContactPerson.address?.email)
        }

        if (customer.address) {
            findTemplateAndSetText(tableEntry,
                AUFTRAGEBER_LIST_CONTACT_ZIP_CODE, customer.address.zipCode)
            findTemplateAndSetText(tableEntry, AUFTRAGEBER_LIST_CONTACT_CITY,
                customer.address.city)
            findTemplateAndSetText(tableEntry,
                AUFTRAGEBER_LIST_CONTACT_STREET_AND_NR,
                customer.address.streetAndNr)
        }

        //is active
        this.createIsActive(customer, tableEntry, AUFTRAGEBER_LIST_IS_ACTIVE)

        //edit user
        this.createEditBtn(customer, tableEntry, AUFTRAGEBER_LIST_EDIT_BTN,
            () => this.openForEdit(customer))

        //list buildings
        findTemplateAndSetOnClick(tableEntry, AUFTRAGEBER_LIST_OBJECTS_BTN,
            () => {
                if (customer.id) {
                    loadBuildings(customer)
                }
            })

        //list buildings
        findTemplateAndSetOnClick(tableEntry, AUFTRAGEBER_LIST_CONTACTS_BTN,
            () => {
                if (customer.id) {
                    loadAuftraggeberKontakteFormular(customer, null, null)
                }
            })

        //delete
        this.createDeleteBtn(customer, tableEntry, AUFTRAGEBER_LIST_DELETE_BTN)
    }

    protected openForEdit(customer: ICustomerDto) {
        actionsInternal.loadAuftraggeberFormular(customer)
    }

    private getCsvBtnHtml(): JQuery {
        return $('#auftraggeber-list-csv-btn')
    }

    private getCsvContactsBtnHtml(): JQuery {
        return $('#auftraggeber-list-csv-contacts-btn')
    }

    private getCsvObjectsBtnHtml(): JQuery {
        return $('#auftraggeber-list-csv-objects-btn')
    }

    initAfterHtmlLoaded(callback: Function | null = null) {
        super.initAfterHtmlLoaded(callback);

        onClick(this.getCsvBtnHtml(), () => {
            this.loadCsvCustomers()
        })

        onClick(this.getCsvContactsBtnHtml(), () => {
            this.loadCsvContacts()
        })

        onClick(this.getCsvObjectsBtnHtml(), () => {
            this.loadCsvObjects()
        })
    }

    private loadCsvCustomers() {
        const currentDateAndTimeStr = moment().format('YYYY-MM-DD_HH-mm-ss')
        downloadFileFromServer('/customer/export/csv/customers', null,
            "TIM-Auftraggeber_" + currentDateAndTimeStr + ".csv",
            DownloadFileTypes.CSV);
    }

    private loadCsvContacts() {
        const currentDateAndTimeStr = moment().format('YYYY-MM-DD_HH-mm-ss')
        downloadFileFromServer('/customer/export/csv/contacts', null,
            "TIM-Auftraggeber_Kontakte_" + currentDateAndTimeStr + ".csv",
            DownloadFileTypes.CSV);
    }

    private loadCsvObjects() {
        const currentDateAndTimeStr = moment().format('YYYY-MM-DD_HH-mm-ss')
        downloadFileFromServer('/customer/export/csv/objects', null,
            "TIM-Auftraggeber_Objekte_" + currentDateAndTimeStr + ".csv",
            DownloadFileTypes.CSV);
    }
}

export const auftraggeberListPage = new AuftraggeberListPage()
