import {onChange} from '../../tools/templateTools'
import {calendarSettingsDataProvider, CalendarSettingsDto} from '../../model/calendar_settings'
import {toNumber} from '../../tools/tools'
import {AbstractCalendarSettingsPage} from './abstract_settings_page'

export class TagesansichtPage extends AbstractCalendarSettingsPage {
	private calendarSettings = new CalendarSettingsDto()

	constructor() {
		super('settings-day-view')
	}

	public customFill(target: JQuery, calendarSettings: CalendarSettingsDto) {
		this.calendarSettings = calendarSettings

		onChange(this.getStartTimeHtml(), () => {
			this.calendarSettings.dayStartTime = toNumber(this.getStartTimeHtml().val())
		})
		onChange(this.getEndTimeHtml(), () => {
			this.calendarSettings.dayEndTime = toNumber(this.getEndTimeHtml().val())
		})

		calendarSettingsDataProvider.fillSelectWithTimes(this.getStartTimeHtml(), 0, 24)
		calendarSettingsDataProvider.fillSelectWithTimes(this.getEndTimeHtml(), 0, 24)

		this.getStartTimeHtml().val(toNumber(this.calendarSettings.dayStartTime))
		this.getEndTimeHtml().val(toNumber(this.calendarSettings.dayEndTime))
	}

	protected save() {
		let dto = new CalendarSettingsDto()
		dto.id = -1
		dto.dayStartTime = this.calendarSettings.dayStartTime
		dto.dayEndTime = this.calendarSettings.dayEndTime
		this.dataProvider.save(dto)
	}

	private getStartTimeHtml(): JQuery {
		return this.getHtml('start-time')
	}

	private getEndTimeHtml(): JQuery {
		return this.getHtml('end-time')
	}
}

export const tagesansichtPage = new TagesansichtPage()
