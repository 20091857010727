import {AbstractEditModal} from './abstract_edit_modal'
import {CustomerBuildingDataProvider, customerDataProvider, CustomerDto, ICustomerDto} from '../../model/customer'
import {BuildingDto, IBuildingDto} from '../../model/building'
import {getInputValAsString, setValOrEmpty} from '../../tools/tools'
import {AddressDto} from '../../model/model_base'
import {actionsInternal} from '../page_manager'
import {onClick} from '../../tools/templateTools'

const HTML_ID_BUILDINGS_MODAL_NAME = 'modal-building-name'
const HTML_ID_BUILDINGS_MODAL_CUSTOM_ID = 'modal-building-custom-id'
const HTML_ID_BUILDINGS_MODAL_STREET_AND_NR = 'modal-building-street-and-nr'
const HTML_ID_BUILDINGS_MODAL_ZIPCODE = 'modal-building-zipcode'
const HTML_ID_BUILDINGS_MODAL_CITY = 'modal-building-city'
const HTML_ID_BUILDINGS_MODAL_COMMENT = 'modal-building-comment'
const HTML_ID_BUILDINGS_MODAL_SAVE_BTN = 'modal-building-save-btn'

export class BuildingEditModal extends AbstractEditModal<IBuildingDto, CustomerBuildingDataProvider> {
	private static instance: BuildingEditModal
	customer: ICustomerDto

	constructor(customerBuildingDataProvider: CustomerBuildingDataProvider, customer: ICustomerDto) {
		super(customerBuildingDataProvider, 'modal-building')
		this.customer = customer
		if (BuildingEditModal.instance) {
			return BuildingEditModal.instance
		}
		return this
	}

	protected createNewDto(): IBuildingDto {
		return new BuildingDto()
	}

	protected clearCustom(): void {
		$('#modal-building-customer-name').text(customerDataProvider.getFullName(this.customer))
		$('#modal-building-customer-custom-id').text(this.customer.customId + '')
	}

	protected collectDataToDto(dto: IBuildingDto): void {
		//customer id
		dto.customer = new CustomerDto()
		dto.customer.id = this.customer.id

		//name
		dto.name = getInputValAsString(HTML_ID_BUILDINGS_MODAL_NAME)
		//customerId
		dto.customId = getInputValAsString(HTML_ID_BUILDINGS_MODAL_CUSTOM_ID)
		//comment
		dto.comment = getInputValAsString(HTML_ID_BUILDINGS_MODAL_COMMENT)

		//### ADDRESS ###
		if (!dto.address) {
			dto.address = new AddressDto()
		}
		//streetAndNr
		dto.address.streetAndNr = getInputValAsString(HTML_ID_BUILDINGS_MODAL_STREET_AND_NR)
		//zipCode
		dto.address.zipCode = getInputValAsString(HTML_ID_BUILDINGS_MODAL_ZIPCODE)
		//city
		dto.address.city = getInputValAsString(HTML_ID_BUILDINGS_MODAL_CITY)
	}

	protected validateBeforeSave(dto: IBuildingDto): boolean {
		return true
	}

	protected fillFromDto(dto: IBuildingDto): void {
		setValOrEmpty(HTML_ID_BUILDINGS_MODAL_NAME, dto.name)
		setValOrEmpty(HTML_ID_BUILDINGS_MODAL_CUSTOM_ID, dto.customId)
		setValOrEmpty(HTML_ID_BUILDINGS_MODAL_COMMENT, dto.comment)

		if (dto.address) {
			setValOrEmpty(HTML_ID_BUILDINGS_MODAL_STREET_AND_NR, dto.address.streetAndNr)
			setValOrEmpty(HTML_ID_BUILDINGS_MODAL_ZIPCODE, dto.address.zipCode)
			setValOrEmpty(HTML_ID_BUILDINGS_MODAL_CITY, dto.address.city)
		}
	}

	protected postHtmlLoaded(): void {
		onClick($('#' + HTML_ID_BUILDINGS_MODAL_SAVE_BTN), () => this.saveToServer())
	}

	protected postSaveToServer(dto: IBuildingDto) {
		actionsInternal.buildingListPage.fillTable()
	}
}
