import {setOnClick} from '../tools/templateTools'
import {gt} from '../tools/translation'

const FOOTER_PARENTS_LINKS = 'footer-parents-links'

class Footer {
	private arrow = $('<i class="i i-Pfeil"></i>')

	public addParent(parentTextId: string, parentJumpFkt: Function, clearLinks: boolean = true) {
		let parentText = gt(parentTextId)
		let parentLinksContainer = $('#' + FOOTER_PARENTS_LINKS)
		if (clearLinks) {
			parentLinksContainer.empty()
		}

		let parentElement = $('<button />')
		parentElement.addClass('nakedButton')
		parentElement.text(parentText)
		setOnClick(parentElement, parentJumpFkt)
		parentElement.append(this.arrow)

		parentLinksContainer.append(parentElement)
	}
}

export const footer = new Footer()
