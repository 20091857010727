import {AbstractDtoFormular} from './formular'
import {
	CustomerBuildingDataProvider,
	customerDataProvider,
	CustomerDto,
	ICustomerDto
} from '../../model/customer'
import {BuildingDto, IBuildingDto} from '../../model/building'
import {auftraggeberFormular} from './auftrageberFormular'
import {actionsInternal, loadBuildings} from '../page_manager'
import {getValueOrError} from '../../tools/tools'
import {onClick} from '../../tools/templateTools'
import {gt} from '../../tools/translation'
import {encodeHtml} from "../../tools/StringTools";

export class BuildingsFormular extends AbstractDtoFormular<IBuildingDto, CustomerBuildingDataProvider> {
	public static instance: BuildingsFormular
	private readonly customer: ICustomerDto

	private constructor(customer: ICustomerDto) {
		super(
			new CustomerBuildingDataProvider(getValueOrError(customer.id)),
			gt('button.new.object') ?? 'neues Objekt',
			'button.new.object'
		)
		this.customer = customer
	}

	public static create(customer: ICustomerDto): BuildingsFormular {
		BuildingsFormular.instance = new BuildingsFormular(customer)
		return BuildingsFormular.instance
	}

	public backAction() {
		this.handleSwitchToTblView()
	}

	protected customFill() {
		const _tabel = gt('button.tabel') ?? 'Tabelle'
		const _allClients = gt('button.allClients') ?? 'alle Auftraggeber'
		const _tableAll = `${encodeHtml(_tabel)}<br>${encodeHtml(_allClients)}`
		const _customer = gt('page.buildingsFormular.button.customerEdit') ?? ['Auftraggeber', 'bearbeiten']
		const _customerName = customerDataProvider.getShortName(this.customer) ?? ''
		const _customerButton = `${encodeHtml(_customer.at(0))}<br><b>${encodeHtml(_customerName)}</b><br>${encodeHtml(_customer.at(1))}`
		this.addCustomBtnSpacer()
		//this.addCustomBtn("Kontakte", () => ContactFormular.instance.fill())

		this.addCustomBtn(_customerButton, () => auftraggeberFormular.fill(this.customer))
		this.addCustomBtn(_tableAll, () => actionsInternal.loadAuftraggeber())
	}

	protected getTitle(dto: IBuildingDto): string {
		return gt('page.buildings.name') ?? 'Objekt'
	}

	protected getSubTitle(dto: IBuildingDto): JQuery {
		let container = $('<span>')
		container.text(
			`AG: (${this.customer.customId ?? this.customer.id}) ${customerDataProvider.getFullName(
				this.customer
			)}  /  ${this.provider.getFullName(dto)}`
		)
		container.addClass('clickable')
		onClick(container, () => {
			if (this.confirmDiscardChanges()) {
				auftraggeberFormular.fill(this.customer)
			}
		})
		return container
	}

	protected bindElementToView(dto: IBuildingDto): void {
		let section = this.addSectionTitle(gt('page.formular.title.general') ?? 'Allgemein')
		const _general = gt('page.buildingsFormular.fields.general')
		const _address = gt('page.buildingsFormular.fields.billingAddress')
		const _last =
			_general.at(4) && _general.at(5) ? `<b>${_general.at(4)}</b> <br>${_general.at(5)}` : '<b>Keine</b> <br>Arbeitsadresse'
		this.bindSimpleValueToInputFieldAndAddToView(BuildingDto.setterCustomId, section, _general.at(0) ?? 'Objekt Nr.')
		this.bindSimpleValueToInputFieldAndAddToView(BuildingDto.setterName, section, _general.at(1) ?? 'Objektname')
		this.bindSimpleValueToInputFieldAndAddToView(BuildingDto.setterComment, section, _general.at(2) ?? 'Infos')
		this.bindSimpleValueToInputFieldAndAddToView(BuildingDto.setterBillingAddresses, section, _general.at(3) ?? 'Rechnungsadresse')
		this.bindSimpleValueToInputFieldAndAddToView(BuildingDto.setterNotUserForWork, section, _last)

		section = this.addSectionTitle(gt('page.formular.title.address') ?? 'Adresse')
		this.bindSimpleValueToInputFieldAndAddToView(BuildingDto.setterStreetAndNr, section, _address.at(0) ?? 'Straße / HausNr.')
		this.bindSimpleValueToInputFieldAndAddToView(CustomerDto.setterZipCode, section, _address.at(1) ?? 'PLZ')
		this.bindSimpleValueToInputFieldAndAddToView(BuildingDto.setterCity, section, _address.at(2) ?? 'Stadt')
		this.bindSimpleValueToInputFieldAndAddToView(BuildingDto.setterCountry, section, _address.at(3) ?? 'Land')
	}

	protected createNewDto(): IBuildingDto {
		let buildingDto = new BuildingDto()
		buildingDto.customer = new CustomerDto()
		buildingDto.customer.id = this.customer.id
		return buildingDto
	}

	protected canBeDeleted(dto: IBuildingDto): boolean {
		return true
	}

	protected handleSwitchToTblView() {
		loadBuildings(this.customer)
	}
}
