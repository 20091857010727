import {AbstractEditModal} from '../abstract_edit_modal'
import {
    addEmptyOption,
    addOption,
    findCustomSelectOption,
    findDatalistOption,
    isChecked,
    onChange,
    onClick,
    onSelectChange,
    onSelectCleared,
    selectOption,
    setChecked2,
    setHtmlId
} from '../../../tools/templateTools'
import {IUserDto, userDataProvider} from '../../../model/user'
import {
    CustomerBuildingDataProvider, customerDataProvider, ICustomerDto
} from '../../../model/customer'
import {
    cloneWithJson,
    collectSelected,
    convertTimeNumberToTimeStr,
    convertTimeStrToTimeNumber,
    decimalHourToNumber,
    HashMap,
    mustNotNull,
    nN,
    setSelectedOptions,
    toDecimalHour,
    toNumber,
    toStringOrEmpty
} from '../../../tools/tools'
import {IBuildingDto} from '../../../model/building'
import {
    IJobDto,
    jobDataProvider,
    JobDataProvider,
    JobDto,
    JobType,
    toJobType,
    WorkWindowDto
} from '../../../model/job'
import {
    DayOfWeek, DayOfWeeks, dayOfWeekToShort, toDayOfWeek
} from '../../../model/dayofweek'
import {ITaskTypeDto, taskTypeDataProvider} from '../../../model/tasktype'
import {createNewFromTemplate} from '../../../tools/templates'
import {calendarSettingsDataProvider} from '../../../model/calendar_settings'
import {popupService} from '../../../tools/popups'
import {refreshUIAfterJobChanged} from './calendar_tools'
import {destroyMultiSelect, initMultiSelect} from '../../../tools/multi_select'
import {CONSOLE_DEBUG} from '../../../consties'
import {getMainPhone} from '../../../model/model_base'
import {Events} from '../../../tools/calendar_widget'
import {actionsInternal, setDialogOpen} from '../../page_manager'
import {gt} from '../../../tools/translation'
import {IActiveJobDto} from "../../../model/active_job";
import {mitarbeiterGruppenPage} from "../../list/mitarbeitergruppen_edit_page";

const prefix = `new-order`
const once = `once`
const repeatable = `repeat`

export class NewOrderModal extends AbstractEditModal<IJobDto, JobDataProvider> {
    private static instance: NewOrderModal

    private TemplateId_WhTage = `${prefix}-iteration-day-template`
    private TemplateId_WorkWindowAndWorkTime = `${prefix}-iteration-variable-day-template`

    private customers = new HashMap<ICustomerDto>()
    private customerSelected: ICustomerDto | null = null
    private contacts = new HashMap<IUserDto>()
    private contactSelected: IUserDto | null = null
    private buildings = new HashMap<IBuildingDto>()
    private buildingSelected: IBuildingDto | null = null
    private buildingDataProvider: CustomerBuildingDataProvider | null = null
    private jobTypeSelected = JobType.ONCE
    private taskTypes = new HashMap<ITaskTypeDto>()
    private isVariable = false
    private timerValidator = setInterval(() => {
    }, 10000)

    constructor() {
        super(jobDataProvider, `${prefix}`)

        if (NewOrderModal.instance) {
            return NewOrderModal.instance
        }
        return this
    }

    public setStartDate(date: Date) {
        this.getDateCalendar().dispatchEvent(
            new CustomEvent(Events.DATE_SELECTED, {detail: {date}}))
        this.getStartDateCalendar().dispatchEvent(
            new CustomEvent(Events.DATE_SELECTED, {detail: {date}}))
    }

    protected getHtmlDir(): string {
        return `kalendar`
    }

    private getOrderIdHtml(): JQuery {
        return $(`#${prefix}-id`)
    }

    private getOrderJarhCheckboxHtml(): JQuery {
        return $(`#${prefix}-jahr-checkbox`)
    }

    private getPlusButtonAuftraggeber() {
        return $(`#${prefix}-plus-button-auftraggeber`)
    }

    private getPlusButtonObject() {
        return $(`#${prefix}-plus-button-object`)
    }

    private getPlusButtonSettings() {
        return $(`#${prefix}-plus-button-settings`)
    }

    protected clearCustom() {
        const calendarWeeks = this.getCustomWeeksCalendarCheckboxsHtml()
        const container = document.querySelector(`.${prefix}__container`)
        const _checkboxs = document.querySelectorAll(`.${prefix} input:checked`)
        const _active = [..._checkboxs].map(item => item as HTMLInputElement)
            .filter(item => item.checked)
        _active.length && _active.forEach(input => (input.checked = false))
        container?.scrollTo(0, 0)
        this.emptyBuildingSelectHtml()
        calendarWeeks.length && calendarWeeks.forEach(
            input => (input.checked = false))
        this.getRhytmChangeListHtml().empty()
    }

    protected createNewDto(): IJobDto {
        return new JobDto()
    }

    protected getTemplateIds(): string[] {
        return [this.TemplateId_WhTage, this.TemplateId_WorkWindowAndWorkTime]
    }

    protected postHtmlLoaded() {
        //### WH Tage ###
        this.getWhTageListHtml().empty()
        this.getWorkWindowAndWorkTimeContainerHtml().empty()

        calendarSettingsDataProvider.fillSelectWithTimes(
            this.getWorkWindowStartHtml(once), 0, 24)
        calendarSettingsDataProvider.fillSelectWithTimes(
            this.getWorkWindowEndHtml(once), 0, 24)

        calendarSettingsDataProvider.fillSelectWithTimes(
            this.getWorkWindowStartHtml(repeatable), 0, 24)
        calendarSettingsDataProvider.fillSelectWithTimes(
            this.getWorkWindowEndHtml(repeatable), 0, 24)

        DayOfWeeks.forEach(dayOfWeek => {
            const whTag = createNewFromTemplate(this.TemplateId_WhTage)
            const whVariableDay = createNewFromTemplate(
                this.TemplateId_WorkWindowAndWorkTime)

            setHtmlId(whTag.find(`input[name="iteration-work-days"]`),
                `${prefix}-iteration-work-day-${dayOfWeek}`)
            setHtmlId(whVariableDay.find(`input[name="iteration-work-days"]`),
                `${prefix}-iteration-variable-work-day-${dayOfWeek}`)

            whTag.find(`.${prefix}__radio-group__label`)
                .text(dayOfWeekToShort(dayOfWeek))
            whVariableDay.find(`.${prefix}__radio-group__label`)
                .text(dayOfWeekToShort(dayOfWeek))

            if (calendarSettingsDataProvider.current && calendarSettingsDataProvider.current.content.stepsInH) {
                this.findTimeSelect(whVariableDay, `${prefix}-worktime`,
                    dayOfWeek, toNumber(
                        calendarSettingsDataProvider.current.content.stepsInH),
                    2)

                this.findTimeSelect(whVariableDay, `${prefix}-worktime-start`,
                    dayOfWeek, 0, 24)
                this.findTimeSelect(whVariableDay, `${prefix}-worktime-end`,
                    dayOfWeek, 0, 24)

                this.getWhTageListHtml().append(whTag)
                this.getWorkWindowAndWorkTimeContainerHtml()
                    .append(whVariableDay)

                let input: HTMLInputElement;

                input = document.getElementById(
                    `${prefix}-iteration-variable-work-day-${dayOfWeek}`) as HTMLInputElement

                if (input) {
                    input.addEventListener('change', (e: any) => {
                        if (e.target.checked) {
                            e.target.closest('.new-order__radio-group__details')
                                ?.classList.add('checked')
                        } else {
                            e.target.closest('.new-order__radio-group__details')
                                ?.classList.remove('checked')
                        }
                    })
                }
            }

        })
    }

    protected fillDefaults(callback: Function) {
        clearInterval(this.timerValidator)
        this.clearCustom()
        this.setDisableNotChangeableValues(false)
        this.timerValidator = setInterval(() => {
            this.checkRequireFields()
        }, 1000)
        this.checkRequireFields()

        //floor and position
        calendarSettingsDataProvider.fillSelectWithFloorNames(
            this.getFloorSelectionHtml())
        calendarSettingsDataProvider.fillSelectWithPositionNames(
            this.getPositionSelectionHtml())

        //### reset all datas
        this.customers = new HashMap<ICustomerDto>()
        this.customerSelected = null
        this.contacts = new HashMap<IUserDto>()
        this.contactSelected = null
        this.buildings = new HashMap<IBuildingDto>()
        this.buildingSelected = null
        this.buildingDataProvider = null
        this.jobTypeSelected = JobType.ONCE
        this.taskTypes = new HashMap<ITaskTypeDto>()

        //### customer selection ###
        this.setCustomerSelectDisabled(this.editState)
        onSelectChange(this.getCustomerSelectHtml(),
            e => this.handleCustomerChange(e))
        onSelectCleared(this.getCustomerSelectHtml(),
            () => this.handleCustomerClear())

        this.fillCustomers(
            mustNotNull(customerDataProvider.loadListAllManagedCache?.content));

        //### job details ###
        this.fillJobDetails()

        //### task type ####
        this.fillTaskTypes()

        //### save btn ###
        onClick(this.getSaveBtnHtml(), () => this.saveToServer())
        onClick(this.getSaveAsNewBtnHtml(),
            () => this.openDialogForNew(this.dto, true))
        onClick(this.getObjectGroupBtnHtml(), () => {
            const close = confirm(gt('alerts.discardChanges'))
            if (!close) return
            this.hideDialog()
            actionsInternal.loadMitarbeiterGruppen(
                () => mitarbeiterGruppenPage.setCurrentUserGroupById(
                    this.dto?.building!.userGroupId!))
        })

        onClick(this.getPlusButtonAuftraggeber(), () => {
            if (this.customerSelected) {
                this.hideDialog()
                actionsInternal.loadAuftraggeberFormular(this.customerSelected)
            } else {
                this.hideDialog()
                actionsInternal.loadAuftraggeber()
            }
        })

        onClick(this.getPlusButtonObject(), () => {
            if (this.customerSelected && this.buildingSelected) {
                this.hideDialog()
                actionsInternal.loadAuftraggeberObjekteFormular(
                    this.customerSelected, this.buildingSelected, () => {
                    })
            }
        })

        onClick(this.getPlusButtonSettings(), () => {
            this.hideDialog()
            actionsInternal.loadEinstellungenLeistungsart()
        })

        this.setDefaultWorkWindowAndWorkTime()
        initMultiSelect(this.getTaskTypeSelectHtml())

        this.setStartDate(new Date())

        callback();
    }

    private fillCustomers(customersLoaded: ICustomerDto[]) {
        this.customers.clear()
        this.getCustomerSelectHtml().empty()
        addEmptyOption(this.getCustomerSelectHtml())
        customersLoaded.forEach(customer => {
            if (!customer.active) return
            const text = this.toSelectText(customer)
            const customerId = customer.id
            addOption(this.getCustomerSelectHtml(), customerId, text, false);
            this.customers.put(customerId, customer)
        })
        if (!this.dto) {
            initMultiSelect(this.getCustomerSelectHtml())
        }
    }

    protected collectDataToDto(dto: IJobDto) {
        //floor and position
        dto.position = toStringOrEmpty(this.getPositionSelectionHtml().val())
        dto.floor = toStringOrEmpty(this.getFloorSelectionHtml().val())

        //customer
        //TODO store changes customer
        dto.customer = this.customerSelected

        //contact
        //TODO store changes contact
        dto.contact = this.contactSelected

        dto.building = this.buildingSelected ? this.buildingSelected :
            dto.building
        //building
        //TODO store changes building
        if (!dto.building) {
            //TODO validate Building must be set
            popupService.error(`Building must be set!`)
            return
        }

        dto.annualBilling = isChecked(this.getOrderJarhCheckboxHtml())
        //TODO lage

        //task type
        dto.tasks = collectSelected(this.getTaskTypeSelectHtml(),
            taskTypeDataProvider.createDto)

        //comment
        dto.comment = `${this.getCommentHtml().val()}`

        //Auftragskennung
        dto.customId = `${this.getOrderIdHtml().val()}`.trim()
        dto.customId = dto.customId.length > 1 ? dto.customId : null;
        const workWindows = dto.workWindows ?? []

        this.isVariable = isChecked(
            this.getJobVariableHoursHtml()) && !isChecked(
            this.getJobSameHoursHtml())

        //times
        const _date = isChecked(this.getJobRhythmusOnceHtml()) ?
            `${this.getOnceDateHtml().val()}` :
            `${this.getStartDateHtml().val()}`
        dto.startDate = _date.length > 0 ?
            new Date(_date.split(`.`).reverse().join(`/`)) : new Date()

        if (isChecked(this.getRadioEveryNweekHtml())) {
            this.jobTypeSelected = JobType.EVERY_X_WEEKS
            dto.repeatsEveryXWeeks = !isNaN(
                parseInt(`${this.getInputEveryNweekHtml().val()}`)) ?
                parseInt(`${this.getInputEveryNweekHtml().val()}`) : 1
        } else if (isChecked(this.getRadioCustomWeeksHtml())) {
            this.jobTypeSelected = JobType.YEARLY
            const _weeks = this.getCustomWeeksCalendarCheckboxsHtml()
            if (_weeks) {
                dto.repeatWeekNumbers = _weeks.filter(input => input.checked)
                    .map(input => parseInt(`${input.dataset.value}`))
            }
        }

        if (isChecked(this.getJobRhythmusOnceHtml())) {
            this.jobTypeSelected = JobType.ONCE
            dto.endDate = null
            const workWindow = new WorkWindowDto()
            workWindow.workWindowStart = convertTimeNumberToTimeStr(
                this.getWorkWindowStartHtml(once).val())
            workWindow.workWindowEnd = convertTimeNumberToTimeStr(
                this.getWorkWindowEndHtml(once).val())
            workWindow.workHours = decimalHourToNumber(
                `${this.getWorkTimeHtml(once).val()}`)
            dto.workWindows = [workWindow]
        } else if (isChecked(this.getJobRhythmusMoreHtml())) {
            const endDateStr = `${this.getEndDateHtml().val()}`
            dto.endDate = endDateStr.length > 0 ?
                new Date(endDateStr.split(`.`).reverse().join(`/`)) : null

            if (!this.isVariable) {
                dto.workWindows = DayOfWeeks.filter(
                    day => this.getWhTageCheckboxHtml(day).prop(`checked`))
                    .map(day => {
                        const workWindow = workWindows.find(
                            _windows => _windows.dayOfWeek === day) ?? new WorkWindowDto()
                        workWindow.workWindowStart = convertTimeNumberToTimeStr(
                            this.getWorkWindowStartHtml(repeatable).val())
                        workWindow.workWindowEnd = convertTimeNumberToTimeStr(
                            this.getWorkWindowEndHtml(repeatable).val())
                        workWindow.workHours = decimalHourToNumber(
                            `${this.getWorkTimeHtml(repeatable).val()}`)
                        workWindow.dayOfWeek = day
                        return workWindow
                    })
            } else {
                dto.workWindows = DayOfWeeks.filter(
                    day => this.getVariableTageCheckboxHtml(day)
                        .prop(`checked`)).map(day => {
                    const workWindow = workWindows.find(
                        _windows => _windows.dayOfWeek === day) ?? new WorkWindowDto()
                    workWindow.workWindowStart = convertTimeNumberToTimeStr(
                        this.getWorkWindowStartHtml(day).val())
                    workWindow.workWindowEnd = convertTimeNumberToTimeStr(
                        this.getWorkWindowEndHtml(day).val())
                    workWindow.workHours = decimalHourToNumber(
                        `${this.getWorkTimeHtml(day).val()}`)
                    workWindow.dayOfWeek = day
                    return workWindow
                })
            }
        }

        console.log(dto.workWindows)

        if (isChecked(this.getRhytmChangeCheckboxHtml())) {
            const changeDateStr = `${this.getRhytmChangeDateHtml().val()}`
            const changeDateVal = changeDateStr.length > 0 ?
                new Date(changeDateStr.split(`.`).reverse().join(`/`)) : null
            if (changeDateVal) {
                dto.changeRhythmFromDate = changeDateVal
            }
        }

        dto.jobType = this.jobTypeSelected
    }

    protected fillFromDto(dto: IJobDto, isCopyOrder?: boolean): void {
        clearInterval(this.timerValidator)
        this.setDisabled(this.getSaveBtnHtml(), false)
        this.clearCustom()

        if (isCopyOrder) {
            this.setDisableNotChangeableValues(false)
        } else {
            this.setDisableNotChangeableValues(true)
        }
        onChange(this.getRhytmChangeCheckboxHtml(), this.handleRhytmChange)
        //task types
        setSelectedOptions(this.getTaskTypeSelectHtml(), dto.tasks)
        initMultiSelect(this.getTaskTypeSelectHtml())

        if (CONSOLE_DEBUG) console.dir(dto)

        this.getOrderIdHtml().val(`${dto.id}`)

        //floor and postion
        this.getPositionSelectionHtml().val(toStringOrEmpty(dto.position))
        this.getFloorSelectionHtml().val(toStringOrEmpty(dto.floor))

        //set loaded values
        this.customerSelected = dto.customer
        this.contactSelected = dto.contact
        this.buildingSelected = dto.building
        if (dto.customer && dto.customer.id) {
            this.buildingDataProvider = new CustomerBuildingDataProvider(
                dto.customer.id)
        } else {
            this.buildingDataProvider = null
        }
        this.jobTypeSelected = dto.jobType ? toJobType(dto.jobType) :
            JobType.ONCE
        if (this.jobTypeSelected === JobType.ONCE) {
            this.getJobRhythmusOnceHtml().prop(`checked`, 'true')
            this.getJobTypeRepeatHtml().removeAttr('data-selected')
        } else {
            this.getJobTypeRepeatHtml().attr('data-selected', 'true')
            this.getJobRhythmusMoreHtml().prop(`checked`, 'true')
        }

        if (this.customerSelected) {
            selectOption(this.getCustomerSelectHtml(), this.customerSelected.id)
        }
        initMultiSelect(this.getCustomerSelectHtml())

        //this.handleCustomerChange()

        setChecked2(this.getOrderJarhCheckboxHtml(), dto.annualBilling)

        if (dto.rhythmChangedDates) {
            dto.rhythmChangedDates.forEach(str => {
                const child = document.createElement('span')
                const date = new Date(str)
                child.classList.add('new-order__text-group__item')
                if (date) {
                    const day = date.getDate() < 10 ? `0${date.getDate()}` :
                        `${date.getDate()}`
                    const month = date.getMonth() + 1 < 10 ?
                        `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`
                    const text = `${day}.${month}.${date.getFullYear()}`
                    child.innerText = text
                }
                this.getRhytmChangeListHtml().append(child)
            })
        }

        //customer
        this.fillCustomerFields(dto.customer!, dto.building, () => {
            //contact
            // this.getContactSelectHtml().val('')

            if (dto.contact && dto.contact.id) {
                selectOption(this.getContactSelectHtml(), dto.contact?.id)
            }

            this.handleContactChange()

            //building
            if (dto.building && dto.building.id) {
                findDatalistOption(this.getBuildingSelectHtml(),
                    `${dto.building.id}`)
            }
            this.handleBuildingChange()

            //start / end date
            if (dto.startDate) {
                this.getDateCalendar().dispatchEvent(
                    new CustomEvent(Events.DATE_SELECTED,
                        {detail: {date: dto.startDate}}))
                this.getStartDateCalendar().dispatchEvent(
                    new CustomEvent(Events.DATE_SELECTED,
                        {detail: {date: dto.startDate}}))
            }

            if (dto.endDate) {
                this.getEndDateCalendar().dispatchEvent(
                    new CustomEvent(Events.DATE_SELECTED,
                        {detail: {date: dto.endDate}}))
            }

            const workWindows = dto.workWindows ?? []
            if (dto.jobType === JobType.ONCE) {
                this.getWorkWindowStartHtml(once).val(
                    convertTimeStrToTimeNumber(
                        workWindows[0].workWindowStart + ``))
                this.getWorkWindowEndHtml(once).val(convertTimeStrToTimeNumber(
                    workWindows[0].workWindowEnd + ``, true))
                this.getWorkTimeHtml(once)
                    .val(toDecimalHour(workWindows[0].workHours) + ``)
            }

            //workdays
            DayOfWeeks.forEach(
                dayOfWeek => setChecked2(this.getWhTageCheckboxHtml(dayOfWeek),
                    false))
            DayOfWeeks.forEach(dayOfWeek => setChecked2(
                this.getVariableTageCheckboxHtml(dayOfWeek), false))


            const _isVariable = workWindows.every(
                workWindow => convertTimeStrToTimeNumber(
                    workWindow.workWindowStart) === convertTimeStrToTimeNumber(
                    workWindows[0].workWindowStart) && convertTimeStrToTimeNumber(
                    workWindow.workWindowEnd,
                    true) === convertTimeStrToTimeNumber(
                    workWindows[0].workWindowEnd, true) && toDecimalHour(
                    workWindow.workHours) === toDecimalHour(
                    workWindows[0].workHours))
            this.isVariable = !_isVariable
            workWindows.forEach(workWindow => {
                let dayOfWeek: DayOfWeek = toDayOfWeek(
                    workWindow.dayOfWeek + ``)
                setChecked2(this.getWhTageCheckboxHtml(dayOfWeek))
                setChecked2(this.getVariableTageCheckboxHtml(dayOfWeek))

                this.getWorkWindowStartHtml(dayOfWeek).val(
                    convertTimeStrToTimeNumber(workWindow.workWindowStart + ``))
                this.getWorkWindowEndHtml(dayOfWeek).val(
                    convertTimeStrToTimeNumber(workWindow.workWindowEnd + ``,
                        true))
                this.getWorkTimeHtml(dayOfWeek)
                    .val(toDecimalHour(workWindow.workHours) + ``)
            })

            if (this.isVariable) {
                this.getJobVariableHoursHtml().prop(`checked`, true)
            } else {
                this.getJobSameHoursHtml().prop(`checked`, true)
                this.getWorkTimeHtml(repeatable)
                    .val(toDecimalHour(workWindows[0].workHours))
                this.getWorkWindowStartHtml(repeatable).val(
                    `${convertTimeStrToTimeNumber(
                        workWindows[0].workWindowStart)}`)
                this.getWorkWindowEndHtml(repeatable).val(
                    `${convertTimeStrToTimeNumber(workWindows[0].workWindowEnd,
                        true)}`)
            }

            if (dto.jobType === JobType.YEARLY || dto.repeatWeekNumbers && dto.repeatWeekNumbers.length > 0) {
                setChecked2(this.getRadioCustomWeeksHtml(), true)
                const _weeks = this.getCustomWeeksCalendarCheckboxsHtml()
                if (_weeks) {
                    _weeks
                        .filter(input => dto.repeatWeekNumbers?.includes(
                            parseInt(`${input.dataset.value}`)))
                        .forEach(input => (input.checked = true))
                }
            } else {
                setChecked2(this.getRadioEveryNweekHtml(), true)
                this.getInputEveryNweekHtml().val(
                    `${dto.repeatsEveryXWeeks ? dto.repeatsEveryXWeeks : 1}`)
            }

            this.getCommentHtml().val(toStringOrEmpty(dto.comment))

            this.getOrderIdHtml()
                .val(isCopyOrder ? '' : toStringOrEmpty(dto.customId))

            //initMultiSelect(this.getContactSelectHtml())
            initMultiSelect(this.getBuildingSelectHtml())
        })
    }

    protected postCloseDialog() {
        if (this.hasChanged) {
            refreshUIAfterJobChanged(true);
        }
        this.clearCustom()
    }

    private toSelectText(item: ICustomerDto): string
    private toSelectText(item: IBuildingDto): string
    private toSelectText(item: IUserDto): string

    private toSelectText(item: any): string {
        const _customId = item.customId ? `(${item.customId}) ` : ``
        const _name = customerDataProvider.getShortName(
            item) ?? this.buildingDataProvider?.getFullName(item)
        return `${_customId}${_name}`
    }

    private getTitleHtml(): JQuery {
        return $(`#${prefix}-title`)
    }

    private getFloorSelectionHtml(): JQuery {
        return $(`#${prefix}-floor-selection`)
    }

    private getPositionSelectionHtml(): JQuery {
        return $(`#${prefix}-position-selection`)
    }

    private findTimeSelect(parent: JQuery, findClassName: string,
                           newIdPostFix: string, start: number, end: number) {
        const target = parent.find(`.` + findClassName)
        setHtmlId(target, findClassName + `-` + newIdPostFix)
        calendarSettingsDataProvider.fillSelectWithTimes(target, start, end)
    }

    private setDefaultWorkWindowAndWorkTime() {
        this.getWorkTimeHtml(once).val(toDecimalHour(1))
        this.getWorkWindowStartHtml(once).val(8)
        this.getWorkWindowEndHtml(once).val(12)
        this.getWorkTimeHtml(repeatable).val(toDecimalHour(1))
        this.getWorkWindowStartHtml(repeatable).val(8)
        this.getWorkWindowEndHtml(repeatable).val(12)

        DayOfWeeks.forEach(key => {
            this.getWorkWindowStartHtml(key).val(8)
            this.getWorkWindowEndHtml(key).val(12)
            this.getWorkTimeHtml(key).val(toDecimalHour(1))
        })
    }

    private getWhTageListHtml(): JQuery {
        return $(`#${prefix}-iteration-list`)
    }

    private getSaveBtnHtml(): JQuery {
        return $(`#${prefix}-save-button`)
    }

    private getSaveAsNewBtnHtml(): JQuery {
        return $(`#${prefix}-save-new-button`)
    }

    private getObjectGroupBtnHtml(): JQuery {
        return $(`#${prefix}-object-groups-button`)
    }

    //####### Customer #######
    private handleCustomerEdit() {
        //TODO: open customer to edit
    }

    private getWorkWindowAndWorkTimeContainerHtml(): JQuery {
        return $(`#${prefix}-iteration-variable-day-container`)
    }

    private getRadioEveryNweekHtml(): JQuery {
        return $(`#${prefix}-every-n-week`)
    }

    private getRadioCustomWeeksHtml(): JQuery {
        return $(`#${prefix}-custom-weeks`)
    }

    private getCustomWeeksCalendarHtml(): JQuery {
        return $(`#${prefix}-custom-weeks-calendar`)
    }

    private getCustomWeeksCalendarCheckboxsHtml(): Array<HTMLInputElement> {
        const calendar = this.getCustomWeeksCalendarHtml()[0]
        if (!calendar) return []
        const list = calendar.querySelectorAll(
            `input[type="checkbox"][data-id="${prefix}-custom-weeks-calendar-checkbox"]`)
        if (!list.length) return []
        return [...list].map(item => item as HTMLInputElement)
    }

    private getInputEveryNweekHtml(): JQuery {
        return $(`#${prefix}-iteration-every-n-week`)
    }

    private clearCustomerFields() {
        this.getPlusButtonObject().addClass('table-nav-plus--hidden')
        this.customerSelected = null
        this.getCustomerNameHtml().val(``)
        //contact
        this.contacts.clear()
        this.contactSelected = null
        this.getContactSelectHtml().empty()
        this.clearContactFields()

        //buildings
        this.buildings.clear()
        this.buildingSelected = null
        this.emptyBuildingSelectHtml()
        this.buildingDataProvider = null
        this.clearBuildingFields()
    }

    private handleCustomerClear() {
        this.clearCustomerFields()
    }

    private handleCustomerChange(e: JQuery.TriggeredEvent) {
        const _event = e.originalEvent as CustomEvent
        const _key: string = _event.detail.key ? _event.detail.key : ''

        const customerId = _key ? _key :
            findCustomSelectOption(this.getCustomerSelectHtml())
        if (this.dto?.customer && ("" + this.dto?.customer.id) === customerId) {
            return;
        }
        const customer = customerId ? this.customers.get(customerId) : false
        if (customer) {
            //customer selected
            this.fillCustomerFields(customer, null)
            return
        }
        //customer deselected
        this.clearCustomerFields()
        //nN(callback)
    }

    private getCustomerSelectHtml(): JQuery {
        return $(`#${prefix}-customer-select`)
    }

    private getCustomerSelectFieldHtml(): JQuery {
        return $(`#${prefix}-customer-select + .${prefix}__form-group__input > input[type="search"]`)
    }

    private getCustomerNameHtml(): JQuery {
        return $(`#${prefix}-customer-name`)
    }

    private getCommentHtml(): JQuery {
        return $(`#${prefix}-comment`)
    }

    //####### Contact #######
    private handleContactChange() {
        const contactId = this.getContactSelectHtml().val()
        const contact = contactId ? this.contacts.get(contactId) : false
        if (contact) {
            //contact selected
            this.fillContactFields(contact)
        } else {
            //contact deselected
            this.clearContactFields()
        }
    }

    private fillContactFields(contact: IUserDto) {
        this.contactSelected = contact
        findDatalistOption(this.getContactSelectHtml(), `${contact.id}`)
        this.getContactPhoneNumberHtml()
            .val(toStringOrEmpty(getMainPhone(contact.address)))
        this.getContactEmailHtml().val(toStringOrEmpty(contact.address?.email))
    }

    private clearContactFields() {
        this.contactSelected = null
        this.getContactPhoneNumberHtml().val(``)
        this.getContactEmailHtml().val(``)
    }

    private getContactSelectHtml(): JQuery {
        return $(`#${prefix}-customer-contact-select`)
    }

    private getContactListHtml(): JQuery {
        return $(`#${prefix}-customer-contact-select`)
        //return $(`#${prefix}-customer-contact-list`)
    }

    private getContactPhoneNumberHtml(): JQuery {
        return $(`#${prefix}-customer-contact-phone`)
    }

    private getContactEmailHtml(): JQuery {
        return $(`#${prefix}-customer-contact-email`)
    }

    //####### Building #######
    private handleBuildingChange() {
        const buildingId = findCustomSelectOption(this.getBuildingSelectHtml())
        const building = buildingId ? this.buildings.get(buildingId) : false
        if (building) {
            this.fillBuildingFields(building)
        } else {
            this.clearBuildingFields()
        }
    }

    private fillBuildingFields(building: IBuildingDto) {
        this.clearBuildingFields()

        this.buildingSelected = building
        this.getBuildingShortNameHtml()
            .val(toStringOrEmpty(building.shortName))
        this.getBuildingStreetHtml()
            .val(toStringOrEmpty(building.address?.streetAndNr))
        this.getBuildingZipCodeHtml()
            .val(toStringOrEmpty(building.address?.zipCode))
        this.getBuildingCityHtml().val(toStringOrEmpty(building.address?.city))
    }

    private clearBuildingFields() {
        this.buildingSelected = null
        this.getBuildingShortNameHtml().val(``)
        this.getBuildingStreetHtml().val(``)
        this.getBuildingZipCodeHtml().val(``)
        this.getBuildingCityHtml().val(``)
    }

    private getBuildingSelectHtml(): JQuery {
        return $(`#${prefix}-customer-object-select`)
    }

    private emptyBuildingSelectHtml() {
        destroyMultiSelect(this.getBuildingSelectHtml())
        this.getBuildingSelectHtml().empty()
    }

    private getBuildingShortNameHtml(): JQuery {
        return $(`#neuer-auftrag-customer-building-shortName`)
    }

    private getBuildingStreetHtml(): JQuery {
        return $(`#${prefix}-customer-object-street`)
    }

    private getBuildingZipCodeHtml(): JQuery {
        return $(`#${prefix}-customer-object-zip`)
    }

    private getBuildingCityHtml(): JQuery {
        return $(`#${prefix}-customer-object-city`)
    }

    private validateInputWeek() {
        const _input = `${this.getInputEveryNweekHtml().val()}`
        const value = parseInt(_input)
        if (!value || isNaN(value)) return

        const attr = this.getInputEveryNweekHtml().attr('max')
        const max = attr && !isNaN(parseInt(attr)) ? parseInt(attr) : 52

        if (value > max) {
            this.getInputEveryNweekHtml().val(max)
        } else if (value < 1) {
            this.getInputEveryNweekHtml().val(1)
        }
    }

    //#### JOB Details ####
    private fillJobDetails() {
        DayOfWeeks.forEach(
            dayOfWeek => setChecked2(this.getWhTageCheckboxHtml(dayOfWeek),
                false))
        DayOfWeeks.forEach(dayOfWeek => setChecked2(
            this.getVariableTageCheckboxHtml(dayOfWeek), false))
        this.getInputEveryNweekHtml().val(1)
        setChecked2(this.getJobRhythmusOnceHtml(), true)
        setChecked2(this.getJobSameHoursHtml(), true)
        setChecked2(this.getRadioEveryNweekHtml(), true)
        onChange(this.getInputEveryNweekHtml(), this.validateInputWeek)
        // setChecked2(this.getJobRhythmusMoreHtml(), false)
    }

    private preselectOption(option: IBuildingDto) {
        findDatalistOption(this.getBuildingSelectHtml(), `${option.id}`)
        this.fillBuildingFields(option)
    }

    private fillCustomerFields(customer: ICustomerDto,
                               building: IBuildingDto | null,
                               callback: Function | null = null) {
        //rest all
        this.clearCustomerFields()
        //set new
        this.customerSelected = customer
        this.getPlusButtonObject().removeClass('table-nav-plus--hidden')
        this.getCustomerNameHtml()
            .val(customerDataProvider.getFullName(customer))

        //contacts
        onChange(this.getContactSelectHtml(), this.handleContactChange)

        addEmptyOption(this.getContactSelectHtml())

        customer.contactPersons?.forEach(contact => {
            this.contacts.put(contact.id, contact)

            const _selected = !!customer.mainContactPerson && customer.mainContactPerson.id === contact.id
            addOption(this.getContactSelectHtml(), contact.id,
                userDataProvider.getFullName(contact), _selected)
        })

        //main contact
        if (customer.mainContactPerson) {
            this.fillContactFields(customer.mainContactPerson)
        } else {
            this.clearContactFields()
        }

        //buildings
        if (customer.id) {
            onSelectChange(this.getBuildingSelectHtml(),
                () => this.handleBuildingChange())
            onSelectCleared(this.getBuildingSelectHtml(),
                () => this.handleBuildingChange())
            const buildingDataProvider = new CustomerBuildingDataProvider(
                customer.id)
            this.buildingDataProvider = buildingDataProvider

            if (customer.buildings) {
                customer.buildings.forEach(buildingFromList => {
                    const isSelectedBuilding = building && building.id === buildingFromList.id
                    if (!isSelectedBuilding) {
                        if (buildingFromList.notUserForWork) {
                            return;
                        }
                        if (!buildingFromList.active) {
                            return;
                        }
                    }
                    this.buildings.put(buildingFromList.id, buildingFromList)
                    addOption(this.getBuildingSelectHtml(), buildingFromList.id,
                        this.toSelectText(buildingFromList), false)
                    if (!this.editState && isSelectedBuilding) {
                        this.preselectOption(buildingFromList)
                    }
                })
            }
            nN(callback)
            initMultiSelect(this.getBuildingSelectHtml())
        }
    }

    private getJobTypeContainerHtml(): JQuery {
        return $(`#${prefix}-job-type-container`)
    }

    private getJobTypeOnceHtml(): JQuery {
        return $(`#${prefix}-job-type-once`)
    }

    private getJobTypeRepeatHtml(): JQuery {
        return $(`#${prefix}-job-type-repeat`)
    }

    private getJobRhythmusOnceHtml(): JQuery {
        return $(`#${prefix}-job-rhythmus`)
    }

    private getJobRhythmusMoreHtml(): JQuery {
        return $(`#${prefix}-job-rhythmus-more`)
    }

    private getJobSameHoursHtml(): JQuery {
        return $(`#${prefix}-same-hours`)
    }

    private getJobSameHoursDetailsHtml(): JQuery {
        return $(`#${prefix}-same-hours-details`)
    }

    private getJobVariableHoursHtml(): JQuery {
        return $(`#${prefix}-variable-hours`)
    }

    private getStartDateHtml(): JQuery {
        return $(`#${prefix}-start-date`)
    }

    private getOnceDateHtml(): JQuery {
        return $(`#${prefix}-date`)
    }

    private getDateCalendar() {
        return document.querySelector(`#${prefix}-date-calendar`) as HTMLElement
    }

    private getStartDateCalendar() {
        return document.querySelector(
            `#${prefix}-start-date-calendar`) as HTMLElement
    }

    private getEndDateCalendar() {
        return document.querySelector(
            `#${prefix}-end-date-calendar`) as HTMLElement
    }

    private getEndDateHtml(): JQuery {
        return $(`#${prefix}-end-date`)
    }

    private getWhTageCheckboxHtml(day: DayOfWeek): JQuery {
        return $(`#${prefix}-iteration-work-day-` + day)
    }

    private getVariableTageCheckboxHtml(day: DayOfWeek): JQuery {
        return $(`#${prefix}-iteration-variable-work-day-` + day)
    }

    private getWorkWindowStartHtml(type: string): JQuery {
        return $(`#${prefix}-worktime-start-${type}`)
    }

    private getWorkWindowEndHtml(type: string): JQuery {
        return $(`#${prefix}-worktime-end-${type}`)
    }

    private getWorkTimeHtml(type: string): JQuery {
        return $(`#${prefix}-worktime-${type}`)
    }

    //### TASK TYPE ###
    private fillTaskTypes() {
        taskTypeDataProvider.fillSelectWithTaskTypes(
            this.getTaskTypeSelectHtml())
    }

    private getTaskTypeSelectHtml(): JQuery {
        return $(`#${prefix}-task-type-select`)
    }

    private setElementDatasetDisabled(elem: JQuery, state: boolean) {
        const item = elem[0]
        if (item) {
            item.dataset.disabled = `${state}`
        }
    }

    private setElementDatasetEditable(elem: JQuery, state: boolean) {
        const item = elem[0]
        if (item) {
            item.dataset.edit = `${state}`
        }
    }

    private setParentDatasetDisabled(elem: JQuery, state: boolean) {
        const item = elem[0]
        const container = item ? item.parentElement : null
        if (container) {
            container.dataset.disabled = `${state}`
        }
    }

    private setDisabled(target: JQuery, disable: boolean = true,
                        parent: boolean = false) {
        if (!target[0]){
            return
        }
        const _parent = target[0].parentElement

        if (!parent) {
            if (disable) {
                target.attr('disabled', 'disabled')
            } else {
                target.attr('disabled', null)
            }
        } else if (_parent) {
            const _label = _parent.parentElement
            _parent.dataset.disabled = `${disable}`

            if (_label) {
                _label.dataset.disabled = `${disable}`
            }
        }
    }

    private handleRhytmChange() {
        const checked = isChecked(this.getRhytmChangeCheckboxHtml())
        this.setDisabledRhytmChange(!checked)
    }

    private getRhytmChangeCheckboxHtml(): JQuery {
        return $(`#${prefix}-rhythm-change-checkbox`)
    }

    private getRhytmChangeDateHtml() {
        return $(`#${prefix}-rhythm-change-date`)
    }

    private getRhytmChangeDateCalendar() {
        return document.querySelector(
            `#${prefix}-rhythm-change-date-calendar`) as HTMLElement
    }

    private getRhytmChangeListHtml() {
        return $(`#${prefix}-rhytm-change-dates`)
    }

    private checkRequireFields() {
        const startDate = isChecked(this.getJobRhythmusOnceHtml()) ?
            this.getOnceDateHtml() : this.getStartDateHtml()
        const workTime = isChecked(this.getJobRhythmusOnceHtml()) ?
            this.getWorkTimeHtml(once) : isChecked(this.getJobSameHoursHtml()) ?
                this.getWorkTimeHtml(repeatable) : this.getTaskTypeSelectHtml()
        const fields = [this.getCustomerSelectHtml(),
            this.getBuildingSelectHtml(), this.getBuildingStreetHtml(),
            this.getBuildingZipCodeHtml(), this.getBuildingCityHtml(),
            this.getTaskTypeSelectHtml(), startDate, workTime]

        const isFilled = fields.filter(field => !`${field.val()}`)
        this.setDisabled(this.getSaveBtnHtml(), isFilled.length !== 0)
    }

    private setDisabledRhytmChange(disable: boolean) {
        // this.setDisabled(this.getSaveBtnHtml(), disable)
        this.setElementDatasetEditable(this.getJobTypeRepeatHtml(), !disable)
        this.setElementDatasetDisabled(
            this.getWorkWindowAndWorkTimeContainerHtml(), disable)
        this.setDisabled(this.getWorkTimeHtml(repeatable), disable, true)
        this.setDisabled(this.getWorkWindowStartHtml(repeatable), disable, true)
        this.setDisabled(this.getWorkWindowEndHtml(repeatable), disable, true)
    }

    private setDisableNotChangeableValues(disable: boolean) {
        // this.setDisabled(this.getSaveBtnHtml(), disable)

        this.setElementDatasetDisabled(this.getJobTypeOnceHtml(), disable)
        this.setElementDatasetDisabled(this.getJobTypeRepeatHtml(), disable)
        this.setElementDatasetDisabled(
            this.getWorkWindowAndWorkTimeContainerHtml(), disable)
        this.setDisabled(this.getOrderIdHtml(), disable, true)
        this.setDisabled(this.getOrderJarhCheckboxHtml(), disable, true)
        this.setDisabled(this.getStartDateHtml(), disable)
        this.setDisabled(this.getEndDateHtml(), disable)

        this.setDisabled(this.getCustomerNameHtml(), disable, true)
        this.setDisabled(this.getContactPhoneNumberHtml(), disable, true)
        this.setDisabled(this.getContactEmailHtml(), disable, true)

        this.setDisabled(this.getBuildingStreetHtml(), disable, true)
        this.setDisabled(this.getBuildingZipCodeHtml(), disable, true)
        this.setDisabled(this.getBuildingCityHtml(), disable, true)

        this.setDisabled(this.getWorkTimeHtml(once), disable, true)
        this.setDisabled(this.getWorkTimeHtml(repeatable), disable, true)
        this.setDisabled(this.getWorkWindowStartHtml(once), disable, true)
        this.setDisabled(this.getWorkWindowStartHtml(repeatable), disable, true)
        this.setDisabled(this.getWorkWindowEndHtml(once), disable, true)
        this.setDisabled(this.getWorkWindowEndHtml(repeatable), disable, true)
    }

    private setCustomerSelectDisabled(state: boolean) {
        this.setParentDatasetDisabled(this.getCustomerSelectHtml(), state)
    }

    public openDialogForNew(dto?: IJobDto | null | undefined | IActiveJobDto,
                            isCopyOrder?: boolean) {
        console.log('dto', dto);
        this.clearCustom()
        document.dispatchEvent(new Event('handlePageLoaded'))
        setDialogOpen()
        this.clearDialog()
        this.setEditState(false)
        if (dto) {
            let loaded_dto = this.dataProvider.getFromAllCachs(dto.id)

            this.fillDefaults(() => {
                this.dto = null
                loaded_dto = cloneWithJson(loaded_dto)
                loaded_dto = jobDataProvider.convert(loaded_dto!)
                loaded_dto!.id = null
                this.fillFromDto(loaded_dto!, isCopyOrder)

                this.setTitle(gt(this.titleNewTextId))
                this.showDialog()

                this.isEdit = false
            })
        } else {
            this.fillDefaults(() => {
                this.setTitle(gt(this.titleNewTextId))
                this.showDialog()
            })
        }


    }

}

export const newOrderModal = new NewOrderModal()
