import {AbstractDtoFormular, NameAndId} from './formular'
import {
    GenderEnum,
    IUserDto,
    IWorkTimeDto,
    Roles,
    userDataProvider,
    UserDataProvider,
    UserDto,
    WorkTimeDto
} from '../../model/user'
import {
    convertTimeNumberToTimeStr,
    convertTimeStrToTimeNumber,
    HashMap,
    ICallback,
    Proxy
} from '../../tools/tools'
import {DayOfWeek, DayOfWeeks} from '../../model/dayofweek'
import {
    addDiv,
    addElement,
    addExtendedOption,
    addMultiSelect,
    addTd,
    addTh,
    addTr,
    isChecked,
    onSelectChange,
    onChange,
    setChecked2,
    setHtmlId,
    isSelected
} from '../../tools/templateTools'
import {gt} from '../../tools/translation'
import {
    HTML_INPUT_TYPE,
    IViewModelConverterWithOptionsForArray
} from '../../model/model_base'
import {calendarSettingsDataProvider} from '../../model/calendar_settings'
import {actionsInternal} from '../page_manager'
import {ITaskTypeDto} from '../../model/tasktype'
import {initMultiSelect} from '../../tools/multi_select'
import {globalstate} from "../../model/globalstate";

class MaFormular extends AbstractDtoFormular<IUserDto, UserDataProvider> {
    constructor() {
        super(userDataProvider, 'neuer Mitarbeiter', 'button.new.employee')
    }

    protected customFill(dto: IUserDto | null) {
        this.addCustomBtnSpacer()
        this.addCustomBtn(gt('page.auftraggeber.groups') ?? 'Gruppen',
            actionsInternal.loadMitarbeiterGruppen)
    }

    protected bindValueToMultiSelectAndAddToView(setter: IViewModelConverterWithOptionsForArray<IUserDto, ITaskTypeDto>,
                                                 target: JQuery) {
        const select = addMultiSelect(target)
        let viewData = setter.toView(this.currentDto)

        setter.getOptions().forEach(option => {
            const _props = option.id
            const _isSelected = viewData.find(
                value => setter.equals(value, option.id)) ? true : false
            const _option = addExtendedOption(select, _props.id, _props.name,
                _props.customId, _props.shortName, _isSelected)

            onSelectChange(_option, () => {
                this.setHasChanged(true)
                const _isSelected = isSelected(_option)
                if (_isSelected) {
                    viewData.push(option.id)
                } else {
                    viewData = viewData.filter(
                        value => !setter.equals(value, option.id))
                }
                setter.fromView(this.currentDto, viewData)
            })
        })

        initMultiSelect(select)
    }

    protected bindElementToView(dto: IUserDto): void {
        let section = this.addSectionTitle(
            gt('page.formular.title.general') ?? 'Allgemein')
        const _general = gt('page.mitarbeiterFormular.fields.general')
        const _contact = gt('page.mitarbeiterFormular.fields.contact')
        const _contractData = gt('page.mitarbeiterFormular.fields.contractData')
        const _edv = gt('page.mitarbeiterFormular.fields.edv')

        this.bindValueToRadioGroupAndAddToView(UserDto.setterGender, section,
            gt('page.formular.title.gender') ?? 'Geschlecht')
        this.bindSimpleValueToInputFieldAndAddToView(UserDto.setterCustomId,
            section, _general.at(0) ?? 'PN*',
            _general.at(1) ?? 'Personalnummer')
        this.bindSimpleValueToInputFieldAndAddToView(UserDto.setterFirstname,
            section, _general.at(2) ?? 'Vorname*')
        this.bindSimpleValueToInputFieldAndAddToView(UserDto.setterLastname,
            section, _general.at(3) ?? 'Nachname*')
        this.bindSimpleValueToInputFieldAndAddToView(UserDto.setterCitizenship,
            section, _general.at(5) ?? 'ST',
            _general.at(6) ?? 'Staatsangehörigkeit')
        this.bindSimpleValueToInputFieldAndAddToView(UserDto.setterComment,
            section, _general.at(7) ?? 'Infos')

        section = this.addSectionTitle(
            gt('page.mitarbeiterFormular.title.availability') ?? 'Verfügbarkeit')
        this.addText(section,
            gt('page.mitarbeiterFormular.subtitle.availability') ?? 'Verfügbarkeit ist nicht die Vertragliche Arbeitszeit!')
        this.addPossibleWorkTimes(section, dto)

        section = this.addSectionTitle(
            gt('page.mitarbeiterFormular.title.contact') ?? 'Kontakt')
        this.bindSimpleValueToInputFieldAndAddToView(UserDto.setterStreetAndNr,
            section, _contact.at(0) ?? 'Straße / HausNr.')
        this.bindSimpleValueToInputFieldAndAddToView(UserDto.setterZipCode,
            section, _contact.at(1) ?? 'PLZ')
        this.bindSimpleValueToInputFieldAndAddToView(UserDto.setterCity,
            section, _contact.at(2) ?? 'Stadt')
        this.bindSimpleValueToInputFieldAndAddToView(UserDto.setterCountry,
            section, _contact.at(3) ?? 'Land')
        this.bindSimpleValueToInputFieldAndAddToView(UserDto.setterPhone,
            section, _contact.at(4) ?? 'Festnetz')
        this.bindSimpleValueToInputFieldAndAddToView(UserDto.setterPhone2,
            section, _contact.at(5) ?? 'Mobil')
        this.bindSimpleValueToInputFieldAndAddToView(UserDto.setterEmail,
            section, _contact.at(6) ?? 'Email')

        section = this.addSectionTitle(
            gt('page.mitarbeiterFormular.title.contractData') ?? 'Vertragsdaten')
        this.bindValueToRadioGroupAndAddToView(UserDto.setterUserTypeId,
            section,
            gt('page.mitarbeiterFormular.subtitle.contractData') ?? 'Vertragsart')
        this.bindSimpleValueToInputFieldAndAddToView(UserDto.setterVacationDays,
            section, _contractData.at(0) ?? 'Urlaubstage')
        this.bindSimpleValueToInputFieldAndAddToView(UserDto.setterStartDate,
            section, _contractData.at(1) ?? 'Eintritt')
        this.bindSimpleValueToInputFieldAndAddToView(UserDto.setterEndDate,
            section, _contractData.at(2) ?? 'Austritt')

        //Vertragliche Arbeitszeiten
        const vmzProxy = new Proxy<JQuery>()
        const vwzHtml = this.bindSimpleValueToInputFieldAndAddToView(
            UserDto.setterWorkHoursPerWeek, section,
            _contractData.at(3) ?? 'WVZ',
            _contractData.at(4) ?? 'Wöchtliche Vertrags Zeiten', null, ma => {
                vmzProxy.value.val(
                    UserDto.setterWorkHoursPerMonth.toView(ma).toFixed(2))
            })
        const vmzHtml = this.bindSimpleValueToInputFieldAndAddToView(
            UserDto.setterWorkHoursPerMonth, section,
            _contractData.at(5) ?? 'MVZ',
            _contractData.at(6) ?? 'Monatliche Vertrags Zeiten', null, ma => {
                vwzHtml.val(
                    UserDto.setterWorkHoursPerWeek.toView(ma).toFixed(2))
            })
        vmzProxy.value = vmzHtml

        section = this.addSectionTitle(
            gt('page.mitarbeiterFormular.title.edv') ?? 'EDV')
        this.bindSimpleValueToInputFieldAndAddToView(UserDto.setterActive,
            section, _edv.at(0) ?? 'Active', null,
            dto => userDataProvider.current.content.id === dto.id)
        this.bindSimpleValueToInputFieldAndAddToView(UserDto.setterCanLogin,
            section, _edv.at(1) ?? 'Kann einloggen', null,
            dto => userDataProvider.current.content.id === dto.id)
        this.bindSimpleValueToInputFieldAndAddToView(UserDto.setterName,
            section, _edv.at(2) ?? 'Login')
        const password = this.bindSimpleValueToInputFieldAndAddToView(
            UserDto.setterPassword, section, _edv.at(3) ?? 'Password', null,
            dto => userDataProvider.current.content.id === dto.id)
        password.attr('autocomplete', 'new-password')
        this.bindValueToCheckBoxGroupAndAddToView(UserDto.setterRoles, section,
            _edv.at(4) ?? 'Rollen',
            dto => userDataProvider.current.content.id === dto.id)

        section = this.addSectionTitle(
            gt('page.mitarbeiterFormular.title.services') ?? 'Leistungen')
        this.addText(section,
            gt('page.mitarbeiterFormular.note.services') ?? 'Wenn keine Leistungen ausgewählt sind, ist das gleichbedeutend mit ALLEN Leistungen!',
            false)
        this.bindValueToMultiSelectAndAddToView(UserDto.setterTaskTypes,
            section)
    }

    protected createNewDto(): IUserDto {
        const userDto = new UserDto()
        userDto.roles = [Roles.WORKER]
        userDto.gender = GenderEnum.FEMALE

        userDto.possibleWorkTimes = []

        userDto.possibleWorkTimes.push(
            new WorkTimeDto(DayOfWeek.MONDAY, '06:00', '18:00'))
        userDto.possibleWorkTimes.push(
            new WorkTimeDto(DayOfWeek.TUESDAY, '06:00', '18:00'))
        userDto.possibleWorkTimes.push(
            new WorkTimeDto(DayOfWeek.WEDNESDAY, '06:00', '18:00'))
        userDto.possibleWorkTimes.push(
            new WorkTimeDto(DayOfWeek.THURSDAY, '06:00', '18:00'))
        userDto.possibleWorkTimes.push(
            new WorkTimeDto(DayOfWeek.FRIDAY, '06:00', '18:00'))

        return userDto
    }

    protected canBeDeleted(dto: IUserDto): boolean {
        return this.provider.current.content.id !== dto.id
    }

    protected handleSwitchToTblView() {
        actionsInternal.loadMitarbeiter()
    }

    protected getTitle(dto: IUserDto): string {
        return gt('page.mitarbeiterFormular.name') ?? 'Mitarbeiter'
    }

    protected loadList(callback: ICallback<NameAndId<number>[], void>): void {
        globalstate.refresh(() => this.provider.loadListAllInternalManaged(
            dtos => this.convertToNameAndIds(dtos, callback), true, true));
    }

    private addPossibleWorkTimes(section: JQuery, dto: IUserDto) {
        const timesByDay = new HashMap<IWorkTimeDto>()
        dto.possibleWorkTimes?.forEach(time => {
            timesByDay.put(time.dayOfWeek, time)
        })

        const container = addDiv(section)
        container.css('margin-top', '20px')
        container.css('width', '100%')
        const table = addElement(container, 'table')
        table.css('width', '80%')
        const thead = addElement(table, 'thead')
        const theadTr = addTr(thead)
        const inputId = 'id_possibleWorkTime_' + new Date().getTime() + '_'
        addTd(theadTr)
        DayOfWeeks.forEach(day => {
            const th = addTh(theadTr)
            th.css('width', '50px')
            th.css('text-align', 'center')

            const td = addDiv(th, gt(day + '-short'))
            td.attr('for', inputId + day)
        })
        const tbody = addElement(table, 'tbody')
        const tbodyTr = addTr(tbody)
        addTd(tbodyTr)
        DayOfWeeks.forEach(day => {
            const td = addTd(tbodyTr)
            td.css('text-align', 'center')

            const inputHtml = addElement(td, 'input')
            setHtmlId(inputHtml, inputId + day)
            inputHtml.attr('type', HTML_INPUT_TYPE.checkbox)
            onChange(inputHtml, () => {
                const checked = isChecked(inputHtml)

                const start = $('#' + inputId + day + '_start')
                const stop = $('#' + inputId + day + '_stop')
                if (checked) {
                    const workTimeDto = new WorkTimeDto(day, '06:00', '18:00')
                    timesByDay.put(day, workTimeDto)
                    start.val(convertTimeStrToTimeNumber(workTimeDto.startTime))
                    stop.val(
                        convertTimeStrToTimeNumber(workTimeDto.endTime, true))
                    start.show()
                    stop.show()
                } else {
                    start.hide()
                    start.val('')
                    stop.hide()
                    stop.val('')
                    timesByDay.remove(day)
                }
                dto.possibleWorkTimes = timesByDay.toArray()
                this.setHasChanged(true)
            })

            const time = timesByDay.get(day)
            if (time) {
                setChecked2(inputHtml, true)
            } else {
                setChecked2(inputHtml, false)
            }
        })

        const timeVonTbodyTr = addTr(tbody)

        const _labels = gt('page.mitarbeiterFormular.fields.availability')
        const tdVon = addTd(timeVonTbodyTr, _labels.at(0) ?? 'von')
        tdVon.css('padding', '5px')
        DayOfWeeks.forEach(day => {
            const inputHtml = addElement(addTd(timeVonTbodyTr), 'select')
            calendarSettingsDataProvider.fillSelectWithTimes(inputHtml, 0, 24)
            setHtmlId(inputHtml, inputId + day + '_start')
            inputHtml.attr('type', HTML_INPUT_TYPE.time)

            const time = timesByDay.get(day)
            let orgValue: any = null
            if (time) {
                orgValue = convertTimeStrToTimeNumber(time.startTime)
                inputHtml.val(orgValue)
            } else {
                inputHtml.hide()
            }

            onChange(inputHtml, () => {
                let time = timesByDay.get(day)
                if (!time) {
                    time = new WorkTimeDto(day)
                    timesByDay.put(day, time)
                }

                const newValue = convertTimeNumberToTimeStr(inputHtml.val())
                if (orgValue === newValue) {
                    return
                }
                orgValue = newValue
                time.startTime = newValue
                this.setHasChanged(true)
            })
        })

        const timeBisTbodyTr = addTr(tbody)
        const tdBis = addTd(timeBisTbodyTr, _labels.at(1) ?? 'bis')
        tdBis.css('padding', '5px')
        DayOfWeeks.forEach(day => {
            const inputHtml = addElement(addTd(timeBisTbodyTr), 'select')
            calendarSettingsDataProvider.fillSelectWithTimes(inputHtml, 0, 24)
            setHtmlId(inputHtml, inputId + day + '_stop')
            inputHtml.attr('type', HTML_INPUT_TYPE.time)
            const time = timesByDay.get(day)
            let orgValue: any = null
            if (time) {
                orgValue = convertTimeStrToTimeNumber(time.endTime, true)
                inputHtml.val(orgValue)
            } else {
                inputHtml.hide()
            }

            onChange(inputHtml, () => {
                let time = timesByDay.get(day)
                if (!time) {
                    time = new WorkTimeDto(day)
                    timesByDay.put(day, time)
                }

                const newValue = convertTimeNumberToTimeStr(inputHtml.val())
                if (orgValue === newValue) {
                    return
                }
                orgValue = newValue
                time.endTime = newValue

                this.setHasChanged(true)
            })
        })
    }
}

export const maFormular = new MaFormular()
