import {
	AbstractWithIdAndNameAndAddressDto,
	AbtractDataProvider,
	IAbstractWithIdAndNameDto
} from './model_base'
import {REST_CONTROLLER} from '../tools/restConsties'
import {globalstate} from "./globalstate";

export interface IUserTypeDto extends IAbstractWithIdAndNameDto {}

export class UserTypeDto extends AbstractWithIdAndNameAndAddressDto implements IUserTypeDto {}

export class UserTypeDataProvider extends AbtractDataProvider<IUserTypeDto> {
	constructor() {
		super(REST_CONTROLLER.USER_TYPE)
	}

	createDto(): IUserTypeDto {
		return new UserTypeDto()
	}

	protected getUserGlobalState(): boolean {
		return true;
	}
}

export const userTypeDataProvider = new UserTypeDataProvider()
globalstate.userTypeDataProvider=userTypeDataProvider
