import {
    AbstractWithIdAndNameDto, AbtractDataProvider, IAbstractWithIdAndNameDto
} from './model_base'
import {
    executeRestCall, IdParameter, nullJsonObjectConverter
} from '../tools/rest'
import {ICallback} from '../tools/tools'
import {
    REST_CONTROLLER, REST_METHODS, REST_OPERATION
} from '../tools/restConsties'
import {globalstate} from "./globalstate";
import {userTypeDataProvider} from "./usertype";

export enum JobGroupType {
    ONCE = 'ONCE', REPEATING = 'REPEATING'
}

export const JobGroupTypes = [JobGroupType.ONCE, JobGroupType.REPEATING]

export interface IJobGroupDto extends IAbstractWithIdAndNameDto {
    memberCount: number | null;
    jobIds: number[] | null;

    type: JobGroupType | null;
}

export class JobGroup extends AbstractWithIdAndNameDto implements IJobGroupDto {
    memberCount: number | null = null;
    jobIds: number[] | null = null;
    type: JobGroupType = JobGroupType.REPEATING;
}

export class JobGroupDataProvider extends AbtractDataProvider<IJobGroupDto> {
    constructor() {
        super(REST_CONTROLLER.JOB_GROUP)
    }

    protected getUserGlobalState(): boolean {
        return true;
    }

    createDto(): IJobGroupDto {
        return new JobGroup()
    }

    public loadGroupList(success?: ICallback<any, void>) {
        return executeRestCall(this.controller,
            REST_OPERATION.ALL_MANAGED_BASIC, REST_METHODS.GET, null, success,
            null)
    }

    public addGroup(data: { name: string, type: string },
                    success: ICallback<any, void>) {
        executeRestCall(this.controller, REST_OPERATION.SAVE, REST_METHODS.POST,
            nullJsonObjectConverter, success, null, data)
    }

    public deleteGroup(id: number, success?: ICallback<any, void>) {
        executeRestCall(this.controller, REST_OPERATION.DELETE,
            REST_METHODS.DELETE, nullJsonObjectConverter, success, null, null,
            new IdParameter(id))
    }

    public addJobToGroup(groupId: number, jobId: number,
                         success?: ICallback<any, void>) {
        executeRestCall(this.controller, REST_OPERATION.MEMBER,
            REST_METHODS.POST, nullJsonObjectConverter, success, null, null,
            {parentId: groupId, id: jobId})
    }

    public deleteJobFromGroup(groupId: number, jobId: number,
                              success?: ICallback<any, void>) {
        executeRestCall(this.controller, REST_OPERATION.MEMBER,
            REST_METHODS.DELETE, nullJsonObjectConverter, success, null, null,
            {parentId: groupId, id: jobId})
    }
}

export const jobGroupDataProvider = new JobGroupDataProvider()
globalstate.jobGroupDataProvider=jobGroupDataProvider
