import $ from 'jquery'
import 'bootstrap'
import {isSelected, setChecked2} from './templateTools'
import moment from 'moment'
import {IAbstractDto} from '../model/model_base'
import {DayOfWeek, DayOfWeeks} from '../model/dayofweek'

export interface IDynmicObject {
    [key: string]: any
}

export interface ICallback<TYPE, RESULT> {
    (key: TYPE): RESULT
}

export interface ICallbackHandler<TYPE, RESULT> {
    (callback: ICallback<TYPE, RESULT>): void
}

export function roundTo2(value: number): string {
    return (Math.round(value * 100) / 100).toFixed(2)
}

export function setChecked(htmlId: string, htmlIdPostFix: string | null = null,
                           checked: boolean = true) {
    let postFix = htmlIdPostFix ? '-' + htmlIdPostFix : ''
    setChecked2($('#' + htmlId + postFix), checked)
}

export function setValOrEmpty(htmlId: string, valueOrg: any | null) {
    let value = ''
    if (valueOrg) {
        value = valueOrg
    }
    $('#' + htmlId).val(value)
}

export function getInputValAsString(htmlId: string): string {
    return toStringOrEmpty($('#' + htmlId).val())
}

export function getSelectedValFromRadio(htmlName: string): string | null {
    return toStringIfNotNull($("input[name='" + htmlName + "']:checked").val())
}

export function toStringIfNotNull(value: any): string | null {
    if (!value) {
        return null
    }
    value = value.toString().trim()
    if (value.length < 1) {
        return null
    }

    return value
}

export function toStringOrEmpty(value: any): string {
    if (!value) {
        return ''
    }
    value = value.toString().trim()
    if (value.length < 1) {
        return ''
    }

    return value
}

export function getAsNumber(htmlId: string): number | null {
    let value = $('#' + htmlId).val()
    if (!value) {
        return null
    }
    if (value.toString().trim().length < 1) {
        return null
    }

    return value as number
}

export function nN<VALUE, RESULT>(fkt: ICallback<VALUE, RESULT> | null | Function,
                                  value: VALUE = {} as VALUE): RESULT | null {
    if (fkt instanceof Function) {
        return fkt(value)
    }

    return null
}

export function getValueOrError<TYPE>(value: TYPE | null): TYPE {
    if (value) {
        return value
    }
    throw Error('Not SET')
}

export interface IMap<VALUE> {
    [key: string | number]: VALUE
}

export class HashMap<VALUE> {
    protected _map: IMap<VALUE> = {}
    private _index: number = 0

    public has(key: string | number): boolean {
        return this._map['' + key] !== undefined
    }

    public forEach(callback: ICallback<VALUE, void>) {
        this.toArray().forEach(value => callback(value))
    }

    public forEachWithKeys(callback: ICallback<[string | number, VALUE], void>) {
        Object.entries(this._map).map(value => callback(value))
    }

    public remove(key: string | number | null) {
        if (key === null || key === undefined) {
            throw new Error('key must be not null, but was: ' + key)
        }
        delete this._map['' + key]
    }

    public get(key: any): VALUE | null {
        let result = this._map['' + key]
        return result ? result : null
    }

    public putWithIndex(value: VALUE): number {
        this.put(this._index, value)
        return this._index++
    }

    public put(key: string | number | null, value: VALUE) {
        if (key === null || key === undefined) {
            throw new Error('key must be not null, but was: ' + key)
        }
        this._map[key] = value
    }

    public clear() {
        this._index = 0
        this._map = {}
    }

    public toArray(): VALUE[] {
        return Object.entries(this._map).map(value => value[1])
    }

    public contains(key: string | number): boolean {
        return this.has(key)
    }

    public length(): number {
        return this.toArray().length
    }
}

export class HashMapWithArray<VALUE> extends HashMap<VALUE[]> {
    public putArray(key: string | number | null, value: VALUE) {
        if (key === null || key === undefined) {
            throw new Error('key must be not null, but was: ' + key)
        }
        let mapElement: VALUE[] = this._map[key]
        if (!mapElement) {
            mapElement = []
            this._map[key] = mapElement
        }
        mapElement.push(value)
    }
}

export function convertTimeStrToTimeNumber(source: string | null | any,
                                           endTime: boolean = false): number {
    if (!source) {
        throw Error()
    }
    let splitted = source.split(':')
    let hours: number = +splitted[0]
    if (endTime && hours === 0) {
        hours = 24
    }
    let minutes: number = +splitted[1]
    minutes = minutes === 0 ? 0 : 1 / (60 / minutes)

    return hours + minutes
}

export function toDecimalHour(source: number | null): string {
    const str = `${source}`
    const number = isNaN(parseFloat(str)) ? 1.0 : parseFloat(str)
    return `${number.toFixed(2)}`.replaceAll(".", ",")
}

export function decimalHourToNumber(source: string): number {
    source = source.replaceAll(",", ".");
    const number = isNaN(parseFloat(source)) ? 1 : parseFloat(source)
    const str = number.toFixed(2)
    return parseFloat(str)
}

export function convertTimeNumberToTimeStr(source: any): string {
    source = +(source + '')
    let numberStr = source.toFixed(2).split('.')
    let hour = numberStr[0]
    let minutes = (+numberStr[1] / 100) * 60
    let minutesStr = minutes.toFixed(0)
    return addLeadingZero(hour) + ':' + addLeadingZero(minutesStr)
}

export function addLeadingZero(numberStr: string): string {
    return numberStr.length < 2 ? '0' + numberStr : numberStr
}

export function confirmYesNo(question: string): boolean {
    return window.confirm(question)
}

export function popupMessage(message: string) {
    alert(message)
}

export function handleError(errorMessage: any) {
    throw new Error(errorMessage)
}

export function convertDateToInputFormat(date: Date | null): string {
    if (!date) {
        return ''
    }
    const day = ('0' + date.getDate()).slice(-2)
    const month = ('0' + (date.getMonth() + 1)).slice(-2)

    return `${date.getFullYear()}-${month}-${day}`
}

export function convertDateToYearMonthDayFormat(date: string | null): Date | null {
    if (!date) {
        return null;
    }

    return new Date((date).split('.').reverse().join('/'));
}

export function convertDateToUnixFormat(date: string | null): number | null {
    if (!date) {
        return null;
    }

    return moment(convertDateToYearMonthDayFormat(date)).valueOf();
}


export function convertDateToCalendarFormat(date: Date | null): string {
    if (!date) {
        return ''
    }
    const day = ('0' + date.getDate()).slice(-2)
    const month = ('0' + (date.getMonth() + 1)).slice(-2)

    return `${day}.${month}.${date.getFullYear()}`
}

export function convertDateToDateStr(date: Date | null): string {
    if (!date) {
        return "null"
    }
    return convertDateToDateStrNoYear(date) + date.getFullYear()
}

export function convertDateToDateStrNoYear(date: Date): string {
    const day = ('0' + date.getDate()).slice(-2)
    const month = ('0' + (date.getMonth() + 1)).slice(-2)
    return day + '.' + month + '.'
}

export function convertDateToTimeStr(date: Date | any): string {
    return moment(date).format('HH:mm')
}

export function getDaysBetweenDates(startDate: Date, endDate: Date): Date[] {
    let date: Date[] = [];
    while (moment(startDate) <= moment(endDate)) {
        date.push(startDate!);
        startDate = moment(startDate).add(1, 'days').toDate();
    }
    return date;
}

//2022-W01
export function convertDateToYearWeekStr(date: Date): string {
    let week = getWeekNumber(date)
    return week[0] + '-W' + (week[1] < 10 ? '0' + week[1] : week[1])
}

function getWeekNumber(d: Date): number[] {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()))
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7))
    // Get first day of year
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1)).getTime()
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil(((d.getTime() - yearStart) / 86400000 + 1) / 7)
    // Return array of year and week number
    return [d.getUTCFullYear(), weekNo]
}

export function getMonday(date: Date): Date {
    for (let i = 0; i < 7; i++) {
        if (moment(date).day() === 1) {
            return date
        }
        date = addDays(date, -1)
    }
    throw Error('this could not be!')
}

export function getDayInWeekFromDays(date: Date,
                                     day: DayOfWeek | null = null): Date {
    if (!day) {
        throw Error('day == null')
    }
    let index = DayOfWeeks.indexOf(day) + 1
    return getDayInWeek(date, index)
}

export function getDayInWeek(date: Date, targetDayIndex: number): Date {
    let daySundayFix = date.getDay() < 1 ? 7 : date.getDay()
    if (daySundayFix === targetDayIndex) {
        return date
    }
    if (daySundayFix < targetDayIndex) {
        let diff = daySundayFix - targetDayIndex
        return addDays(date, -diff)
    }
    let diff = targetDayIndex - daySundayFix
    return addDays(date, diff)
}

export function convertYearWeekStrToDate(yearWeekStr: string): Date {
    return moment(yearWeekStr).toDate()
}

export function addDays(source: Date, days: number): Date {
    let newDate = source.getDate() + days
    let result = new Date(source)
    result.setDate(newDate)
    return result
}

export function convertDateToTimeNumber(date: Date | null | any,
                                        endTime: boolean = false): number {
    if (!date) {
        throw Error()
    }
    return convertTimeStrToTimeNumber(convertDateToTimeStr(date), endTime)
}

export function getToDayAsStr(): string {
    return convertDateToInputFormat(new Date())
}

export function toNumber(value: any): number {
    if (typeof value === 'number') {
        return value as number
    }
    return +value
}

export function toBoolean(value: any): boolean {
    if (typeof value === 'boolean') {
        return value as boolean
    }
    return value ? true : false
}

/**
 * ONLY the ID!!!!
 */
export function collectSelected<DTO extends IAbstractDto>(target: JQuery,
                                                          createDtoFkt: ICallback<void, DTO>): DTO[] {
    let result: DTO[] = []
    for (let option of target.children()) {
        let optionJQuery = $(option)
        if (!isSelected(optionJQuery)) {
            continue
        }
        let dto = createDtoFkt()
        dto.id = toNumber(optionJQuery.val())
        result.push(dto)
    }
    return result
}

export function setSelectedOptions<DTO extends IAbstractDto>(target: JQuery,
                                                             list: DTO[] | null) {
    list?.forEach(dto => {
        console.log(dto)
        console.log(target)
        target.find("option[value='" + dto.id + "']").attr('selected', 'true')
    })
}

export function newDateOrNull(date: Date | string | null | any): Date | null {
    return date ? new Date(date) : null
}

export function mustNotNull<TARGET>(source: TARGET | null | undefined): TARGET {
    if (source !== null && source !== undefined) {
        return source
    }
    throw Error('MUST be set!')
}

export function cloneWithJson<VALUE>(value: VALUE): VALUE {
    return JSON.parse(JSON.stringify(value))
}

export class Proxy<VALUE> {
    private _value: VALUE | null = null

    get value(): VALUE {
        if (!this._value) {
            throw Error('Not set!')
        }
        return this._value
    }

    set value(value: VALUE | null) {
        this._value = value
    }
}

export const debounce = (fn: Function, ms = 150) => {
    let timeoutId: ReturnType<typeof setTimeout>
    return function (this: any, ...args: any[]) {
        fn.apply(this, args);
        /*
        clearTimeout(timeoutId)
        timeoutId = setTimeout(() => fn.apply(this, args), ms)
         */
    }
}

export const throttle = (fn: Function, wait: number = 300) => {
    let inThrottle: boolean, lastFn: ReturnType<typeof setTimeout>,
        lastTime: number
    inThrottle = false
    return function (this: any) {
        const context = this, args = arguments
        if (!inThrottle) {
            fn.apply(context, args)
            lastTime = Date.now()
            inThrottle = true
        } else {
            clearTimeout(lastFn)
            lastFn = setTimeout(() => {
                if (Date.now() - lastTime >= wait) {
                    fn.apply(context, args)
                    lastTime = Date.now()
                }
            }, Math.max(wait - (Date.now() - lastTime), 0))
        }
    }
}

export const runAsync = async (fn: Function) => {
    return new Promise(() => fn()).then()
}