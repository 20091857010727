import {
    AbtractDataProvider,
    AbtractDataProviderWithName,
    IAbstractDto,
    IAbstractWithIdAndNameDto,
    Tuple
} from '../../model/model_base'
import {AbstractClass} from '../../tools/abstract_class'
import {
    findTemplateAndRemove,
    findTemplateAndSetChecked,
    findTemplateAndSetDataBsContent,
    findTemplateAndSetOnClick,
    onClick,
    setDisabledVisual,
    setHtmlId,
    setOnClick
} from '../../tools/templateTools'
import {
    confirmYesNo, HashMap, ICallback, ICallbackHandler, IMap, nN
} from '../../tools/tools'
import {createNewFromTemplate, scanForTemplates} from '../../tools/templates'
import {AbstractEditModal} from '../modals/abstract_edit_modal'
import {ListPagination} from '../../tools/list_pagination'
import {CONSOLE_DEBUG} from '../../consties'
import {IActiveJobDto} from "../../model/active_job";

export class GrouppedDto<DTO extends IAbstractDto> {
    dtos: DTO[] = []
}

export abstract class AbstractListPage<DTO extends IAbstractDto, DATA_PROVIDER extends AbtractDataProvider<DTO>> extends AbstractClass {
    loadDtosFkt: ICallbackHandler<DTO[], void>
    dataProvider: DATA_PROVIDER
    dtos: DTO[] = []
    containterHtmlId: string
    templateTabbleEntyHtmlId: string
    dtoTableEntyHtmlId: string
    pagination: ListPagination

    constructor(dataProvider: DATA_PROVIDER, containterHtmlId: string,
                templateTabbleEntyHtmlId: string, dtoTableEntyHtmlId: string) {
        super()
        this.dataProvider = dataProvider
        this.loadDtosFkt = this.dataProvider.loadListAllManaged
        this.containterHtmlId = containterHtmlId
        this.templateTabbleEntyHtmlId = templateTabbleEntyHtmlId
        this.dtoTableEntyHtmlId = dtoTableEntyHtmlId
        this.pagination = new ListPagination()
    }

    public initAfterHtmlLoaded(callback: Function | null = null) {
        scanForTemplates(this.getTemplateIds())
        scanForTemplates([this.templateTabbleEntyHtmlId])
        nN(callback)
    }

    public updateTable(dtos: DTO[]) {
        this.customStartWorkUpdateTable();
        let container = $('#' + this.containterHtmlId)
        //clear table

        container.empty()

        let rows: Tuple<number, JQuery>[] = []
        let groupDtos = this.groupDtos(dtos);
        if (groupDtos) {
            groupDtos.forEach(groupDto => {
                rows = rows.concat(this.createGroupRow(groupDto))
            })
        } else {
            dtos.forEach(dto => {
                rows = rows.concat(this.createSingleRow(dto))
            })

        }

        this.addCustomRows(rows);
        rows = rows.sort((a, b) => a.value1 - b.value1)
        rows.forEach(row => {
            container.append(row.value2)
        })

        //this.pagination.saveList(dtos)
        this.customEndWorkUpdateTable();
    }

    protected createGroupRow(groupDto: GrouppedDto<DTO>): Tuple<number, JQuery>[] {
        return [];
    }

    protected groupDtos(dtos: DTO[]): HashMap<GrouppedDto<DTO>> | null {
        return null;
    }

    public fillTable(callback: Function | null = null) {
        //load worker
        this.loadList(dtos => {
            this.updateTable(dtos);
            nN(callback)
        })
    }

    protected createSingleRow(dto: DTO): Tuple<number, JQuery>[] {
        //create new entry
        let tableEntry = this.createTableEntry(dto)

        return [new Tuple<number, JQuery>(0, tableEntry)]
    }

    protected loadList(success: ICallback<DTO[], void>) {
        this.dataProvider.loadListAllManaged(success, true, true)
    }

    protected getTemplateIds(): string[] {
        return []
    }

    protected abstract getModal(): AbstractEditModal<DTO, DATA_PROVIDER>

    protected createTableEntry(dto: DTO): JQuery {
        let tableEntry = createNewFromTemplate(this.templateTabbleEntyHtmlId)
        setHtmlId(tableEntry, this.dtoTableEntyHtmlId + '_' + dto.id)
        this.fillTableEntry(dto, tableEntry)
        return tableEntry
    }

    protected abstract fillTableEntry(dto: DTO, tableEntry: JQuery): void

    protected onClickOpenEdit(target: JQuery, dto: DTO) {
        onClick(target, () => this.openForEdit(dto))
        target.addClass('clickable')
    }

    protected openForEdit(dto: DTO) {
        this.getModal().openDialogForEdit(dto)
    }

    protected createIsActive(dto: DTO, target: JQuery, isActiveHtmlId: string,
                             alt = false) {
        let isActive = dto.active !== null ? dto.active : true
        let isActiveCheckbox = findTemplateAndSetChecked(target, isActiveHtmlId,
            isActive)
        setOnClick(isActiveCheckbox, () => {
            if (dto.id) {
                dto.active = !dto.active
                setDisabledVisual(target, dto.active)
                if (!alt) {
                    this.dataProvider.setIsActive(dto.id, dto.active)
                } else {
                    this.dataProvider.setIsActiveAlt(dto.id, dto.active)
                }
            }
        })
        setDisabledVisual(target, isActive)
    }

    protected createEditBtn(dto: DTO, target: JQuery, editBtnHtmlId: string,
                            callback: ICallback<DTO, void>) {
        //edit user
        findTemplateAndSetOnClick(target, editBtnHtmlId, () => callback(dto))
    }

    protected createDeleteBtn(dto: DTO, target: JQuery,
                              deleteBtnHtmlId: string) {
        findTemplateAndSetOnClick(target, deleteBtnHtmlId, () => {
            if (!dto.id) {
                return
            }
            if (!confirmYesNo(
                'Wirklich löschen: ' + this.getFullName(dto) + '?')) {
                return
            }
            this.dataProvider.delete(dto.id, result => {
                if (CONSOLE_DEBUG) console.log(result)
            })
            target.remove()
        })
    }

    protected abstract getFullName(dto: DTO): string

    protected createComment(dto: DTO, target: JQuery, commentHtmlId: string) {
        if (dto.comment) {
            findTemplateAndSetDataBsContent(target, commentHtmlId, dto.comment)
        } else {
            findTemplateAndRemove(target, commentHtmlId)
        }
    }

    protected addCustomRows(rows: Tuple<number, JQuery>[]) {

    }

    public customEndWorkUpdateTable() {

    }

    public customStartWorkUpdateTable() {

    }
}

export abstract class AbstractListPageWithName<DTO extends IAbstractWithIdAndNameDto, DATA_PROVIDER extends AbtractDataProviderWithName<DTO>> extends AbstractListPage<DTO, DATA_PROVIDER> {
    protected getFullName(dto: DTO): string {
        return this.dataProvider.getFullName(dto)
    }
}
