import {isEngLang} from '../tools/translation'

export enum DayOfWeek {
	MONDAY = 'MONDAY',
	TUESDAY = 'TUESDAY',
	WEDNESDAY = 'WEDNESDAY',
	THURSDAY = 'THURSDAY',
	FRIDAY = 'FRIDAY',
	SATURDAY = 'SATURDAY',
	SUNDAY = 'SUNDAY'
}

export const DayOfWeeks = [
	DayOfWeek.MONDAY,
	DayOfWeek.TUESDAY,
	DayOfWeek.WEDNESDAY,
	DayOfWeek.THURSDAY,
	DayOfWeek.FRIDAY,
	DayOfWeek.SATURDAY,
	DayOfWeek.SUNDAY
]

export function toDayOfWeek(dayOfWeekName: any): DayOfWeek {
	return DayOfWeek[dayOfWeekName as keyof typeof DayOfWeek]
}

export function toDateOrNull(date: any): Date | null {
	if (!date) {
		return null
	}
	return new Date(date)
}

export function convertDateToDayOfWeek(date: Date): DayOfWeek {
	let day = date.getDay() - 1
	return DayOfWeeks[day < 0 ? 6 : day]
}

export function dayOfWeekToShort(dayOfWeek: DayOfWeek): string {
	switch (dayOfWeek) {
		case DayOfWeek.MONDAY:
			return 'Mo'
		case DayOfWeek.TUESDAY:
			return isEngLang() ? 'Di' : 'Tu'
		case DayOfWeek.WEDNESDAY:
			return isEngLang() ? 'Mi' : 'We'
		case DayOfWeek.THURSDAY:
			return isEngLang() ? 'Do' : 'Th'
		case DayOfWeek.FRIDAY:
			return 'Fr'
		case DayOfWeek.SATURDAY:
			return 'Sa'
		case DayOfWeek.SUNDAY:
			return isEngLang() ? 'So' : 'Su'
	}
}
