import {CONSOLE_DEBUG, FILE_VERSION, HTML_ROOT_ID} from '../../consties'
import {AbstractClass} from '../../tools/abstract_class'
import {loadTemplates, scanForTemplates} from '../../tools/templates'
import { Tooltip } from '../../tools/tooltip'
import {setDialogClosed} from '../page_manager'
import {ICallback, ICallbackHandler} from "../../tools/tools";

export abstract class AbstractModal extends AbstractClass {
	protected modalHtmlId: string
	protected htmlTargetHtmlId: string
	protected modalId: string
	protected htmlIsLoaded: boolean = false

	constructor(modalHtmlId: string) {
		super()
		this.modalHtmlId = modalHtmlId
		this.htmlTargetHtmlId = modalHtmlId + '-target'
		this.modalId = modalHtmlId
	}

	public hideDialog() {
		setDialogClosed()
		let target = jQuery('#' + this.modalHtmlId)
		if (CONSOLE_DEBUG) console.log('Close: ' + this.modalHtmlId, target)
		target.modal('hide')
		this.postCloseDialog()
		Tooltip.init();
		document.dispatchEvent(new Event('closeModal'))
	}

	protected postCloseDialog() {}

	public loadHtml(): void {
		if (this.htmlIsLoaded) {
			this.postHtmlLoaded()
		}
		this.htmlIsLoaded = true

		//
		let target = $('#' + this.htmlTargetHtmlId)
		if (target.length === 0) {
			target = $('<span id="' + this.htmlTargetHtmlId + '" />')
			$('#' + HTML_ROOT_ID).append(target)
		}

		if (CONSOLE_DEBUG) console.log('load: ' + this.modalId)
		let htmlDir = this.getHtmlDir()
		htmlDir = htmlDir ? htmlDir + '/' : ''
		target.load('modals/' + htmlDir + this.modalId + '.html?' + FILE_VERSION, () => {
			if (this.isLoadingExternalTemplates()) {
				loadTemplates('modals/' + htmlDir + this.modalId + '_templates', () => {
					scanForTemplates(this.getTemplateIds())
					this.postHtmlLoaded()
				})
			} else {
				scanForTemplates(this.getTemplateIds())
				this.postHtmlLoaded()
			}
		})
	}

	protected isLoadingExternalTemplates(): boolean {
		return false
	}

	protected getTemplateIds(): string[] {
		return []
	}

	public disableDialog() {
		$('#' + this.modalHtmlId + ' :input').prop('disabled', true)
	}

	public enableDialog() {
		$('#' + this.modalHtmlId + ' :input').prop('disabled', false)
	}

	protected postHtmlLoaded(): void {}

	public showDialog(success: Function | null = null) {
		if (CONSOLE_DEBUG) console.log('Open Modal: ' + this.modalId);
		jQuery('#' + this.modalHtmlId).modal({
			keyboard: false,
			backdrop: 'static'
		});
		jQuery('#' + this.modalHtmlId).modal('show');
		document.dispatchEvent(new Event('openModal'));
        if (success) {
        	success();
        }
	}

	protected getHtmlDir(): string {
		return ''
	}
}
