import {AbstractDtoFormular} from './formular'
import {CustomerContactPersonDataProvider, customerDataProvider, ICustomerDto} from '../../model/customer'
import {auftraggeberFormular} from './auftrageberFormular'
import {IUserDto, Roles, UserDto} from '../../model/user'
import {toStringOrEmpty, getValueOrError} from '../../tools/tools'
import {onClick} from '../../tools/templateTools'
import {actionsInternal} from '../page_manager'
import {gt} from '../../tools/translation'
import {encodeHtml} from "../../tools/StringTools";

export class ContactFormular extends AbstractDtoFormular<IUserDto, CustomerContactPersonDataProvider> {
	public static instance: ContactFormular
	private readonly customer: ICustomerDto

	private constructor(customer: ICustomerDto) {
		super(
			new CustomerContactPersonDataProvider(getValueOrError(customer.id)),
			gt('button.new.contactPerson') ?? 'Neuer Ansprechpartner',
			'button.new.contactPerson'
		)
		this.customer = customer
	}

	public static create(customer: ICustomerDto): ContactFormular {
		ContactFormular.instance = new ContactFormular(customer)
		return ContactFormular.instance
	}

	protected customFill() {
		const _tabel = gt('button.tabel') ?? 'Tabelle'
		const _allClients = gt('button.allClients') ?? 'alle Auftraggeber'
		const _tableAll = `${encodeHtml(_tabel)}<br>${encodeHtml(_allClients)}`
		const _customer = gt('page.buildingsFormular.button.customerEdit') ?? ['Auftraggeber', 'bearbeiten']
		const _customerName = customerDataProvider.getShortName(this.customer) ?? ''
		const _customerButton = `${encodeHtml(_customer.at(0))}<br><b>${encodeHtml(_customerName)}</b><br>${encodeHtml(_customer.at(1))}`
		this.addCustomBtnSpacer()
		//this.addCustomBtn("Objekte", () => BuildingsFormular.instance.fill())
		this.addCustomBtn(_customerButton, () => auftraggeberFormular.fill(this.customer))
		this.addCustomBtn(_tableAll, () => actionsInternal.loadAuftraggeber())
		this.getTableVewBtnHtml().hide()
	}

	protected bindElementToView(dto: IUserDto): void {
		let section = this.addSectionTitle(gt('page.formular.title.general') ?? 'Allgemein', true, 'page.formular.title.general')
		const _contact = gt('page.contactFormular.fields.mainContact')
		const _connection = gt('page.contactFormular.fields.connection')
		this.bindValueToRadioGroupAndAddToView(UserDto.setterGender, section, gt('page.formular.title.gender') ?? 'Geschlecht', '')
		//this.bindSimpleValueToInputFieldAndAddToView(UserDto.setterCustomId, section, "KontaktId")
		//this.bindSimpleValueToInputFieldAndAddToView(UserDto.setterTitle, section, "Titel")
		this.bindSimpleValueToInputFieldAndAddToView(UserDto.setterFirstname, section, _contact.at(0) ?? 'Vorname')
		this.bindSimpleValueToInputFieldAndAddToView(UserDto.setterLastname, section, _contact.at(1) ?? 'Nachname')
		this.bindSimpleValueToInputFieldAndAddToView(UserDto.setterPosition, section, _contact.at(2) ?? 'Position / Abteilung')
		this.bindSimpleValueToInputFieldAndAddToView(UserDto.setterComment, section, _contact.at(3) ?? 'Infos')
		this.bindSimpleValueToInputFieldAndAddToView(UserDto.setterMainContact, section, _contact.at(4) ?? 'Hauptkontakt')

		section = this.addSectionTitle(gt('page.contactFormular.title.connection') ?? 'Kontakt')
		this.bindSimpleValueToInputFieldAndAddToView(UserDto.setterPhone, section, _connection.at(0) ?? 'Festnetz')
		this.bindSimpleValueToInputFieldAndAddToView(UserDto.setterPhone2, section, _connection.at(1) ?? 'Mobil')
		this.bindSimpleValueToInputFieldAndAddToView(UserDto.setterEmail, section, _connection.at(2) ?? 'Email')
		this.bindSimpleValueToInputFieldAndAddToView(UserDto.setterFax, section, _connection.at(3) ?? 'Fax')
	}

	protected createNewDto(): IUserDto {
		let buildingDto = new UserDto()
		buildingDto.roles = [Roles.CONTACT_PERSON]
		return buildingDto
	}

	protected canBeDeleted(dto: IUserDto): boolean {
		return true
	}

	protected handleSwitchToTblView() {
		throw Error()
	}

	protected getTitle(dto: IUserDto): string {
		return gt('page.auftraggeberFormular.contactPerson') ?? 'Ansprechpartner'
	}

	protected getSubTitle(dto: IUserDto): JQuery {
		let container = $('<span>')
		container.text(
			`AG: (${this.customer.customId ?? this.customer.id}) ${customerDataProvider.getFullName(
				this.customer
			)}  /  ${this.convertToName(dto)}`
		)
		container.addClass('clickable')
		onClick(container, () => {
			if (this.confirmDiscardChanges()) {
				auftraggeberFormular.fill(this.customer)
			}
		})
		return container
	}

	protected convertToName(dto: IUserDto): string {
		let contactTitle = ''
		if (!dto.firstname && !dto.lastname) {
			if (dto.customId) {
				contactTitle = dto.customId
			} else {
				contactTitle = '???'
			}
		} else {
			contactTitle = toStringOrEmpty(dto.firstname) + ' ' + toStringOrEmpty(dto.lastname)
		}
		if (dto.title) {
			contactTitle = dto.title + ' ' + contactTitle
		}
		return contactTitle
	}
}
