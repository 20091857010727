import {
    AbstractWithIdAndNameDto,
    AbtractDataProviderWithName,
    IAbstractWithIdAndNameDto
} from './model_base'
import {REST_CONTROLLER} from '../tools/restConsties'
import {globalstate} from "./globalstate";
import {IUserDto, userDataProvider} from "./user";

export interface IUserGroupDto extends IAbstractWithIdAndNameDto {
    buildingId: number | null
    memberIds: number[] | null
}

export class UserGroupDto extends AbstractWithIdAndNameDto implements IUserGroupDto {
    buildingId: number | null = null
    memberIds: number[] | null = null
}

export class UserGroupDataProvider extends AbtractDataProviderWithName<IUserGroupDto> {
    constructor() {
        super(REST_CONTROLLER.USER_GROUP)
    }

    createDto(): IUserGroupDto {
        return new UserGroupDto()
    }

    protected getUserGlobalState(): boolean {
        return true;
    }

    public getAllMembers(group: IUserGroupDto): IUserDto[] {
        const result: IUserDto[] = [];
        group.memberIds?.forEach(memberId => {
            if (memberId) {
                const user = userDataProvider.getFromAllCachs(memberId);
                if (user) {
                    result.push(user);
                }
            }
        })
        return result
    }
}

export const userGroupDataProvider = new UserGroupDataProvider()
globalstate.userGroupDataProvider = userGroupDataProvider
