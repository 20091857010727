import {
    ArrayJsonObjectConverter,
    executeRestCall,
    executeRestCallJson,
    IdParameter,
    IJsonToObjectConverter,
    nullJsonObjectConverter,
    ParentChildIdContainer
} from '../tools/rest'
import {
    ICallback, IMap, nN, toBoolean, toNumber, toStringOrEmpty
} from '../tools/tools'
import {popupService} from '../tools/popups'
import {NameAndId} from '../pages/formulare/formular'
import {
    REST_CONTROLLER, REST_METHODS, REST_OPERATION
} from '../tools/restConsties'
import {globalstate} from "./globalstate";

export interface IBooleanDto {
    value: boolean
}

export class CachedDto<CONTENT> {
    hash: number | null = null
    content: CONTENT
    useCache: boolean | null = null
    //local - may be not correct!!!
    time = Date.UTC(1970, 1, 1)
    lastUse = Date.now()

    constructor(content: CONTENT) {
        this.content = content;
    }

}

export abstract class AbtractDataProvider<DTO extends IAbstractDto> implements IJsonToObjectConverter<DTO> {
    controller: REST_CONTROLLER
    public current!: CachedDto<DTO>;

    constructor(baseUrl: REST_CONTROLLER) {
        this.controller = baseUrl
    }

    convert(source: DTO): DTO {
        return source
    }

    removeAFromB(a: DTO[], b: DTO[]): DTO[] {
        return b.filter(aValue => !this.contains(a, aValue))
    }

    aInsideB(a: DTO[], b: DTO[]): boolean {
        for (const value of a) {
            if (!this.contains(b, value)) {
                return false
            }
        }
        return true
    }

    contains(target: DTO[], dto: DTO): boolean {
        if (!dto.id) {
            return false
        }
        for (const value of target) {
            if (value.id === dto.id) {
                return true
            }
        }
        return false
    }

    protected abstract getUserGlobalState(): boolean;

    public abstract createDto(): DTO

    public clearCache() {
        this.loadListAllCache = null
        this.loadListAllInternalManagedCache = null
        this.loadListAllManagedCache = null
        if (this.current) {
            this.current.hash = -1
        }
    }

    public loadListAllCache: CachedDto<DTO[]> | null = null;

    public loadListAll(success: ICallback<DTO[], void>,
                       compact: boolean | null = null,
                       compact2: boolean | null = null) {
        if (this.getUserGlobalState()) {
            globalstate.refresh(() => {
                success(
                    this.loadListAllCache ? this.loadListAllCache.content : [])
            })
        } else {
            this.loadListCached(REST_OPERATION.ALL_CACHED, result => {
                this.loadListAllCache = result;
                success(result.content)
            }, this, null, compact, compact2, this.loadListAllCache)
        }
    }

    public loadListAllInternalManagedCache: CachedDto<DTO[]> | null = null;

    public loadListAllInternalManaged(success: ICallback<DTO[], void>,
                                      compact: boolean | null = null,
                                      compact2: boolean | null = null) {
        if (this.getUserGlobalState()) {
            globalstate.refresh(() => {
                success(this.loadListAllInternalManagedCache ?
                    this.loadListAllInternalManagedCache.content : [])
            })
        } else {
            this.loadListCached(REST_OPERATION.ALL_INTERNAL_MANAGED_CACHED,
                result => {
                    this.loadListAllInternalManagedCache = result;
                    success(result.content)
                }, this, null, compact, compact2,
                this.loadListAllInternalManagedCache)
        }
    }

    public loadListAllManagedCache: CachedDto<DTO[]> | null = null;

    public loadListAllManaged(success: ICallback<DTO[], void>,
                              compact: boolean | null = null,
                              compact2: boolean | null = null) {
        if (this.getUserGlobalState()) {
            if (this.loadListAllManagedCache) {
                success(this.loadListAllManagedCache.content)
                globalstate.refresh()
            } else {
                globalstate.refresh(() => {
                    success(this.loadListAllManagedCache ?
                        this.loadListAllManagedCache.content : [])
                })
            }
        } else {
            this.loadListCached(REST_OPERATION.ALL_MANAGED_CACHED, result => {
                this.loadListAllManagedCache = result;
                success(result.content)
            }, this, null, compact, compact2, this.loadListAllManagedCache)
        }
    }

    public loadListAllMembers<MEMBER>(id: number,
                                      success: ICallback<MEMBER[], void>,
                                      converter: IJsonToObjectConverter<MEMBER>,
                                      compact: boolean | null = null,
                                      compact2: boolean | null = null) {

        executeRestCallJson(this.controller, REST_OPERATION.ALL_MEMBERS,
            REST_METHODS.GET, new ArrayJsonObjectConverter(converter), success,
            null, null, {id, compact, compact2})
    }

    public loadList<RESULT>(operation: REST_OPERATION,
                            success: ICallback<RESULT[], void>,
                            converter: IJsonToObjectConverter<RESULT>,
                            data: any | null = null,
                            compact: boolean | null = null,
                            compact2: boolean | null = null) {
        executeRestCallJson(this.controller, operation, REST_METHODS.GET,
            new ArrayJsonObjectConverter(converter), success, null, data,
            {compact, compact2})
    }

    public loadListCached<RESULT>(operation: REST_OPERATION,
                                  success: ICallback<CachedDto<RESULT[]>, void>,
                                  converter: IJsonToObjectConverter<RESULT>,
                                  data: any | null = null,
                                  compact: boolean | null = null,
                                  compact2: boolean | null = null,
                                  cachedResult: CachedDto<RESULT[]> | null = null) {
        let hash = cachedResult ? cachedResult.hash : -1
        executeRestCallJson(this.controller, operation, REST_METHODS.GET, null,
            (result) => {
                let cachedDto = result as CachedDto<RESULT[]>;
                if (cachedDto.useCache) {
                    if (!cachedResult) {
                        throw new Error("Cache MISSED!")
                    }
                    success(cachedResult)
                } else {
                    cachedDto.content = new ArrayJsonObjectConverter(
                        converter).convert(cachedDto.content)
                    success(cachedDto)
                }
            }, null, data, {compact, compact2, hash})
    }

    public setIsActive(id: number, isActive: boolean,
                       success: ICallback<string, void> | null = null) {
        executeRestCall(this.controller, REST_OPERATION.ACTIVE,
            REST_METHODS.PATCH, nullJsonObjectConverter, success, null,
            new SetActive(id, isActive))
    }

    public setIsActiveAlt(id: number, isActive: boolean,
                          success: ICallback<string, void> | null = null) {
        executeRestCall(REST_CONTROLLER.BUILDING, REST_OPERATION.ACTIVE,
            REST_METHODS.PATCH, nullJsonObjectConverter, success, null,
            new SetActive(id, isActive))
    }

    public delete(id: number, success: ICallback<string, void> | null = null) {
        executeRestCall(this.controller, REST_OPERATION.DELETE,
            REST_METHODS.DELETE, nullJsonObjectConverter, () => {
                globalstate.refresh(success, true)
            }, null, null, new IdParameter(id))
    }

    public addMember(parentId: number, memberId: number | null,
                     success: ICallback<string, void> | null = null) {
        executeRestCall(this.controller, REST_OPERATION.MEMBER,
            REST_METHODS.POST, nullJsonObjectConverter, success, null, null,
            new ParentChildIdContainer(parentId, memberId))
    }

    public removeMember(parentId: number, memberId: number | null,
                        success: ICallback<string, void> | null = null) {
        executeRestCall(this.controller, REST_OPERATION.MEMBER,
            REST_METHODS.DELETE, nullJsonObjectConverter, success, null, null,
            new ParentChildIdContainer(parentId, memberId))
    }

    public load(id?: number | null, success?: ICallback<DTO, void>) {
        executeRestCallJson(this.controller, REST_OPERATION.GET,
            REST_METHODS.GET, this, success, null, null,
            new IdParameter(id || null))
    }

    public loadOrGetFromCacheInternalManagedCache(id?: number | null,
                                                  success?: ICallback<DTO, void>) {
        this.loadOrGetFromCache(id, success,
            this.loadListAllInternalManagedCache)
    }

    public loadOrGetFromCacheAll(id?: number | null,
                                 success?: ICallback<DTO, void>) {
        this.loadOrGetFromCache(id, success, this.loadListAllCache)
    }

    public loadOrGetFromCache(id?: number | null,
                              success?: ICallback<DTO, void>,
                              cache?: CachedDto<DTO[]> | null) {
        if (cache?.content) {
            for (const dto of cache?.content) {
                if (dto.id === id) {
                    success ? success(dto) : undefined
                    return;
                }
            }
        }
        this.load(id, success)
    }

    public getFromAllInternalManagedCache(id?: number | null): DTO | null {
        return this.getFromCache(id, this.loadListAllInternalManagedCache)
    }

    public getFromAllCachs(id?: number | null): DTO | null {
        let result = this.getFromAll(id);
        if (result) {
            return result;
        }
        result = this.getFromAllManaged(id);
        if (result) {
            return result;
        }
        result = this.getFromAllInternalManagedCache(id);
        return result;
    }

    public getFromAll(id?: number | null): DTO | null {
        return this.getFromCache(id, this.loadListAllCache)
    }

    public getFromAllManaged(id?: number | null): DTO | null {
        return this.getFromCache(id, this.loadListAllManagedCache)
    }


    addToCache(dto: DTO) {
        if (!this.loadListAllCache) {
            this.loadListAllCache = new CachedDto<DTO[]>([]);
        }
        this.removeFromCache(dto.id, this.loadListAllCache)
        //this.removeFromCache(dto.id,this.loadListAllManagedCache)
        //this.removeFromCache(dto.id,this.loadListAllInternalManagedCache)
        this.loadListAllCache?.content.push(dto)
    }

    public getFromCache(id?: number | null,
                        cache?: CachedDto<DTO[]> | null): DTO | null {
        if (cache && cache.content) {
            for (const dto of cache?.content) {
                if (dto.id === id) {
                    return dto;
                }
            }
        }
        return null;
    }

    public removeFromCache(id?: number | null,
                           cache?: CachedDto<DTO[]> | null) {
        if (cache && cache.content) {
            cache.content = cache?.content.filter(dto => dto.id !== id)
        }
    }

    public save(dto: DTO, callback: ICallback<number, void> | null = null,
                showMessage: boolean = true) {
        let callbackInternal = callback
        if (showMessage) {
            callbackInternal = result => {
                popupService.info('Änderungen gespeichert!')
                nN(callback, result)
            }
        }
        if (dto.id) {
            this.saveEdit(dto, callbackInternal)
        } else {
            this.saveNew(dto, callbackInternal)
        }
    }

    public saveNew(dto: DTO, success: ICallback<number, void> | null = null) {
        executeRestCall(this.controller, REST_OPERATION.SAVE, REST_METHODS.POST,
            nullJsonObjectConverter, success, null, dto)
    }

    public saveEdit(dto: DTO, success: ICallback<number, void> | null = null) {
        executeRestCall(this.controller, REST_OPERATION.SAVE, REST_METHODS.PUT,
            nullJsonObjectConverter, success, null, dto)
    }
}

export abstract class AbtractDataProviderWithName<DTO extends IAbstractWithIdAndNameDto> extends AbtractDataProvider<DTO> {
    public getFullName(dto: DTO | null): string {
        if (!dto || !dto.name) {
            return '???'
        }
        return dto.name
    }

    public getShortName(dto: DTO | null): string {
        if (!dto || !dto.name) {
            return '???'
        }
        return dto.name
    }
}

export class SetActive {
    private id: number
    private active: boolean

    constructor(userID: number, isActive: boolean) {
        this.id = userID
        this.active = isActive
    }
}

export interface IAddressDto extends IMap<any> {
    streetAndNr: string | null
    zipCode: string | null
    city: string | null
    country: string | null
    email: string | null
    phone: string | null
    phone2: string | null
    fax: string | null
}

export class AddressDto implements IAddressDto {
    streetAndNr: string | null = null
    zipCode: string | null = null
    city: string | null = null
    country: string | null = null
    email: string | null = null
    phone: string | null = null
    phone2: string | null = null
    fax: string | null = null
}

export function getMainPhone(address: IAddressDto | null | undefined): string {
    if (!address) {
        return ''
    }
    if (address.phone2 && address.phone2.trim().length > 0) {
        return address.phone2.trim()
    }
    return address.phone ? address.phone.trim() : ''
}

export interface IIDDto extends IMap<any> {
    id: number | null
}

export interface IAbstractDto extends IIDDto {
    useOnlyId: boolean | null

    customId: string | null
    customValue: string | null
    comment: string | null
    active: boolean | null
}

export enum HTML_INPUT_TYPE {
    text = 'text',
    date = 'date',
    number = 'number',
    tel = 'tel',
    week = 'week',
    time = 'time',
    email = 'email',
    checkbox = 'checkbox',
    radio = 'radio',
    password = 'password',
    select = 'select',
    url = 'url'
}

export abstract class IViewModelConverter<DTO, VIEW> {
    public abstract getFieldName(): string

    public abstract fromView(dto: DTO, value: VIEW): void

    public abstract toView(dto: DTO): VIEW

    public abstract getHtmlType(): HTML_INPUT_TYPE
}

export abstract class IViewModelConverterWithOptions<DTO, VIEW> extends IViewModelConverter<DTO, VIEW> {
    public abstract getOptions(): NameAndId<VIEW>[]
}

export abstract class IViewModelConverterWithOptionsForArray<DTO, VIEW> extends IViewModelConverter<DTO, VIEW[]> {
    public abstract getOptions(): NameAndId<VIEW>[]

    public abstract equals(a: VIEW, b: VIEW): boolean
}

export class AbstractDto implements IAbstractDto {
    id: number | null = null
    public static setterId: IViewModelConverter<AbstractDto, number> = {
        getFieldName(): string {
            return 'id'
        }, toView(dto: AbstractDto): number {
            return toNumber(dto.id)
        },

        fromView(dto: AbstractDto, value: number): void {
            dto.id = value
        },

        getHtmlType(): HTML_INPUT_TYPE {
            return HTML_INPUT_TYPE.number
        }
    }

    useOnlyId: boolean | null = false
    public static setterUseOnlyId: IViewModelConverter<AbstractDto, boolean> = {
        getFieldName(): string {
            return 'useOnlyId'
        }, toView(dto: AbstractDto): boolean {
            return toBoolean(dto.useOnlyId)
        },

        fromView(dto: AbstractDto, value: boolean): void {
            dto.useOnlyId = value
        },

        getHtmlType(): HTML_INPUT_TYPE {
            return HTML_INPUT_TYPE.checkbox
        }
    }
    customId: string | null = null
    public static setterCustomId: IViewModelConverter<AbstractDto, string> = {
        getFieldName(): string {
            return 'customId'
        }, toView(dto: AbstractDto): string {
            return toStringOrEmpty(dto.customId)
        },

        fromView(dto: AbstractDto, value: string): void {
            dto.customId = value
        },

        getHtmlType(): HTML_INPUT_TYPE {
            return HTML_INPUT_TYPE.text
        }
    }
    customValue: string | null = null
    public static setterCustomValue: IViewModelConverter<AbstractDto, string> = {
        getFieldName(): string {
            return 'customValue'
        }, toView(dto: AbstractDto): string {
            return toStringOrEmpty(dto.customValue)
        },

        fromView(dto: AbstractDto, value: string): void {
            dto.customValue = value
        },

        getHtmlType(): HTML_INPUT_TYPE {
            return HTML_INPUT_TYPE.text
        }
    }
    comment: string | null = null
    public static setterComment: IViewModelConverter<AbstractDto, string> = {
        getFieldName(): string {
            return 'comment'
        }, toView(dto: AbstractDto): string {
            return toStringOrEmpty(dto.comment)
        },

        fromView(dto: AbstractDto, value: string): void {
            dto.comment = value
        },

        getHtmlType(): HTML_INPUT_TYPE {
            return HTML_INPUT_TYPE.text
        }
    }

    active: boolean | null = true
    public static setterActive: IViewModelConverter<AbstractDto, boolean> = {
        getFieldName(): string {
            return 'active'
        }, toView(dto: AbstractDto): boolean {
            return toBoolean(dto.active)
        },

        fromView(dto: AbstractDto, value: boolean): void {
            dto.active = value
        },

        getHtmlType(): HTML_INPUT_TYPE {
            return HTML_INPUT_TYPE.checkbox
        }
    }
}

export interface IAbstractWithIdAndNameDto extends IAbstractDto {
    name: string | null
    shortName?: string | null
}

export class AbstractWithIdAndNameDto extends AbstractDto implements IAbstractWithIdAndNameDto {
    name: string | null = null
    public static setterName: IViewModelConverter<IAbstractWithIdAndNameDto, string> = {
        getFieldName(): string {
            return 'name'
        }, toView(dto: IAbstractWithIdAndNameDto): string {
            return toStringOrEmpty(dto.name)
        },

        fromView(dto: IAbstractWithIdAndNameDto, value: string): void {
            dto.name = value
        },

        getHtmlType(): HTML_INPUT_TYPE {
            return HTML_INPUT_TYPE.text
        }
    }
}

export interface IAbstractWithIdAndNameAndAddressDto extends IAbstractWithIdAndNameDto {
    address: IAddressDto | null
}

export class AbstractWithIdAndNameAndAddressDto extends AbstractWithIdAndNameDto implements IAbstractWithIdAndNameAndAddressDto {
    address: IAddressDto = new AddressDto()

    public static setterStreetAndNr: IViewModelConverter<IAbstractWithIdAndNameAndAddressDto, string> = {
        getFieldName(): string {
            return 'streetAndNr'
        }, toView(dto: IAbstractWithIdAndNameAndAddressDto): string {
            if (!dto.address) {
                return ''
            }
            return toStringOrEmpty(dto.address.streetAndNr)
        },

        fromView(dto: IAbstractWithIdAndNameAndAddressDto,
                 value: string): void {
            if (!dto.address) {
                dto.address = new AddressDto()
            }
            dto.address.streetAndNr = value
        },

        getHtmlType(): HTML_INPUT_TYPE {
            return HTML_INPUT_TYPE.text
        }
    }
    public static setterZipCode: IViewModelConverter<IAbstractWithIdAndNameAndAddressDto, string> = {
        getFieldName(): string {
            return 'zipCode'
        }, toView(dto: IAbstractWithIdAndNameAndAddressDto): string {
            if (!dto.address) {
                return ''
            }
            return toStringOrEmpty(dto.address.zipCode)
        },

        fromView(dto: IAbstractWithIdAndNameAndAddressDto,
                 value: string): void {
            if (!dto.address) {
                dto.address = new AddressDto()
            }
            dto.address.zipCode = value
        },

        getHtmlType(): HTML_INPUT_TYPE {
            return HTML_INPUT_TYPE.text
        }
    }
    public static setterCity: IViewModelConverter<IAbstractWithIdAndNameAndAddressDto, string> = {
        getFieldName(): string {
            return 'city'
        }, toView(dto: IAbstractWithIdAndNameAndAddressDto): string {
            if (!dto.address) {
                return ''
            }
            return toStringOrEmpty(dto.address.city)
        },

        fromView(dto: IAbstractWithIdAndNameAndAddressDto,
                 value: string): void {
            if (!dto.address) {
                dto.address = new AddressDto()
            }
            dto.address.city = value
        },

        getHtmlType(): HTML_INPUT_TYPE {
            return HTML_INPUT_TYPE.text
        }
    }
    public static setterCountry: IViewModelConverter<IAbstractWithIdAndNameAndAddressDto, string> = {
        getFieldName(): string {
            return 'country'
        }, toView(dto: IAbstractWithIdAndNameAndAddressDto): string {
            if (!dto.address) {
                return ''
            }
            return toStringOrEmpty(dto.address.country)
        },

        fromView(dto: IAbstractWithIdAndNameAndAddressDto,
                 value: string): void {
            if (!dto.address) {
                dto.address = new AddressDto()
            }
            dto.address.country = value
        },

        getHtmlType(): HTML_INPUT_TYPE {
            return HTML_INPUT_TYPE.text
        }
    }
    public static setterEmail: IViewModelConverter<IAbstractWithIdAndNameAndAddressDto, string> = {
        getFieldName(): string {
            return 'email'
        }, toView(dto: IAbstractWithIdAndNameAndAddressDto): string {
            if (!dto.address) {
                return ''
            }
            return toStringOrEmpty(dto.address.email)
        },

        fromView(dto: IAbstractWithIdAndNameAndAddressDto,
                 value: string): void {
            if (!dto.address) {
                dto.address = new AddressDto()
            }
            dto.address.email = value
        },

        getHtmlType(): HTML_INPUT_TYPE {
            return HTML_INPUT_TYPE.email
        }
    }
    public static setterPhone: IViewModelConverter<IAbstractWithIdAndNameAndAddressDto, string> = {
        getFieldName(): string {
            return 'phone'
        }, toView(dto: IAbstractWithIdAndNameAndAddressDto): string {
            if (!dto.address) {
                return ''
            }
            return toStringOrEmpty(dto.address.phone)
        },

        fromView(dto: IAbstractWithIdAndNameAndAddressDto,
                 value: string): void {
            if (!dto.address) {
                dto.address = new AddressDto()
            }
            dto.address.phone = value
        },

        getHtmlType(): HTML_INPUT_TYPE {
            return HTML_INPUT_TYPE.tel
        }
    }
    public static setterPhone2: IViewModelConverter<IAbstractWithIdAndNameAndAddressDto, string> = {
        getFieldName(): string {
            return 'phone2'
        }, toView(dto: IAbstractWithIdAndNameAndAddressDto): string {
            if (!dto.address) {
                return ''
            }
            return toStringOrEmpty(dto.address.phone2)
        },

        fromView(dto: IAbstractWithIdAndNameAndAddressDto,
                 value: string): void {
            if (!dto.address) {
                dto.address = new AddressDto()
            }
            dto.address.phone2 = value
        },

        getHtmlType(): HTML_INPUT_TYPE {
            return HTML_INPUT_TYPE.tel
        }
    }
    public static setterFax: IViewModelConverter<IAbstractWithIdAndNameAndAddressDto, string> = {
        getFieldName(): string {
            return 'fax'
        }, toView(dto: IAbstractWithIdAndNameAndAddressDto): string {
            if (!dto.address) {
                return ''
            }
            return toStringOrEmpty(dto.address.fax)
        },

        fromView(dto: IAbstractWithIdAndNameAndAddressDto,
                 value: string): void {
            if (!dto.address) {
                dto.address = new AddressDto()
            }
            dto.address.fax = value
        },

        getHtmlType(): HTML_INPUT_TYPE {
            return HTML_INPUT_TYPE.tel
        }
    }
    zipCode: string | null = null
    city: string | null = null
    country: string | null = null
    email: string | null = null
    phone: string | null = null
    phone2: string | null = null
    fax: string | null = null
}

export class Tuple<VALUE1, VALUE2> {
    public value1: VALUE1
    public value2: VALUE2

    constructor(value1: VALUE1, value2: VALUE2) {
        this.value1 = value1
        this.value2 = value2
    }
}
