class CalendarPersistence {
	private static _instance: CalendarPersistence
	private expand: Array<Boolean> = [true, true, false]

	constructor() {
		if (CalendarPersistence._instance) {
			return CalendarPersistence._instance
		}

		CalendarPersistence._instance = this
	}

	public getExpandState = () => {
		return this.expand
	}

	public setExpandState = (id: number) => {
		if (id >= 0 && id < this.expand.length) {
			this.expand[id] = !this.expand[id]
		}
	}
}

export default new CalendarPersistence()
