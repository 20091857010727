import {AbstractClass} from '../../tools/abstract_class'
import {
    cloneWithJson,
    confirmYesNo,
    toStringOrEmpty,
    HashMap,
    ICallback,
    mustNotNull,
    nN,
    toBoolean,
    toNumber
} from '../../tools/tools'
import {createNewFromTemplate, scanForTemplates} from '../../tools/templates'
import {
    addDiv,
    addElement,
    addSpan,
    isChecked,
    onChange,
    onClick,
    onKeyUp,
    onOff,
    setChecked2,
    setDisable,
    setHtmlId
} from '../../tools/templateTools'
import {
    AbtractDataProviderWithName,
    HTML_INPUT_TYPE,
    IAbstractWithIdAndNameDto,
    IIDDto,
    IViewModelConverter,
    IViewModelConverterWithOptions,
    IViewModelConverterWithOptionsForArray
} from '../../model/model_base'
import {gt} from '../../tools/translation'
import {globalstate} from "../../model/globalstate";

export class NameAndId<ID> {
    public name: string
    public id: ID
    public active: boolean

    constructor(name: string, id: ID, active: boolean) {
        this.name = name
        this.id = id
        this.active = active
    }
}

const HTML_FORMULAR_TITLE = 'formular-title'
const HTML_FORMULAR_SUB_TITLE = 'formular-sub-title'
const HTML_FORMULAR_BACK_BTN = 'formular-back-btn'
const HTML_FORMULAR_SWITCH_TO_TABLE_VIEW_BTN = 'formular-switch-to-tbl-view-btn'
const HTML_FORMULAR_RESET_BTN = 'formular-reset-btn'

const HTML_FORMULAR_LIST = 'formular-selection-container'
const HTML_FORMULAR_LIST_ENTRY_TEMPLATE = 'formular-selection-element-template'
const HTML_FORMULAR_LIST_ENTRY_DEACTIVE_TEMPLATE = 'formular-selection-element-deactive-template'
const HTML_FORMULAR_LIST_ENTRY_LABEL = 'formular-selection-element-label'
const HTML_FORMULAR_LIST_FILTER = 'formular-filter'

const HTML_FORMULAR_SECTION_TITLE_TEMPLATE = 'formular-data-section-title-template'

const HTML_FORMULAR_ELEMENT_CONTAINER = 'formular-data-container'

const HTML_FORMULAR_ELEMENT_TEMPLATE = 'formular-data-simple-template'
const HTML_FORMULAR_ELEMENT_LABEL = 'formular-data-simple-label'
const HTML_FORMULAR_ELEMENT_INPUT = 'formular-data-simple-input'
const HTML_FORMULAR_SAVE_BTN = 'formular-save-btn'

const HTML_FORMULAR_NEW_BTN = 'formular-new-btn'
const HTML_FORMULAR_DELETE_BTN = 'formular-delete-btn'
const HTML_FORMULAR_CUSTOM_BTN_TEMPLATE = 'formular-custom-btn-template'
const HTML_FORMULAR_CUSTOM_BTN_SPACER_TEMPLATE = 'formular-custom-btn-spacer-template'
const HTML_FORMULAR_CUSTOM_BTN_ANKER = 'formular-custom-btn-anker'

export const DTO_CREATE_NEW = -1

export abstract class AbstractFormular<DTO extends IIDDto> extends AbstractClass {
    protected elementIsChanged: boolean = false
    protected isValid: boolean = true
    protected currentDto: DTO = {} as DTO
    protected listById = new HashMap<JQuery>()
    protected listByName = new HashMap<JQuery>()
    private readonly newBtnLabel: string
    private readonly translationKey: string

    protected constructor(newBtnLabel: string, translationKey: string) {
        super()
        this.newBtnLabel = newBtnLabel
        this.translationKey = translationKey
    }

    protected backAction() {
        window.history.back()
    }

    public fill(dto: DTO | null | number = null) {
        onClick($('#' + HTML_FORMULAR_BACK_BTN), this.backAction)
        this.getTableVewBtnHtml().show()
        if (dto !== DTO_CREATE_NEW) {
            if (!dto) {
                if (this.currentDto.id) {
                    dto = this.currentDto
                }
            }
        }
        onClick(this.getTableVewBtnHtml(), () => {
            if (!this.confirmDiscardChanges()) {
                return
            }
            this.handleSwitchToTblView()
        })

        scanForTemplates([HTML_FORMULAR_LIST_ENTRY_TEMPLATE,
            HTML_FORMULAR_SECTION_TITLE_TEMPLATE,
            HTML_FORMULAR_ELEMENT_TEMPLATE,
            HTML_FORMULAR_LIST_ENTRY_DEACTIVE_TEMPLATE,
            HTML_FORMULAR_CUSTOM_BTN_TEMPLATE,
            HTML_FORMULAR_CUSTOM_BTN_SPACER_TEMPLATE])

        onClick(this.getSaveBtnHtml(), () => this.saveIfNeeded())

        onClick(this.getResetBtnHtml(), this.reset)

        onClick($('#' + HTML_FORMULAR_DELETE_BTN), this.deleteWithQuestion)

        onKeyUp(this.getFilterHtml(), this.filter)
        const newBtn = $('#' + HTML_FORMULAR_NEW_BTN)
        onClick(newBtn, this.handleNew)
        newBtn.text(this.newBtnLabel)
        newBtn.attr('data-translation-key', this.translationKey)

        //fill list
        this.loadListAndSetDto(dto ? false : true)

        //fill dto
        if (dto !== DTO_CREATE_NEW && dto) {
            this.handleElementSelected(mustNotNull((dto as DTO).id))
        } else {
            dto = this.createNewDto()
            this.showElement(dto)
        }

        //alte dynamic löschen
        $('.formular-dynamic').remove()
        this.customFill(dto as DTO)
    }

    protected geBackBtnHtml(): JQuery {
        return $('#' + HTML_FORMULAR_BACK_BTN)
    }

    protected getTableVewBtnHtml(): JQuery {
        return $('#' + HTML_FORMULAR_SWITCH_TO_TABLE_VIEW_BTN)
    }

    protected customFill(dto: DTO | null) {
    }

    protected abstract delete(dto: DTO,
                              success: ICallback<string, void> | null): void

    protected abstract canBeDeleted(dto: DTO): boolean

    protected showElement(dto: DTO): void {
        //prepare
        this.currentDto = this.cloneDto(dto)
        this.setHasChanged(false)
        this.getElementContainerHtml().empty()

        //mark selected
        this.deselectAll()
        this.markSelected(toNumber(this.currentDto.id))

        //enable / disable delete
        setDisable(this.getDeleteBtnHtml(),
            !this.canBeDeleted(this.currentDto) || (this.currentDto.id ? false :
                true))

        //title
        const title = this.getTitle(this.currentDto)
        const _title = title.trim().toLowerCase()
        $('#' + HTML_FORMULAR_TITLE).text(title)
        $('#' + HTML_FORMULAR_TITLE)
            .attr('data-translation-key', `page.formular.title.${_title}`)

        //subtitle
        this.getSubTitleHtml().empty()
        this.getSubTitleHtml().append(this.getSubTitle(this.currentDto))

        //data
        this.bindElementToView(this.currentDto)
        document.dispatchEvent(new Event('handlePageLoaded'))
    }

    protected getSubTitleHtml(): JQuery {
        return $('#' + HTML_FORMULAR_SUB_TITLE)
    }

    protected abstract getSubTitle(dto: DTO): JQuery

    protected abstract handleSwitchToTblView(): void

    protected abstract createNewDto(): DTO

    protected addCustomBtnSpacer(label: string | null = null): JQuery {
        const spacer = createNewFromTemplate(
            HTML_FORMULAR_CUSTOM_BTN_SPACER_TEMPLATE)
        if (label) {
            spacer.text(label)
        }
        spacer.insertBefore($('#' + HTML_FORMULAR_CUSTOM_BTN_ANKER))
        return spacer
    }

    protected addCustomBtn(label: string, callback: Function,
                           translationKey?: string): JQuery {
        const btn = createNewFromTemplate(HTML_FORMULAR_CUSTOM_BTN_TEMPLATE)

        btn.find('.formular-custom-btn').html(label)

        if (translationKey) {
            btn.find('.formular-custom-btn')
                .attr('data-translation-key', translationKey)
        }

        btn.insertBefore($('#' + HTML_FORMULAR_CUSTOM_BTN_ANKER))
        onClick(btn, () => {
            if (!this.confirmDiscardChanges()) {
                return
            }
            callback()
        })
        return btn
    }

    protected addSectionTitle(title: string, open: boolean = true,
                              translationKey?: string): JQuery {
        const secTitle = createNewFromTemplate(
            HTML_FORMULAR_SECTION_TITLE_TEMPLATE, translationKey)
        secTitle.text(title)

        if (translationKey) {
            secTitle.attr('data-translation-key', translationKey)
        }

        this.getElementContainerHtml().append(secTitle)
        const sectionDiv = addDiv(this.getElementContainerHtml())
        if (!open) {
            sectionDiv.hide()
        }

        onClick(secTitle, () => {
            sectionDiv.toggle(1000)
        })
        return sectionDiv
    }

    protected postSaved(id: number): void {
        this.setHasChanged(false)
        this.loadWithNoQuestion(toNumber(id))
        this.fillList()
    }

    protected bindValueToRadioGroupAndAddToView<VIEW>(setter: IViewModelConverterWithOptions<DTO, VIEW>,
                                                      target: JQuery,
                                                      label: string | null = null,
                                                      translationKey?: string) {
        const container = addDiv(target)
        container.css('text-align', 'center')
        // container.css('margin-bottom', '15px')

        if (label) {
            const title = addDiv(container, gt(label))
            title.css('padding', '5px')
            title.css('font-weight', 'bolder')

            if (translationKey) {
                title.attr('data-translation-key', translationKey)
            }
        }

        const viewData = setter.toView(this.currentDto)
        const inputs: JQuery[] = []

        setter.getOptions().forEach((option, _id) => {
            const id = `id_${setter.getFieldName()}_${new Date().getTime()}_${_id}`

            const containerForInput = addSpan(container)
            containerForInput.css('padding', '5px')

            //create label
            const label = addElement(containerForInput, 'label',
                gt(option.name))
            label.attr('for', id)
            label.css('padding', '5px')

            //create checkbox
            const input = addElement(containerForInput, 'input')
            setHtmlId(input, id)
            input.attr('name', setter.getHtmlType + '_' + setter.getFieldName())
            input.css('padding', '5px')
            input.attr('type', setter.getHtmlType)

            const isSelected = viewData === option.id
            setChecked2(input, isSelected)

            inputs.push(input)

            onChange(input, () => {
                this.setHasChanged(true)
                setter.fromView(this.currentDto, option.id)
            })
        })
    }

    protected addText(target: JQuery, text: string, padding = true): JQuery {
        const container = addDiv(target)
        const textHtml = addDiv(container, gt(text))

        if (padding) {
            container.css('padding-left', '20%')
            textHtml.css('width', '80%')
        }
        container.css('width', '100%')
        container.css('text-align', 'center')
        textHtml.css('text-align', 'center')
        textHtml.css('font-weight', 'bolder')
        textHtml.css('font-size', '0.8em')
        return textHtml
    }

    protected bindValueToCheckBoxGroupAndAddToView<VIEW>(setter: IViewModelConverterWithOptionsForArray<DTO, VIEW>,
                                                         target: JQuery,
                                                         label: string | null = null,
                                                         readOnlyProvider: ICallback<DTO, boolean> | null = null) {
        const container = addDiv(target)
        container.css('text-align', 'center')
        container.css('margin-bottom', '15px')

        if (label) {
            const title = addDiv(container, gt(label))
            title.css('padding', '5px')
            title.css('font-weight', 'bolder')
        }

        let viewData = setter.toView(this.currentDto)
        const inputs: JQuery[] = []

        const readOnly = readOnlyProvider ? readOnlyProvider(this.currentDto) :
            false
        setter.getOptions().forEach((option, _id) => {
            const id = `id_${setter.getFieldName()}_${new Date().getTime()}_${_id}`

            const containerForInput = addSpan(container)
            containerForInput.css('padding', '5px')

            //create label
            const label = addElement(containerForInput, 'label',
                gt(option.name))
            label.attr('for', id)
            label.css('padding', '5px')

            //create checkbox
            const input = addElement(containerForInput, 'input')
            setHtmlId(input, id)
            input.attr('name', setter.getHtmlType + '_' + setter.getFieldName())
            input.css('padding', '5px')
            input.attr('type', setter.getHtmlType)
            setDisable(input, readOnly)

            const isSelected = viewData.find(
                value => setter.equals(value, option.id)) ? true : false
            setChecked2(input, isSelected)

            inputs.push(input)

            onChange(input, () => {
                this.setHasChanged(true)
                const isSelected = isChecked(input)
                if (isSelected) {
                    viewData.push(option.id)
                } else {
                    viewData = viewData.filter(
                        value => !setter.equals(value, option.id))
                }
                setter.fromView(this.currentDto, viewData)
            })
        })
    }

    protected bindSimpleValueToInputFieldAndAddToView<VIEW>(setter: IViewModelConverter<DTO, VIEW>,
                                                            target: JQuery,
                                                            label: string,
                                                            toolTip: any = null,
                                                            readOnlyProvider: ICallback<DTO, boolean> | null = null,
                                                            onChangeHandler: ICallback<DTO, void> | null = null): JQuery {
        //create input field
        const inputId = `id_${setter.getFieldName()}_${new Date().getTime()}_${Math.round(
            Math.random() * 10)}`
        const elementHtml = createNewFromTemplate(
            HTML_FORMULAR_ELEMENT_TEMPLATE)
        const labelHtml = elementHtml.find('.' + HTML_FORMULAR_ELEMENT_LABEL)
        const labelSplitted = label.split(':')
        labelHtml.html(labelSplitted[0])
        if (labelSplitted.length > 1) {
            labelHtml.css('font-size', labelSplitted[1])
        }
        labelHtml.attr('for', inputId)

        const inputHtml = elementHtml.find('.' + HTML_FORMULAR_ELEMENT_INPUT)
        setHtmlId(inputHtml, inputId)
        inputHtml.attr('name', setter.getFieldName)
        inputHtml.attr('type', setter.getHtmlType)

        if (setter.getHtmlType() === HTML_INPUT_TYPE.password) {
            inputHtml.attr('autocomplete', 'new-password')
        }

        if (toolTip) {
            inputHtml.attr('placeholder', toolTip)
            inputHtml.attr('title', toolTip)
            labelHtml.attr('title', toolTip)
        }

        let handleChange: () => void
        switch (setter.getHtmlType()) {
            case HTML_INPUT_TYPE.checkbox:
                const orgStateCheckBox = toBoolean(
                    setter.toView(this.currentDto))
                setChecked2(inputHtml, orgStateCheckBox)
                inputHtml.removeClass()
                handleChange = () => {
                    const newStateCheckBox = isChecked(inputHtml)
                    if (!this.elementIsChanged && newStateCheckBox === orgStateCheckBox) {
                        return
                    }
                    this.setHasChanged(true)

                    // @ts-ignore
                    setter.fromView(this.currentDto, newStateCheckBox)
                    nN(onChangeHandler, this.currentDto)
                }

                break

            case HTML_INPUT_TYPE.number:
                let orgStateNumber = toNumber(setter.toView(this.currentDto))
                    .toFixed(2)
                inputHtml.val(orgStateNumber)
                handleChange = () => {
                    const newState = toNumber(inputHtml.val()).toFixed(2)
                    if (!this.elementIsChanged && newState === orgStateNumber) {
                        return
                    }
                    orgStateNumber = newState
                    this.setHasChanged(true)

                    // @ts-ignore
                    setter.fromView(this.currentDto, newState)
                    nN(onChangeHandler, this.currentDto)
                }
                break

            default:
                const _input = inputHtml[0] as HTMLInputElement
                inputHtml.val(toStringOrEmpty(setter.toView(this.currentDto)))
                let orgState = inputHtml.val()
                handleChange = () => {
                    const newState = inputHtml.val()

                    if (setter.getHtmlType() === HTML_INPUT_TYPE.tel) {
                        _input.value = _input.value.replace(/[^0-9-+]/g, '')
                    } else if (setter.getHtmlType() === HTML_INPUT_TYPE.email) {
                        _input.classList.toggle('invalid',
                            !_input.validity.valid && _input.value.length > 0)
                        this.isValid = !_input.classList.contains('invalid')
                    }

                    if (!this.elementIsChanged && newState === orgState) {
                        return
                    }
                    orgState = newState
                    this.setHasChanged(true)

                    // @ts-ignore
                    setter.fromView(this.currentDto, newState)
                    nN(onChangeHandler, this.currentDto)
                }
                break
        }

        const readOnly = readOnlyProvider ? readOnlyProvider(this.currentDto) :
            false

        if (readOnly) {
            setDisable(inputHtml)
        } else {
            onKeyUp(inputHtml, handleChange)
            onOff(inputHtml, 'focusout', handleChange)
            onChange(inputHtml, handleChange)
        }

        //add to container
        target.append(elementHtml)

        return inputHtml
    }

    protected abstract loadList(callback: ICallback<NameAndId<number>[], void>): void

    protected abstract loadElement(id: number,
                                   callback: ICallback<DTO, void>): void

    protected abstract bindElementToView(dto: DTO): void

    protected abstract getTitle(dto: DTO): string

    protected abstract save(dto: DTO,
                            callback: ICallback<number, void> | null): void

    protected setHasChanged(changed: boolean) {
        this.elementIsChanged = changed
        if (this.isValid) {
            this.getSaveBtnHtml().html("Speichern")
        } else {
            this.getSaveBtnHtml().html(
                "Daten können nicht gespeichert werden!<br>Fehler in der Eingabe!")
        }
        setDisable(this.getSaveBtnHtml(), !(changed && this.isValid))
        setDisable(this.getResetBtnHtml(),
            (!this.currentDto.id || !changed) ? true : false)
    }

    protected getListHtml(): JQuery {
        return $('#' + HTML_FORMULAR_LIST)
    }

    protected getElementContainerHtml(): JQuery {
        return $('#' + HTML_FORMULAR_ELEMENT_CONTAINER)
    }

    protected deselectAll() {
        this.listById.forEach(html => html.removeClass('selected'))
    }

    protected markSelected(id: number) {
        const html = this.listById.get(id)
        if (html) {
            html.addClass('selected')
        }
    }

    protected abstract cloneDto(dto: DTO): DTO

    protected confirmDiscardChanges(): boolean {
        if (!this.elementIsChanged) {
            return true
        }
        return confirmYesNo(
            gt('alerts.discardChanges') ?? 'Änderungen verwerfen?')
    }

    private loadListAndSetDto(setDto: boolean) {
        this.fillList(names => {
            if (!setDto) {
                return
            }
            if (names.length > 0) {
                this.handleElementSelected(names[0].id)
            }
        })
    }

    private getDeleteBtnHtml(): JQuery {
        return $('#' + HTML_FORMULAR_DELETE_BTN)
    }

    private getFilterHtml(): JQuery {
        return $('#' + HTML_FORMULAR_LIST_FILTER)
    }

    private getResetBtnHtml(): JQuery {
        return $('#' + HTML_FORMULAR_RESET_BTN)
    }

    private getSaveBtnHtml(): JQuery {
        return $('#' + HTML_FORMULAR_SAVE_BTN)
    }

    private fillList(callback: ICallback<NameAndId<number>[], void> | null = null) {
        //liste leeren
        this.getListHtml().empty()
        this.listById.clear()
        this.listByName.clear()

        this.loadList(names => {
            names.forEach(name => this.addListElement(name))
            nN(callback, names)
        })
    }

    private addListElement(name: NameAndId<number>) {
        const templateId = name.active ? HTML_FORMULAR_LIST_ENTRY_TEMPLATE :
            HTML_FORMULAR_LIST_ENTRY_DEACTIVE_TEMPLATE
        const listEntry = createNewFromTemplate(templateId)
        listEntry.find('.' + HTML_FORMULAR_LIST_ENTRY_LABEL).text(name.name)
        onClick(listEntry, () => this.handleElementSelected(name.id))
        if (name.id === this.currentDto.id) {
            listEntry.addClass('selected')
        }

        this.getListHtml().append(listEntry)
        this.listById.put(name.id, listEntry)
        this.listByName.put(name.id, listEntry)
    }

    private handleElementSelected(id: number) {
        //check if anything has changed?
        if (!this.confirmDiscardChanges()) {
            return
        }

        //load new element
        this.loadWithNoQuestion(id)
    }

    private loadWithNoQuestion(id: number) {
        this.loadElement(id, this.showElement)
    }

    private saveIfNeeded() {
        if (!this.elementIsChanged) {
            return true
        }
        if (!confirmYesNo(
            gt('alerts.saveChanges') ?? 'Änderungen speichern?')) {
            return false
        }
        this.save(this.currentDto, this.postSaved)
        return true
    }

    private reset() {
        //check if anything has changed?
        if (!this.confirmDiscardChanges()) {
            return
        }
        this.isValid = true;
        this.loadWithNoQuestion(toNumber(this.currentDto.id))
    }

    private filter() {
        const filter = ('' + this.getFilterHtml().val()).toLocaleLowerCase()
        if (filter.length === 0) {
            this.listByName.forEach(html => html.show())
        } else {
            this.listByName.forEach(html => {
                const name = html.find('.' + HTML_FORMULAR_LIST_ENTRY_LABEL)
                    .text() + ''
                if (name.toLocaleLowerCase().indexOf(filter) < 0) {
                    //not found
                    html.hide()
                } else {
                    //found
                    html.show()
                }
            })
        }
    }

    private handleNew() {
        //check if anything has changed?
        if (!this.confirmDiscardChanges()) {
            return
        }
        this.isValid = true;
        this.showElement(this.createNewDto())
    }

    private deleteWithQuestion() {
        if (!this.currentDto.id) {
            return
        }
        const _really = gt('alerts.really') ?? 'Wirklich'
        const _delete = gt('alerts.delete') ?? 'löschen?'
        if (!confirmYesNo(
            `${_really} ${this.getTitle(this.currentDto)} ${_delete}`)) {
            return
        }
        this.delete(this.currentDto, () => this.loadListAndSetDto(true))
    }
}

export abstract class AbstractDtoFormular<DTO extends IAbstractWithIdAndNameDto, PROVIDER extends AbtractDataProviderWithName<DTO>> extends AbstractFormular<DTO> {
    protected provider: PROVIDER

    protected constructor(provider: PROVIDER, newBtnLabel: string,
                          translationKey: string) {
        super(newBtnLabel, translationKey)
        this.provider = provider
    }

    protected cloneDto(source: DTO): DTO {
        const target = cloneWithJson(source)
        this.provider.convert(target)
        return target
    }

    protected isSelected(nameAndId: NameAndId<number>): boolean {
        return this.currentDto.id === nameAndId.id
    }

    protected delete(dto: DTO, success: ICallback<string, void> | null = null) {
        this.provider.delete(toNumber(dto.id), success)
    }

    protected save(dto: DTO, callback: ICallback<number, void> | null) {
        this.provider.save(dto, callback)
    }

    protected loadElement(id: number, callback: ICallback<DTO, void>): void {
        this.provider.load(id, callback)
    }

    protected loadList(callback: ICallback<NameAndId<number>[], void>): void {
        globalstate.refresh(() => this.provider.loadListAllManaged(
            dtos => this.convertToNameAndIds(dtos, callback), true, true));
    }

    protected convertToNameAndIds(dtos: DTO[],
                                  callback: ICallback<NameAndId<number>[], void>) {
        const nameAndIds = dtos.map(
            dto => new NameAndId(this.convertToName(dto), toNumber(dto.id),
                dto.active ? dto.active : false))
        callback(nameAndIds)
    }

    protected convertToName(dto: DTO): string {
        return this.provider.getShortName(dto)
    }

    protected getSubTitle(dto: DTO): JQuery {
        const container = $('<span>')
        container.text(
            `(${dto.customId ?? dto.id}) ${this.provider.getFullName(dto)}`)
        return container
    }
}
