import {customerDataProvider, ICustomerDto} from './customer'
import {
    AbstractWithIdAndNameAndAddressDto,
    AbtractDataProviderWithName,
    HTML_INPUT_TYPE,
    IAbstractWithIdAndNameAndAddressDto,
    IViewModelConverter
} from './model_base'
import {IJsonToObjectConverter} from '../tools/rest'
import {toStringOrEmpty, toBoolean} from '../tools/tools'
import {REST_CONTROLLER} from '../tools/restConsties'

export interface IBuildingDto extends IAbstractWithIdAndNameAndAddressDto {
    customer: ICustomerDto | null
    shortName: string | null
    billingAddresses: boolean | null
    notUserForWork: boolean | null
    userGroupId: number | null
}

export class BuildingDto extends AbstractWithIdAndNameAndAddressDto implements IBuildingDto {
    public static setterBillingAddresses: IViewModelConverter<IBuildingDto, boolean> = {
        getFieldName(): string {
            return 'billingAddresses'
        }, toView(dto: IBuildingDto): boolean {
            return toBoolean(dto.billingAddresses)
        },

        fromView(dto: IBuildingDto, value: boolean): void {
            dto.billingAddresses = value
        },

        getHtmlType(): HTML_INPUT_TYPE {
            return HTML_INPUT_TYPE.checkbox
        }
    }
    public static setterNotUserForWork: IViewModelConverter<IBuildingDto, boolean> = {
        getFieldName(): string {
            return 'notUserForWork'
        }, toView(dto: IBuildingDto): boolean {
            return toBoolean(dto.notUserForWork)
        },

        fromView(dto: IBuildingDto, value: boolean): void {
            dto.notUserForWork = value
        },

        getHtmlType(): HTML_INPUT_TYPE {
            return HTML_INPUT_TYPE.checkbox
        }
    }
    public static setterShortName: IViewModelConverter<IBuildingDto, string> = {
        getFieldName(): string {
            return 'shortName'
        }, toView(dto: IBuildingDto): string {
            return toStringOrEmpty(dto.shortName)
        },

        fromView(dto: IBuildingDto, value: string): void {
            dto.shortName = value
        },

        getHtmlType(): HTML_INPUT_TYPE {
            return HTML_INPUT_TYPE.text
        }
    }
    customer: ICustomerDto | null = null
    billingAddresses: boolean | null = null
    shortName: string | null = null
    notUserForWork: boolean | null = null
    userGroupId: number | null = null
}

export const buildingConverter: IJsonToObjectConverter<IBuildingDto> = {
    convert(source: IBuildingDto | null): IBuildingDto | null {
        if (source && source.customer) {
            source.customer = customerDataProvider.convert(source.customer)
        }
        return source
    }
}

export class BuildingDataProvider extends AbtractDataProviderWithName<IBuildingDto> {

    constructor() {
        super(REST_CONTROLLER.BUILDING)
    }

    protected getUserGlobalState(): boolean {
        return false;
    }

    createDto(): IBuildingDto {
        return new BuildingDto()
    }
}
