import {gtObj} from './translation'

type SelectTranslation = {
	label: string | null
	placeholder: string | null
}

const getSelectTranslations = (select: HTMLElement): SelectTranslation => {
	const _translationAttr = select.dataset.translationSelectKey
	const _translationObj = _translationAttr ? gtObj(_translationAttr) : null
	const _translation = {
		label: _translationObj && _translationObj.label ? _translationObj.label : select.dataset.label ?? null,
		placeholder: _translationObj && _translationObj.placeholder ? _translationObj.placeholder : null
	}

	if (_translation.placeholder) {
		select.setAttribute('placeholder', _translation.placeholder)
	}

	return _translation
}

const createSearchSection = (container: HTMLElement, labels: Map<string, HTMLElement>, _placeholder: string, _isRoot = false) => {
	const searchFilter = (el: HTMLElement, search: string): boolean => {
		const _search = search.toLowerCase().trim()
		const dataValue = el.getAttribute('data-value')?.toLowerCase() ?? ''
		const innerText = el.innerText.toLowerCase()
		return dataValue.includes(_search) || innerText.includes(_search)
	}

	const search = document.createElement('div')
	const searchField = document.createElement('input')

	searchField.setAttribute('placeholder', _placeholder)

	searchField.setAttribute('type', 'search')
	if (!_isRoot) {
		search.classList.add('select-search', 'search')
	} else {
		search.classList.add('select-search-inline')
	}
	searchField.addEventListener(
		'keyup',
		() => {
			const _search = searchField.value
			labels.forEach(label => {
				label.dataset.show = `${searchFilter(label, _search)}`
			})
		},
		{passive: true}
	)
	searchField.addEventListener(
		'search',
		() => {
			const _search = searchField.value
			labels.forEach(label => {
				label.dataset.show = `${searchFilter(label, _search)}`
			})
		},
		{passive: true}
	)
	search.append(searchField)
	container.append(search)
	return searchField
}

const createDropDown = (labels: Map<string, HTMLElement>, multiple: boolean, _translation: SelectTranslation) => {
	const dropdown = document.createElement('div')
	const dropdownInner = document.createElement('div')
	dropdown.classList.add('select-dropdown')
	dropdownInner.classList.add('select-dropdown-inner')
	if (multiple) createSearchSection(dropdownInner, labels, _translation.placeholder ?? '')
	dropdown.append(dropdownInner)
	return {_dropdown: dropdown, dropdown: dropdownInner}
}

const createSelectStructure = (classList: DOMTokenList, parent: HTMLElement): HTMLDivElement => {
	const _select = document.createElement('div')
	classList.forEach(_class => _select.classList.add(_class))
	_select.classList.add('select')
	_select.setAttribute('tabindex', '-1')
	_select.dataset.expand = 'false'
	parent.dataset.open = 'false'

	const toggleContent = (state?: boolean) => {
		const _prev = _select.dataset.expand ? _select.dataset.expand.includes('true') : false
		const _next = typeof state === 'undefined' ? `${!_prev}` : `${state}`
		_select.dataset.expand = _next
		parent.dataset.open = _next
	}

	_select.addEventListener('close', () => toggleContent(false), {passive: true})

	_select.addEventListener('focusin', () => toggleContent(true), {passive: true})

	_select.addEventListener('focusout', () => toggleContent(false), {passive: true})

	return _select
}

const createSelectContent = (
	container: HTMLDivElement,
	_labels: Map<string, HTMLElement>,
	_chosen: Map<string, HTMLElement>,
	options: NodeListOf<HTMLOptionElement>,
	_select: HTMLElement,
	multiple = true
) => {
	const _translation = getSelectTranslations(_select)
	const headLabel = _translation.label ?? ''
	const _fieldsId = `select-option-${Math.floor(Math.random() * 1000 + 50)}`
	const _field = multiple ? `input[type="checkbox"]` : `input[type="radio"]`
	const {_dropdown, dropdown} = createDropDown(_labels, multiple, _translation)
	const content = document.createElement('div')
	container.append(_dropdown)

	if (multiple) {
		content.classList.add('select-content')
		container.append(content)
	} else {
		const _searchField = createSearchSection(container, _labels, _translation.placeholder ?? '', true)
		container.addEventListener('focus', () => {
			_searchField.focus()
		})

		_searchField.addEventListener('search', () => {
			if (_searchField.value.length === 0) {
				_select.dispatchEvent(new CustomEvent('clearField'))
			}
		})

		_select.addEventListener('selectChanged', ((e: CustomEvent) => {
			if (!e.detail || !e.detail.text) return
			const _text = e.detail.text
			_searchField.value = `${_text}`
		}) as EventListener)
	}

	const updateSingleSelect = ({key, item}: {key: string; item: HTMLElement}) => {
		if (!item) return
		const _textElem = item.querySelector('.select-option-text') as HTMLElement
		const checkbox = item.querySelector('input[type="radio"]') as HTMLInputElement
		if (checkbox) {
			checkbox.checked = true
			container.dispatchEvent(new CustomEvent('close'))
			_select.dispatchEvent(new CustomEvent('selectChanged', {detail: {key: key, text: _textElem.textContent}}))
		}
	}

	const updateSelect = (clicked: boolean) => {
		options.forEach(option => {
			if (option.hasAttribute('disabled')) return

			const _prevState = option.getAttribute('selected') === 'true'
			const _key = option.getAttribute('value')
			if (_key && _chosen.has(_key)) {
				option.setAttribute('selected', 'true')
				if (!_prevState && clicked) {
					option.dispatchEvent(new CustomEvent('selectChanged'))
				}
			} else if (option.hasAttribute('selected')) {
				option.removeAttribute('selected')
				if (_prevState && clicked) {
					option.dispatchEvent(new CustomEvent('selectChanged'))
				}
			}
		})
	}

	const updateChosen = () => {
		if (!_labels.size) return

		_labels.forEach((label, key) => {
			const _checkbox = label.querySelector(`${_field}`) as HTMLInputElement
			if (!_checkbox) return

			const _checked = _checkbox.checked

			if (!_checked && _chosen.has(key)) {
				_chosen.delete(key)
			} else if (_checked) {
				_chosen.set(key, label)
			}
		})
	}

	const selectionClear = () => {
		_labels.forEach(label => {
			const _checkbox = label.querySelector(`${_field}`) as HTMLInputElement
			if (!_checkbox) return
			_checkbox.checked = false
		})

		updateChosen()
		updateSelect(false)
		container.dispatchEvent(new CustomEvent('close'))
	}

	_select.addEventListener('clearField', selectionClear)

	const onCheckClick = (clicked = false) => {
		content.innerHTML = ''

		updateChosen()

		multiple &&
			_chosen.forEach((label, key) => {
				const _btn = document.createElement('button')
				const _clone = document.createElement('span')
				const _span = document.createElement('span')
				_clone.dataset.key = `${key}`
				_clone.classList.add('select-content-item')
				_span.innerText = label.dataset.short ?? label.innerText
				_btn.innerText = 'x'

				_btn.addEventListener(
					'click',
					() => {
						const option = _labels.get(key)
						if (option) {
							const _optionCheckbox = option.querySelector(`${_field}`) as HTMLInputElement
							_optionCheckbox.checked = false
						}
						_chosen.delete(key)
						_clone.remove()
						updateSelect(true)
					},
					{once: true}
				)
				_clone.append(_span)
				_clone.append(_btn)
				content.append(_clone)
			})

		updateSelect(clicked)
	}

	const observer = new MutationObserver(mutations => {
		mutations.forEach(mutation => {
			if (mutation.type === 'attributes') {
				updateChosen()
				if (_chosen && _chosen.size > 0) {
					setTimeout(() => {
						if (!_chosen || !_chosen.size) return
						const [key, item] = _chosen.entries().next().value

						updateSingleSelect({key, item})
					}, 30)
				}
			}
		})
	})

	if (!options.length) return

	if (container.getAttribute('data-init') !== 'true' && !multiple) {
		options.forEach(option => {
			observer.observe(option, {
				attributes: true
			})
		})
	}

	const labelSection = document.createElement('div')
	labelSection.classList.add('select-labels')

	options.forEach(option => {
		if (option.hasAttribute('disabled')) return

		const label = document.createElement('label')
		const text = document.createElement('span')
		const check = document.createElement('input')
		const box = document.createElement('span')
		check.setAttribute('name', `${_fieldsId}`)
		check.setAttribute('type', multiple ? 'checkbox' : 'radio')

		check.checked = option.selected

		check.addEventListener('change', () => onCheckClick(true))

		text.classList.add('select-option-text')
		box.classList.add('select-option-box')
		label.classList.add('select-option')
		label.dataset.value = option.value

		if (option.dataset.short) {
			label.dataset.short = option.dataset.short
		}

		text.innerText = option.innerText

		label.append(check)
		label.append(box)
		label.append(text)
		_labels.set(option.value, label)
		labelSection.append(label)
	})

	onCheckClick()

	const headerContent = document.createElement('div')
	const footerContent = document.createElement('div')

	if (headLabel.length > 0) {
		const headerTitle = document.createElement('h6')
		headerTitle.classList.add('select-header-title')
		headerTitle.innerText = headLabel
		headerContent.append(headerTitle)
	}

	headerContent.classList.add('select-header')
	footerContent.classList.add('select-footer')

	const manageButton = document.createElement('button')
	manageButton.classList.add('select-manage-button')
	//manageButton.innerText = gt('modal.multiselect.button.manage')
	manageButton.addEventListener(
		'click',
		() => {
			container.dataset.expand = 'false'
		},
		{passive: true}
	)
	footerContent.append(manageButton)

	dropdown.append(headerContent)
	dropdown.append(labelSection)
	dropdown.append(footerContent)
}

const _initSelectElement = (select: HTMLElement, multiple = true) => {
	const _labels = new Map()
	const _chosen = new Map()

	const options = select.querySelectorAll('option')
	const parent = select.parentNode as HTMLElement
	if (select.getAttribute('data-init') === 'true') {
		const _prev = parent.querySelector('div.select')
		if (_prev) _prev.remove()
	}

	const div = createSelectStructure(select.classList, parent)
	createSelectContent(div, _labels, _chosen, options, select, multiple)

	parent.append(div)
	parent.classList.toggle('multiple-select', true)
	select.setAttribute('data-init', 'true')
}

export const initMultiSelect = (elem: JQuery<HTMLElement>) => {
	const select: HTMLElement = elem[0]
	if (!select) return
	const _isMultiple = select.hasAttribute('multiple')

	_initSelectElement(select, _isMultiple)
}

const _destroySelectElement = (select: HTMLElement) => {
	const parent = select.parentNode as HTMLElement
	parent.classList.toggle('multiple-select', false)
	if (select.getAttribute('data-init') === 'true') {
		const _prev = parent.querySelector('div.select')
		if (_prev) _prev.remove()
		select.setAttribute('data-init', 'false')
	}
}

export const destroyMultiSelect = (elem: JQuery<HTMLElement>) => {
	const select: HTMLElement = elem[0]
	if (!select) return

	_destroySelectElement(select)
}

export const initMultiSelectLegacy = (selector: string) => {
	const _search = document.querySelectorAll(`${selector}`)
	if (!_search.length) return

	const _selectList = [..._search].map(_ => _ as HTMLElement)
	_selectList.forEach(select => _initSelectElement(select))
}
