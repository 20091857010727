import {CachedDto} from "./model_base";
import {
    IUserTypeDto, UserTypeDataProvider, userTypeDataProvider
} from "./usertype";
import {ArrayJsonObjectConverter, executeRestCallJson} from "../tools/rest";
import {
    isOnline, REST_CONTROLLER, REST_METHODS, REST_OPERATION
} from "../tools/restConsties";
import {IUserDto, UserDataProvider, userDataProvider} from "./user";
import {
    ITaskTypeDto, TaskTypeDataProvider, taskTypeDataProvider
} from "./tasktype";
import {
    ITaskTypeAbbreviationDto,
    TaskTypeAbbreviationDataProvider,
    taskTypeAbbreviationDataProvider
} from "./tasktypeAbbreviation";
import {CompanyDataProvider, companyDataProvider, ICompanyDto} from "./company";
import {
    CalendarSettingsDataProvider,
    calendarSettingsDataProvider,
    ICalendarSettingsDto
} from "./calendar_settings";
import {
    IUserGroupDto, UserGroupDataProvider, userGroupDataProvider
} from "./usergroup";
import {
    IJobGroupDto, JobGroupDataProvider, jobGroupDataProvider
} from "./job_group";
import {
    CustomerDataProvider, customerDataProvider, ICustomerDto
} from "./customer";
import {WorkerAwayDataProvider} from "./worker_away";
import {ActiveJobDataProvider, activeJobDataProvider} from "./active_job";
import {JobDataProvider, jobDataProvider} from "./job";
import moment from "moment";
import {nN} from "../tools/tools";

class GlobalStateRequest {
    public userTypesHash: number = -1;
    public userHimSelfHash: number = -1;
    public usersManagedInternalHash: number = -1;
    public taskTypesHash: number = -1;
    public taskTypeAbbreviationsHash: number = -1;
    public companyHash: number = -1;
    public calendarSettingsHash: number = -1;
    public jobGroupsHash: number = -1;
    public userGroupsHash: number = -1;
    public customersHash: number = -1;
}

interface IGlobalStateResponse {
    userTypes: CachedDto<IUserTypeDto[]>
    userHimSelf: CachedDto<IUserDto>
    usersManagedInternal: CachedDto<IUserDto[]>
    taskTypes: CachedDto<ITaskTypeDto[]>
    taskTypeAbbreviations: CachedDto<ITaskTypeAbbreviationDto[]>
    company: CachedDto<ICompanyDto>
    calendarSettings: CachedDto<ICalendarSettingsDto>
    jobGroups: CachedDto<IJobGroupDto[]>
    userGroups: CachedDto<IUserGroupDto[]>
    customers: CachedDto<ICustomerDto[]>
}

class Globalstate {
    public userTypeDataProvider!: UserTypeDataProvider;
    public userDataProvider!: UserDataProvider;
    public taskTypeDataProvider!: TaskTypeDataProvider;
    public taskTypeAbbreviationDataProvider!: TaskTypeAbbreviationDataProvider;
    public companyDataProvider!: CompanyDataProvider;
    public calendarSettingsDataProvider!: CalendarSettingsDataProvider;
    public customerDataProvider!: CustomerDataProvider;
    public userGroupDataProvider!: UserGroupDataProvider;
    public jobGroupDataProvider!: JobGroupDataProvider;
    public workerAwayDataProvider!: WorkerAwayDataProvider;
    public activeJobDataProvider!: ActiveJobDataProvider;
    public jobDataProvider!: JobDataProvider;

    private callbacks: Function[] = []
    private isRunning = false;
    public ready = false;
    private lastRun: Date | null = null;

    public executeIfReady(callback: Function): void {
        if (this.ready) {
            callback();
            return;
        }
        this.refresh(callback)
    }


    public refresh(callback: Function | null = null, force:boolean = false): void {
        if (!isOnline()) {
            throw Error("Not online!");
        }

        if (!force){
            if (this.lastRun) {
                if (moment(this.lastRun).add(10, "seconds").isAfter(new Date())) {
                    console.log("globle state ... to young, skip refresh");
                    nN(callback);
                    return;
                }
            }
        }

        console.log("Refresh: " + this.isRunning)
        if (callback) {
            this.callbacks.push(callback)
        }
        if (this.isRunning) {
            return
        }
        this.isRunning = true;
        let request = {
            userTypesHash: this.userTypeDataProvider.loadListAllCache?.hash,
            userHimSelfHash: this.userDataProvider.current ?
                this.userDataProvider.current.hash : -1,
            usersManagedInternalHash: this.userDataProvider.loadListAllInternalManagedCache?.hash,
            taskTypesHash: this.taskTypeDataProvider.loadListAllCache?.hash,
            taskTypeAbbreviationsHash: this.taskTypeAbbreviationDataProvider.loadListAllCache?.hash,
            companyHash: this.companyDataProvider.current ?
                this.companyDataProvider.current?.hash : -1,
            calendarSettingsHash: this.calendarSettingsDataProvider.current ?
                this.calendarSettingsDataProvider.current?.hash : -1,
            jobGroupsHash: this.jobGroupDataProvider.loadListAllManagedCache?.hash,
            userGroupsHash: this.userGroupDataProvider.loadListAllManagedCache?.hash,
            customersHash: this.customerDataProvider.loadListAllManagedCache?.hash
        } as GlobalStateRequest

        executeRestCallJson(REST_CONTROLLER.GLOBAL_STATE, REST_OPERATION.NONE,
            REST_METHODS.POST, null, (response: IGlobalStateResponse) => {
                this.lastRun = new Date();

                //user types
                if (!response.userTypes.useCache) {
                    response.userTypes.content = new ArrayJsonObjectConverter(
                        this.userTypeDataProvider).convert(
                        response.userTypes.content)
                    this.userTypeDataProvider.loadListAllCache = response.userTypes
                }

                //user himself
                if (!response.userHimSelf.useCache) {
                    response.userHimSelf.content = this.userDataProvider.convert(
                        response.userHimSelf.content)
                    this.userDataProvider.current = response.userHimSelf
                }

                //users
                if (!response.usersManagedInternal.useCache) {
                    response.usersManagedInternal.content = new ArrayJsonObjectConverter(
                        this.userDataProvider).convert(
                        response.usersManagedInternal.content)
                    this.userDataProvider.loadListAllInternalManagedCache = response.usersManagedInternal
                }

                //task types
                if (!response.taskTypes.useCache) {
                    response.taskTypes.content = new ArrayJsonObjectConverter(
                        this.taskTypeDataProvider).convert(
                        response.taskTypes.content)
                    this.taskTypeDataProvider.loadListAllCache = response.taskTypes
                }

                //task type abbreviation
                if (!response.taskTypeAbbreviations.useCache) {
                    response.taskTypeAbbreviations.content = new ArrayJsonObjectConverter(
                        this.taskTypeAbbreviationDataProvider).convert(
                        response.taskTypeAbbreviations.content)
                    this.taskTypeAbbreviationDataProvider.loadListAllCache = response.taskTypeAbbreviations
                }

                //company
                if (!response.company.useCache) {
                    response.company.content = this.companyDataProvider.convert(
                        response.company.content)
                    this.companyDataProvider.current = response.company
                }

                //calendarSettings
                if (!response.calendarSettings.useCache) {
                    response.calendarSettings.content = this.calendarSettingsDataProvider.convert(
                        response.calendarSettings.content)
                    this.calendarSettingsDataProvider.current = response.calendarSettings
                }

                //customers
                if (!response.customers.useCache) {
                    response.customers.content = new ArrayJsonObjectConverter(
                        this.customerDataProvider).convert(
                        response.customers.content)
                    this.customerDataProvider.loadListAllManagedCache = response.customers
                }

                //userGroups
                if (!response.userGroups.useCache) {
                    response.userGroups.content = new ArrayJsonObjectConverter(
                        this.userGroupDataProvider).convert(
                        response.userGroups.content)
                    this.userGroupDataProvider.loadListAllManagedCache = response.userGroups
                }

                //jobGroups
                if (!response.jobGroups.useCache) {
                    response.jobGroups.content = new ArrayJsonObjectConverter(
                        this.jobGroupDataProvider).convert(
                        response.jobGroups.content)
                    this.jobGroupDataProvider.loadListAllManagedCache = response.jobGroups
                }
                this.ready = true;
                this.executeCallbacks();
            }, () => {
                this.callbacks = []
                this.isRunning = false
            }, request)
    }

    private executeCallbacks() {
        this.callbacks.reverse().forEach(callback => callback())
        this.callbacks = []
        this.isRunning = false
    }

    private autoRefreshIsRunning = false;

    public startAutoRefresh() {
        if (this.autoRefreshIsRunning) {
            return;
        }
        this.autoRefreshIsRunning = true;
        this.autoRefresh();
    }

    private autoRefresh() {
        if (!isOnline()) {
            this.autoRefreshIsRunning = false;
            return;
        }
        this.refresh(() => {
            setTimeout(() => this.autoRefresh(), 60000)
        })
    }

    clearCache() {
        this.ready = false;
        this.lastRun = null;
        this.userTypeDataProvider.clearCache();
        this.userDataProvider.clearCache();
        this.taskTypeDataProvider.clearCache();
        this.taskTypeAbbreviationDataProvider.clearCache();
        this.companyDataProvider.clearCache();
        this.calendarSettingsDataProvider.clearCache();
        this.jobGroupDataProvider.clearCache();
        this.userGroupDataProvider.clearCache();
        this.customerDataProvider.clearCache();
        this.activeJobDataProvider.clearCache();
        this.jobDataProvider.clearCache();
    }
}

export const globalstate = new Globalstate()