import {AbstractListPage, GrouppedDto} from './abstract_list_page'
import {
    activeJobDataProvider, ActiveJobDataProvider, ActiveJobDto, IActiveJobDto
} from '../../model/active_job'
import {AbstractEditModal} from '../modals/abstract_edit_modal'
import {activeJobModal} from '../modals/kalendar/active_job_modal'
import {
    convertDateToTimeNumber,
    getDayInWeekFromDays,
    HashMap,
    ICallback,
    mustNotNull,
    toDecimalHour,
    toNumber,
    toStringOrEmpty
} from '../../tools/tools'
import {convertDateToDayOfWeek, DayOfWeek} from '../../model/dayofweek'
import {
    isChecked, onClick, onClickRemove, onEnter, setChecked2, setText
} from '../../tools/templateTools'
import {createNewFromTemplate} from '../../tools/templates'
import {IActiveUserJobDto} from '../../model/active_user_job'
import {Tuple} from '../../model/model_base'
import moment from 'moment'
import {gt} from '../../tools/translation'
import {containsIgnoreCase, encodeHtml} from '../../tools/StringTools'
import {Events} from '../../tools/calendar_widget'
import {csvStr, downloadFile, DownloadFileTypes} from "../../tools/file_tools";

const HTML_ID_EVALUATIONS = 'evaluations-page'
const HTML_ID_EVALUATIONS_TBODY = HTML_ID_EVALUATIONS + '-rows'
const HTML_ID_EVALUATIONS_ROW_TEMPLATE = HTML_ID_EVALUATIONS + '-row-template'

const URL_REPORT_MA = 'ma'
const HEADER_REPORT_REQUEST = 'tim-report-request'

enum EvaluationsGrouping {
    none = "none", job = "job", customer = "customer", object = "object"
}

export class EvaluationsListPage extends AbstractListPage<IActiveJobDto, ActiveJobDataProvider> {
    private dateFilterStart: Date
    private dateFilterEnd: Date
    private userIds = new HashMap<IActiveUserJobDto>();
    private workTimeSume = 0;
    private currentCsvFileName: string = "not-set";
    private currentDataAsCsv: string = "not-set";

    constructor() {
        super(activeJobDataProvider, HTML_ID_EVALUATIONS_TBODY,
            HTML_ID_EVALUATIONS_ROW_TEMPLATE, HTML_ID_EVALUATIONS)
        this.dateFilterStart = getDayInWeekFromDays(new Date(),
            DayOfWeek.MONDAY)
        this.dateFilterEnd = getDayInWeekFromDays(new Date(), DayOfWeek.SUNDAY)
    }

    protected getTemplateIds(): string[] {
        return [];
    }

    public initAfterHtmlLoaded(callback: Function | null = null) {
        this.getFilterStartCalendar().dispatchEvent(
            new CustomEvent(Events.DATE_SELECTED,
                {detail: {date: this.dateFilterStart}}))
        this.getFilterEndCalendar().dispatchEvent(
            new CustomEvent(Events.DATE_SELECTED,
                {detail: {date: this.dateFilterEnd}}))
        onClick($(`#${HTML_ID_EVALUATIONS}-filter-btn`), () => this.fillTable())
        onEnter(this.getFilterHtml(), () => this.fillTable())

        //csv download
        onClick(this.getCsvDownloadBtnHtml(), this.startCsvDownloadBtnHtml)

        //overview btn
        onClick(this.getOverviewBtnHtml(), () => {
            this.checkGroupingRadio(EvaluationsGrouping.customer);
            this.getFilterHtml().val("");
            this.fillTable();
        })

        onClick($(`#${HTML_ID_EVALUATIONS}-filter-this-week-btn`), () => {
            this.dateFilterStart = getDayInWeekFromDays(new Date(),
                DayOfWeek.MONDAY)
            this.dateFilterEnd = getDayInWeekFromDays(new Date(),
                DayOfWeek.SUNDAY)
            this.getFilterStartCalendar().dispatchEvent(
                new CustomEvent(Events.DATE_SELECTED,
                    {detail: {date: this.dateFilterStart}}))
            this.getFilterEndCalendar().dispatchEvent(
                new CustomEvent(Events.DATE_SELECTED,
                    {detail: {date: this.dateFilterEnd}}))
            this.fillTable()
        })


        super.initAfterHtmlLoaded(callback)
    }


    protected createSingleRow(dto: IActiveJobDto): Tuple<number, JQuery>[] {
        let result: Tuple<number, JQuery>[] = [];
        result.push(new Tuple<number, JQuery>(
            dto.date ? toNumber(dto.date.getTime()) : dto.id!,
            this.createRow(dto)))
        return result
    }

    public getShortObjectOrAuth(job: any) {
        if (!job) {
            return ''
        }

        let result = ''
        if (job.customId) {
            result += job.customId
        }
        if (job.building.name) {
            result += job.building.name
        }
        return result
    }


    protected loadList(success: ICallback<IActiveJobDto[], void>) {
        const _startVal = `${this.getFilterStart().val()}`
        const _endVal = `${this.getFilterEnd().val()}`
        const _startDate = _startVal.split('.').reverse().join('/')
        const _endDate = _endVal.split('.').reverse().join('/')
        this.dateFilterStart = new Date(_startDate)
        this.dateFilterEnd = new Date(_endDate)
        let filterStr = toStringOrEmpty(this.getFilterHtml().val());

        this.currentDataAsCsv = ""
        const groupingName = this.getGroupingName();
        this.currentCsvFileName = "TIM_Auswertung" //
            + (groupingName.length > 0 ? "_" + groupingName : "") //
            + (filterStr.length > 0 ? "_" + encodeURIComponent(filterStr) : "") //filter
            + "_" + _startDate + "-" + _endDate; //dates

        this.dataProvider.loadListBetweenDate(this.dateFilterStart,
            this.dateFilterEnd, result => {
                let activeJobDtos = result.activeJobDtos;

                //do not change cache for displaying
                activeJobDtos
                    .forEach(aJ => {
                        if (aJ.activeUserJobsTemp) {
                            aJ.activeUserJobsTemp = [...aJ.activeUserJobs!];
                        }
                        aJ.customerTemp = aJ.job!.customer;
                        aJ.buildingTemp = aJ.job!.building;
                    });

                if (filterStr.length > 0) {
                    filterStr = filterStr.toLowerCase();
                    activeJobDtos = activeJobDtos.filter(aJ => {
                        if (!aJ.job) {
                            return false
                        }
                        if (containsIgnoreCase(aJ.job.customId, filterStr)) {
                            return true;
                        }
                        if (containsIgnoreCase(aJ.job.customer!.name,
                            filterStr)) {
                            return true;
                        }
                        if (containsIgnoreCase(aJ.job.customer!.customId,
                            filterStr)) {
                            return true;
                        }
                        if (containsIgnoreCase(aJ.job.building!.name,
                            filterStr)) {
                            return true;
                        }
                        if (containsIgnoreCase(aJ.job.building!.customId,
                            filterStr)) {
                            return true;
                        }
                        return false;
                    })
                }

                //collect visible ma
                this.userIds.clear()
                activeJobDtos.forEach(aJ => {
                    if (!aJ.activeUserJobsTemp) {
                        return
                    }
                    aJ.activeUserJobsTemp.forEach(aUJ => {
                        this.userIds.put(aUJ.userId, aUJ)
                    })
                })
                success(activeJobDtos)
            })
    }

    protected getFullName(dto: IActiveJobDto): string {
        return mustNotNull(dto.job).customId + ''
    }

    protected getModal(): AbstractEditModal<IActiveJobDto, ActiveJobDataProvider> {
        return activeJobModal
    }

    protected fillTableEntry(dto: IActiveJobDto, tableEntry: JQuery): void {
        throw Error('Not supported!')
    }

    private getFilterStart(): JQuery {
        return $(`#${HTML_ID_EVALUATIONS}-filter-start`)
    }

    private getFilterEnd(): JQuery {
        return $(`#${HTML_ID_EVALUATIONS}-filter-end`)
    }

    private getFilterStartCalendar() {
        return document.querySelector(
            `#${HTML_ID_EVALUATIONS}-filter-start-calendar`) as HTMLElement
    }

    private getFilterEndCalendar() {
        return document.querySelector(
            `#${HTML_ID_EVALUATIONS}-filter-end-calendar`) as HTMLElement
    }


    private getFilterHtml(): JQuery {
        return $('#' + HTML_ID_EVALUATIONS + '-filter-obj-aufNr')
    }

    private getOverviewBtnHtml(): JQuery {
        return $('#' + HTML_ID_EVALUATIONS + '-overview-btn')
    }

    private getCsvDownloadBtnHtml(): JQuery {
        return $('#' + HTML_ID_EVALUATIONS + '-csv-download-btn')
    }

    private getGroupingRadioHtml(type: EvaluationsGrouping): JQuery {
        return $(`#${HTML_ID_EVALUATIONS}-grouping-radio-` + type)
    }

    private checkGroupingRadio(type: EvaluationsGrouping) {
        setChecked2(this.getGroupingRadioHtml(EvaluationsGrouping.job),
            type === EvaluationsGrouping.job);
        setChecked2(this.getGroupingRadioHtml(EvaluationsGrouping.customer),
            type === EvaluationsGrouping.customer);
        setChecked2(this.getGroupingRadioHtml(EvaluationsGrouping.object),
            type === EvaluationsGrouping.object);
        setChecked2(this.getGroupingRadioHtml(EvaluationsGrouping.none),
            type === EvaluationsGrouping.none);
    }

    private getGroupping(): EvaluationsGrouping {
        if (isChecked(
            this.getGroupingRadioHtml(EvaluationsGrouping.customer))) {
            return EvaluationsGrouping.customer;
        }
        if (isChecked(this.getGroupingRadioHtml(EvaluationsGrouping.job))) {
            return EvaluationsGrouping.job;
        }
        if (isChecked(this.getGroupingRadioHtml(EvaluationsGrouping.object))) {
            return EvaluationsGrouping.object;
        }

        return EvaluationsGrouping.none;
    }

    private getGroupingName(): string {
        switch (this.getGroupping()) {
            case EvaluationsGrouping.none:
                return "";
            case EvaluationsGrouping.job:
                return "Auftrag";
            case EvaluationsGrouping.customer:
                return "Kunde";
            case EvaluationsGrouping.object:
                return "Objekt";

        }
        return "unknown";
    }

    protected groupDtos(dtos: IActiveJobDto[]): HashMap<GrouppedDto<IActiveJobDto>> | null {
        const grouppingType = this.getGroupping();

        if (grouppingType == EvaluationsGrouping.none) {
            dtos.forEach(dto => {
                dto.trafficTimeTemp = 0;
                dto.activeUserJobs?.forEach(aUJ => {
                    if (dto.withAway && aUJ.awayHours) {
                        dto.trafficTimeTemp! += aUJ.awayHours;
                    }
                    if (dto.withWayBack && aUJ.wayBackHours) {
                        dto.trafficTimeTemp! += aUJ.wayBackHours;
                    }
                })
            });
            return null;
        }

        let result = new HashMap<GrouppedDto<IActiveJobDto>>();
        //group by job
        dtos.forEach(dto => {
            let groupKey: number;
            switch (grouppingType) {
                case EvaluationsGrouping.job:
                    groupKey = dto.job!.id!;
                    break;
                case EvaluationsGrouping.customer:
                    groupKey = dto.job!.customer!.id!;
                    break;
                case EvaluationsGrouping.object:
                    groupKey = dto.job!.building!.id!;
                    break;

            }
            let group = result!.get(groupKey);
            if (!group) {
                group = new GrouppedDto<IActiveJobDto>();
                result!.put(groupKey, group);
            }
            group.dtos.push(dto);

        });
        return result;
    }

    protected createGroupRow(groupDto: GrouppedDto<IActiveJobDto>): Tuple<number, JQuery>[] {
        const temp = new ActiveJobDto()

        temp.date = null;
        switch (this.getGroupping()) {
            case EvaluationsGrouping.job:
                temp.job = groupDto.dtos[0].job;
                temp.tasks = groupDto.dtos[0].job!.tasks
                temp.comment = groupDto.dtos[0].job!.comment
                temp.customerTemp = groupDto.dtos[0].job!.customer;
                temp.buildingTemp = groupDto.dtos[0].job!.building;
                break;
            case EvaluationsGrouping.customer:
                temp.customerTemp = groupDto.dtos[0].job!.customer;
                break;
            case EvaluationsGrouping.object:
                temp.buildingTemp = groupDto.dtos[0].job!.building;
                temp.customerTemp = groupDto.dtos[0].job!.customer;
                break;

        }
        temp.workHoursBookedTemp = 0;
        temp.trafficTimeTemp = 0;
        groupDto.dtos.forEach(aJ => {
            let workHours = 0;
            aJ.activeUserJobs?.forEach(aUJ => {
                let startTime = convertDateToTimeNumber(aUJ.startTime)
                let endTime = convertDateToTimeNumber(aUJ.endTime, true)
                workHours += endTime - startTime

                if (aJ.withAway && aUJ.awayHours) {
                    temp.trafficTimeTemp! += aUJ.awayHours;
                }
                if (aJ.withWayBack && aUJ.wayBackHours) {
                    temp.trafficTimeTemp! += aUJ.wayBackHours;
                }
            });
            temp.workHoursBookedTemp! += workHours;
        });

        return this.createSingleRow(temp);
    }

    private createRow(dto: IActiveJobDto): JQuery {
        let groupping = this.getGroupping();
        let rowHtml = createNewFromTemplate(this.templateTabbleEntyHtmlId)
        const firstLine = this.currentDataAsCsv.length === 0;
        let headLineCsv = "";

        //date
        let dateHtml = rowHtml.find('.' + HTML_ID_EVALUATIONS + '-row-date')
        if (dto.date) {
            const dayOfWeek = convertDateToDayOfWeek(dto.date);
            const dateStr = moment(dto.date).format('DD.MM.YY');
            dateHtml.text(gt(dayOfWeek + '-short') + ' ' + dateStr)

            this.currentDataAsCsv += dateStr + ";"; //csv
            headLineCsv += "Datum;";
        } else {
            dateHtml.text("");
        }

        const customerIdHtml = rowHtml.find(
            '.' + HTML_ID_EVALUATIONS + "-" + 'row-customer-customId');
        const customerNameHtml = rowHtml.find(
            '.' + HTML_ID_EVALUATIONS + "-" + 'row-customer-name');
        if (dto.customerTemp) {
            const showJobDetails = () => {
                this.checkGroupingRadio(EvaluationsGrouping.job);
                this.getFilterHtml().val("" + dto.customerTemp!.name);
                this.fillTable();
            }
            //customer
            //customer - custom id
            headLineCsv += "KdN;";
            this.currentDataAsCsv += csvStr(dto.customerTemp.customId) + ";"; //csv
            customerIdHtml.text("" + dto.customerTemp.customId);
            onClick(customerIdHtml, showJobDetails);
            customerIdHtml.addClass("clickable");

            //customer - custom name
            headLineCsv += "Auftraggeber;";
            this.currentDataAsCsv += csvStr(dto.customerTemp.name) + ";"; //csv
            customerNameHtml.text("" + dto.customerTemp.name);
            onClick(customerNameHtml, showJobDetails);
            customerNameHtml.addClass("clickable");
        }

        if (dto.job) {
            //job customId
            this.setText(rowHtml, 'row-job-customId', dto.job.customId)
            headLineCsv += "AufNr.;";
            this.currentDataAsCsv += csvStr(dto.job.customId) + ";"; //csv
        }

        if (dto.buildingTemp) {
            //object - customId
            headLineCsv += "ObjNr.;";
            this.currentDataAsCsv += csvStr(dto.buildingTemp.customId) + ";"; //csv
            this.setText(rowHtml, 'row-objekt-customId',
                dto.buildingTemp.customId);

            //object - name
            headLineCsv += "Objekt;";
            this.currentDataAsCsv += csvStr(dto.buildingTemp.name) + ";"; //csv
            this.setText(rowHtml, 'row-objekt-name', dto.buildingTemp.name);
        }

        if (groupping === EvaluationsGrouping.none || groupping === EvaluationsGrouping.job) {
            //tasks
            let tasksHtml = rowHtml.find(
                '.' + HTML_ID_EVALUATIONS + '-row-tasks-names')
            let taskNames = ''
            let taskNamesCsv = ''
            dto.tasks?.forEach(task => {
                if (taskNames.length > 0) {
                    taskNames += '<br>'
                }
                taskNamesCsv += csvStr(task.name) + (taskNamesCsv.length < 0 ?
                    " " : ""); //csv
                taskNames += encodeHtml(task.name);
            })
            tasksHtml.html(taskNames)
            headLineCsv += "LNr. / Leistungsart;";
            this.currentDataAsCsv += taskNamesCsv + ";"; //csv

            //details
            headLineCsv += "Leistungsdetails;";
            this.currentDataAsCsv += csvStr(dto.comment) + ";"; //csv
            this.setText(rowHtml, 'row-job-comment', dto.comment);
        }


        //time
        if (!dto.workHoursBookedTemp) {
            dto.workHoursBookedTemp = (dto as ActiveJobDto).calculateBookedTime();
        }
        const workHoursBookedAsStr = toDecimalHour(
            dto.workHoursBookedTemp! + dto.trafficTimeTemp!);
        headLineCsv += "Std.\n";
        this.currentDataAsCsv += workHoursBookedAsStr; //csv
        this.setText(rowHtml, 'row-work-time', workHoursBookedAsStr);
        this.workTimeSume += dto.workHoursBookedTemp! + dto.trafficTimeTemp!;

        this.currentDataAsCsv += "\n"; //csv

        if (firstLine) {
            this.currentDataAsCsv = headLineCsv + this.currentDataAsCsv;
        }
        return rowHtml
    }

    setText(element: JQuery, query: string, value: any): JQuery {
        return setText(element, '.' + HTML_ID_EVALUATIONS + "-" + query, value);
    }

    customStartWorkUpdateTable() {
        this.workTimeSume = 0;
    }

    customEndWorkUpdateTable() {
        //sum
        let workTimeSumStr = toDecimalHour(this.workTimeSume);
        $('#' + HTML_ID_EVALUATIONS + "-" + 'row-work-time-sum')
            .text(workTimeSumStr);
    }

    private startCsvDownloadBtnHtml() {
        this.fillTable(
            () => downloadFile(this.currentCsvFileName, DownloadFileTypes.CSV,
                this.currentDataAsCsv));
    }
}

export const evaluationsListPage = new EvaluationsListPage()

//summe zeiten