import {AbstractListPage, GrouppedDto} from './abstract_list_page'
import {
    activeJobDataProvider, ActiveJobDataProvider, IActiveJobDto
} from '../../model/active_job'
import {AbstractEditModal} from '../modals/abstract_edit_modal'
import {activeJobModal} from '../modals/kalendar/active_job_modal'
import {
    convertDateToInputFormat,
    convertDateToTimeNumber,
    convertDateToTimeStr,
    convertTimeNumberToTimeStr,
    convertTimeStrToTimeNumber,
    getDayInWeekFromDays,
    toStringOrEmpty,
    HashMap,
    ICallback,
    mustNotNull,
    toDecimalHour,
    toNumber
} from '../../tools/tools'
import {convertDateToDayOfWeek, DayOfWeek} from '../../model/dayofweek'
import {addSpan, isChecked, onClick, onEnter} from '../../tools/templateTools'
import {createNewFromTemplate} from '../../tools/templates'
import {newOrderModal} from '../modals/kalendar/new-order'
import {maHinzufuegenModal} from '../modals/kalendar/ma_hinzufuegen_modal'
import {
    getShortNameFromWorker, IActiveUserJobDto
} from '../../model/active_user_job'
import {loadCalendarWeekView} from '../page_manager'
import {getMainPhone, Tuple} from '../../model/model_base'
import moment from 'moment'
import {gt} from '../../tools/translation'
import {deleteJobModal} from '../modals/kalendar/deleteJobModal'
import {
    containsIgnoreCase, encodeHtml, toFixedNumber
} from '../../tools/StringTools'
import {openInNewTab} from '../../tools/rest'
import {selectMaForEinsatzReport} from '../modals/selectMaForEinsatzReport'
import {Events} from '../../tools/calendar_widget'
import {IUserDto, userDataProvider, UserDto} from "../../model/user";
import {AWAY_TYPE, IWorkerAwayDto} from "../../model/worker_away";
import {handleWorkerAwayModalOpen} from "../modals/kalendar/worker_away_modal";
import {TimeTools} from "../../tools/time_tools";
import {downloadFile, DownloadFileTypes} from "../../tools/file_tools";

const HTML_ID_EINSAETZE = 'einsaetze-page'
const HTML_ID_EINSAETZE_TBODY = HTML_ID_EINSAETZE + '-rows'
const HTML_ID_EINSAETZE_ROW_TEMPLATE = HTML_ID_EINSAETZE + '-row-template'
const HTML_ID_EINSAETZE_ROW_AWAY_TEMPLATE = HTML_ID_EINSAETZE + '-row-away-template'

const URL_REPORT_MA = 'ma'
const HEADER_REPORT_REQUEST = 'tim-report-request'

class ActiveUserJobsReportRequest {
    userId: number
    startDate: Date
    endDate: Date
    password: string | null

    constructor(userId: number, startDate: Date, endDate: Date,
                password: string | null) {
        this.userId = userId
        this.startDate = startDate
        this.endDate = endDate
        this.password = password
    }
}

export class EinsaetzeListPage extends AbstractListPage<IActiveJobDto, ActiveJobDataProvider> {
    private dateFilterStart: Date
    private dateFilterEnd: Date
    private userIds = new HashMap<IActiveUserJobDto>()
    private workTimeSume = 0;
    private currentCsvFileName: string = "not-set"
    private currentDataAsCsv: string = "not-set";

    constructor() {
        super(activeJobDataProvider, HTML_ID_EINSAETZE_TBODY,
            HTML_ID_EINSAETZE_ROW_TEMPLATE, HTML_ID_EINSAETZE)
        this.dateFilterStart = getDayInWeekFromDays(new Date(),
            DayOfWeek.MONDAY)
        this.dateFilterEnd = getDayInWeekFromDays(new Date(), DayOfWeek.SUNDAY)
    }

    protected getTemplateIds(): string[] {
        return [HTML_ID_EINSAETZE_ROW_AWAY_TEMPLATE];
    }

    public initAfterHtmlLoaded(callback: Function | null = null) {
        this.getFilterStartCalendar().dispatchEvent(
            new CustomEvent(Events.DATE_SELECTED,
                {detail: {date: this.dateFilterStart}}))
        this.getFilterEndCalendar().dispatchEvent(
            new CustomEvent(Events.DATE_SELECTED,
                {detail: {date: this.dateFilterEnd}}))
        onClick($(`#${HTML_ID_EINSAETZE}-filter-btn`), () => this.fillTable())
        onEnter(this.getFilterWorkerHtml(), () => this.fillTable())
        onEnter(this.getFilterObjAufnr(), () => this.fillTable())
        onClick($(`#${HTML_ID_EINSAETZE}-filter-this-week-btn`), () => {
            this.dateFilterStart = getDayInWeekFromDays(new Date(),
                DayOfWeek.MONDAY)
            this.dateFilterEnd = getDayInWeekFromDays(new Date(),
                DayOfWeek.SUNDAY)
            this.getFilterStartCalendar().dispatchEvent(
                new CustomEvent(Events.DATE_SELECTED,
                    {detail: {date: this.dateFilterStart}}))
            this.getFilterEndCalendar().dispatchEvent(
                new CustomEvent(Events.DATE_SELECTED,
                    {detail: {date: this.dateFilterEnd}}))
            this.fillTable()
        })

        onClick($('#einsaetze-page-filter-add-job-btn'), () => {
            newOrderModal.openDialogForNew()
        })

        //csv download
        onClick(this.getCsvDownloadBtnHtml(), this.startCsvDownloadBtnHtml)

        onClick(this.getPdfBtnHtml(), () => {
            let activeUserJobDtos = this.userIds.toArray()
            if (activeUserJobDtos.length < 1) {
                return
            }
            if (activeUserJobDtos.length === 1) {
                this.LoadReportSingleMa(activeUserJobDtos[0].userId)
                return
            }
            this.OpenMaSelectForReport(activeUserJobDtos)
        })

        super.initAfterHtmlLoaded(callback)
    }

    customStartWorkUpdateTable() {
        this.workTimeSume = 0;
    }

    private OpenMaSelectForReport(activeUserJobDtos: IActiveUserJobDto[]) {
        selectMaForEinsatzReport.showDialogWithData(activeUserJobDtos,
            userId => {
                this.LoadReportSingleMa(userId)
            })
    }

    private LoadReportSingleMa(userId: number) {
        let reportRequest = new ActiveUserJobsReportRequest(userId,
            this.dateFilterStart, this.dateFilterEnd, null)

        const myHeaders = new Headers()
        let reportRequestJson = JSON.stringify(reportRequest)
        let reportRequestBase64 = btoa(reportRequestJson)
        myHeaders.append(HEADER_REPORT_REQUEST, reportRequestBase64)

        openInNewTab('/reports/v1/' + URL_REPORT_MA, myHeaders)
    }

    protected createSingleRow(dto: IActiveJobDto): Tuple<number, JQuery>[] {
        let result: Tuple<number, JQuery>[] = []
        if (dto.activeUserJobsTemp && dto.activeUserJobsTemp.length > 0) {
            dto.activeUserJobsTemp.forEach(activeUserJob => {
                let dateIndexStr = convertDateToInputFormat(
                    mustNotNull(dto.date)) + ' ' + convertDateToTimeStr(
                    mustNotNull(activeUserJob.startTime))
                let dateIndex = new Date(dateIndexStr)
                result.push(
                    new Tuple<number, JQuery>(toNumber(dateIndex.getTime()),
                        this.createMaRow(dto, activeUserJob)))
            })
        } else {
            result.push(new Tuple<number, JQuery>(
                toNumber(mustNotNull(dto.date).getTime()),
                this.createMaRow(dto, null)))
        }

        return result
    }

    public getShortObjectOrAuth(job: any) {
        if (!job) {
            return ''
        }

        let result = ''
        if (job.customId) {
            result += job.customId
        }
        if (job.building.name) {
            result += job.building.name
        }
        return result
    }


    protected loadList(success: ICallback<IActiveJobDto[], void>) {
        const _startVal = `${this.getFilterStart().val()}`
        const _endVal = `${this.getFilterEnd().val()}`
        const _startDate = _startVal.split('.').reverse().join('/')
        const _endDate = _endVal.split('.').reverse().join('/')
        this.dateFilterStart = new Date(_startDate)
        this.dateFilterEnd = new Date(_endDate)

        this.currentDataAsCsv = ""
        this.currentCsvFileName = "TIM_Einsaetze_" + _startDate + "-" + _endDate;

        this.dataProvider.loadListBetweenDate(this.dateFilterStart,
            this.dateFilterEnd, result => {
                let activeJobDtos = result.activeJobDtos

                let filterWorker = toStringOrEmpty(
                    this.getFilterWorkerHtml().val())
                    .toLocaleLowerCase()
                let filterWorkerIsUsed = filterWorker.trim().length > 0;
                //do not change cache for displaying
                activeJobDtos.forEach(
                    aJ => aJ.activeUserJobsTemp = filterWorkerIsUsed ?
                        (aJ.activeUserJobs ? [...aJ.activeUserJobs] : null) :
                        aJ.activeUserJobs);

                //nur ungeplante Einsätze
                if (this.getOnlyJobsWithNoWorkerAssigned()) {
                    activeJobDtos = activeJobDtos.filter(aJ => {
                        if (!aJ.activeUserJobsTemp || aJ.activeUserJobsTemp.length === 0) {
                            return true
                        }
                        return false
                    })
                }

                let filterObjAuth = toStringOrEmpty(
                    this.getFilterObjAufnr().val()).toLocaleLowerCase()
                if (filterObjAuth.length > 0) {
                    activeJobDtos = activeJobDtos.filter(aJ => {
                        if (!aJ.job) {
                            return false
                        }
                        return this.getShortObjectOrAuth(aJ.job)
                            .toLocaleLowerCase().indexOf(filterObjAuth) > -1
                    })
                }
                if (filterWorkerIsUsed) {
                    activeJobDtos = activeJobDtos.filter(aJ => {
                        if (!aJ.activeUserJobsTemp || aJ.activeUserJobsTemp.length === 0) {
                            return false
                        }
                        aJ.activeUserJobsTemp = aJ.activeUserJobsTemp.filter(
                            aUJ => {

                                return getShortNameFromWorker(aUJ)
                                    .toLocaleLowerCase()
                                    .indexOf(filterWorker) > -1
                            })
                        return aJ.activeUserJobsTemp.length > 0
                    })
                }

                //collect visible ma
                this.userIds.clear()
                activeJobDtos.forEach(aJ => {
                    if (!aJ.activeUserJobsTemp) {
                        return
                    }
                    aJ.activeUserJobsTemp.forEach(aUJ => {
                        this.userIds.put(aUJ.userId, aUJ)
                    })
                })
                success(activeJobDtos)
            })
    }

    private getOnlyJobsWithNoWorkerAssigned() {
        return isChecked($('#einsaetze-page-filter-open-job'));
    }

    protected getFullName(dto: IActiveJobDto): string {
        return mustNotNull(dto.job).customId + ''
    }

    protected getModal(): AbstractEditModal<IActiveJobDto, ActiveJobDataProvider> {
        return activeJobModal
    }

    protected fillTableEntry(dto: IActiveJobDto, tableEntry: JQuery): void {
        throw Error('Not supported!')
    }

    private getFilterStart(): JQuery {
        return $(`#${HTML_ID_EINSAETZE}-filter-start`)
    }

    private getFilterEnd(): JQuery {
        return $(`#${HTML_ID_EINSAETZE}-filter-end`)
    }

    private getFilterStartCalendar() {
        return document.querySelector(
            `#${HTML_ID_EINSAETZE}-filter-start-calendar`) as HTMLElement
    }

    private getFilterEndCalendar() {
        return document.querySelector(
            `#${HTML_ID_EINSAETZE}-filter-end-calendar`) as HTMLElement
    }

    private getFilterWorkerHtml(): JQuery {
        return $('#' + HTML_ID_EINSAETZE + '-filter')
    }

    private getFilterObjAufnr(): JQuery {
        return $('#' + HTML_ID_EINSAETZE + '-filter-obj-aufNr')
    }

    private getPdfBtnHtml(): JQuery {
        return $('#einsaetze-page-pdf')
    }

    private getCsvDownloadBtnHtml(): JQuery {
        return $('#einsaetze-page-csv')
    }

    customEndWorkUpdateTable() {
        //sum
        $('#' + HTML_ID_EINSAETZE + "-" + 'row-work-time-sum')
            .text(toDecimalHour(this.workTimeSume));
    }

    protected addCustomRows(rows: Tuple<number, JQuery>[]) {
        //filter only jobs with no worker assigned
        if (this.getOnlyJobsWithNoWorkerAssigned()) {
            return;
        }

        //filter object
        if ((this.getFilterObjAufnr().val() + "").length > 0) {
            return;
        }

        let workers = userDataProvider.loadListAllInternalManagedCache?.content!;

        //filter ma
        let workerNameFilter = this.getFilterWorkerHtml().val() + "";
        if (workerNameFilter.length > 0) {
            workers = workers.filter(worker =>//
                containsIgnoreCase(worker.customId, workerNameFilter) || //
                containsIgnoreCase(worker.firstname, workerNameFilter) || //
                containsIgnoreCase(worker.lastname, workerNameFilter))
        }
        workers.filter(worker => worker.aways)
            .forEach(worker => worker.aways!.forEach(
                away => this.addAwayRows(rows, worker, away)));
    }

    private addAwayRows(rows: Tuple<number, JQuery>[], worker: IUserDto,
                        away: IWorkerAwayDto) {
        let date = away.startDate!
        let dateAsMoment = moment(date);
        let endDateAsMoment = moment(away.endDate);
        while (dateAsMoment.isSameOrBefore(endDateAsMoment)) {
            if (dateAsMoment.isSameOrAfter(
                this.dateFilterStart) && dateAsMoment.isSameOrBefore(
                this.dateFilterEnd)) {
                rows.push({
                    value1: toNumber(mustNotNull(date).getTime()),
                    value2: this.createAwayRow(worker, away, date)
                } as Tuple<number, JQuery>);
            }
            dateAsMoment = dateAsMoment.add(1, "days");
            date = dateAsMoment.toDate();
        }
    }

    private createAwayRow(worker: IUserDto, away: IWorkerAwayDto,
                          date: Date): JQuery {
        //create row
        const rowAwayHtml = createNewFromTemplate(
            HTML_ID_EINSAETZE_ROW_AWAY_TEMPLATE);

        //date
        const dateHtml = rowAwayHtml.find(
            '.' + HTML_ID_EINSAETZE + '-row-away-date')
        const dayOfWeek = convertDateToDayOfWeek(date)
        dateHtml.text(gt(dayOfWeek + '-short') + ' ' + moment(date)
            .format('DD.MM.YY'))

        //start/end
        const startTimeHtml = rowAwayHtml.find(
            '.' + HTML_ID_EINSAETZE + '-row-away-start')
        const endTimeHtml = rowAwayHtml.find(
            '.' + HTML_ID_EINSAETZE + '-row-away-end')
        let commentType: String;
        let timeHtmlStr = "";
        let weekWorkTimePerDay = UserDto.setterWorkHoursPerWeek.toView(
            worker) / 5;

        let time = 0;
        if (away.fullDay) {
            startTimeHtml.text('')
            endTimeHtml.text('')

            const days = TimeTools.diffDays(away.startDate!, away.endDate!);
            commentType = "ganztägig";
            if (away.awayType == AWAY_TYPE.HOLIDAY || away.awayType == AWAY_TYPE.ILLNESS) {
                time = weekWorkTimePerDay;
                timeHtmlStr = toDecimalHour(time);
            }
        } else {
            startTimeHtml.text(convertDateToTimeStr(away.startDate))
            endTimeHtml.text(convertDateToTimeStr(away.endDate))
            commentType = "halber Tag";
            if (away.awayType == AWAY_TYPE.HOLIDAY || away.awayType == AWAY_TYPE.ILLNESS) {
                time = weekWorkTimePerDay / 2;
                timeHtmlStr = toDecimalHour(time);
            }
        }
        // time
        const timeHtml = rowAwayHtml.find(
            '.' + HTML_ID_EINSAETZE + '-row-away-time')
        timeHtml.text(timeHtmlStr)
        this.workTimeSume += time;

        // comment
        const commentHtml = rowAwayHtml.find(
            '.' + HTML_ID_EINSAETZE + '-row-away-comment')
        commentHtml.text(
            commentType + (away.comment ? "; " + away.comment : ""))

        //type
        const typeHtml = rowAwayHtml.find(
            '.' + HTML_ID_EINSAETZE + '-row-away-type');
        typeHtml.text(gt(away.awayType!));


        //worker name
        const maNameHtml = rowAwayHtml.find(
            '.' + HTML_ID_EINSAETZE + '-row-away-ma-name')
        maNameHtml.text(getShortNameFromWorker(worker))

        //worker customId
        const maCustomIdHtml = rowAwayHtml.find(
            '.' + HTML_ID_EINSAETZE + '-row-away-ma-customId')
        maCustomIdHtml.text("PN: " + worker.customId!)

        //action - jump to week
        const viewWeekBtnHtml = rowAwayHtml.find(
            '.' + HTML_ID_EINSAETZE + '-row-away-view-week-btn')
        onClick(viewWeekBtnHtml, () => loadCalendarWeekView(date))

        //action - edit away
        const editAwayBtnHtml = rowAwayHtml.find(
            '.' + HTML_ID_EINSAETZE + '-row-away-edit-btn')
        onClick(editAwayBtnHtml,
            () => handleWorkerAwayModalOpen(null, worker, away,
                () => this.fillTable()))

        return rowAwayHtml;
    }

    private createMaRow(dto: IActiveJobDto,
                        activeUserJob: IActiveUserJobDto | null): JQuery {
        if (!dto.date || !dto.job) {
            throw new Error()
        }
        const firstLine = this.currentDataAsCsv.length === 0;
        let headLineCsv = "";

        let rowHtml = createNewFromTemplate(this.templateTabbleEntyHtmlId)

        let dateHtml = rowHtml.find('.' + HTML_ID_EINSAETZE + '-row-date')
        let dayOfWeek = convertDateToDayOfWeek(dto.date)
        let dateStr = gt(dayOfWeek + '-short') + ' ' + moment(dto.date)
            .format('DD.MM.YY');
        dateHtml.text(dateStr)
        this.currentDataAsCsv += moment(dto.date).format('DD.MM.YY') + ";"; //csv
        headLineCsv += "Datum;";

        let startHtml = rowHtml.find(
            '.' + HTML_ID_EINSAETZE + '-row-work-start')
        startHtml.text('')
        let endHtml = rowHtml.find('.' + HTML_ID_EINSAETZE + '-row-work-end')
        endHtml.text('')

        let timeHtml = rowHtml.find('.' + HTML_ID_EINSAETZE + '-row-work-time')
        timeHtml.text('')

        if (activeUserJob) {
            let startTime = convertDateToTimeNumber(activeUserJob.startTime)
            let endTime = convertDateToTimeNumber(activeUserJob.endTime, true)
            let time = endTime - startTime
            let startTimeStr = convertTimeNumberToTimeStr(startTime);
            startHtml.text(startTimeStr)
            let endTimeStr = convertTimeNumberToTimeStr(endTime);
            endHtml.text(endTimeStr)
            let awayHoursStr = "";
            let wayBackHoursStr = "";
            if (dto.withAway && activeUserJob.awayHours && activeUserJob.awayHours > 0) {
                time += activeUserJob.awayHours
                awayHoursStr = toFixedNumber(activeUserJob.awayHours);
                startHtml.html(startHtml.text() + '<br>(' + awayHoursStr + ')')
            }
            if (dto.withWayBack && activeUserJob.wayBackHours && activeUserJob.wayBackHours > 0) {
                time += activeUserJob.wayBackHours
                wayBackHoursStr = toFixedNumber(activeUserJob.wayBackHours);
                endHtml.html(endHtml.text() + '<br>(' + wayBackHoursStr + ')')
            }
            let workTimeStr = toFixedNumber(time);
            timeHtml.text(workTimeStr)
            this.workTimeSume += time;
            this.currentDataAsCsv += startTimeStr + ";" + awayHoursStr + ";" + endTimeStr + ";" + wayBackHoursStr + ";" + workTimeStr + ";"; //csv
        } else {
            this.workTimeSume += dto.workHours ? dto.workHours : 0;
            let workTimeStr = toFixedNumber(dto.workHours);
            timeHtml.text(workTimeStr)
            let alertSymbol = addSpan(timeHtml, ' !!!')
            alertSymbol.css('color', 'red')
            timeHtml.attr('title', 'noch keine Mitarbeiter eingeplant')

            this.currentDataAsCsv += ";;;;" + workTimeStr + ";"; //csv
        }
        headLineCsv += "Von;Fahrtweg (Von);Bis;Fahrtweg (Bis);Std.;";

        let jobCustomIdHtml = rowHtml.find(
            '.' + HTML_ID_EINSAETZE + '-row-job-customId')
        let jobCustomIdStr = toStringOrEmpty(dto.job.customId);
        jobCustomIdHtml.text(jobCustomIdStr)
        this.currentDataAsCsv += jobCustomIdStr + ";"; //csv
        headLineCsv += "AufNr.;";

        let maNameHtml = rowHtml.find('.' + HTML_ID_EINSAETZE + '-row-ma-name')
        let maName = getShortNameFromWorker(activeUserJob);
        maNameHtml.text(maName);
        this.currentDataAsCsv += maName + ";"; //csv
        headLineCsv += "Mitarbeiter;";

        let objektNameHtml = rowHtml.find(
            '.' + HTML_ID_EINSAETZE + '-row-objekt-name')

        const arrToStr = (_arr: Array<string | null | undefined>): string => _arr.filter(
            _item => (!!_item ? _item : '')).join(' ')

        const getObjectFullDescription = (): string[] => {
            if (!dto.job) return []

            let _fieldsRaw = [dto.job.building.name,
                dto.job.building.address?.streetAndNr,
                [dto.job.building.address?.zipCode,
                    dto.job.building.address?.city], 'hr',
                [dto.job.contact?.firstname, dto.job.contact?.lastname],
                getMainPhone(dto.job.contact?.address)] as string[];

            _fieldsRaw = _fieldsRaw
                .map(_field => (!_field ? '' :
                    typeof _field === 'string' ? _field : arrToStr(_field)))
                .filter(str => str.length > 0)
                .map(_field => encodeHtml(_field))
                .map(_field => _field === "hr" ? "<hr>" : _field);


            return _fieldsRaw;
        }

        const transformDescriptionIntoHtml = (_description: Array<string>): string => {
            if (!_description.length) return ''
            const _list = _description.map(
                _item => (_item.includes('<hr>') ? _item :
                    `<span>${_item}</span>`))
            if (_list.at(-1)?.includes('<hr>')) {
                const _key = 'page.einsaetze.noContact'
                const _text = gt(_key)
                _list.push(
                    `<span data-translation-key="${_key}">${_text}</span>`)
            }
            return _list.join('\n')
        }


        headLineCsv += "Objekt;";
        let objDescription = '' + encodeHtml(dto.job.building.name)
        if (isChecked($('#einsaetze-page-filter-address'))) {
            const _objDescription = ['<div class="table-multirow">', '',
                '</div>']
            const _description = transformDescriptionIntoHtml(
                getObjectFullDescription())

            if (_description.length) {
                _objDescription[1] = _description
            }

            objDescription = _objDescription.join('\n')

            let objectWithAddress = [dto.job.building.name,
                dto.job.building.address?.streetAndNr,
                [dto.job.building.address?.zipCode,
                    dto.job.building.address?.city],
                [dto.job.contact?.firstname, dto.job.contact?.lastname],
                getMainPhone(dto.job.contact?.address)] as string[];
            this.currentDataAsCsv += objectWithAddress.map(
                _field => (!_field ? '' :
                    typeof _field === 'string' ? _field : arrToStr(_field)))
                .filter(str => str.length > 0).join(", ") + ";"; //csv
        } else {
            this.currentDataAsCsv += dto.job.building.name + ";"; //csv
        }
        objektNameHtml.html(objDescription)

        let tasksHtml = rowHtml.find(
            '.' + HTML_ID_EINSAETZE + '-row-tasks-names')
        let taskNamesForHtml = ''
        dto.tasks?.forEach(task => {
            if (!task) {
                return
            }
            if (taskNamesForHtml.length > 0) {
                taskNamesForHtml += '<br>'
            }
            taskNamesForHtml += encodeHtml(task.name);
            this.currentDataAsCsv += task.name; //csv
        })
        tasksHtml.html(taskNamesForHtml)
        this.currentDataAsCsv += ";"; //csv
        headLineCsv += "Leistung;";

        let commentHtml = rowHtml.find(
            '.' + HTML_ID_EINSAETZE + '-row-job-comment')
        let jobDescriptionStr = dto.comment ? dto.comment + '' : "";
        commentHtml.text(jobDescriptionStr)
        this.currentDataAsCsv += jobDescriptionStr; //csv
        headLineCsv += "Leistungsdetails";


        let editJobBtnHtml = rowHtml.find(
            '.' + HTML_ID_EINSAETZE + '-row-edit-job-btn')
        onClick(editJobBtnHtml, () => newOrderModal.openDialogForEdit(dto.job))

        let editActiveJobBtnHtml = rowHtml.find(
            '.' + HTML_ID_EINSAETZE + '-row-edit-activjob-btn')
        onClick(editActiveJobBtnHtml,
            () => activeJobModal.openDialogForEdit(dto))

        let editActiveUserJobBtnHtml = rowHtml.find(
            '.' + HTML_ID_EINSAETZE + '-row-edit-activjob-ma-btn')
        onClick(editActiveUserJobBtnHtml,
            () => maHinzufuegenModal.openDialogForEdit(dto))

        let viewWeekBtnHtml = rowHtml.find(
            '.' + HTML_ID_EINSAETZE + '-row-view-week-btn')
        onClick(viewWeekBtnHtml, () => loadCalendarWeekView(dto.date))

        let deleteBtnHtml = rowHtml.find(
            '.' + HTML_ID_EINSAETZE + '-row-delete-btn')
        onClick(deleteBtnHtml,
            () => deleteJobModal.showDialogWithData(dto, () => {
                this.fillTable()
            }))


        this.currentDataAsCsv += "\n"; //csv
        if (firstLine) {
            this.currentDataAsCsv = headLineCsv + "\n" + this.currentDataAsCsv;
        }

        return rowHtml
    }

    private startCsvDownloadBtnHtml() {
        this.fillTable(
            () => downloadFile(this.currentCsvFileName, DownloadFileTypes.CSV,
                this.currentDataAsCsv));
    }

}

export const einsaetzeListPage = new EinsaetzeListPage()
