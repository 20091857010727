import {
    arrayConvert,
    executeRestCall,
    executeRestCallJson,
    nullJsonObjectConverter,
    ParentChildIdContainer
} from '../tools/rest'
import {toStringOrEmpty, ICallback, toBoolean} from '../tools/tools'
import {
    AbstractWithIdAndNameAndAddressDto,
    AbtractDataProviderWithName,
    AddressDto,
    HTML_INPUT_TYPE,
    IAbstractWithIdAndNameAndAddressDto,
    IAbstractWithIdAndNameDto,
    IViewModelConverter,
    IViewModelConverterWithOptions
} from './model_base'
import {
    GenderEnum, GenderNameId, IUserDto, userDataProvider, UserDto
} from './user'
import {BuildingDto, IBuildingDto} from './building'
import {NameAndId} from '../pages/formulare/formular'
import {
    REST_CONTROLLER, REST_METHODS, REST_OPERATION
} from '../tools/restConsties'
import {globalstate} from "./globalstate";

export enum CustomerType {
    COMPANY = 'COMPANY', PERSON = 'PERSON'
}

export function toCustomerType(name: any): CustomerType | null {
    if (name == null) {
        return null
    }
    return CustomerType[name as keyof typeof CustomerType]
}

export const CustomerTypeNameId = [new NameAndId<CustomerType>(
    CustomerType.COMPANY, CustomerType.COMPANY, true),
    new NameAndId<CustomerType>(CustomerType.PERSON, CustomerType.PERSON, true)]
export const CustomerTypesAll = [CustomerType.COMPANY, CustomerType.PERSON]

export interface ICustomerDto extends IAbstractWithIdAndNameAndAddressDto {
    website: string | null
    taxId: string | null
    mainContactPerson: IUserDto | null
    customerType: CustomerType | null
    contactPersons: IUserDto[] | null
    buildings: IBuildingDto[] | null
}

export class CustomerDto extends AbstractWithIdAndNameAndAddressDto implements ICustomerDto {
    public static setterWebsite: IViewModelConverter<ICustomerDto, string> = {
        getFieldName(): string {
            return 'website'
        }, toView(dto: ICustomerDto): string {
            return toStringOrEmpty(dto.website)
        },

        fromView(dto: ICustomerDto, value: string): void {
            dto.website = value
        },

        getHtmlType(): HTML_INPUT_TYPE {
            return HTML_INPUT_TYPE.url
        }
    }
    public static setterTaxId: IViewModelConverter<ICustomerDto, string> = {
        getFieldName(): string {
            return 'taxId'
        }, toView(dto: ICustomerDto): string {
            return toStringOrEmpty(dto.taxId)
        },

        fromView(dto: ICustomerDto, value: string): void {
            dto.taxId = value
        },

        getHtmlType(): HTML_INPUT_TYPE {
            return HTML_INPUT_TYPE.text
        }
    }

    public static setterMainContactPersonGender: IViewModelConverterWithOptions<ICustomerDto, GenderEnum | null> = {
        getFieldName(): string {
            return 'mainContactPersonGender'
        }, toView(dto: ICustomerDto): GenderEnum | null {
            if (dto.mainContactPerson) {
                return dto.mainContactPerson.gender ?
                    dto.mainContactPerson.gender : null
            } else {
                return null
            }
        },

        fromView(dto: ICustomerDto, value: GenderEnum | null): void {
            if (!dto.mainContactPerson) {
                dto.mainContactPerson = new UserDto()
            }
            dto.mainContactPerson.gender = value
        },

        getHtmlType(): HTML_INPUT_TYPE {
            return HTML_INPUT_TYPE.radio
        },

        getOptions(): NameAndId<GenderEnum>[] {
            return GenderNameId
        }
    }

    public static setterMainContactPersonFirstName: IViewModelConverter<ICustomerDto, string> = {
        getFieldName(): string {
            return 'mainContactPersonNameFirstName'
        }, toView(dto: ICustomerDto): string {
            if (dto.mainContactPerson) {
                return toStringOrEmpty(dto.mainContactPerson.firstname)
            } else {
                return ''
            }
        },

        fromView(dto: ICustomerDto, value: string): void {
            if (!dto.mainContactPerson) {
                dto.mainContactPerson = new UserDto()
            }
            dto.mainContactPerson.firstname = value
        },

        getHtmlType(): HTML_INPUT_TYPE {
            return HTML_INPUT_TYPE.text
        }
    }
    public static setterMainContactPersonLastName: IViewModelConverter<ICustomerDto, string> = {
        getFieldName(): string {
            return 'mainContactPersonNameLastName'
        }, toView(dto: ICustomerDto): string {
            if (dto.mainContactPerson) {
                return toStringOrEmpty(dto.mainContactPerson.lastname)
            } else {
                return ''
            }
        },

        fromView(dto: ICustomerDto, value: string): void {
            if (!dto.mainContactPerson) {
                dto.mainContactPerson = new UserDto()
            }
            dto.mainContactPerson.lastname = value
        },

        getHtmlType(): HTML_INPUT_TYPE {
            return HTML_INPUT_TYPE.text
        }
    }

    public static setterMainContactPersonPosition: IViewModelConverter<ICustomerDto, string> = {
        getFieldName(): string {
            return 'mainContactPersonPosition'
        }, toView(dto: ICustomerDto): string {
            if (dto.mainContactPerson) {
                return toStringOrEmpty(dto.mainContactPerson.position)
            } else {
                return ''
            }
        },

        fromView(dto: ICustomerDto, value: string): void {
            if (!dto.mainContactPerson) {
                dto.mainContactPerson = new UserDto()
            }
            dto.mainContactPerson.position = value
        },

        getHtmlType(): HTML_INPUT_TYPE {
            return HTML_INPUT_TYPE.text
        }
    }

    public static setterMainContactPersonComment: IViewModelConverter<ICustomerDto, string> = {
        getFieldName(): string {
            return 'mainContactPersonComment'
        }, toView(dto: ICustomerDto): string {
            if (dto.mainContactPerson) {
                return toStringOrEmpty(dto.mainContactPerson.comment)
            } else {
                return ''
            }
        },

        fromView(dto: ICustomerDto, value: string): void {
            if (!dto.mainContactPerson) {
                dto.mainContactPerson = new UserDto()
            }
            dto.mainContactPerson.comment = value
        },

        getHtmlType(): HTML_INPUT_TYPE {
            return HTML_INPUT_TYPE.text
        }
    }

    public static setterMainContactPersonAsMain: IViewModelConverter<ICustomerDto, boolean> = {
        getFieldName(): string {
            return 'mainContact'
        }, toView(dto: ICustomerDto): boolean {
            return toBoolean(dto.mainContactPerson?.mainContact)
        },

        fromView(dto: ICustomerDto, value: boolean): void {
            if (!dto.mainContactPerson) {
                dto.mainContactPerson = new UserDto()
            }
            dto.mainContactPerson.mainContact = value
        },

        getHtmlType(): HTML_INPUT_TYPE {
            return HTML_INPUT_TYPE.checkbox
        }
    }

    public static setterMainContactPersonPhone: IViewModelConverter<ICustomerDto, string> = {
        getFieldName(): string {
            return 'mainContactPersonPhone'
        }, toView(dto: ICustomerDto): string {
            if (dto.mainContactPerson) {
                return toStringOrEmpty(dto.mainContactPerson.address?.phone)
            } else {
                return ''
            }
        },

        fromView(dto: ICustomerDto, value: string): void {
            if (!dto.mainContactPerson) {
                dto.mainContactPerson = new UserDto()
            }
            if (!dto.mainContactPerson.address) {
                dto.mainContactPerson.address = new AddressDto()
            }
            dto.mainContactPerson.address.phone = value
        },

        getHtmlType(): HTML_INPUT_TYPE {
            return HTML_INPUT_TYPE.tel
        }
    }

    public static setterMainContactPersonPhone2: IViewModelConverter<ICustomerDto, string> = {
        getFieldName(): string {
            return 'mainContactPersonPhone2'
        }, toView(dto: ICustomerDto): string {
            if (dto.mainContactPerson) {
                return toStringOrEmpty(dto.mainContactPerson.address?.phone2)
            } else {
                return ''
            }
        },

        fromView(dto: ICustomerDto, value: string): void {
            if (!dto.mainContactPerson) {
                dto.mainContactPerson = new UserDto()
            }
            if (!dto.mainContactPerson.address) {
                dto.mainContactPerson.address = new AddressDto()
            }
            dto.mainContactPerson.address.phone2 = value
        },

        getHtmlType(): HTML_INPUT_TYPE {
            return HTML_INPUT_TYPE.tel
        }
    }

    public static setterMainContactPersonEmail: IViewModelConverter<ICustomerDto, string> = {
        getFieldName(): string {
            return 'mainContactPersonEmail'
        }, toView(dto: ICustomerDto): string {
            if (dto.mainContactPerson) {
                return toStringOrEmpty(dto.mainContactPerson.address?.email)
            } else {
                return ''
            }
        },

        fromView(dto: ICustomerDto, value: string): void {
            if (!dto.mainContactPerson) {
                dto.mainContactPerson = new UserDto()
            }
            if (!dto.mainContactPerson.address) {
                dto.mainContactPerson.address = new AddressDto()
            }
            dto.mainContactPerson.address.email = value
        },

        getHtmlType(): HTML_INPUT_TYPE {
            return HTML_INPUT_TYPE.email
        }
    }

    public static setterMainContactPersonFax: IViewModelConverter<ICustomerDto, string> = {
        getFieldName(): string {
            return 'mainContactPersonFax'
        }, toView(dto: ICustomerDto): string {
            if (dto.mainContactPerson) {
                return toStringOrEmpty(dto.mainContactPerson.address?.fax)
            } else {
                return ''
            }
        },

        fromView(dto: ICustomerDto, value: string): void {
            if (!dto.mainContactPerson) {
                dto.mainContactPerson = new UserDto()
            }
            if (!dto.mainContactPerson.address) {
                dto.mainContactPerson.address = new AddressDto()
            }
            dto.mainContactPerson.address.fax = value
        },

        getHtmlType(): HTML_INPUT_TYPE {
            return HTML_INPUT_TYPE.tel
        }
    }

    public static setterCustomerType: IViewModelConverterWithOptions<ICustomerDto, CustomerType | null> = {
        getFieldName(): string {
            return 'customerType'
        }, toView(dto: ICustomerDto): CustomerType | null {
            return dto.customerType
        },

        fromView(dto: ICustomerDto, value: CustomerType | null): void {
            dto.customerType = value
        },

        getHtmlType(): HTML_INPUT_TYPE {
            return HTML_INPUT_TYPE.radio
        },

        getOptions(): NameAndId<CustomerType>[] {
            return CustomerTypeNameId
        }
    }
    website: string | null = null
    taxId: string | null = null
    mainContactPerson: IUserDto | null = null
    customerType: CustomerType | null = null

    contactPersons: IUserDto[] | null = null
    buildings: IBuildingDto[] | null = null
}

export class CustomerDataProvider extends AbtractDataProviderWithName<ICustomerDto> {
    constructor() {
        super(REST_CONTROLLER.CUSTOMER)
    }

    public setMainContact(customerId: number, userId: number,
                          success: ICallback<string, void> | null = null) {
        executeRestCall(this.controller, REST_OPERATION.MAIN_CONTACT,
            REST_METHODS.PUT, nullJsonObjectConverter, success, null,
            new SetMainContact(customerId, userId))
    }

    createDto(): ICustomerDto {
        return new CustomerDto()
    }

    protected getUserGlobalState(): boolean {
        return true;
    }

    convert(source: ICustomerDto): ICustomerDto {
        if (source) {
            if (source.mainContactPerson) {
                source.mainContactPerson = userDataProvider.convert(
                    source.mainContactPerson)
            }
            source.contactPersons = arrayConvert(userDataProvider,
                source.contactPersons)
        }
        return super.convert(source)
    }

    getShortName(dto: ICustomerDto | null): string {
        if (!dto) {
            return '???'
        }
        if (!dto.name) {
            if (dto.customerType === CustomerType.PERSON.toString()) {
                return userDataProvider.getShortName(dto.mainContactPerson)
            }
        }
        return super.getShortName(dto)
    }

    getFullName(dto: ICustomerDto | null): string {
        if (!dto) {
            return '???'
        }
        if (!dto.name) {
            if (dto.customerType === CustomerType.PERSON.toString()) {
                return userDataProvider.getFullName(dto.mainContactPerson)
            }
        }
        return super.getFullName(dto)
    }
}

export const customerDataProvider = new CustomerDataProvider()
globalstate.customerDataProvider = customerDataProvider

class SetMainContact {
    parentId: number
    userId: number

    constructor(parentId: number, userId: number) {
        this.parentId = parentId
        this.userId = userId
    }
}

export abstract class AbstractCustomerChildDataProvider<CHILD_DTO extends IAbstractWithIdAndNameDto> extends AbtractDataProviderWithName<CHILD_DTO> {
    parentId: number

    constructor(controller: REST_CONTROLLER, parentId: number) {
        super(controller)
        this.parentId = parentId
    }

    protected getUserGlobalState(): boolean {
        return false;
    }

    public delete(childId: number,
                  success: ICallback<string, void> | null = null) {
        executeRestCall(this.controller, REST_OPERATION.DELETE,
            REST_METHODS.DELETE, nullJsonObjectConverter, success, null, null,
            new ParentChildIdContainer(this.parentId, childId))
    }

    public saveNew(childDto: CHILD_DTO,
                   success: ICallback<number, void> | null = null) {
        executeRestCall(this.controller, REST_OPERATION.SAVE, REST_METHODS.POST,
            nullJsonObjectConverter, success, null, childDto,
            new ParentChildIdContainer(this.parentId, childDto.id))
    }

    public saveEdit(childDto: CHILD_DTO,
                    success: ICallback<number, void> | null = null) {
        executeRestCall(this.controller, REST_OPERATION.SAVE, REST_METHODS.PUT,
            nullJsonObjectConverter, success, null, childDto,
            new ParentChildIdContainer(this.parentId, childDto.id))
    }

    public loadListAllManaged(success: ICallback<CHILD_DTO[], void>,
                              compact: boolean | null = null,
                              compact2: boolean | null = null) {
        executeRestCallJson(this.controller, REST_OPERATION.ALL,
            REST_METHODS.GET, nullJsonObjectConverter, success, null, null, {
                parentId: this.parentId, compact, compact2
            })
    }

    public load(id: number, success: ICallback<CHILD_DTO, void>) {
        executeRestCallJson(this.controller, REST_OPERATION.GET,
            REST_METHODS.GET, nullJsonObjectConverter, success, null, null,
            new ParentChildIdContainer(this.parentId, id))
    }
}

export class CustomerContactPersonDataProvider extends AbstractCustomerChildDataProvider<IUserDto> {
    constructor(parentId: number) {
        super(REST_CONTROLLER.CUSTOMER_CONTACT_PERSON, parentId)
    }

    createDto(): IUserDto {
        return new UserDto()
    }

    protected getUserGlobalState(): boolean {
        return false;
    }
}

export class CustomerBuildingDataProvider extends AbstractCustomerChildDataProvider<IBuildingDto> {
    constructor(parentId: number) {
        super(REST_CONTROLLER.CUSTOMER_BUILDING, parentId)
    }

    createDto(): IBuildingDto {
        return new BuildingDto()
    }
}
