import {AbstractDto, IAbstractDto} from './model_base'
import {IJsonToObjectConverter} from '../tools/rest'
import {newDateOrNull} from '../tools/tools'
import {IUserDto} from "./user";

export interface IActiveUserJobDto extends IAbstractDto {
    activeJobId: number
    userId: number

    startTime: Date | null
    endTime: Date | null

    awayHours: number
    wayBackHours: number

    firstname: string | null
    lastname: string | null
}

export const activeUserJobConverter: IJsonToObjectConverter<IActiveUserJobDto> = {
    convert(source: IActiveUserJobDto | null): IActiveUserJobDto | null {
        if (source) {
            source.startTime = newDateOrNull(source.startTime)
            source.endTime = newDateOrNull(source.endTime)
        }
        return source
    }
}

export const getShortNameFromWorker = (ajJ: IActiveUserJobDto | IUserDto | null) => {
    if (!ajJ) {
        return ''
    }

    let result = ''
    if (ajJ.lastname) {
        result += ajJ.lastname
    }
    if (ajJ.lastname && ajJ.firstname) {
        result += ', '
    }
    if (ajJ.firstname) {
        result += ajJ.firstname
    }
    return result
}

export class ActiveUserJobDto extends AbstractDto implements IActiveUserJobDto {
    activeJobId: number = -1
    userId: number = -1

    startTime: Date | null = null
    endTime: Date | null = null

    awayHours: number = 0
    wayBackHours: number = 0
    firstname: string | null = null
    lastname: string | null = null
}
