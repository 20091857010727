import {ChangePasswordOwnRequestDto, IUserDto, UserDataProvider, userDataProvider} from '../../model/user'
import {AbstractSettingsPage} from './abstract_settings_page'
import {toStringOrEmpty} from '../../tools/tools'
import {popupService} from '../../tools/popups'
import {logout} from '../../tools/rest'

export class PasswortAendernPage extends AbstractSettingsPage<IUserDto, UserDataProvider> {
	constructor() {
		super('settings-password-change', userDataProvider)
	}

	public customFill(target: JQuery, loadedUser: IUserDto) {
		//custom id
		this.getHtml('custom-id').val(toStringOrEmpty(loadedUser.customId))
		//name
		this.getHtml('name').val(toStringOrEmpty(loadedUser.name))
	}

	protected save() {
		let oldPassword = toStringOrEmpty(this.getHtml('old-password').val())
		let newPassword1 = toStringOrEmpty(this.getHtml('new-password-1').val())
		let newPassword2 = toStringOrEmpty(this.getHtml('new-password-2').val())
		let dto = new ChangePasswordOwnRequestDto(oldPassword, newPassword1, newPassword2)
		this.dataProvider.changePassword(dto, message => {
			logout(() => {
				popupService.info(message)
			})
		})
	}
}

export const passwortAendernPage = new PasswortAendernPage()
