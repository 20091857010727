import {AbstractDto, AbtractDataProvider} from '../../model/model_base'
import {cloneWithJson, ICallback, nN} from '../../tools/tools'
import {gt} from '../../tools/translation'
import {AbstractModal} from './abstract_modal'
import {setDialogOpen} from '../page_manager'
import {CONSOLE_DEBUG} from '../../consties'
import {activeJobDataProvider} from "../../model/active_job";

export abstract class AbstractEditModal<DTO extends AbstractDto, DATA_PROVIDER extends AbtractDataProvider<DTO>> extends AbstractModal {
    dto: DTO | null = null
    checkedFieldsHtmlIds: string[]
    selectedFieldsHtmlIds: string[]
    dataProvider: DATA_PROVIDER
    modalTitleHtmlId: string
    titleEditTextId: string
    titleNewTextId: string
    editState: boolean
    isEdit: boolean
    hasChanged: boolean

    constructor(dataProvider: DATA_PROVIDER, modalHtmlId: string,
                checkedFieldsHtmlIds: string[] = [],
                selectedFieldsHtmlIds: string[] = []) {
        super(modalHtmlId)

        this.titleEditTextId = modalHtmlId + '.title.edit'
        this.titleNewTextId = modalHtmlId + '.title.new'
        this.dataProvider = dataProvider
        this.modalTitleHtmlId = modalHtmlId + '-title'
        this.checkedFieldsHtmlIds = checkedFieldsHtmlIds
        this.selectedFieldsHtmlIds = selectedFieldsHtmlIds
        this.editState = false
        this.isEdit = true
        this.hasChanged = false
    }


    protected reload(success: ICallback<DTO | null, void> | null = null) {
        if (!this.dto || !this.dto.id) {
            if (success) success(null)
            return
        }

        this.openDialogForEdit(this.dto, success)
    }

    protected getDtoOrCreate(): DTO {
        if (!this.dto) {
            this.dto = this.createNewDto()
        }
        return this.dto
    }

    public clearDialog() {
        this.dto = null
        //input ... not radio
        $('#' + this.modalHtmlId + ' :input')
            .not(':input[type=radio]')
            .val('')

        //selected
        this.selectedFieldsHtmlIds.forEach(
            selectedFieldsHtmlId => $('#' + selectedFieldsHtmlId + ' option')
                .prop('selected', false))

        //checked
        this.checkedFieldsHtmlIds.forEach(
            checkedFieldsHtmlId => $('#' + checkedFieldsHtmlId)
                .prop('checked', false))

        this.clearCustom()
    }

    protected clearCustom(): void {
    }

    protected abstract createNewDto(): DTO

    protected collectDataToDto(dto: DTO): void {
    }

    protected validateBeforeSave(dto: DTO): boolean {
        return true
    }

    protected fillFromDto(dto: DTO, isCopyOrder?: boolean): void {
    }

    public openDialogForEdit(dto: DTO | null | undefined | any,
                             success: ICallback<DTO | null, void> | null = null) {
        this.hasChanged = false
        this.clearCustom()
        document.dispatchEvent(new Event('handlePageLoaded'))
        setDialogOpen()
        if (!dto || !dto.id) {
            throw Error('Error')
        }

        let loaded_dto = this.dataProvider.convert(
            cloneWithJson(this.dataProvider.getFromAllCachs(dto.id)!));
        this.clearDialog()
        this.setEditState(true)
        this.fillDefaults(() => {
            this.dto = loaded_dto

            this.fillFromDto(loaded_dto!)

            this.setTitle(gt(this.titleEditTextId))
            this.showDialog()

            if (success) success(dto)
        })
    }

    public saveAsNewToServer(callback: ICallback<number, void> | null = null,
                             hideDialog: boolean = true) {
        this.hasChanged = true
        if (this.dto === null) {
            this.dto = this.createNewDto()
        }

        this.collectDataToDto(this.dto)
        this.dto = this.cleanDataBeforeSave(this.dto)

        if (!this.validateBeforeSave(this.dto)) {
            return
        }

        const savedCallBack = (id: number) => {
            activeJobDataProvider.refreshCache(true)
            if (this.dto) {
                this.dto.id = id
                this.postSaveToServer(this.dto)
            }
            if (hideDialog) {
                this.hideDialog()
            }
            nN(callback, id)
        }

        this.dto.id = null
        this.dto.customId = null

        this.dataProvider.saveNew(this.dto, savedCallBack)
    }

    public saveToServer(callback: ICallback<number, void> | null = null,
                        hideDialog: boolean = true) {
        this.hasChanged = true
        if (this.dto === null) {
            this.dto = this.createNewDto()
        }

        this.collectDataToDto(this.dto)
        this.dto = this.cleanDataBeforeSave(this.dto)

        if (!this.validateBeforeSave(this.dto)) {
            return
        }

        if (CONSOLE_DEBUG) console.log(this.dto)
        const savedCallBack = (id: number) => {
            activeJobDataProvider.refreshCache(true)
            if (this.dto) {
                this.dto.id = id
                this.postSaveToServer(this.dto)
            }
            if (hideDialog) {
                this.hideDialog()
            }
            nN(callback, id)
        }
        if (!this.isEdit) {
            this.dataProvider.saveNew({...this.dto, id: null}, savedCallBack)
        } else if (this.dto.id !== null && this.dto.id > -1) {
            this.dataProvider.saveEdit(this.dto, savedCallBack)
        } else {
            this.dataProvider.saveNew(this.dto, savedCallBack)
        }
    }

    protected setTitle(title: string) {
        if (CONSOLE_DEBUG) console.log('title', this.modalTitleHtmlId)
        $('#' + this.modalTitleHtmlId).text(title)
    }

    protected setEditState(_state: boolean) {
        $('#' + this.modalHtmlId).attr('data-edit', `${_state}`)
        this.editState = _state
        console.log(`Edit State: ${this.editState}`)
    }


    protected fillDefaults(callback: Function, isEdit?: boolean): void {
        nN(callback)
    }

    protected postSaveToServer(dto: DTO): void {
    }

    protected cleanDataBeforeSave(dto: DTO): DTO {
        return dto
    }
}
