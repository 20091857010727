import {ICustomerDto} from '../model/customer'
import {IUserDto} from '../model/user'
import {actionsInternal} from '../pages/page_manager'

export class TableHelper {
	protected static searchValue: string

	protected static filterCustomerDtos(): Array<ICustomerDto> {
		const _v = TableHelper.searchValue

		const _transformString = (str: string | null | undefined) => (str ? str.replace(/\s/g, '').toLowerCase() : '')
		const _testValue = (str: string | null | undefined, search: string): boolean =>
			_transformString(str).includes(_transformString(search))
		const _filter = (_c: ICustomerDto) =>
			_testValue(_c.customId, _v) ||
			_testValue(_c.name, _v) ||
			_testValue(_c.mainContactPerson?.firstname, _v) ||
			_testValue(_c.mainContactPerson?.lastname, _v) ||
			_testValue(_c.address?.city, _v) ||
			_testValue(_c.address?.country, _v) ||
			_testValue(_c.address?.email, _v) ||
			_testValue(_c.address?.phone, _v) ||
			_testValue(_c.address?.phone2, _v) ||
			_testValue(_c.address?.streetAndNr, _v) ||
			_testValue(_c.address?.zipCode, _v) ||
			_testValue(_c.mainContactPerson?.address?.city, _v) ||
			_testValue(_c.mainContactPerson?.address?.email, _v) ||
			_testValue(_c.mainContactPerson?.address?.phone, _v) ||
			_testValue(_c.mainContactPerson?.address?.phone2, _v) ||
			_testValue(_c.mainContactPerson?.address?.streetAndNr, _v) ||
			_testValue(_c.mainContactPerson?.address?.zipCode, _v)

		if (!_v) {
			document.dispatchEvent(new Event('searchEvent'))
		}

		return _v
			? actionsInternal.auftraggeberListPage.dtos.filter((customer: ICustomerDto) => _filter(customer))
			: actionsInternal.auftraggeberListPage.dtos
	}

	protected static filterWorkerDtos(): Array<IUserDto> {
		const _v = TableHelper.searchValue

		const _transformString = (str: string | null | undefined) => (str ? str.replace(/\s/g, '').toLowerCase() : '')
		const _testValue = (str: string | null | undefined, search: string): boolean =>
			_transformString(str).includes(_transformString(search))
		const _filterWorker = (_c: IUserDto) =>
			_testValue(_c.customId, _v) ||
			_testValue(_c.name, _v) ||
			_testValue(_c.firstname, _v) ||
			_testValue(_c.lastname, _v) ||
			_testValue(_c.address?.email, _v) ||
			_testValue(_c.address?.phone, _v)

		if (!_v) {
			document.dispatchEvent(new Event('searchEvent'))
		}

		return _v
			? actionsInternal.mitarbeiterListPage.dtos.filter((worker: IUserDto) => _filterWorker(worker))
			: actionsInternal.mitarbeiterListPage.dtos
	}

	protected static searchAction() {
		if (actionsInternal.auftraggeberListPage.dtos) {
			const _dtos = TableHelper.filterCustomerDtos()
			actionsInternal.auftraggeberListPage.updateTable(_dtos)
		}
	}

	protected static searchActionWorker() {
		if (actionsInternal.mitarbeiterListPage.dtos) {
			const _dtos = TableHelper.filterWorkerDtos()
			actionsInternal.mitarbeiterListPage.updateTable(_dtos)
		}
	}

	public static onSearchChange(selector: string, isWorker = false) {
		const _field = document.querySelector(selector.trim())
		const _input = _field && _field.tagName.toLowerCase() === 'input' ? (_field as HTMLInputElement) : null

		if (_input) {
			TableHelper.searchValue = _input.value.trim().toLowerCase()

			document.addEventListener(
				'updatePage',
				() => {
					_input.value = ''
				},
				{once: true, passive: true}
			)

			if (isWorker) {
				TableHelper.searchActionWorker()
			} else {
				TableHelper.searchAction()
			}
		}
	}
}
